@mixin mainFont($p) {
    @if ($p == "bundle1"){
    font-family: 'Domine', sans-serif;
    
    }@else if ($p == "bundle2") {
    font-family: 'Quattrocento Sans', sans-serif;
    
    }@else if ($p == "bundle3") {
    font-family: 'Asap', sans-serif;

    }@else if ($p == "bundle4") {
    font-family: 'Rubik', sans-serif;

    }@else if ($p == "bundle5") {
    font-family: 'Maven Pro', sans-serif;

    }@else if ($p == "bundle6") {
    font-family: 'Gentium Basic', serif;
    }
}
@mixin secondFont($p) {
    @if ($p == "bundle1"){
    font-family: 'Open Sans', sans-serif;
    
    }@else if ($p == "bundle2") {
    font-family: 'Raleway', sans-serif;
    
    }@else if ($p == "bundle3") {
    font-family: 'Signika', sans-serif;

    }@else if ($p == "bundle4") {
    font-family: 'Exo', sans-serif;

    }@else if ($p == "bundle5") {
    font-family: 'Maven Pro', sans-serif;

    }@else if ($p == "bundle6") {
    font-family: 'Karla', sans-serif;
    }
}