.promo-banner
{
    height:100vh;
    max-height:700px;
    position: relative;
    overflow: hidden;

    .container-grid
    {
        max-width:1440px;
        display: flex;
        // flex-direction: column;
        align-items: center;
        height:100%;
    }

    &__img
    {
        position: absolute;
        top:-12.5%;
        left:-12.5%;
        // right:0;
        // bottom:0;
        width:125%;
        height:125%;

        img
        {
            display: block;
            object-fit:cover;
            font-family: 'object-fit: cover;';
            width:100%;
            height:100%;
        }
    }

    .list
    {
        &__item
        {
            // margin-right:100px;
            margin:0;
            padding-bottom:0;
        }

        &__content
        {
            margin-left:0;
            margin-bottom:0;
            align-self:auto;
        }
    }


    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        .container-grid
        {
            padding-left:60px;
            padding-right:60px;
        }
        .list
        {
            &__item
            {
                margin-right:0;
            }

            &__content
            {
                margin:0 !important;
            }
        }

        &__img
        {
            top:0;
            left:0;
            width:100%;
            height:100%;
            transform: none !important;
        }
    }
    
    @media (max-width: #{breakpoint(tb) - 1}px)
    {
        max-height:480px;
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        .container-grid
        {
            padding-left:30px;
            padding-right:30px;
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}