

/* COLORS
=================================================================== */
    /* $mainColor
    =================================================================== */
    .intro .txt h1,
    {
        color: $mainColor;
    }
    /* $secondColor
    =================================================================== */
    /* $thirdColor
    =================================================================== */
    .list__type,
    .has-hover .instagram__link:hover,
    .has-hover .instagram__link:focus,
    {
        color: $thirdColor
    }


/* BACKGROUND-COLORS
=================================================================== */
    /* $mainColor
    =================================================================== */
    /* $secondColor
    =================================================================== */
    .menuTemplate .banner.noBg,
    .dinningTemplate .banner.noBg,
    .greenTemplate .banner,
    .noFeatured .banner,
    .intro,
    {
        background-color: $secondColor;
    }
    /* $thirdColor
    =================================================================== */
    footer,
    header .reservations a,
    header .trigger,
    header .navigation,
    .list__button,
    // .has-hover .block__item:hover .block__txt,
    // .has-hover .block__item:focus .block__txt,
    .has-hover .block__item:hover .block__bg,
    .has-hover .block__item:focus .block__bg,
    {
        background-color: $thirdColor;    
    }
        /* $thirdColor + 20% black
        =================================================================== */
        footer .colophon,
        .has-hover header .reservations a:hover,
        .has-hover header .reservations a:focus,
        .locations_cards .restaurant,
        header .navigation__wrapper .btn.btn,
        .list__content:hover .list__button,
        .list__content:focus .list__button,
        // .list__item > a:hover .list__button,
        // .list__item > a:focus .list__button,
        .list__load:hover,
        .list__load:focus,

        {
            background-color: mix( $thirdColor, #000, 80% );
        }
        /* $thirdColor + 40% black
        =================================================================== */
        .has-hover .locations_cards .restaurant:hover,
        .has-hover header .navigation__wrapper .btn.btn:hover,
        .has-hover header .navigation__wrapper .btn.btn:focus,
        .has-hover header .trigger:hover,
        .has-hover.menu-open header .trigger,
        .has-hover header .trigger:focus,
        // .has-hover .locations_cards .restaurant:focus .location,
        {
            background-color: mix( $thirdColor, #000, 60% );
        }
        /* $thirdColor + opacity
        =================================================================== */
        header .overlay,
        {
            background-color: rgba( $thirdColor, .6 );
        }
