.intro,
div.title,
{
    height:$introHeight;

    .txt
    {
        display: flex;
        position: relative;
        width:100%;
        z-index: 10;
        left:0;
        height:100%;
        align-items:center;
        justify-content: center;
        flex-direction:column;


        h1
        {
            width:100%;
            text-align:center;
            letter-spacing:2px; 
            margin:0 30px;
            font-size: 6rem;
            //text-shadow:5px 5px 5px rgba(0,0,0,0.5);

            .locationTemplate &
            {
                margin-bottom:10px;
            }
            .socialTemplate &
            {
                margin-bottom:15px;
            }
            .greenDetailsTemplate &,
            .friendsTemplate &,
            .productsTemplate &,
            .contactTemplate &,
            .pageErrorTemplate &,
            .newsDetailsTemplate &,
            {
                margin: 0 0 10px;
            }
            .friendsTemplate &,
            .productsTemplate &,
            .contactTemplate &,
            {
                padding:0 30px;
            }

            span
            {
                padding: 10px 20px;
            }

        }

        img
        {
            margin:0 auto 15px;
            display: block;
        }

        span.devider,
        {
            display: none;
            width:30px;
            height:2px;
            margin:0 auto 13px;
        }


        p
        {
            display: none;
            // color:#f6f6f6;
            text-align:center;
            margin:17px 0 0 0;

            .bundle4 & {
                font-weight: 500;
            }

            .socialTemplate &
            {
                line-height:26px; 
                padding:0px 30px 0 30px; 
                max-width:410px; 
                margin:0 auto;
            }

            .friendsTemplate &,
            .productsTemplate &,
            .faqTemplate &,
            .contactTemplate &,
            .pageErrorTemplate &,
            {
                // margin:17px auto 0;
                margin:4px auto 0;
                max-width:740px;
            }

            .friendsTemplate &,
            .productsTemplate &,
            .contactTemplate &,
            {
                padding: 0px 30px;
            }
        }
    }

    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }

    @media (max-width: #{breakpoint(lt)}px)
    {
    }

    @media (max-width: #{breakpoint(btb)}px)
    {
    }

    @media (max-width: #{breakpoint(tb)}px)
    {
    }

    @media (max-width: #{breakpoint(bmb)}px)
    {
        // height:$introHeightSmall;
        padding-top:60px;
    }

    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}

