.banner
{
    .txt
    {
        display: flex;
        position: relative;
        width:100%;
        z-index: 10;
        left:0;
        height:100%;
        align-items:center;
        justify-content: center;

        .greenTemplate &,
        .dinningTemplate &,
        {
            padding:60px 40px;
            flex-direction:column;
        }

        h1,
        .menuTemplate .foodCateg & h2,
        .greenTemplate & h2,
        {
            width:100%;
            text-align:center;
            letter-spacing:2px; 
            margin:0 30px;

            span
            {
                padding: 10px 20px;
            }
        }
        
        .greenTemplate &,
        .dinningTemplate &,
        {
            h1,
            {
                margin-bottom:10px;
            }
            h2
            {
                margin:0 0 10px 0;
            }
        }

        h2
        {

        }

        p
        {
            // color:#f6f6f6;
            text-align:center;
            margin:17px 0 0 0;

            .dinningTemplate &,
            {
                padding:0px 30px;
            }

            .greenTemplate &
            {
                margin:17px auto 0;
                max-width:740px;
                padding: 0 20px;
            }

            .menuTemplate &,
            .defaultTemplate &,
            {
                display: none;
            }

            a
            {

            }
        }

        span.devider,
        {
            display: block;
            width:30px;
            height:2px;
            margin:0 auto;

            .greenTemplate &,
            {
                margin-bottom:15px;
            }

            .defaultTemplate &,
            {
                display: none;
            }
        }
    }

    &#{&}#{&},
    {
        p
        {
            .bundle4 & {
                font-weight: 500;
            }
            letter-spacing: 1.8px;
        }
    }

    .menuTemplate &,
    .dinningTemplate &,
    .defaultTemplate &,
    .greenTemplate &,
    {
        width:100%;
        position:relative;
        overflow:hidden;
        height:$introHeight;


        &.parallax{
            background-attachment: fixed;

            .mobileOnly &,
            .ie &
            {
                background-attachment: scroll;
                background-position: center center;
            }

            background-position: 50% 0;
        }
    }

    .greenTemplate &
    {
        height:auto;
        background-image:none !important;
    }

    .iPad .menuTemplate &,
    .iPad .dinningTemplate &,
    {
        background-position:center center !important;
        background-size:cover !important;
        height:auto;

        .txt{
            position: relative;
            padding:125px 0;
            transform:none;

            h1{
                max-width: 700px;
                margin: 0 auto;
            }
        }

    }


    .dinningTemplate &,
    .defaultTemplate &
    {
        &:before
        {
            content:"";
            width:100%;
            height:100%;
            position: absolute;
            top:0;
            left:0;
        }
    }
    .dinningTemplate &,
    {
        &::before
        {            
            background:url(../img/layout/FiltreLarge.png) center center no-repeat;
            opacity:0.5;
        }
    }

    .menuTemplate .foodCateg &,
    {
        .bg
        {
            position: absolute;
            height: 140%;
            width: 100%;
            background-size:100%;
        }
    }






    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(btb)}px)
    {
    }
    
    @media (max-width: #{breakpoint(tb)}px)
    {
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        .menuTemplate &,
        .dinningTemplate &,
        .defaultTemplate &,
        .greenTemplate &,
        {
            padding-top:60px;
            // height: $introHeightSmall;
        }
        
        .menuTemplate &,
        .dinningTemplate &,
        {
            // height:auto;
            background-size:cover !important;
            background-attachment:scroll !important;

            // div.txt
            // {
            //     position: relative;
            //     padding:50px 0;
            //     transform:none;
            // }
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}