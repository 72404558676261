.restaurant
{
    padding:80px 0;

    .container-grid
    {
        max-width:1440px;
    }

    .list
    {
        &__item
        {
            margin-bottom: 0;
            padding-bottom:0;
        }
        &__content
        {
            background-color: transparent;
            padding:0;
            margin:0 0 0 100px;
            // max-width:none;
        }

        &__img
        {
            max-height:none;
            width:auto;
            position: relative;
            z-index: 1;
            margin-top: -110px;
            margin-bottom: -110px;

            img
            {
                transform:none !important;
            }
        }
    }

    + .news__list
    {
        .list__item
        {
            &:nth-child(odd)
            {
                margin-right:0;
                margin-left:100px;
                .list__img
                {
                    order:2;
                }

                .list__content
                {
                    margin-left:0;
                    // margin-right:-7.5%;
                    margin-right:-17.6%;
                    order:1
                }
            }
            &:nth-child(even)
            {
                margin-right:100px;
                margin-left:0;
                .list__img
                {
                    order:1;
                }

                .list__content
                {
                    margin-left:-17.6%;
                    // margin-right:-7.5%;
                    margin-right:0;
                    order:2
                }
            }
        }
    }


    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        padding:60px 0;
        .container-grid
        {
            padding-left:60px;
            padding-right:60px;
        }

        + .news__list
        {
            .list__item
            {
                &:nth-child(odd),
                &:nth-child(even),
                {
                    margin-left:0;
                    margin-right:0;
                }
            }
        }

        .list
        {
            &__content
            {
                margin-bottom:60px;
                margin-top:30px !important;
            }
            &__img
            {
                // margin-top:-90px;
                // margin-bottom:-90px;
                margin:0;
                max-height:480px;
                transform:none !important;
                
            }
        }
    }
    
    @media (max-width: #{breakpoint(tb)}px)
    {
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        padding:30px 0;
        .container-grid
        {
            padding-left:30px;
            padding-right:30px;
        }

        .list
        {
            &__content
            {
                margin-bottom:30px;
            }
            &__img
            {
                // margin:0;
            }
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}