.navigation
{
    position: fixed;
    left:0;
    top:0;
    // width:100%;
    z-index:1;
    max-width:50%;
    height:100vh;
    min-width:320px;
    // padding:0 100px;
        // overflow:auto;

    .admin-bar & { top:32px }

    transform: translateX(-100%);
    padding: 120px 0 0px;


    &__wrapper
    {
        // padding:35px 100px 35px;
        // opacity:0;
        // transition: opacity $duration ease .1s;

        width:100%;
        height:100%;
        display: flex;

        flex-direction: column;
        justify-content: center;
        // align-items:center;
        // padding: 120px 0 60px;
        // margin:120px 0;
        

        // height:auto !important;
        
    }


    .locations_cards
    {
        // margin:80px 100px 0;
        margin:40px 30px 0 100px ;
        font-size:0;
        position: relative;
        
        transform: translateX( calc(-100% - 100px) );


        .restaurant
        {
            position: relative;
            cursor:pointer;
            display: inline-block;
            vertical-align: middle;
            transition:background-color $duration ease;
            padding:0 0 0 20px;
            font-size:0;
            text-align:center;
            margin-right:12px;
            margin-bottom:14px;


            .icon
            {
                fill: currentColor;
                width:21px;
                height:21px;
                display: inline-block;
                vertical-align: middle
            }
        }

        .location
        {
            @extend %reset-button;
            border-radius: 0;
            position: relative;
            appearance: none;
            cursor:pointer;
            padding-left:12px;
            vertical-align:middle;
            color:currentColor;


            line-height:50px;
            letter-spacing: tracking(100);

        }
    }


    &__nav
    {
        @extend %reset-ul-ol;
        // margin-right:130px;
        margin-left:100px;
        margin-right:90px;

        li
        {
            // display: block;

            line-height: 1;
            padding: 16px 0;
            position: relative;
            // transform: translateX(-100%);
            transform: translateX( calc(-100% - 100px) );

            &:first-child
            {
                padding-top:0;
            }
            &:last-child
            {
                padding-bottom:0;
            }
        }

        a
        {
            display: block;
            // color:currentColor;
            transition: color 1s ease;
            position: relative;

            &::before
            {
                content:'';
                position: absolute;
                top:50%;
                left:-100px;
                width:0;
                height:2px;
                transform: translate( 0, -50%);

                background-color:$backgroundColor;
                transition: width $duration cubic-bezier(0.445, 0.05, 0.55, 0.95);
            }

            .has-hover &:hover:before,
            .has-hover &:focus:before,
            {
                width:80px;
            }
        }
    }



    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }

    @media (max-width: 1024px)
    {
        .locations_cards
        {
            margin:30px 60px 0 100px;
        }
    }

    @media (max-height: 700px)
    {
        &__nav
        {
            li
            {
                padding:10px 0;
            }
        }
    }
    
    @media (max-width: #{breakpoint(btb)}px)
    {

        width: 100%;
        padding:70px 0 0;

        &__wrapper
        {
        }
        &__nav
        {
            margin:0 30px 0 60px;

            li
            {
                padding:10px 0;
                transform: translateX( calc( -100% - 60px ) );
            }

            a
            {
                .has-hover &:hover:before,
                {
                    width:45px;
                }
            }
        }

        .locations_cards
        {
            margin:30px 60px 0;
                transform: translateX( calc( -100% - 60px ) );


            .restaurant,
            .btn.btn.btn,
            {
                display: block;
                margin-right:0;
                width:100%;
                max-width:220px;
            }
        }
    }
    
    @media (max-width: #{breakpoint(tb) - 1}px)
    {
        max-width:none;
        // width: 100%;
    }
    
    @media (max-width: #{breakpoint(bmb) - 1}px)
    {
            // padding:70px 0 0;
        &__wrapper
        {
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }

}