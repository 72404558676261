.list
{
    &__item
    {
        position: relative;
        // display: block;
        display: flex;
        margin-bottom:100px;
        // padding-bottom:50px;
        // margin-right:100px;
        align-items:flex-start;
    }

    &__content
    {
        display: flex;
        flex-direction: column;
        position: relative;
        padding:60px 120px 60px 100px;
        z-index:2;

        // margin-left:calc(-6.5% - 50px);
        // margin-left:-7.5%;
        // margin-left:-17.6%
        margin-left:-200px;
        margin-bottom:50px;

        // max-height:200px;
        // overflow:hidden;

        // width:calc( 100% - 100px - 100px );
        width:100%;
        // max-width:49.5%;
        // max-width:50%;
        // max-width:54.4%;
        max-width:620px;
        // margin-bottom:50px;

        // margin-top:100px;
        // align-self:stretch;
        align-self:flex-end;
        // justify-content:center;

        // order:-1;
    }

    &__load
    {
        margin-bottom:100px;
        display: block;
        text-align: center;

        letter-spacing: tracking(100);
        line-height:50px;


        transition: color $duration ease, background-color $duration ease;
    }

    &__type,
    &__title,
    {
        @extend %reset-pm;
    }

    &__type
    {
        order:-1;
    }

    &__title,
    {
        line-height:1.25em;
        margin:20px 0;
    }

    &__excerpt,
    {
        line-height: 1.55em;
    }


    &__img > a,
    &__content,
    {
       
    }

    &__img
    {
        position: relative;
        width:calc( 100% - 0px - 100px );
        // max-width:51.5%;
        // max-width:58%;
        overflow:hidden;
        opacity:.99;
        backface-visibility:hidden;
        z-index:1;

        max-height:480px;

        > a
        {
            display: block;
             position: relative;
        z-index:1;
        }

        // margin-bottom:50px;

        // will-change: transform;


            // transition: transform $duration cubic-bezier(0.445, 0.05, 0.55, 0.95);


        &::before
        {
            content:'';
            top:0;
            bottom:0;
            left:0;
            right:0;
            position: absolute;
            background-color: rgba(black, .1);
            z-index:1;
        }
        img
        {
            display: block;
            position: relative;
            // top:0;
            // left:0;
            width:100%;
            height:100%;

            max-width:none;
            // height:auto;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            // backface-visibility:hidden;
            will-change: transform;

            transition: transform $duration+1s cubic-bezier(0.19, 1, 0.22, 1);

            // .has-hover .list__item:hover &,
            // .has-hover .list__item:focus &,
            // .has-hover .list__item > a:hover &,
            // .has-hover .list__item > a:focus &,
            // {
            //     transform: translate3d(0,0,0) scale(1.02, 1.02);
            // }
        }

        .has-hover & > a:hover > img,
        .has-hover & > a:focus > img,
        {
            transform: translate3d(0,0,0) scale(1.02, 1.02);
        }
    }

    &__button
    {
        margin-top:20px;
        font-size:0;
        padding:0 30px;
        display: inline-block;
        white-space: nowrap;
        align-self:flex-start;

        transition:background-color $duration ease;
        will-change:width;

        line-height:40px;

        span
        {
            // margin-left:-15px;
            letter-spacing: tracking(100);
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
        }

        &::before
        {
            content:'';
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width:0;
            height:2px;
            // margin:0 0 0 30px;
            will-change: width, margin-right;

            background-color: currentColor;
            transition: width $duration+.5s cubic-bezier(0.19, 1, 0.22, 1), margin-right $duration+.5s cubic-bezier(0.19, 1, 0.22, 1);
        }

        // .has-hover .list__item:hover &::before,
        // .has-hover .list__item:focus &::before,
        // .has-hover .list__item > a:hover &::before,
        // .has-hover .list__item > a:focus &::before,

         .has-hover .list__content:hover &::before,
        .has-hover .list__content:focus &::before,
        {
            width:40px;
            margin-right:20px;
            // margin-left:-15px
        }
    }


    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        &__item
        {
            margin-bottom: 80px;

            display: block;
            padding-bottom:0;
        }
        &__content
        {
            padding: 40px 60px;
            width:auto;
            max-width:none;
            margin:-100px 60px 0 !important;
        }
        &__img
        {
            max-width:none;
            width:100%;
        }

        &__load
        {
            margin-bottom:80px;
        }


    }
    
    @media (max-width: #{breakpoint(tb)}px)
    {
        &__title
        {
            margin:12px 0;
        }

        &__button
        {
            margin-top:8px;
        }
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        &__img
        {
            max-height:350px;

            img
            {
                width:100% !important;
                height:100% !important;
            }
        }
        &__item
        {
            margin-bottom:40px;
        }
        &__content
        {
            padding: 30px 30px;
            margin:-100px 15px 0 !important;
            // width:calc( 100% - 30px - 30px );
        }

        &__load
        {
            margin-bottom:40px;
        }

        &__button
        {
            // align-self:center;
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}