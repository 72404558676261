/*::-moz-selection{background: #fcd700; color: #fff; text-shadow: none;}
::selection {background: #fcd700; color: #fff; text-shadow: none;}*/

/*a:link {-webkit-tap-highlight-color: #fcd700;}*/




/* ==========================================================================
    HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
    ========================================================================== */


html,
button,
input,
select,
textarea {color: #222;}
body {
    line-height: 1.4;
    cursor: default;
    -webkit-text-size-adjust: none;
}
img.inline.inline.inline.inline{ 
    display: inline-block;
    margin:0 25px;
}
img{
    max-width: 100%;
    height: auto;
}
::-webkit-input-placeholder {
   color: rgba(255,255,255,1);
   opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
   color: rgba(255,255,255,1);
   opacity: 1;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: rgba(255,255,255,1);
   opacity: 1;
}

:-ms-input-placeholder {  
   color: rgba(255,255,255,1);
   opacity: 1;
}

input[name=newsletter]::-webkit-input-placeholder {
   color: rgba(0,0,0,0.5);
   opacity: 1;
}
input[name=newsletter]:-moz-placeholder { /* Firefox 18- */
   color: rgba(0,0,0,0.5);
   opacity: 1;
}
input[name=newsletter]::-moz-placeholder {  /* Firefox 19+ */
   color: rgba(0,0,0,0.5);
   opacity: 1;
}
input[name=newsletter]:-ms-input-placeholder {  
   color: rgba(0,0,0,0.5);
   opacity: 1;
}


[class^="icon-"], [class*=" icon-"],
.aa{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::-moz-selection {background: #b3d4fc;text-shadow: none;}
::selection {background: #b3d4fc;text-shadow: none;}
hr {display: block;height: 1px;border: 0;border-top: 1px solid #ccc;margin: 1em 0;padding: 0;}
img {vertical-align: middle;}
fieldset {border: 0;margin: 0;padding: 0;}
textarea {resize: vertical;}
.chromeframe {margin: 0.2em 0;background: #ccc;color: #000;padding: 0.2em 0;}

*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  filter:none !important;
   -webkit-overflow-scrolling: touch
 }
body{-webkit-overflow-scrolling: touch}
 // *{outline:none!important;}

#windowRef{
    position: fixed;
    height:100vh;
    width: 100%;
    top:0;
    left:0;
    z-index: -999;
}

#widgetReservation {
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 800px;
    z-index: 1;
    padding: 100px 0;
}

.btn, .btn-group.open .dropdown-toggle{
    &:active{
        box-shadow: none;
    }
}



/********************************************
 *
 * SCSS
 *  
 *********************************************/
@mixin transitioned($transProperty: color, $transDuration: 0.25s){ 
    -moz-transition-duration: $transDuration; 
    -moz-transition-property: $transProperty; 
    -moz-transition-delay: 0s; 
    -moz-transition-timing-function: linear; 
    -webkit-transition-duration: $transDuration; 
    -webkit-transition-property: $transProperty;
    -webkit-transition-delay: 0s;
    -webkit-transition-timing-function: linear;
    transition-duration: $transDuration; 
    transition-property: $transProperty;
    transition-delay: 0s; 
    transition-timing-function: linear;
}
@mixin rounded-corners($radius: 3px){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius; 
    border-radius: $radius;
}

.fluidImage{max-width: 100%;}
.img100{width: 100%;}

a.skipContent{
    display: block;
    color:#fff;
    width: 100%;
    @include mainFont($package);
    transform: translateY(-100%);
    will-change: transform;
    transition:all 0.5s;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color:#000;
    padding:20px 0; 
    opacity: 0;
    z-index: 1000000;

    &:focus{
        transform:translateY(0);
        opacity: 1;
    }
}


/* ==========================================================================
    General
    ========================================================================== */
    p a{
        color:$thirdColor;
        transition:all 0.25s;
        text-decoration: underline;
        &:hover,
        &:focus{
            color:$secondColor;
            text-decoration: none;
        }
    }
html, body{
    // min-width: 1024px;
}
.container{width:100%; max-width:1920px; padding:0;position: relative;}
// 

.dropdown-menu{
    display:block !important; opacity:1;min-height: 0 !important;max-height:0 !important;overflow:hidden;transition:all 0.5s;
    transform:translateZ(0px);
    -webkit-transform:translateZ(0px);
}
.open>.dropdown-menu{
    min-height: 0 !important;
    opacity:1;
    max-height: 500px !important;
}
.safari .bootstrap-select.btn-group .btn .caret{
    margin-top:-6px;
}

.dropDown_b_b{
    float:left;
    cursor:pointer;
    padding-left: 12px;
    padding-right: 40px;
    overflow: hidden;
    position: relative;
    background:#1f1e1e;
    transition: background 0.3s ease;

    &.locations{
        background:#1f1e1e url(../img/layout/iconPinWhite.png) 14px center no-repeat;
        background-size: 13px 15px;
        padding-right: 63px;
        padding-left: 24px;

        select{
            text-indent: 1.1rem;
        }
    }

    &.locations.white{
        background:#e1dede url(../img/layout/iconPin.png) 15px center no-repeat;
        background-size: 13px 15px;
        transition: background 0.3s ease, opacity 0.3s ease;
        padding-right: 43px;
    }

    &.white{
        background:#e1dede;

        &.giftcard{
            float: right;
            // padding-right: 0;
        }

        &:before{
            color: #000007;
        }

        &:hover, &:focus{
            background-color:#e1dede!important;
            opacity: 0.7;
        }

        select{
            color:#000;
            font-family: 'Asap', sans-serif;
            font-weight: 700;
            font-style: normal;
        }
    }

    &:before{
        position: absolute;
        color:#fff;
        top: 15px;
        right: 16px;
        content: '\e804';
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        height: 12px;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: .2em;
        font-size: 11px;
        font-size: 1.1rem;
    }

    select{
        position: relative;
        @include mainFont($package);
        font-size:13px;
        font-size:1.3rem;
        font-style:italic; 
        font-weight: 400;
        line-height:42px;
        letter-spacing: 1px;
        height: 42px;
        width: calc(100% + 93px);
        background: transparent;
        border: none;
        color:#fff;
        cursor: pointer;
        z-index: 1;
        -webkit-appearance:none;

        option
        {
            color:#000;
        }
    }
}

.btn{
    // background: #E1DEDE;
    background:$thirdColor;
    &:hover,
    &:focus{
        color:white;
        background:darken($thirdColor, 10%)
    }
    // @include mainFont($package);
    font-weight: 700;
    font-size: 14px;
    font-size:1.4rem;
    text-decoration: none;
    // letter-spacing: 1px;
    letter-spacing: tracking(100);
    // color: #101010;
    color:white;
    border-radius: 0px;
    // font-style: italic;
    header & {font-style: normal;}
    padding: 8px 20px 8px 20px;
    -moz-transition-duration: 0.3s;
    -moz-transition-property: all;
    -moz-transition-delay: 0s;
    -moz-transition-timing-function: linear;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-property: all;
    -webkit-transition-delay: 0s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    span{
        vertical-align: middle;
        font-size: 12px;
        font-size:1.2rem;
        position: relative;
        // top: 1px;
        // .safari & {top:0;}
        // .bundle5 & {top:-1px;}
    }


    &.reservations
    {
        margin-bottom: 28px;
    }


}

// .reservation .location{
//  width: 198px !important;
// }

.reservation{
    width:100%; 
    height:auto; 
    background: #000; 
    position:fixed; 
    z-index:1500; 
    bottom:100%; 
    left:0;
    display: none;
    // min-width: 1024px;
}

.reservation .btn{
    font-style: normal;
    &.reserve{
        padding-top: 11px;
        padding-bottom: 10px;
    }
}

.reservation .container{
    margin: 0 auto;
    max-width: 1250px;
}

.reservation > div{position:relative;}
.reservation .close{
    @include mainFont($package); 
    font-weight:500; 
    text-transform:uppercase; 
    font-size:11px; 
    font-size:1.1rem;
    color:#fff; 
    position:absolute; 
    top:60px; 
    right:0px; 
    padding: 20px 40px 20px 20px;
    background: url(../img/layout/iconClose.png) right center no-repeat;
    background-size: 10px 10px;
    background-position: 81% 50%; 
    filter: alpha(opacity=100); 
    opacity:1; 
    letter-spacing:1px;
    z-index: 999;
    transition:all 0.25s;
    &:hover,
    &:focus{
        opacity:0.7;
    }
}
.reservation .txt{
    width: 400px; 
    margin:80px 50px 45px 0px;  
    float: left;
}
.reservation form{
    margin:80px 0px 0px 0px;
    float:right 
}

.reservation .field:first-child .dropdown-toggle{
    background-image: url(../img/layout/iconPinWhite.png);
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 13px 16px;
    padding-left: 41px;
    &.disabled{
        opacity:1;
        background-color:transparent;
        span.caret{display: none;}
    }
    
}
.reservation .field .dropdown-toggle{
    &:hover,&:focus{
        color: white;
        background-color: #bf7617;
    }
}

.reservation .field{

    label{
        display: block;
    }
}

.ui-datepicker{
    background:$secondColor; 
    padding: 10px;
    font-size: 13px;
    font-size:1.3rem;
    text-align: center;
    color: rgba(255,255,255,0.5);
    @include mainFont($package);
    // -webkit-font-smoothing: antialiased;
    margin-top: 2px;
    display: none;
}
.ui-datepicker th{
    text-align: center;
}
.ui-datepicker .ui-state-default{
    color: #fff;
    @include transitioned('color', 0.3s);
}
.ui-datepicker td{
    cursor: pointer;
}
.ui-datepicker td:hover .ui-state-default,
.ui-datepicker td:focus .ui-state-default{
    //removed because of tarrylodgepc wedding css de merde dans le newsletter.
    // color: #fff;
    text-decoration: none;
}
.ui-datepicker::before{
    content: "";
    background: rgba(255,255,255,0.1);
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: -1;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next{
    cursor: pointer;
    text-decoration: none;
    padding: 4px 5px;
    color: #fff;
}

.ui-icon-circle-triangle-e:before,
.ui-icon-circle-triangle-w:before{
    font-size: 11px;
    font-size:1.1rem;
}

.ui-datepicker .ui-datepicker-next:hover,
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:focus,
.ui-datepicker .ui-datepicker-prev:focus{
    background: rgba(255,255,255,0.1);
}
.ui-datepicker .ui-datepicker-prev{
    float: left;
    margin-right: 10px;
}
.ui-datepicker .ui-datepicker-next{
    float: right;
    margin-left: 10px;
}
.ui-datepicker .ui-datepicker-title{
    overflow: hidden;
    text-align: center;
    color: #fff;
    line-height: 23px;
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    font-size:1.4rem;
}
.ui-datepicker-calendar{
    width: 205px;
    margin-top: 5px;
}
.ui-datepicker .ui-datepicker-today a{
    color: #fff !important;
}
.ui-datepicker .ui-state-disabled .ui-state-default{
    color: white;
}
.ui-datepicker .ui-state-disabled{
    opacity: 0.5;
}
.ui-datepicker .ui-state-active{
    background: $thirdColor;
    color: #fff;
    padding:2px 5px;
}

.reservation input[type=text], .iPad .reservation input[type=date]{
    border: none;
    padding:12px 15px;
    min-width: 100px;
    color: white;
    font-size: 13px;
    font-size:1.3rem;
    height: 44px;
    background: rgba(255,255,255,0.1);
    @include mainFont($package); 
}
.iPad .reservation input[type=date]{
    -webkit-appearance:none;
}

.reservation form .field{
    float: left;
    margin-right: 10px;
}
.reservation label{
    color: #f6f6f6;
    font-size: 11px;
    font-size:1.1rem;
    text-transform: uppercase;
    @include mainFont($package); 
    font-weight: normal;
    letter-spacing: 1px;
    margin-top: 1em;
    margin-top: 42px;
    margin-bottom: 10px;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default{
    background: none;
}

// .bootstrap-select .btn:focus{
//  outline: none !important;
// }

.dropdown-menu{
    box-shadow: none;
    border: none;
}

.reservation .reserve{
    float: left;
    margin-top: 67px;
    background: $thirdColor;
    border-radius: 0px;
    color: #fff;
}

.reservation .reserve:hover, .reservation .reserve:focus{
    background: darken($thirdColor, 10%);
}

.reservation .reserve .icon-right-open{
    // font-size: 11px;
    margin-left: 5px;
}

.reservation .txt > div{ font-size:18px; font-size:1.8rem;color:#ffffff; letter-spacing:1px; text-transform:uppercase; font-weight:700;}
.reservation .txt p{
    color:#f6f6f6; 
    // @include mainFont($package); 
    font-size:14px; 
    font-size:1.4rem;
    line-height:20px; 
    margin:0 0 20px 0;  
}
.reservation .txt p:last-child{
    margin: 0px;    
}
.reservation .txt p a{color:#fff!important; text-decoration:underline; @include transitioned('color', 0.3s);}
.reservation .txt p a:hover, .reservation .txt p a:focus{color:#f6f6f6;text-decoration:none; }



.carousel-indicators{
    z-index: 333;
}

/* ==========================================================================
    Home
    ========================================================================== */
.homeTemplate{padding:0;}
.carousel{
    &:before{
        content:'';
        position: absolute;
        width:100%;
        height:100px;
        background:linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 75%, rgba(0,0,0,0.25) 100%);
        
        bottom:0;
        left:0;
        z-index: 40;
    }
}
.homeTemplate.newsFirst .news{
    background:#e1dede;
}
.homeTemplate .carousel-indicators{
    bottom:17px; 
    z-index: 50;
    font-size: 0px;
}
.bx_inner{overflow:visible;}
.homeTemplate .carousel-indicators li{@include rounded-corners($radius: 0px); width:52px; height:4px; margin:0 2px; border:none; background:#f6f6f6; filter: alpha(opacity=20); opacity:0.2; @include transitioned('opacity', 0.3s);}
.homeTemplate .carousel-indicators li:hover, .homeTemplate .carousel-indicators li:focus, .homeTemplate .carousel-indicators li.active{ filter: alpha(opacity=100); opacity:1; margin:0 2px;}
.homeTemplate .bx_caption{
    position: absolute;
    text-align: center;
    text-shadow:none; 
    top:50%; 
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%); 
    bottom: auto; 
    padding:0;
    .btn{
        @include mainFont($package);
        margin-top:20px;
        background:#fff;
        color:#101010;
        transition:all,0.25s;
    }


    > a{
        display: inline-block;
        
        &:hover,
        &:focus{
                text-decoration: none;
            .btn,h2 span{
                color:white;
                background:$thirdColor;
            }
        }
    }
}
.homeTemplate .bx_caption h2{
    font-size:60px; 
    font-size:6rem;
    @include secondFont($package); 
    text-align:center; 
    text-transform:uppercase; 
    letter-spacing:2px; 
    margin:0 0 13px 0;
    word-break: break-word;

    span{
        font-weight:600; 
        font-style: normal;
        padding: 10px 40px;
        color:#000;
        background-color: #fff;
        transition:all,0.25s;
        display: inline-block;
    }
}

.bx_caption{
    left: 30px;
    right: 30px;
    *{   -webkit-backface-visibility: hidden;}
}
.homeTemplate .bx-viewport .item{
    position: relative;
    &:before{
        content:'';
        background:url(../img/layout/FiltreLarge.png) center center no-repeat;
        position: absolute;
        top:0;
        left:0;
        width:100%;
        opacity: 0.3;
        height:100%;
    }
}
.homeTemplate .bx_caption span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff; }


.homeTemplate .news .container{
    text-align:center;
    position:relative;
    max-width: 1752px;
    margin: 0 auto;

    padding: 60px 0px 70px 0px;
}

.homeTemplate .news .container h2{
    @include secondFont($package); 
    font-size:36px; 
    font-size:3.6rem;
    color:#272727; 
    margin:0px; 
    text-align: center;
    margin-bottom: -37px;
    position: relative;
    z-index: 10;
    font-weight: 700;
    a{
        color:inherit;
        @include transitioned('all',0.25s);
        &:hover,
        &:focus{text-decoration: none;color:$thirdColor;}
    }

}

.seeAllHolder{
    margin-bottom: 10px;
}

.homeTemplate .news{
    background:$mainColor; 
    max-width: 1920px;
    margin: 0 auto;
}

.homeTemplate .news .container .seeAll{
    text-decoration:none; 
    text-transform:uppercase; 
    @include mainFont($package); color:$thirdColor; 
    font-size:14px; 
    font-size:1.4rem;
    font-weight:700; 
    letter-spacing:1px; 
    @include transitioned('color', 0.3s);
    line-height: 36px;
    position: absolute;
    right:90px;
    z-index: 10;
}
.homeTemplate .news .container .seeAll:hover, .homeTemplate .news .container .seeAll:focus{color:$secondColor;}
.homeTemplate .news .container .seeAll span{font-size:10px; font-size:1rem;display:inline-block; margin:0 0 0 5px;}
.homeTemplate .news .container article{

    vertical-align:top;
    text-align: center;
    & > div{
        margin: 0 auto;
    }
}


.homeTemplate .news .container article img{width:100%; height:auto; display:block; margin:0 0 18px 0; background:$mainColor; @include transitioned('opacity', 0.3s);}
.homeTemplate .news .container article a:hover img, .homeTemplate .news .container article a:focus img{filter: alpha(opacity=60); opacity:0.6;}
.homeTemplate .news .container article time{text-transform: uppercase;@include mainFont($package); font-size:12px; font-size:1.2rem; margin:0 0 18px 0; color:#000007; margin:0 0 18px 0; display:block; letter-spacing:1px;}
.homeTemplate .news .container article time span{text-transform:uppercase;}
.homeTemplate .news .container article h3{@include secondFont($package); font-weight:700; font-size:18px; font-size:1.8rem; margin:0 0 13px 0; text-transform:uppercase;}
.homeTemplate .news .container article h3 a{color:#000007; text-decoration:none; @include transitioned('color', 0.3s);}
.homeTemplate .news .container article h3 a:hover, .homeTemplate .news .container article h3 a:focus{color:$thirdColor;}
.homeTemplate .news .container article.listing p{
    @include mainFont($package); 
    font-size:14px; 
    font-size:1.4rem;
    color:#000007;  
    line-height:20px;
    // padding: 0px;
}
.homeTemplate .news .container article.listing p a{color:$thirdColor; text-decoration:underline; @include transitioned('color', 0.3s);}
.homeTemplate .news .container article.listing p a:hover, .homeTemplate .news .container article.listing p a:focus{color:$secondColor; text-decoration:none; }
.homeTemplate .news .container article.listing p a.btn{
    background:$thirdColor; 
    @include mainFont($package); 
    font-weight:700; 
    // font-style:italic; 
    font-size:14px; 
    font-size:1.4rem;
    text-decoration:none; 
    @include autoLightDark('color',$secondColor);
    @include rounded-corners($radius: 0px); 
    padding:8px 20px 8px 20px;  
    @include transitioned('background', 0.3s);
    margin-top: 18px;
    &:hover,
    &:focus{
        background:darken($thirdColor,10%);
        color:white;

    }
}
.homeTemplate .news .container article.listing p a.btn span{font-size:8px;font-size:0.8rem;display:inline-block; margin:0 0 0 10px;}

.homeTemplate .mosaique{background:$secondColor;}
.homeTemplate .mosaique div{padding:0; position:relative; cursor:pointer;}
.homeTemplate .mosaique a.elmt{
    background:$secondColor;
    display:block;
    padding:0;
    z-index: 50;

    .bgImg{
        transition:opacity 0.25s;
        position: relative;

        &:after{
            content:'';
            width:100%;
            height:100%;
            position: absolute;
            top:0;
            left:0;
            background:rgba(0, 0, 0,0);
            -webkit-transition:0.25s  all;
                    transition:0.25s  all;
        }

    }
    &:hover,
    &:focus{
        .content:not(.location):not(.chief){
            h3{
                color:#fff;
            }
            p{
                color:#fff;
            }
            span.devider{
                background-color:#fff;
            }
            span.social{
                color:#fff;
            }
        }
    }

    .content{
        position:absolute;
        top:50%;
        -webkit-transform:translate(-50%,-50%);
                transform:translate(-50%,-50%);
        left:50%;
        z-index:10;
        text-align: center;
        max-width: 100%;
        padding:10px;
        @include transitioned('background', 0.3s);

        &:not(.location):not(.chief){
            background-color: #fff;
            h3{
                padding:10px ;
                @include transitioned('all', 0.3s);
                color:#000;
            }
            > p {
                display: none;
                padding:5px 10px;
                @include transitioned('background-color,color', 0.3s);
                
                span.social{
                    padding: 5px;
                    @include transitioned('color', 0.3s);
                }
            }
        }

        h3{@include secondFont($package); font-weight:600; font-size:36px; font-size:3.8rem;text-transform:uppercase; color:#fff; margin:0; letter-spacing: 3px; word-wrap:break-word;}
    }


    &.location * ,&.chief * {
        @include autoLightDark('color',$secondColor);
    }
    &.location span.devider,&.chief span.devider{
        @include autoLightDark('background',$secondColor);
    }
}

.homeTemplate .mosaique div .content.location h3{
    background:url(../img/layout/iconPinWhite.png) top center no-repeat;
    background-size:18px 22px;
    padding:44px 0 0 0;
    margin-top:-44px;
}
.homeTemplate .mosaique div .content.chief h3{background:url(../img/layout/iconChief.png) top center no-repeat; background-size:29px 26px; padding:46px 0 0 0; margin-top:-46px;}
.homeTemplate .mosaique div .content span.devider{width:30px; height:2px; margin:13px auto; display:none; background:#000;@include transitioned('background', 0.3s); }
.homeTemplate .mosaique div .content p{ margin:0;}
.homeTemplate .mosaique div .content > p{color:#000; text-decoration:none; /*font-style:italic;*/ font-weight:700; @include mainFont($package); letter-spacing:1px; font-size:14px; font-size:1.4rem;}
.homeTemplate .mosaique div .content > p span{font-size: 11px; font-size:1.1rem; position: relative; top: 1px;}
.homeTemplate .mosaique div .content > p span.social{color:#000; font-size:19px; font-size:1.9rem; text-decoration:none; margin:0 4px;}
.homeTemplate .mosaique div .bg{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:5;
    background:#101010;
    -webkit-transition:background 0.25s;
            transition:background 0.25s;
    
}
.homeTemplate .mosaique a:hover,
.homeTemplate .mosaique a:focus{
    .chief + .bg,.location + .bg{
    //opacity:0.1;  
    //background:darken($secondColor,5%);           
    background:#000;
    }
}
.mosaique{
    // -webkit-font-smoothing: subpixel-antialiased;
    // transition-duration:0 !important; 
//  -webkit-transform-style: preserve-3d;
// -webkit-backface-visibility: hidden;
    // img {    -webkit-backface-visibility: hidden; }
    // .bgImg{-webkit-transform: translateZ(0px);}
}
// .homeTemplate .mosaique a.elmt  img{@include transitioned('opacity', 0.3s);}
// .homeTemplate .mosaique a.elmt:hover  img{filter: alpha(opacity=85); opacity:0.85;}

/* ==========================================================================
    Menu
    ========================================================================== */
.menuTemplate{padding:0;}
.menuTemplate .container{position:relative; background:$mainColor;}

.menuTemplate .stickyMenu{
    position:absolute; 
    top:405px; 
    left:0; 
    z-index:100; 
    padding:0;
}
.menuTemplate .stickyMenu.floating.top{
    position: fixed;
    top: 45px;
}

.menuTemplate .stickyMenu.floating.bottom{
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
}

.menuTemplate .stickyMenu{position:absolute; top:405px; left:0; z-index:100; padding:0;}
.menuTemplate nav{
    background:$mainColor; /*margin:0 0 0 75px;*/ margin:0; padding:40px 20px 40px 0; float:left;
    // padding-right: 20px;
}
.menuTemplate nav ul{padding:0; margin:0; list-style:none;}
.menuTemplate nav ul li{padding:0; margin:0;}
.menuTemplate nav ul li a{color:#250b06; text-decoration:none; text-transform:uppercase; @include mainFont($package); font-size:11px; font-size:1.1rem; font-weight:700; display:block; padding:10px 0 10px 15px; position:relative; @include transitioned('background, color', 0.3s);}
// .menuTemplate nav > ul li a:hover, .menuTemplate nav > ul > li.active > a{background:$thirdColor; color:#fff;}
.menuTemplate nav ul li a span.bullet{width:6px; height:6px; @include rounded-corners($radius: 3px); background:#ccc7c6; display:inline-block; margin:0 10px 0 0; @include transitioned('background', 0.3s);}
.menuTemplate nav > ul li a:hover span.bullet, .menuTemplate nav > ul li a:focus span.bullet, .menuTemplate nav > ul li.active a span.bullet{background:#fff;}
.menuTemplate nav ul li a span.mask{width:9px; height:36px; background:url(../img/layout/maskActiveMenu.png) right center no-repeat; background-size:9px 36px; position:absolute; top:0; right:0; margin:0;}
.menuTemplate nav ul li ul{padding:0 0 0 16px; height:0; overflow:hidden;}
// .menuTemplate nav ul li:nth-child(1) ul{height:auto;}
.menuTemplate nav ul li ul li{margin:12px 0; padding-left:15px;}
.menuTemplate nav ul li ul li:nth-child(1){margin:10px 0 3px;}
.menuTemplate nav ul li ul li a{font-weight:400; @include transitioned('color', 0.3s); display:inline; padding:0 0 0 0px;}
.menuTemplate nav ul li ul li a:hover, .menuTemplate nav ul li.active ul li a:hover, .menuTemplate nav ul li ul li a:focus, .menuTemplate nav ul li.active ul li a:focus{color:$thirdColor; background:transparent;}
.menuTemplate nav ul li.active ul li a{background:transparent; color:#250b06;}
// .menuTemplate nav ul li.active ul li.active a{color:$thirdColor; font-weight:bold; background:transparent;}
.menuTemplate nav .download{border-top:1px solid #ccc7c6; width:180px; margin:15px 0 0 0;}
.menuTemplate nav .download a{
    display: block;
    color:#250b06; 
    text-decoration:none; 
    text-transform:uppercase; 
    @include mainFont($package); 
    font-size:11px; 
    font-size:1.1rem;
    font-weight:700; 
    padding:10px 30px; 
    margin:0px 0 0 0;transition:all 0.25s;
}
.menuTemplate nav .download a:hover,
.menuTemplate nav .download a:focus{
    &,span{
        color:$secondColor;
    }
}
.menuTemplate nav .download a span{transition:all 0.25s;font-weight:400; color:$thirdColor; /*font-style:italic;*/ text-transform:none;}

.menuTemplate .foodCateg{float:left; clear:both; width:100%;}
.menuTemplate .foodCateg > div{padding:0;}
// .menuTemplate .banner, .dinningTemplate .banner{
//     width:100%;
//     position:relative;
//     height:250px;
//     overflow:hidden;
//     &.parallax{
//         background-attachment: fixed;
//         .mobileOnly &, .ie & {background-attachment: scroll; background-position: center center;}
//         background-position: 50% 0;
//     }

//     &.noBg{
//         background-color:$secondColor;
//     }
// }

.stickyMenuMobileToggle{
    display: none;
}
.menuTemplate .noBG{background:$secondColor;}
// .menuTemplate .foodCateg .banner{
//     .bg{
//         position: absolute;
//         height: 140%;
//         width: 100%;
//         background-size:100%;
//     }
// }

// .menuTemplate .banner .txt{position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); left:0; z-index:10; width:100%;}
// .menuTemplate .banner .txt h2{font-size:60px; font-size:6.0rem; @include secondFont($package); font-weight:600; text-align:center; text-transform:uppercase; letter-spacing:2px; }
// .menuTemplate .banner .txt h2 span{padding: 10px 20px; background-color: #fff; color: #000; display: inline-block;}
// .menuTemplate .banner .txt span.devider, .dinningTemplate .banner .txt span.devider{display:none;width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff; margin:0 auto;}
// .menuTemplate .banner .txt p{display:none;@include mainFont($package); font-size:14px; font-size:1.4rem; font-weight:700; color:#f6f6f6; text-align:center; margin:17px 0 0 0;}
.menuTemplate .foodCateg .categ{float:left; clear:both; width:100%; padding:60px 30px 45px 0;}
.menuTemplate .foodCateg .categ > div{padding:0;}
.menuTemplate .foodCateg .categ > div.subMenu.right dl dt{margin:0 0 20px 15%;}
.menuTemplate .foodCateg .categ:nth-child(1){border:none;}
.menuTemplate .foodCateg .categ h3{font-size:20px; font-size:2.0rem; @include secondFont($package); font-weight:600; text-transform:uppercase; letter-spacing:2px; text-align:center; color:#000007; margin:0 0 15px;}
.menuTemplate .foodCateg .categ .devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#ccc7c6; margin:0 auto;}
.menuTemplate .foodCateg .categ dl{width:100%; margin:50px 0 0 0;}
.menuTemplate .foodCateg .categ dt{width:60%; display:inline-block; font-size:16px; font-size:1.6rem; @include secondFont($package); font-weight:600; color:#101010; text-align:left; margin:0 0 20px 0; line-height:24px;}
.menuTemplate .foodCateg .categ dt span{@include mainFont($package); font-size:14px; font-size:1.4rem; /*font-style:italic;*/ width:100%; font-weight:400; display:block; line-height:18px;}
// .menuTemplate .foodCateg .categ dd{color:$thirdColor; font-size:17px; @include secondFont($package); font-weight:700; display:inline-block; text-align:right; vertical-align:top; width:18%;}
.menuTemplate .foodCateg .categ dd{color:#101010; font-size:17px; font-size:1.7rem; @include secondFont($package); font-weight:700; display:inline-block; text-align:right; vertical-align:top; width:18%;}

// .iPad .menuTemplate .banner, .iPad .dinningTemplate .banner{
//     background-position:center center !important;
//     background-size:cover !important;
//     height:auto;
//     .txt{
//         position: static;
//         padding:125px 0;
//         transform:none;
//         -webkit-transform:none;
//         h1{
//             max-width: 700px;
//             margin: 0 auto;
//         }
//     }

// }
.menuTemplate .desc.categ{
    border:none;
    //border-bottom:1px solid #ccc7c6;
}
.sectionDesc{margin-top:20px;}
.menuTemplate .desc.categ.categ.categ span{margin-bottom:16px;}
.menuTemplate .desc.desc.desc.categ p, .menuTemplate .sectionDesc.sectionDesc.sectionDesc p{
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 23px;
    text-align: center;
    max-width: 560px;
    margin:0 auto;
}
//YOLO
.menuTemplate .categ.menu{
    width:50%;
    margin-left:25%;
    width:calc(100% - 400px);
    margin-left:200px;
    .subMenu{
        width:50%;
        float:left;
    }
}
a[href*="tel"]{color:inherit;}
/* ==========================================================================
    News
    ========================================================================== */
.newsTemplate{padding:0;}
.newsTemplate .container{background:$mainColor;}

// .newsTemplate .upcoming{width:100%; background:$secondColor; padding:70px 0 60px; text-align:center;}
.newsTemplate .upcoming{width:100%; background:$secondColor; padding:41px 0 30px; text-align:center;}
.newsTemplate .upcoming h1{
    color:$mainColor; 
    font-size:36px; 
    font-size:3.6rem;
    @include secondFont($package); 
    font-weight:600; 
    text-align:center; 
    text-transform:uppercase; 
    margin:0 0 10px; 
    letter-spacing:2px;
    padding: 0px 30px;
}
.newsTemplate .upcoming span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
.newsTemplate .upcoming h2{font-weight:700; font-size:18px; font-size:1.8rem; text-transform:uppercase; margin:0 0 20px 0; letter-spacing: 1.2px;}
.newsTemplate .upcoming h2 a{color:$mainColor; text-decoration:none; @include transitioned('color', 0.3s);}
.newsTemplate .upcoming h2 a:hover, .newsTemplate .upcoming h2 a:focus{color:$thirdColor;}
.newsTemplate .upcoming article{
    margin:0px;
    padding: 0px 20px 47px;
    margin-top: 30px;
    float:none;
    max-width:20%;
    &.noCta{padding-bottom:0;}
}
.newsTemplate .upcoming article time{ &,& * {color:$thirdColor; text-align:center; margin:0 0 10px 0;}} //Fucking ios
.newsTemplate .upcoming article .ctaHolder{
    position: absolute;
    bottom:0;
    width:100%;
    text-align: center;
    left:0;
}
.newsTemplate .upcoming article .ctaHolder a.btn{background:#e1dede; color:$secondColor;}
.newsTemplate .upcoming article .ctaHolder a.btn:hover, .newsTemplate .upcoming article .ctaHolder a.btn:focus{background:$thirdColor; color:white;}

.newsTemplate .main{
    margin:0 auto; 
    padding:70px 0 45px; 
    text-align:center; 
    border-bottom:1px solid #ccc7c6; 
    float:none;
    &.nothingToShow{
        padding-bottom:0;
        border:none
    }
}
.newsTemplate .main h1{
    color:#101010; 
    font-size:36px; 
    font-size:3.6rem;
    @include secondFont($package); 
    font-weight:700; 
    text-align:center; 
    text-transform:uppercase; 
    margin:0 0 10px; 
    letter-spacing:2px;
    padding: 0px 30px;
}
.newsTemplate .main p{
    padding: 0px 30px;
}

.newsTemplate .main span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#c8c8c8;}
.newsTemplate .main h5{color:#101010; @include mainFont($package); font-size:14px; font-size:1.4rem;font-weight:700; margin:0;}
.newsTemplate .main article{width:100%; margin:45px auto 0;}

.newsTemplate .main article img{
    width:100%; 
    height:auto; 
    margin:65px auto 0;
}
.newsTemplate .main article time{margin:25px 0 18px 0;}

.newsTemplate .main article h3{
    color:#101010; 
    font-size:50px;
    font-size:5rem; 
    @include secondFont($package); 
    font-weight:600; 
    text-align:center; 
    text-transform:uppercase; 
    letter-spacing:2px;
    padding: 0px 30px;
}

.newsTemplate .main article p{
    width:680px; 
    margin:0 auto 20px; 
    height:auto;
    font-size: 14px;
    font-size:1.4rem;
    line-height: 20px;
    @include mainFont($package);

}

.newsTemplate .oldNews{
    text-align:center; 
    padding:10px 0 60px 0;
}
.newsTemplate article{
    margin:65px 0px 0; 
    padding:0px; 
    display:inline-block; 
    vertical-align:top;
}
.newsTemplate article img{width:100%; height:auto; display:block; margin:0 0 18px 0; background:$mainColor; @include transitioned('opacity', 0.3s);}
.newsTemplate article a:hover img, .newsTemplate article a:focus img{opacity:0.8;}
.newsTemplate article time{text-transform: uppercase;@include mainFont($package); &,& * {color:#000007;}font-size:12px; font-size:1.2rem;margin:0 0 18px 0;  margin:0 0 18px 0; display:block; letter-spacing:1px;}
.newsTemplate article time span{text-transform:uppercase;}
.newsTemplate article h3{@include secondFont($package); font-weight:700; font-size:18px; font-size:1.8rem; margin:0 0 13px 0; text-transform:uppercase;}
.newsTemplate article h3 a{color:#000007; text-decoration:none; @include transitioned('color', 0.3s);}
.newsTemplate article h3 a:hover, .newsTemplate article h3 a:focus{color:$thirdColor;}
// .newsTemplate article p{
//  @include mainFont($package); 
//  font-size:13px; 
//  color:#000007; 
//  margin:0 0 10px 0; 
//  line-height:20px;
// }
// .newsTemplate article p a{color:$thirdColor; text-decoration:none; @include transitioned('color', 0.3s);}
// .newsTemplate article p a:hover{color:$secondColor;}
// .newsTemplate article p a.btn{
//  background:$thirdColor; 
//  @include mainFont($package); 
//  font-weight:700; 
//  font-style:italic; 
//  font-size:14px; 
//  text-decoration:none; 
//  color:#fff; 
//  @include rounded-corners($radius: 0px); 
//  padding:8px 20px 8px 20px;  
//  @include transitioned('background', 0.3s);
//  margin-top: 18px;
//  &:hover{background:darken($thirdColor,10%);}
// }
// .newsTemplate article .ctaHolder a.btn span{font-size:8px; display:inline-block; margin:0 0 0 10px;}
// .newsTemplate article p a.btn:hover{background:$thirdColor;}

.newsTemplate .more{border-bottom:1px solid #ccc7c6; margin:0 auto 40px; margin-top: 20px; float:none; text-align:center; clear:both;}
.newsTemplate .more p{margin:0 0 -9px 0;}
.newsTemplate .more a{@include mainFont($package); font-weight:700; /*font-style:italic;*/ font-size:14px; font-size:1.4rem;text-decoration:none; color:#101010; background:$mainColor; padding:10px 48px; @include transitioned('color', 0.3s);}
.newsTemplate .more a:hover, .newsTemplate .more a:focus{color:$thirdColor;}
.newsTemplate .more a span{font-size:11px; font-size:1.1rem; display:inline-block; margin:0 0 0 10px;}

/* ==========================================================================
    News Details
    ========================================================================== */
.newsDetailsTemplate{padding-bottom:0px;}
.newsDetailsTemplate .container{background:$mainColor;  position:relative;}

.newsDetailsTemplate .stickySocial{
    position:absolute; 
    // top:290px;
    top:440px;
    left:200px; 
    z-index:100; 
    padding:0;  
}
.newsDetailsTemplate .stickySocial p{@include mainFont($package); font-size:16px; font-size:1.6rem;margin:0; color:#101010; text-transform:uppercase; font-weight:700; letter-spacing:1px; display:inline-block;}
.newsDetailsTemplate .stickySocial a{font-size:17px; font-size:1.7rem;margin:0 0 0 10px; text-decoration:none; display:inline-block; color:#101010; @include transitioned('color', 0.3s);}
.newsDetailsTemplate .stickySocial a:hover, .newsDetailsTemplate .stickySocial a:focus{color:$thirdColor;}

.newsDetailsTemplate .stickyMore{
    position:absolute;
    // top:290px; 
    top:440px; 
    right:200px;
    z-index:100;
    padding:0;
    text-align:right;}
.newsDetailsTemplate .stickyMore >div{width:320px;}
.newsDetailsTemplate .stickyMore .seeAll{text-decoration:none; text-transform:uppercase; @include mainFont($package); color:$thirdColor; font-size:14px; font-size:1.4rem;font-weight:700; letter-spacing:1px; @include transitioned('color', 0.3s);}
.newsDetailsTemplate .stickyMore .seeAll:hover, .newsDetailsTemplate .stickyMore .seeAll:focus{color:$secondColor;}
.newsDetailsTemplate .stickyMore .seeAll span:last-child{font-size:10px; font-size:1rem; display:inline-block; margin:0 0 0 5px;}

.newsDetailsTemplate .title{width:100%; background:$secondColor; padding:70px 0 60px; text-align:center;}
.newsDetailsTemplate .title h1{
    color:$mainColor; 
    font-size:36px; 
    font-size:3.6rem;
    @include secondFont($package); 
    font-weight:600; 
    text-align:center; 
    text-transform:uppercase; 
    margin:0 0 10px; 
    letter-spacing:2px;
    padding: 0 30px;
}
.newsDetailsTemplate .title span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
.newsDetailsTemplate .title time{@include mainFont($package); font-size:14px; font-size:1.4rem; color:#e1dede; text-transform:uppercase; font-weight:700; letter-spacing:1px; margin:20px 0 0 0;}

.newsDetailsTemplate .main{
    margin:0 auto; 
    padding:80px 0 65px; 
    text-align:center; 
    border-bottom:1px solid #ccc7c6; 
    float:none; 
    margin:0 auto 30px;
    padding-bottom: 20px;
}
.newsDetailsTemplate .main article{width:100%; margin:0 auto;}
.newsDetailsTemplate .main article img{
    max-width:740px; 
    height:auto; 
    margin:0 auto 35px;
    width:auto;
}
.newsDetailsTemplate .main article p{
    max-width:680px; 
    margin:0 auto 28px; 
    @include mainFont($package); 
    font-size:14px; 
    font-size:1.4rem;
    color:#000007; 
    line-height:20px;
}
.newsDetailsTemplate .main article p a{color:$thirdColor; text-decoration:underline; @include transitioned('color', 0.3s);}
.newsDetailsTemplate .main article p a:hover, .newsDetailsTemplate .main article p a:focus{color:$secondColor; text-decoration:none; }

.newsDetailsTemplate .oldNews{
    text-align:center; 
    padding:10px 0 0px 0;
}
.newsDetailsTemplate .oldNews > h3{@include secondFont($package); font-weight:600; font-size:20px; font-size:2rem;color:#101010; text-transform:uppercase; letter-spacing:1px; margin:0 0 15px 0;}
.newsDetailsTemplate .oldNews .devider{width:30px; height:2px; margin:0 auto -25px; display:block; background:#ccc7c6;}
.newsDetailsTemplate article{ 
    margin:65px 0px 40px 0px; 
    padding:0px; 
    display:inline-block; 
    vertical-align:top;
}
.newsDetailsTemplate article img{width:100%; height:auto; display:block; margin:0 0 18px 0; background:$mainColor; @include transitioned('opacity', 0.3s);}
.newsDetailsTemplate article time{text-transform: uppercase;@include mainFont($package); &, & * {color:#000007;}font-size:12px; font-size:1.2rem;margin:0 0 18px 0; color:#000007; margin:0 0 18px 0; display:block; letter-spacing:1px;}
.newsDetailsTemplate article time span{text-transform:uppercase;}
.newsDetailsTemplate article h3{@include secondFont($package); font-weight:700; font-size:18px; font-size:1.8rem;margin:0 0 13px 0; text-transform:uppercase;}
.newsDetailsTemplate article h3 a{color:#000007; text-decoration:none; @include transitioned('color', 0.3s);}
.newsDetailsTemplate article h3 a:hover, .newsDetailsTemplate article h3 a:focus{color:$thirdColor;}
.newsDetailsTemplate article.listing p{
    @include mainFont($package); 
    font-size:14px; 
    font-size:1.4rem;
    color:#000007; 
    margin:0 0 18px 0; 
    line-height:20px;
}
.newsDetailsTemplate article.listing .ctaHolder a{color:$thirdColor; text-decoration:none; @include transitioned('color', 0.3s);}
.newsDetailsTemplate article.listing .ctaHolder a.btn{background:$thirdColor; @include mainFont($package); font-weight:700; /*font-style:italic; */font-size:14px; font-size:1.4rem;text-decoration:none; color:#fff; @include rounded-corners($radius: 0px); padding:8px 20px 8px 20px;  @include transitioned('background', 0.3s);}
.newsDetailsTemplate article.listing .ctaHolder a.btn span{font-size:8px; font-size:0.8rem;display:inline-block; margin:0 0 0 10px;}
.newsDetailsTemplate article.listing .ctaHolder a.btn:hover, .newsDetailsTemplate article.listing .ctaHolder a.btn:focus{background:darken($thirdColor,10%);}

/* ==========================================================================
    Private Dinning
    ========================================================================== */
.dinningTemplate{padding:0;}
.dinningTemplate .container{position:relative; background:$mainColor;}

.dinningTemplate .stickyMenu{position:absolute; top:405px; left:0; z-index:100; padding:0; } // DISPLAY NONE FOR DEBUGGING

.dinningTemplate nav{background:$mainColor ; margin:0 0 0 75px; padding:40px; float:left;}
.dinningTemplate nav ul, .menuTemplate nav ul{
    padding:0;
    margin:0;
    list-style:none;
    width:171px;
    div.text{display:inline-block;}
    & > li{
        a{
            color:$secondColor;
        }
        position:relative;
        .mask{display:none;}
        border-top:1px solid #ccc7c6;
        &.active{border:none;}
        // &.active ul{margin-bottom:10px;}
        &.active ul li{border:none !important;}
        &:last-child{
            border-bottom:1px solid #ccc7c6;
            ul li:last-child{border:none;}
        }
        ul li{border:none;}
        ul li.active a{color:#101010; font-weight: bold; @include secondFont($package);}
        &:before{
            content:'';
            position: absolute;
            right:-9px;
            top:0;
            @include square(0);
            border-style: solid;
            border-width: 17px 0 17px 9px;
            border-color: transparent transparent transparent transparent;
            @include transitioned('all', 0.3s);
        }
    }
    & > li.active{
        a{background:$thirdColor; color:white;}
        &:before{
            border-color: transparent transparent transparent $thirdColor;
        }
    }
    & > li:not(.active) a:hover,
    & > li:not(.active) a:focus{
        background:#101010;
        color:white;
    }
}
.dinningTemplate, .menuTemplate{
    nav .download{border:none;}
    nav .download a{color:$secondColor; font-weight:400;}
}
.dinningTemplate nav ul li{padding:0; margin:0;}
.dinningTemplate nav ul li a{color:#250b06; text-decoration:none; text-transform:uppercase; @include mainFont($package); font-size:11px; font-size:1.1rem;font-weight:700; display:block; padding:10px 0 9px 15px; position:relative; @include transitioned('background, color', 0.3s);}
.dinningTemplate nav > ul li a:hover, .dinningTemplate nav > ul li a:focus, .dinningTemplate nav > ul > li.active > a{background:$thirdColor; color:#fff;}
.dinningTemplate nav ul li a span.bullet{width:6px; height:6px; @include rounded-corners($radius: 3px); background:#ccc7c6; display:inline-block; margin:0 10px 0 0; @include transitioned('background', 0.3s);}
.dinningTemplate nav > ul li a:hover span.bullet, .dinningTemplate nav > ul li a:focus span.bullet, .dinningTemplate nav > ul li.active a span.bullet{background:#fff;}
// .dinningTemplate nav ul li a span.mask{width:9px; height:36px; background:url(../img/layout/maskActiveMenu.png) right center no-repeat; background-size:9px 36px; position:absolute; top:0; right:0; margin:0;}

// .dinningTemplate .banner{
//     width:100%; position:relative; height:250px; overflow:hidden;
//     &:before{
//         content:"";
//         width:100%;
//         height:100%;
//         position: absolute;
//         top:0;
//         left:0;
//         background:url(../img/layout/FiltreLarge.png) center center no-repeat;
//         opacity:0.5;
//     }
// }
// .dinningTemplate .banner .txt{
//     position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); left:0; z-index:10; width:100%;;

// }
// .dinningTemplate .banner .txt h1{
//     font-size:60px; 
//     font-size:6rem;
//     @include secondFont($package); 
//     font-weight:600; 
//     text-align:center; 
//     text-transform:uppercase; 
//     letter-spacing:2px; 
//     padding: 0 30px;

//     span{
//         padding: 10px 20px;
//         background-color: #fff;
//         color: #000;
//     }
// }
// .dinningTemplate.noFeatured .banner .txt h1{font-size:36px;font-size:3.6rem;}
// .dinningTemplate .banner .txt h2:before{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff; margin:0 auto;}
// .dinningTemplate .banner .txt p{
//     @include mainFont($package); 
//     font-size:14px; 
//     font-size:1.4rem;
//     font-weight:700; 
//     color:#f6f6f6; 
//     text-align:center; 
//     margin:17px 0 0 0;
//     padding:  0px 30px;
// }

.dinningTemplate .categ{float:left; width:100%;}
.dinningTemplate .categ > div{padding:60px 0; float:left; width:100%;}
.dinningTemplate .categ.categ.categ.categ.categ h2{
    @media all and (max-width:1600px){
        max-width: 310px;
        margin: 0 auto 15px;
    }
}
.dinningTemplate .categ h2{
    font-size:20px;
    font-size:2rem;
    @include secondFont($package);
    font-weight:600;
    color:#000007;
    text-align:center;
    text-transform:uppercase;
    letter-spacing:1px;
}
.dinningTemplate .categ .devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#ccc7c6; margin:0 auto 32px;}
.dinningTemplate .categ h3{color:#000007; @include mainFont($package); font-size:22px; font-size:2.2rem;font-weight:700; text-align:center; text-transform:uppercase; letter-spacing:1px; margin:0 0 25px 0; line-height:30px;}
.dinningTemplate .categ .txt{max-width:715px; margin:0 auto;}
.dinningTemplate .categ .txt p{@include secondFont($package);}
.dinningTemplate .categ p{@include mainFont($package);text-align:center; line-height:24px; max-width:715px; margin:0 auto 25px;}
.dinningTemplate .categ p a{color:$thirdColor; text-decoration:underline; @include transitioned('color', 0.3s);}
.dinningTemplate .categ p a:hover ,.dinningTemplate .categ p a:focus{color:$secondColor;text-decoration:none; }

.dinningTemplate .categ .room{
    width:100%; float:left;

    .intro
    {
        height:auto;
        background-color: transparent;
    }
}
.dinningTemplate .categ .room:nth-child(odd){background:#e1dede;}
.dinningTemplate.evenRoom .categ.menu{background:#e1dede;}
.dinningTemplate .categ.menu{padding-bottom:80px;}
.dinningTemplate .categ .room .col-md-6{padding:0;}
.dinningTemplate .categ .room{
    .slider,.noSlider{
        margin:0 auto; 
        max-width:730px;
        img{max-width: 100%;}   
    }
}
[id*="carousel_"]{
    position: relative;
    max-width: 730px;
    #bodyId_dinning & {min-width: 0;}
    margin:0 auto;
    margin-top:25px;
    .dinningTemplate & {margin-top:0;}
    .item.item.item  img{max-width: 100%;}
    .home & {
        max-width: 100%;
        margin:0 auto;
    }

}
.closeslider, .nextslide, .prevslide{
    position: absolute;
    left: -2000px;
    &:focus{
        display: block;
        bottom: 0;
        left: 0;
        background:rgba(229, 145, 36, 0.55);
        color:#fff;
        font-size: 12px;
        font-size:1.2rem;
        z-index:1000;
        padding:10px 20px;
        
    }
}

.bx-controls{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:55;
    .bx-prev{
        position:absolute;
        left:20px;
        top:50%;
        transform:translateY(-50%);
        text-indent:-9999px;
        width:25px;
        height:50px;
        background:url(../img/layout/arrow_slider_prev.png) 0 0 no-repeat;
        transition: opacity 0.25s;
        @media screen and (max-width: 640px) {
            left:10px;
        }
        &:hover{
            opacity:0.3;
        };
    }
    .bx-next{
        position:absolute;
        right:20px;
        top:50%;
        transform:translateY(-50%);
        text-indent:-9999px;
        width:25px;
        height:50px;
        background:url(../img/layout/arrow_slider.png) 0 0 no-repeat;
        transition: opacity 0.25s;
        @media screen and (max-width: 640px) {
            right:10px;
        }
        &:hover{
            opacity:0.3;
        };
    }
}

ul.bx_pager.bx_pager.bx_pager.bx_pager{
    @include resetPM();
    list-style:none;

    width:100%;
    
    margin:0 auto;
    text-align: center !important;
    position: absolute;
    bottom:30px;
    left:0;
    z-index: 55;
    display:none;
    li{
        display:inline-block;
        list-style: none;
        &:before{
            display:none;
        }
        a{
            cursor:pointer;
            padding:10px 0; //Easier to click
            font-size: 0;
            position: relative;
            width: 52px;
            display:block;
            margin:0 3px;
            span{-webkit-transition:background 0.25s;transition:background 0.25s; }
            .outer{
                height:4px;
                width: 100%;
                display:block;
                background:rgba(255,255,255,0.2);
                overflow:hidden;
                position: relative;
                box-shadow:0px 0px 18px 0px rgba(0,0,0,0.5);
                .inner{
                    left:0;
                    height:10px;
                    width:0%;
                    position: absolute;
                    display: block;
                    background:rgba(255,255,255,1);
                }
            }
            &:hover .outer,
            &:focus .outer{
                background:rgba(255,255,255,0.5);
            }
            &.active .inner{
                width:100%;
            }
        }
    }
}
// .dinningTemplate .categ .room .carousel-indicators{bottom:17px;}
// .dinningTemplate .categ .room .carousel-indicators li{@include rounded-corners($radius: 0px); width:52px; height:4px; margin:0 2px; border:none; background:#f6f6f6; filter: alpha(opacity=20); opacity:0.2; @include transitioned('opacity', 0.3s);}
// .dinningTemplate .categ .room .carousel-indicators li:hover, .dinningTemplate .categ .room .carousel-indicators li.active{width:52px; height:4px; filter: alpha(opacity=100); opacity:1; margin:0 2px;}
// .dinningTemplate .categ .room .carousel-fade .bx_inner .item{opacity: 0; transition-property: opacity;}
// .dinningTemplate .categ .room .carousel-fade .bx_inner .active{opacity: 1;}
// .dinningTemplate .categ .room .carousel-fade .bx_inner .active.left, .dinningTemplate .categ .room .carousel-fade .bx_inner .active.right{left: 0; opacity: 0; z-index: 1;}
// .dinningTemplate .categ .room .carousel-fade .bx_inner .next.left, .dinningTemplate .categ .room .carousel-fade .bx_inner .prev.right{opacity: 1;}

.dinningTemplate .categ .menu{padding:60px 0 0 0;}
.dinningTemplate .categ .menu.onlyOneMenu{margin-top:80px;}
.dinningTemplate .categ .menu > .col-md-8{border-bottom:1px solid #ccc7c6; padding:0 10px 30px 10px; position:relative;}
.dinningTemplate .categ .menu.special > .col-md-8{border:2px solid #ccc7c6; padding:60px 10px 30px 10px; margin:-61px 0 0 0;}
.dinningTemplate .categ .menu > .col-md-8 ul{padding:0; list-style:none;}
.dinningTemplate .categ .menu .price{position:absolute; top: -42px; right:2%; color:$thirdColor; font-family:Verdana, Geneva, sans-serif; font-size:18px; font-size:1.8rem;font-weight:bold;}
.dinningTemplate .categ .menu.special .price{top: 2%; right: 2%;}
.dinningTemplate .categ .menu li{width:auto; @include secondFont($package); font-weight:600; font-size:16px; font-size:1.6rem; text-align:left; margin:0 0 15px 0;}
.dinningTemplate .categ .menu li span{width:100%; @include mainFont($package); font-size:13px; font-size:1.3rem;/*font-style:italic;*/ width:100%; font-weight:400; display:block; line-height:18px;}
.dinningTemplate .categ .menu .note{@include mainFont($package); font-size:13px; font-size:1.3rem;width:100%; font-weight:400; display:block; line-height:18px; clear:both; width:100%; text-align:center; margin:50px 0 20px 0; float:left;}

.dinningTemplate .categ .inquire{background:$secondColor; margin:0px 0 0 0;}
.dinningTemplate .categ .inquire > div{padding:0;}
.dinningTemplate .categ .inquire.inquire.inquire h2{color:#f6f6f6 !important;}
.dinningTemplate .categ .inquire .content p{color:#f6f6f6; font-size:17px; font-size:1.7rem;line-height:20px; max-width:575px; margin:0 auto 30px;}
.dinningTemplate .categ .inquire p{text-align:center; margin:0 auto;}
.dinningTemplate .categ .inquire p a.btn{background:#f6f6f6; @include mainFont($package); font-weight:700; /*font-style:italic;*/ font-size:14px; font-size:1.4rem;text-decoration:none; color:#101010; @include rounded-corners($radius: 0px); padding:8px 20px 8px 20px; @include transitioned('all', 0.3s);}
// .dinningTemplate .categ .inquire p a.btn span{font-size:8px; display:inline-block; margin:0 0 0 10px;}
.dinningTemplate .categ .inquire p a.btn:hover,
.dinningTemplate .categ .inquire p a.btn:focus{background:$thirdColor; color:white; }


.bannerSep{
    height:15px;
    background:#e1dede;
}

/* ==========================================================================
    Private Dinning Page
    ========================================================================== */

.dinningPage{
    .banner{
        // height:360px;
        // overflow:inherit!important;
        .txt{
            @media all and (max-width:640px){
                .content_drop_down{
                    width:300px!important;
                    .dropDown_b_b{
                        // width:140px!important;
                        width:auto !important;
                        float:none;

                        .dropdown{
                            
                            .dropdown-toggle{
                                font-size:10px!important;
                                font-size:1rem!important;
                                padding-left:17px!important;
                                background-size: 7px 9px!important;
                            }
                            .dropdown-menu,a{
                                font-size:11px;
                                font-size:1.1rem;
                                min-width:auto;
                            }
                        }
                    }
                    .dropDown_b_b.concepts{
                        margin-left:0 !important;
                        margin-top:20px;
                        // margin-left:10px;
                        .dropdown{
                            .dropdown-toggle{
                                background-size:6px 11px!important;
                            }
                        }
                    }
                }
            }
            .content_drop_down{
                display: inline-block;
                // left: 50%;
                position: relative;
                // transform: translate(-50%,0);
                margin-top: 40px;

                .dropDown_b_b.concepts{
                    padding-left: 30px;
                    margin-left: 20px;
                    background: #1f1e1e url(../img/layout/fork_icon.png) 14px center no-repeat;
                    background-size:7px 16px;
                }
            }
        }
    }
    .listingRestaurants{
        width:100%;
        .wrapper_location{
            float:left;
            width:100%;
            padding:60px 0 30px 0;
            border-bottom: 1px solid #ccc7c6;
            .location_title{
                .title{
                    height:auto;
                    font-size:36px;
                    font-size:3.6rem;
                    @include secondFont($package);
                    text-transform: uppercase;
                    text-align:center;
                }
                .border_bottom{
                    width:30px;
                    margin:5px auto 0 auto;
                    height:2px;
                    background-color:#2c2b2b;
                }
                .nbr{
                    width: 100%;
                    text-align: center;
                    font-size:13px;
                    font-size:1.3rem;
                    @include mainFont($package);
                    font-weight:600;
                    margin-top:16px;
                }
            }
            @media all and (max-width:1190px){
                .the_restaurant_list{
                    // width:580px!important;
                    margin:0 auto;
                }
            }
            @media all and (max-width:1780px){
                .listingHolder.the_restaurant_list{
                    // width:1160px;
                    // margin:0 auto;

                }
                .listingHolder.alone{
                    width:auto;
                }
            }
            @media all and (max-width:640px){
                .the_restaurant_list{
                    // width:auto!important;
                }
            }
            .the_restaurant_list{
                width:auto !important;
                position:relative;
                // left:50%;
                // -webkit-transform: translate(-50%);
                // -moz-transform: translate(-50%);
                // -o-transform: translate(-50%);
                // transform: translate(-50%);
                text-align:center;
                display: block;
                // display: table;
                font-size:0;

                @media all and (max-width:640px){
                    &.onlyOne{
                        // left: inherit;
                        // transform:translate(0,0);
                    }
                    article.the_article{    
                        width:100%;
                        position:relative;
                        // text-align:left!important;
                            
                        &.onlyOne{
                            margin: 0 auto;
                            // float: none;
                            // display: block;
                        }

                        .wrapper_resto{
                            .wrap_nav{
                                height:auto;
                                a{  
                                    height:40px;
                                    line-height:20px;
                                    width:100%;
                                    color: rgba(246, 246, 246, 0.9);
                                    .onglet{
                                        line-height: 20px;
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }
                }
                &.onlyOne{
                    article.the_article{
                        margin: 0 auto;
                        // float: none;
                        // display: block;
                    }
                }

                article{    
                    // width:500px;
                    // margin:40px 40px;
                    // text-align: inherit!important;
                    // float:left;
                    background-color:#f6f6f6;
                    padding:0px;
                    vertical-align:top;


                    width:33.33333%;
                    margin:0;
                    // padding-left:25px;
                    // padding-right:25px;
                    padding:25px;
                    // float:left;
                    // display: table-cell;

                    &:nth-child(3n + 1)
                    {
                        // clear:left;
                    }
                    
                    .wrapper_resto{
                        height:560px!important;
                        width:100%;
                        position: relative;
                        .head_image{
                            height:258px;
                            position: relative;
                            .image_logo{
                                position:relative;
                                -webkit-transform: translateY(-50%);
                                -moz-transform: translateY(-50%);
                                -o-transform: translateY(-50%);
                                transform: translateY(-50%);
                                top:50%;
                                // left:50%;

                            }
                        }
                        .wrap_nav{
                            //background-color:#996600;
                            background-color:$mainColor;
                            float:left;
                            min-height:45px;
                            width:100%;
                            a{
                                float:left;
                                width:100%;

                                &.column-2
                                {
                                    width:50%;
                                }
                                &.column-3
                                {
                                    width:33.3333%;
                                }
                                
                                .onglet{
                                    text-align: center;
                                    @include mainFont($package);
                                    color: #fff;
                                    // font-style: italic;
                                    font-weight: 500;
                                    line-height: 25px;
                                    border-right: 1px solid rgba(246, 246, 246, 0.8);
                                    padding: 10px 6px;
                                    letter-spacing: 1px;
                                    text-decoration: none;
                                    font-size: 14px;
                                    font-size: 1.4rem;

                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow:ellipsis;
                                }
                                transition:all 0.3s;


                                @media all and (max-width:768px){
                                    &.column-2,
                                    &.column-3,
                                    {
                                        width: 100%;
                                        border-bottom:1px solid rgba(255, 255, 255, 0.3);

                                        &:last-child
                                        {
                                            border-bottom:0;
                                        }
                                    }
                                }
                            }
                            a:last-child{
                                .onglet{
                                    border-right: none;
                                }
                            }
                            a:hover,
                            a:focus{
                                text-decoration: none;
                                background:#000;
                            }
                        }
                        .content_info{
                            width:100%;
                            float:left;
                            background-color:#fff;
                            // height:254px;
                                padding-bottom: 30px;
                            text-align:center;
                            h2{
                                color:#2c2b2b;
                                font-size:18px;
                                font-size:1.8rem;
                                float:left;
                                //font-family: 'Signika', sans-serif;
                                @include secondFont($package);
                                font-weight: 600;
                                text-transform: uppercase;
                                text-align:center;
                                clear:both;
                                width:100%;
                                margin: 30px 0 20px 0;
                            }
                            address, h3{
                                color:#2c2b2b;
                                font-size:14px;
                                font-size:1.4rem;
                                float:left;
                                //font-family: 'Asap', sans-serif;
                                @include mainFont($package);
                                font-weight: 600;
                                text-transform: uppercase;
                                text-align:center;
                                clear:both;
                                width:100%;
                                margin:2px 0;
                            }
                            p{
                                color:#000007;
                                font-size:13px;
                                font-size:1.3rem;
                                float:left;
                                //font-family: 'Asap', sans-serif;
                                @include mainFont($package);
                                font-weight: 400;
                                text-align:center;
                                clear:both;
                                width:80%;
                                margin-left:10%;
                                img{
                                    margin-left:6px;
                                }

                            }
                        }
                    }


                    @media (max-width: 960px) and (min-width: 641px)
                    {
                        width:50%;
                        // &:nth-child(3n + 1)
                        // {
                        //  clear:none;
                        // }
                        // &:nth-child(2n + 1)
                        // {
                        //  clear:left;
                        // }
                    }

                    @media (max-width: 640px)
                    {
                        width:100%;
                        padding:10px 5px;

                        // clear:left !important;
                    }
                }
            }
        }
        .wrapper_location:last-child{
            border:none;
        }
    }
}

.privateDinningFooter{
    background-color:#f6f6f6!important;
    // max-height:380px;
    .locationFooter{
        .content_restaurants_list{
            float: left;
            position: relative;
            // max-height: 300px;
            column-count:3;
            

            .content_city{
                float:left;
                width:100%;
                height:100%;
                margin-bottom:20px;
                page-break-inside: avoid;
                break-inside: avoid;
                
                .city_title{
                    color:#2c2b2b;
                    font-size:13px;
                    font-size:1.3rem;
                    @include mainFont($package);
                    text-transform: uppercase;
                    font-weight: 600;
                }
                .restaurant_title{
                    color:$mainColor;
                    float:left;
                    clear: both;
                    @include mainFont($package);
                    letter-spacing: 1px;
                    line-height: 20px;
                }
                .restaurant_title:hover,
                .restaurant_title:focus{
                    text-decoration: underline;
                }
            }
        }
    }
}
.footerMenu{
    background-color:#e1e1e1;
    // height:380px;
    .menu-footer-container{
        #menu-footer{
            list-style-type: none;
            padding: 0;
            float: left;
            margin: 35px 0 50px 0;
            font-size: 0;
            li,a{
                text-decoration: none;
                color:#2c2b2b;
                font-size:14px;
                font-size:1.4rem;
                @include mainFont($package);
                font-weight: 600;
                text-transform:uppercase;
                width:auto;
                display: inline-block;
                padding-right: 5px;
            }
            li{
                margin-bottom:20px;
                width:50%;
                vertical-align: top;
            }
            a{
                transition:color 0.3s ease;
            }
            // li,a:hover,a:focus{
            //  color:#996600!important;
            // }
        }
    }
}

@media all and (max-width:640px){
    .privateDinningFooter{
        max-height:100%;
        .col-xs-3{
            width:100%!important;
        }
        .content_restaurants_list{
            padding:20px 0;
        }
    }
    .footerMenu .menu-footer-container #menu-footer{
        margin:35px 0;

        li:last-child{
            margin-bottom: 0;
        }
    }
}

@media all and (max-width:1220px){
    .privateDinningFooter{
        max-height:100%;
    }
    .footerMenu{
        .menu-footer-container #menu-footer li {
            margin-bottom: 5px;
            width: 100%;
        }
    }
}


#bodyId_privateDinningPage{
    footer{
        // // border-top: solid #2c2b2b 7px;
        // .container a.btn{
        //     background-color:#996600!important;
        // }
        // .container a.btn:hover,
        // .container a.btn:focus{
        //     background-color:#0a0000!important;
        // }
        // .container .social a:hover,
        // .container .social a:focus{
        //     background-color:#996600!important;
        // }
        // div{
        //     max-width:100%;
        // }
    }
    .copy{
        background-color:#2c2b2b;
        float:left;
        color:#f6f6f6;
        @include mainFont($package);
        letter-spacing: 1px;
        width:100%;
        font-size:11px;
        font-size:1.1rem;
        padding:0 70px;
        .pull-left,.pull-right{
            margin:20px 0;
        }
    }
}


/* ==========================================================================
    Restaurant
    ========================================================================== */
.defaultTemplate{padding:0;}
.defaultTemplate .container{position:relative; background:$mainColor;}

// .defaultTemplate .banner{
//     width:100%; position:relative; height:250px; overflow:hidden;
//     &:before{
//         content:"";
//         width:100%;
//         height:100%;
//         position: absolute;
//         top:0;
//         left:0;
//         background:rgba(0,0,0,0.9);
//         opacity:0.4;
//     }
// }
// .defaultTemplate .banner .txt{position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); left:0; z-index:10; width:100%; }
// .defaultTemplate .banner .txt h1{
//     font-size:60px; 
//     font-size:6rem;
//     @include secondFont($package); 
//     font-weight:600; 
//     text-align:center; 
//     text-transform:uppercase; 
//     letter-spacing:2px; 
//     margin:0 30px;

//     span{padding: 10px 20px; background-color: #fff; color: #000;}
// }
// .defaultTemplate.noFeatured .banner .txt h1
// {
//     font-size:36px;
//     font-size:3.6rem;
//     color:#fff;
// }
// .defaultTemplate .banner .txt span.devider{display:none;width:30px; height:2px; margin:0 auto 13px; background:#fff; margin:0 auto;}
// .defaultTemplate .banner .txt p{display:none;@include mainFont($package); font-size:14px; font-size:1.4rem;font-weight:700; color:#f6f6f6; text-align:center; margin:17px 0 0 0;}

.defaultTemplate .main{margin:0 auto; padding:55px 0 20px; text-align:center; float:none; margin:0 auto;}
.defaultTemplate .main article{width:100%; margin:0 auto;}
.defaultTemplate .main article blockquote {
    border: none;
    margin:0;
    padding:0;
    text-transform: uppercase;
    font-weight: 700;
}
.defaultTemplate .main article blockquote p{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px!important;
    font-size: 2.2rem!important;
    color: #101010;
    line-height: 30px;
    font-weight: 700;
    max-width: 730px;
}
.defaultTemplate .main article h3{
    @include secondFont($package);
    .bundle3 &{@include mainFont($package)}
    font-size:22px;
    font-size:2.2rem;
    color:#101010;
    line-height:30px;
    font-weight:700;
    max-width:730px;
    margin:15px auto 25px;
    text-transform:uppercase;
}
.defaultTemplate .main article img{
    max-width:730px; 
    height:auto; 
    margin:0 auto 25px;
}

.defaultTemplate .main .bx-wrapper{
    margin-bottom: 25px;
}

.defaultTemplate .main .slider img{
    margin:0 auto;
}
.defaultTemplate .main article p, .newsDetailsTemplate .main article p{
    max-width:730px; 
    margin:0 auto 28px; 
    @include mainFont($package);  
    color:#000007; 
    line-height:26px;
}
.defaultTemplate .main article p a{color:$thirdColor; text-decoration:underline; @include transitioned('color', 0.3s);text-decoration:underline; }
.defaultTemplate .main article p a:hover, .defaultTemplate .main article p a:focus{color:$secondColor;text-decoration:none;}

.defaultTemplate .team{text-align:center; padding:45px 0 40px 0; background:#ebeaea;}
.defaultTemplate .organizations{text-align:center; padding:45px 0 40px 0;}
.defaultTemplate h2, .dinningTemplate .txt h2{
    
    
    @include mainFont($package);
    .bundle3 &{@include secondFont($package)}
    font-weight:600;
    font-size:20px;
    font-size:2.0rem;
    color:#101010;
    text-transform:uppercase;
    letter-spacing:1px;
    margin:0 0 15px 0;
    padding-bottom:20px;
    position: relative;
    &:before{
        width:30px;
        height:2px;

        display:block;
        background:#ccc7c6;
        position: absolute;
        content:'';
        left:50%;
        bottom:0;
        -webkit-transform:translateX(-50%);
                transform:translateX(-50%);
    }
}

.defaultTemplate .team .devider{margin:0 auto -25px;}
.defaultTemplate article{width:400px; margin:65px 90px 0; padding:0px; display:inline-block; float:none; vertical-align:top;}
.listing article[class*="col"], .listing h2{
    text-align: center;
}
[class*="listing"], .listing_Holder{
    display:inline-block;
    &.center{text-align: center;}
    text-align: left;
    width:1748px;
    article{
        padding:0;
        margin: 65px 90px 0;
        width:400px;
        float:none;
        display:inline-block;
        text-align: center;
        padding-bottom:30px;
        [class*="bundle"] &{padding-bottom: 50px;}
        position: relative;
    }
}
.listing_Holder article{padding-bottom:0;}
#secondary .listing_Holder{
    // padding-top:25px;
    border-top:1px solid #ccc7c6;
    margin-top:60px;
    a{
        color:#101010;
        text-decoration: none;
        display: inline-block;
        transition:all 0.25s;
        &:hover,
        &:focus{
            text-decoration: none;
            opacity:0.6;
        }
    }
}

[class*="listing"] article p, .menuTemplate .desc.categ p, .menuTemplate .sectionDesc p{
    @include mainFont($package); 
    font-size:14px; 
    font-size:1.4rem;
    color:#000007; 
    margin:0 0 0px 0; 
    line-height:20px;
    text-align: center !important;
}

[class*="listing"] article p a{color:$thirdColor; text-decoration:underline; @include transitioned('color', 0.3s);}
[class*="listing"] article p a:hover, [class*="listing"] article p a:focus{color:$secondColor;text-decoration:none; }
[class*="listing"] article .ctaHolder {
    position: absolute;
    bottom:0;
    text-align: center;
    width:100%;
    max-height: 50px;
}
[class*="listing"] article .ctaHolder a.btn{
    background:$thirdColor; 
    @include mainFont($package); 
    font-weight:700; 
    // font-style:italic; 
    font-size:14px; 
    font-size:1.4rem;
    text-decoration:none; 
    color:#fff; 
    @include rounded-corners($radius: 0px); 
    padding:8px 20px 8px 20px;  
    @include transitioned('background', 0.3s);
    // margin-top: 18px;
    &:hover,
    &:focus{background:darken($thirdColor,10%);}
}
.defaultTemplate article img{height:auto; display:block; margin:0 auto 18px; background:$mainColor; @include transitioned('opacity', 0.3s);}
.defaultTemplate .team article a:hover img, .defaultTemplate .team article a:focus img{filter: alpha(opacity=60); opacity:0.6;}
.defaultTemplate article h3{@include secondFont($package); font-weight:700; font-size:18px; font-size:1.8rem;margin:0 0 13px 0; text-transform:uppercase;}
.defaultTemplate article h3 a{color:#000007; text-decoration:none; @include transitioned('color', 0.3s);}
.defaultTemplate article h3 a:hover, .defaultTemplate article h3 a:focus{color:$thirdColor;}
.defaultTemplate article a:hover h3, .defaultTemplate article a:focus h3{text-decoration:underline;}
.defaultTemplate article p.txt,{@include mainFont($package); font-size:13px; font-size:1.3rem;color:#000007; margin:0 0 18px 0; line-height:20px; height:auto; font-weight: 400; text-transform: none;}
.defaultTemplate article p.txt a, .defaultTemplate article .content a{color:$thirdColor; text-decoration:none; @include transitioned('color', 0.3s);}
.defaultTemplate article p.txt a:hover, .defaultTemplate article .content a:hover , .defaultTemplate article p.txt a:focus, .defaultTemplate article .content a:focus{color:$secondColor;}
.defaultTemplate article .ctaHolder a.btn{background:$thirdColor; @include mainFont($package); font-weight:700; /*font-style:italic;*/ font-size:14px; font-size:1.4rem;text-decoration:none; color:#fff; @include rounded-corners($radius: 0px); padding:8px 20px 8px 20px; @include transitioned('background', 0.3s);}
// .defaultTemplate article .ctaHolder a.btn span{font-size:8px; display:inline-block; margin:0 0 0 10px;}
.defaultTemplate article .ctaHolder a.btn:hover ,.defaultTemplate article .ctaHolder a.btn:focus{background:darken($thirdColor,10%);}

.defaultTemplate article .content h2{display:none;}

.defaultTemplate .join{background:$secondColor; text-align:center; padding:50px 0; width:100%;}
.defaultTemplate .join h2{@include secondFont($package); font-weight:600; font-size:20px; font-size:2rem;color:#e1dede; text-transform:uppercase; letter-spacing:1px; margin:0 0 15px 0;}
.defaultTemplate .join h3{@include mainFont($package); font-size:22px; font-size:2.2rem;color:#e1dede; line-height:30px; font-weight:700; max-width:730px; margin:15px auto 25px; text-transform:uppercase;}
.defaultTemplate .join p{max-width:720px; padding: 0 20px; @include mainFont($package); font-size:17px; font-size:1.7rem;color:#e1dede; margin:0 auto 23px; line-height:20px;}
.defaultTemplate .join p a:not(.btn){color:#fff!important; text-decoration:underline; @include transitioned('color', 0.3s);}
.defaultTemplate .join p a:hover, .defaultTemplate .join p a:focus{color:#fff;text-decoration:none; }
.defaultTemplate .join p a.btn{
    background:#e1dede; @include mainFont($package); font-weight:700; /*font-style:italic;*/ font-size:14px; font-size:1.4rem;text-decoration:none; color:#101010; @include rounded-corners($radius: 0px); padding:8px 20px 8px 20px;  @include transitioned('all', 0.3s);
    .bundle4 & {
        font-weight: 500;
    }
}
.defaultTemplate .join p a.btn span{ margin:0 0 0 5px;}
.defaultTemplate .join p a.btn:hover, .defaultTemplate .join p a.btn:focus{background:$thirdColor; color:white;}

/* ==========================================================================
    Location
    ========================================================================== */
.locationTemplate{padding:0;}
.locationTemplate .container{position:relative; background:$mainColor;}

// .locationTemplate .intro{width:100%; padding:70px 0 60px; background:$secondColor;}
// .locationTemplate .intro .txt h1{
//     // color:$mainColor;
//     // font-size:36px; 
//     // font-size:3.6rem;
//     // @include secondFont($package); 
//     // font-weight:600; 
//     // text-align:center; 
//     // text-transform:uppercase; 
//     // margin: 0 30px 10px 30px; 
//     // letter-spacing:2px;
// }
// .locationTemplate .intro .txt span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
// .locationTemplate .intro .txt p{
//     // @include mainFont($package);
//     // font-size:14px;
//     // font-size:1.4rem;
//     // font-weight:700;
//     // color:#f6f6f6;
//     // text-align:center;
//     // margin:17px 0 0 0;
    
// }

.locationTemplate .info > div{padding:0; margin:0 auto; float:none;}
.locationTemplate .info > div > div{padding:50px 0 70px 30px;}
.locationTemplate .info h2{@include secondFont($package); font-weight:600; text-transform:uppercase; font-size:22px; font-size:2.2rem;color:#101010; letter-spacing:1px; margin:0 0 40px 0;}
.locationTemplate .info address{@include mainFont($package); font-size:18px; font-size:1.8rem;line-height:26px; margin:0 0 $hSpacing 0; width:350px;}
.locationTemplate .info a.btn{
    background:$thirdColor;
    @include mainFont($package);
    font-weight:700;
    // font-style:italic;
    font-size:14px;
    font-size:1.4rem;
    text-decoration:none;
    color:#ffffff;
    @include rounded-corners($radius: 0px);
    padding:8px 20px 8px 20px;
    @include transitioned('background', 0.3s);
    &:hover,
    &:focus{
        background:darken($thirdColor,10%);
    }
    .bundle4 & {
        font-weight: 500;
    }
}
// .locationTemplate .info a.btn span{font-size:8px; display:inline-block; margin:0 0 0 10px;}


.locationTemplate .info h5{@include mainFont($package); font-size:14px; font-size:1.4rem;color:#101010 !important; text-transform:uppercase; margin:0 0 20px 0;}
.locationTemplate .info table{width:100%; margin:0 0 $hSpacing 0;}
.locationTemplate .info table th,
.locationTemplate .info table td{
    float:left; 
    @include mainFont($package); 
    font-size:18px; 
    font-size:1.8rem;
    color:#101010; 
    line-height:1.2em; 
    font-weight: 400;
    width: 50%;
    margin-bottom:3px;
    font-weight: 400;
}
.locationTemplate .info table td:nth-child(2){float:right; @include mainFont($package); color:$thirdColor;}

.locationTemplate #google-map{width:100%; height:565px;}
.locationTemplate #google-map .gmnoprint,
.locationTemplate #google-map .gm-style-cc{top:0!important; bottom:inherit!important;}
.locationTemplate .locationHolder{
    // min-width: 1024px;
    width:66.66%;
}

/* ==========================================================================
    Social
    ========================================================================== */
.socialTemplate{padding:0;}
.socialTemplate .container{position:relative; background:$secondColor; border-bottom:0px solid $secondColor;}

// .socialTemplate .intro{width:100%; padding:50px 0 40px; background:$secondColor;}
.socialTemplate .intro .txt h1{
    // color:$mainColor; 
    // font-size:36px; 
    // font-size:3.6rem;
    // @include secondFont($package); 
    // font-weight:600; 
    // text-align:center; 
    // text-transform:uppercase; 
    // margin:0 30px 15px 30px; 
    // letter-spacing:2px;
    // text-shadow:5px 5px 5px rgba(0,0,0,0.5);
}
// .socialTemplate .intro .txt span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#404040;}
// .socialTemplate .intro .txt p{
//     @include mainFont($package); 
//     font-size:16px; 
//     font-size:1.6rem;
//     color:#f6f6f6; 
//     text-align:center; 
//     line-height:26px; 
//     padding:0px 30px 0 30px; 
//     max-width:410px; 
//     margin:0 auto;
// }
.socialTemplate .intro .txt p a.social{font-size:20px; font-size:2rem;margin:10px 3px 0 3px; display:inline-block;}
.socialTemplate .intro .txt p a{color:#f6f6f6; text-decoration:none; @include transitioned('color', 0.3s);}
.socialTemplate .intro .txt p a:hover, .socialTemplate .intro .txt p a:focus{color:$thirdColor;text-decoration:none;}

.socialTemplate .mosaique > div{padding:0;}
.socialTemplate .mosaique > div.instagram{
    overflow:hidden;
     -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position:relative;
    .loadingBar{
        position: absolute;
        left:0;
        top:0;
        height:0%;
        background:rgba(255,255,255,0.05);
        width:100%;

    }
    background:transparent;
    .bg{
        position: absolute;
        height:100%;
        width:100%;
        top:0;
        left:0;
        z-index: 1;
    }
    &:before{
        content:'';
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        opacity:0;
        -webkit-transition:opacity 0.5s;
                transition:opacity 0.5s;
        z-index: 10;
        background:-webkit-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
        background:linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
    }
    &:hover:before,
    &:focus:before{opacity:1;}
}
.socialTemplate .mosaique > div.instagram img{width:100%; height:100%; display:block;}
.socialTemplate .mosaique > div.instagram .info{
    transition:all 0.5s;
    position:absolute;
    width:100%;
    height:100%;
    z-index: 15;
    // background:url(../img/layout/shadowInstagram.png) bottom center no-repeat;
    top:100%;
    left:0;
    .user{
        position: absolute;
        line-height: 4px;
        bottom:40px;
        width: 100%;
        padding-left:112px;
        padding-right:82px;
    }
}
.socialTemplate .mosaique > div.instagram:hover .info, .socialTemplate .mosaique > div.instagram:focus .info{top:0%;}

.socialTemplate .mosaique > div.instagram .info .profilePic{width:66px; height:66px; display:block; position:absolute; z-index:10;bottom:27px; left:27px; @include rounded-corners($radius: 33px); background-size:cover;}
.socialTemplate .mosaique > div.instagram .info h3{
    @include secondFont($package);
    font-weight:700;
    text-transform:uppercase;
    font-size:15px;
    font-size:1.5rem;
    color:#e1dede;
    margin:0;
}
.socialTemplate .mosaique > div.instagram .info h4{
    a{
        color:inherit;
    }
    @include mainFont($package);
    font-weight:400;
    text-transform:uppercase;
    font-size:12px;
    font-size:1.2rem;
    color:$thirdColor;
    margin:0;

}
.socialTemplate .mosaique > div.instagram .info span{color:#e1dede; font-size:19px; font-size:1.9rem;position:absolute; bottom:43px; right:40px;}

.socialTemplate .mosaique > div.twitter .profilePic{
    background-size:cover;
    background-position:center center;
    width:66px; 
    height:66px; 
    display:block; 
    margin:0 auto 18px; 
    @include rounded-corners($radius: 33px); 
    background-color:$secondColor;  
    @include transitioned('opacity', 0.3s);
    &:hover,
    &:focus{opacity:0.7;}
}
.socialTemplate .mosaique > div.twitter img:hover, .socialTemplate .mosaique > div.twitter img:focus{filter: alpha(opacity=60); opacity:0.6;}
.socialTemplate .mosaique > div.twitter h3{@include secondFont($package); font-weight:700; text-transform:uppercase; font-size:15px; font-size:1.5;color:#e1dede; text-align:center; width:250px; margin:0 auto 5px;}
.socialTemplate .mosaique > div.twitter h4{@include mainFont($package); font-weight:400; text-transform:uppercase; font-size:12px; font-size:1.2rem;text-align:center; width:250px; margin:0 auto 15px;}
.socialTemplate .mosaique > div.twitter h4 a{color:$thirdColor; text-decoration:none; @include transitioned('color', 0.3s);}
.socialTemplate .mosaique > div.twitter h4 a:hover, .socialTemplate .mosaique > div.twitter h4 a:focus{color:#e1dede;}
.socialTemplate .mosaique > div.twitter span.devider{width:30px; height:2px; margin:0 auto; display:block; background:#404040;}
.socialTemplate .mosaique > div.twitter p{
    @include mainFont($package); 
    font-weight:400; 
    color:#e1dede; 
    font-size:17px; 
    font-size:1.7rem;
    line-height:26px; 
    width:250px; 
    margin:15px auto 0; 
    text-align:center;
}
.socialTemplate .mosaique > div.twitter p a{color:$thirdColor; text-decoration:underline; @include transitioned('color', 0.3s);}
.socialTemplate .mosaique > div.twitter p a:hover, .socialTemplate .mosaique > div.twitter p a:focus{color:#e1dede;text-decoration:none;}
.socialTemplate .mosaique > div.twitter span.icon-twitter{color:#e1dede; font-size:19px; font-size:1.9rem;margin:20px auto 0; display:block; text-align:center;}
.socialTemplate .mosaique .twitter .info{
    display:table;
    height:100%;
    width:100%;
    & > div{
        display:table-cell;
        vertical-align: middle;
    }
}
.socialTemplate .container{
    border-bottom:0;
}
.socialTemplate .more{
    // border-bottom:1px solid #ccc7c6;
    // height:36px;
    float:none;
    text-align:center;
    clear:both;
    position: relative;
    &:before{
        content:'';
        background:rgba(255,255,255,0.1);
        position: absolute;
        top:37px;
        width:90%;
        height:1px;
        left:5%;
    }
}
.socialTemplate .more p{margin:0; height:70px;padding-top: 16px;}
.socialTemplate .more a{
    display:inline-block;
    @include mainFont($package);
    font-weight:700;
    // font-style:italic;
    font-size:14px;
    font-size:1.4rem;
    text-decoration:none;
    color:#e1dede;
    background:$secondColor;
    padding:13px 48px 13px;
    position: relative;

    @include transitioned('color', 0.3s);
    &.disabled, &.disabled:hover, &.disabled:focus{
        color:rgba(#101010,50%/100.0%);
    }
}
.socialTemplate .more a:hover, .socialTemplate .more a:focus{color:$thirdColor;}
.socialTemplate .more a span{font-size:11px; font-size:1.1rem;display:inline-block; margin:0 0 0 10px;}
.socialTemplate .loadMore{
    text-align: center;
    // margin:6px 0 4px;
    width:100%;
    background:$secondColor; 
    position: relative;
}

/* ==========================================================================
    Green Initiative
    ========================================================================== */
.greenTemplate{padding:0;}
.greenTemplate .container{position:relative; background:$mainColor;}

// .greenTemplate .banner{
//     width:100%; position:relative; overflow:hidden;background:$secondColor!important;
//     // &:before{
//     //  content:'';
//     //  position: absolute;
//     //  top:0;
//     //  left:0;
//     //  width:100%;
//     //  height:100%;
//     //  background:rgba($greenColor,50%/100.0%);
//     // }
// }
// .greenTemplate .banner .txt{position:relative; z-index:10; width:100%;padding:60px 40px;}
// .greenTemplate .banner .txt h1{font-size:60px; font-size:6rem;@include secondFont($package); font-weight:600; color:#fff; text-align:center; text-transform:uppercase; letter-spacing:2px; margin:0 0 10px 0; }
// .greenTemplate .banner .txt span.devider{width:30px; height:2px; margin:0 auto 15px; display:block; background:#fff;}
// .greenTemplate .banner .txt h2{font-size:36px; font-size:3.6rem;@include secondFont($package); font-weight:600; color:#fff; text-align:center; text-transform:uppercase; letter-spacing:2px; margin:0 0 10px 0;}
// .greenTemplate .banner .txt p{@include mainFont($package); font-size:13px;font-size:1.3rem;font-weight:700; color:#fff; text-align:center; margin:17px auto 0; max-width:740px; padding: 0 20px;}
// .greenTemplate .banner .txt p a{color:#fff;text-decoration: underline;}
// .greenTemplate .banner .txt p a:hover, .greenTemplate .banner .txt p a:focus{text-decoration: none;}
.greenTemplate .mosaique{
    background:$greenColor; @include transitioned('all',0.25s);

}
.greenTemplate .mosaique div{padding:0; position:relative; cursor:pointer;}
.greenTemplate .mosaique a.elmt{
    display:block;
    background:$secondColor;
    padding:0;
    margin:0;
}
.greenTemplate .mosaique div .content{
    position:absolute;
    top:50%;
    -webkit-transform:translate(-50%,-50%);
            transform:translate(-50%,-50%);
    left: 50%;
    z-index: 10;
    padding:15px;
    background-color: #fff;

    &.location{
        background-color:transparent;

        > *, > .view, > .view span{
            color:#fff;
        }
        
        span.devider{
            background-color: #fff;
        }
    }
}
.greenTemplate .mosaique div .content img{margin:0 auto 12px; display:block;}
.greenTemplate .mosaique div .content h3{@include secondFont($package); font-weight:600; font-size:31px; font-size:3.1rem; text-transform:uppercase; color:$secondColor; text-align:center; margin:0 0 13px 0;}
.greenTemplate .mosaique div .content h4{@include secondFont($package); font-weight:400; font-size:25px; font-size:2.5rem; text-transform:uppercase; color:$secondColor; text-align:center; margin:0 0 13px 0;}
.greenTemplate .mosaique div .content span.devider{width:30px; height:2px; margin:0 auto 18px; display:block; background:$secondColor;}
.greenTemplate .mosaique div .content p{text-align:center; margin:0;}
.greenTemplate .mosaique div .content > p.view{color:$secondColor; text-decoration:none; /*font-style:italic;*/ font-weight:700; @include mainFont($package); letter-spacing:1px; font-size:14px; font-size:1.4rem;text-align:center;}
.greenTemplate .mosaique div .content > p.view span{font-size:8px; font-size:0.8rem;display:inline-block; margin:0 0 0 5px;}
.greenTemplate .mosaique div .content > p a.social{color:$secondColor; font-size:19px; font-size:1.9rem; text-decoration:none; margin:0 4px;}
.greenTemplate .mosaique div .bg{
    width:100%; height:100%; position:absolute; top:0; left:0; z-index:5; background:$greenColor;@include transitioned('all',0.25s)
}
.greenTemplate .mosaique a.elmt{
    background:$greenColor;
    display:block;
    padding:0;
    .bgImg{
        // transition:opacity 0.25s;
        position: relative;
        &:before{
            content:'';
            position: absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;
            // background: rgba(0,0,0,0.8);
            opacity: 0.4;
            display: block;
            
        }
        &:after{
            content:'';
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:rgba(120, 113, 33,0);
            -webkit-transition:0.25s  all;
                    transition:0.25s  all;
        }

    }
    // &:hover .bgImg{opacity:0.7;}
    &:hover .bgImg:after,
    &:focus .bgImg:after{
        background:rgba(120, 113, 33,0.2)
    }
}
.greenTemplate .mosaique div a.elmt:hover .bg, .greenTemplate .mosaique div a.elmt:focus .bg{
    background:darken($greenColor,5%);
}

.greenTemplate .mosaique.lt-1024{
    display:none;
    a.elmt{
        position: relative;
        display:block;
        background-size:cover;
        div.content{
            position: relative;
            display:inline-block;
            -webkit-transform:translateX(-50%);
                    transform:translateX(-50%);
            top:auto;
            padding: 50px;
            min-width: 281px;

            @media(max-width:640px){
                padding: 20px;
                min-width: 221px;
            }
        }
    }
}

/* ==========================================================================
    Green Initiative Details
    ========================================================================== */
.greenDetailsTemplate{padding:0;}
.greenDetailsTemplate .container{position:relative; background:$mainColor; border-bottom:0px solid $secondColor;}

// .greenDetailsTemplate .intro{width:100%; padding:45px 0 50px; background:$greenColor;}
// .greenDetailsTemplate .intro .txt img{margin:0 auto 15px; display:block;}
// .greenDetailsTemplate .intro .txt h1{color:$mainColor; font-size:36px; font-size:3.6rem; @include secondFont($package); font-weight:600; text-align:center; text-transform:uppercase; margin:0 0 10px; letter-spacing:2px;text-shadow:5px 5px 5px rgba(0,0,0,0.5);}
// .greenDetailsTemplate .intro .txt span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
// .greenDetailsTemplate .intro .txt p{@include secondFont($package); font-size:23px; font-size:2.3rem;font-weight:400; color:#f6f6f6; text-align:center; margin:17px 0 0 0;}

.greenDetailsTemplate .pointList{float:left; width:100%; padding:40px 0 80px 0;}
.greenDetailsTemplate .pointList li{padding:0; text-align:center;}
.greenDetailsTemplate .pointList > div{margin:55px auto 0; float:none;}
.greenDetailsTemplate .pointList > div > ol > li:nth-child(odd) {clear:left;}
.greenDetailsTemplate .pointList > .title{font-size:22px; font-size:2.2rem; @include mainFont($package); font-weight: 600; padding:0 20px; max-width:660px; margin:70px auto 0; text-align:center; letter-spacing:1px; line-height:26px;}
.greenDetailsTemplate .pointList > .sousTitle{font-size:12px; font-size:1.2rem; @include mainFont($package); text-transform:uppercase; font-weight:700; padding:0 20px; max-width:660px; margin:45px auto 0; text-align:center; letter-spacing:1px;}
.greenDetailsTemplate .pointList > h2,
.greenDetailsTemplate .pointList > h3,
.greenDetailsTemplate .pointList > h4,
.greenDetailsTemplate .pointList > h5{
    text-align: center;max-width:660px;margin:20px auto 0;
    
    a img{
        margin-bottom: 20px;
        height: auto;
    }
}
.greenDetailsTemplate .pointList .point{display:inline-block; padding: 0 6%;}
.greenDetailsTemplate .pointList .point div{border-top:1px solid #ccc7c6; padding:60px 0 0 0; margin:25px auto 0;}
// .greenDetailsTemplate .pointList .point.lastRight.lastRight.lastRight div{border-bottom:1px solid #ccc7c6; padding-bottom:25px;margin-bottom:-25px;}
.greenDetailsTemplate .pointList .point:nth-child(1) div{border:none;}
.greenDetailsTemplate .pointList .point span{width:50px; height:50px; display:block; margin:0 auto; @include rounded-corners($radius: 25px); background:$greenColor; }
.greenDetailsTemplate .pointList .point span i{position: relative;top: 50%;transform: translate(0px, -50%);display:block;@include secondFont($package); font-size:18px; font-size:1.8rem; font-weight:400; color:#fff;}
.greenDetailsTemplate .pointList .point p{@include mainFont($package); font-size:16px; font-size:1.6rem; line-height:26px; width:90%; margin:15px auto 0;}

.greenDetailsTemplate .stickyMore{position:absolute; top:305px; right:200px; z-index:100; padding:0; text-align:right;}
.greenDetailsTemplate .stickyMore >div{width:320px;}
.greenDetailsTemplate .stickyMore .seeAll{text-decoration:none; text-transform:uppercase; @include mainFont($package); color:$greenColor; font-size:14px; font-size:1.4rem; font-weight:700; letter-spacing:1px; @include transitioned('color', 0.3s);}
.greenDetailsTemplate .stickyMore .seeAll:hover, .greenDetailsTemplate .stickyMore .seeAll:focus{color:$secondColor;}
.greenDetailsTemplate .stickyMore .seeAll span{font-size:10px; font-size:1.0rem; display:inline-block; margin:0 0 0 5px;}

/* ==========================================================================
    Friends
    ========================================================================== */
.friendsTemplate{padding:0;}
.friendsTemplate .container{position:relative; background:$mainColor; border-bottom:0px solid $secondColor;}

// .friendsTemplate .intro{width:100%; padding:45px 0 50px; background:$secondColor;}
// .friendsTemplate .intro .txt h1{color:$mainColor; font-size:36px; font-size:3.6rem; @include secondFont($package); font-weight:600; text-align:center; text-transform:uppercase; margin:0 0 10px; letter-spacing:2px;  padding: 0px 30px; text-shadow:5px 5px 5px rgba(0,0,0,0.5);}
// .friendsTemplate .intro .txt span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
// .friendsTemplate .intro .txt p{@include mainFont($package); font-size:13px; font-size:1.3rem; font-weight:700; color:#f6f6f6; text-align:center; margin:17px auto 0; max-width:740px; padding: 0px 30px;}

.friendsTemplate .friendList{float:left; width:100%; padding:80px 0;}
.friendsTemplate .friendList div{padding:0; text-align:center;}
.friendsTemplate .friendList > div{margin:55px auto 0; float:none;}
.friendsTemplate .friendList img{margin:0 auto 30px; width: 100%; max-width:740px; height:auto; float:none; display:block;}
.friendsTemplate .friendList h3, .friendsTemplate .content h2{

    @include secondFont($package);
    font-weight:600;
    font-size:20px;
    font-size:2.0rem;
    text-transform:uppercase;
    color:#101010;
    letter-spacing:1px;
    text-align:center;
    margin:0 auto 15px;
    padding: 0px 20px;
    
}
.friendsTemplate .content{
    margin-top:0 !important;
}
.friendsTemplate .content h2{
    margin-bottom:30px;
    position: relative;
    &:before{
        width:30px;
        height:2px;

        display:block;
        background:#ccc7c6;
        position: absolute;
        content:'';
        left:50%;
        bottom:-17px;
        -webkit-transform:translateX(-50%);
                transform:translateX(-50%);
    }
}
.friendsTemplate .friendList span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#c3bdbc;}
.friendsTemplate .friendList p{@include mainFont($package); font-size:16px; font-size:1.4rem;color:#101010; line-height:26px; padding: 0px 20px; max-width:710px; margin:0 auto; text-align:center;}
.friendsTemplate .friendList .friend{float:left; padding: 0 6%;}
.friendsTemplate .friendList .friend:nth-child(3n+1){clear:left;}
.friendsTemplate .friendList .friend div{padding:30px 0 0 0; margin:25px auto 0;}
// .friendsTemplate .friendList .friend div{border-top:1px solid #ccc7c6; padding:60px 0 0 0; margin:25px auto 0;}
// .friendsTemplate .friendList .friend.lastRight div, .greenDetailsTemplate .lastRight div{border-bottom:1px solid #ccc7c6; padding-bottom:26px; margin-bottom:-26px;}
// .friendsTemplate .friendList .friend.lastRight + div, .greenDetailsTemplate .lastRight + div{clear:both;}
// .friendsTemplate .friendList .friend:nth-child(1) div, .friendsTemplate .friendList .friend:nth-child(2) div{padding:0; border:none;}
.friendsTemplate .friendList .friend h3{@include mainFont($package); font-size:16px; font-size:1.6rem; font-weight:700; color:#101010; text-transform:uppercase; margin:0 auto 5px;}
.friendsTemplate .friendList .friend p{@include mainFont($package); font-size:16px; font-size:1.6rem; line-height:26px; width:90%; margin:0 auto 5px;}
.friendsTemplate .friendList .friend a{color:$thirdColor; @include mainFont($package); font-size:16px; font-size:1.6rem; margin:0 auto; text-align:center; display:inline; text-decoration:none; @include transitioned('color', 0.3s);word-wrap: break-word;}
.friendsTemplate .friendList .friend a:hover, .friendsTemplate .friendList .friend a:focus{color:$secondColor;}

/* ==========================================================================
    Products
    ========================================================================== */
.productsTemplate{padding:0;}
.productsTemplate .container{position:relative; background:$mainColor; border-bottom:0px solid $secondColor;}


// .productsTemplate .intro{width:100%; padding:45px 0 50px; background:$secondColor;}
// .productsTemplate .intro .txt h1{
//     color:$mainColor; 
//     font-size:36px; 
//     font-size:3.6rem;
//     @include secondFont($package); 
//     font-weight:600; 
//     text-align:center; 
//     text-transform:uppercase; 
//     margin:0 0 10px; 
//     padding: 0px 30px;
//     letter-spacing:2px;
//     text-shadow:5px 5px 5px rgba(0,0,0,0.5);
// }
// .productsTemplate .intro .txt span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
// .productsTemplate .intro .txt p{
//     @include mainFont($package); 
//     font-size:13px; 
//     font-size:1.3rem;
//     font-weight:700; 
//     color:#f6f6f6; 
//     text-align:center; 
//     margin:17px auto 0; 
//     padding: 0px 30px;
//     max-width:740px;
// }

.productsTemplate .main{
    margin:0 auto; padding:60px 0 70px; text-align:center; float:none;
width: 100%;
max-width: 940px;}
.productsTemplate .main article{width:100%; margin:0 auto;}
.productsTemplate .main div{padding:0; display:inline-block;}
.productsTemplate .main div img{
    margin:0 auto; 
    width:100%; 
    height:auto; 
    display:block;
}
.productsTemplate .main div h5{text-align:left; @include mainFont($package); font-size:14px; font-size:1.4rem;font-weight:700; color:$thirdColor; text-transform:uppercase; margin:10px 0 20px;}
.productsTemplate .main div h2{
    color:#272727; 
    font-size:36px; 
    font-size:3.6rem;
    @include secondFont($package); 
    font-weight:600; 
    text-align:center; 
    line-height:44px; 
    text-align:left; 
    text-transform:uppercase; 
    margin:0 0 10px; 
    // word-break: break-all;
    padding-right: 20px;
    letter-spacing:2px;
}
.productsTemplate .main div h6{text-align:left; margin:0 0 27px 0;}
.productsTemplate .main div p{text-align:left;}

.productsTemplate .otherProduct{text-align:center; padding:50px 0 60px 0; background:#ebeaea;}
.productsTemplate .otherProduct h2{@include secondFont($package); font-weight:600; font-size:20px; font-size:2rem; color:#101010; text-transform:uppercase; letter-spacing:1px; margin:0 0 15px 0;}
.productsTemplate .otherProduct .devider{width:30px; height:2px; margin:0 auto -25px; display:block; background:#ccc7c6;}
.productsTemplate.productsTemplate.productsTemplate article{
    // margin:65px 0px 0; 
    // padding:0px; 
    vertical-align: top;
    display:inline-block;
    padding-bottom:30px;
    // vertical-align:top;
}
.productsTemplate article img{
    width:100%;
    height:auto;
    display:block;
    margin:0 0 18px 0;
    background:$mainColor;
    @include transitioned('opacity', 0.3s);

}
.productsTemplate .listingHolder.listingHolder.listingHolder article{
    padding-bottom: 50px;
}
.productsTemplate .listingHolder article img{
    max-height: 275px;
    max-width: 100%;

    width: auto;
    display:block;
    margin:0 auto 18px auto;
    background:$mainColor;
    @include transitioned('opacity', 0.3s);

}
.productsTemplate .otherProduct article img:hover, .productsTemplate .otherProduct article img:focus{filter: alpha(opacity=60); opacity:0.6;}
.productsTemplate article time{@include mainFont($package); text-transform: uppercase;font-size:12px; font-size:1.2rem;margin:0 0 18px 0; color:#000007; margin:0 0 18px 0; display:block; letter-spacing:1px;}
.productsTemplate article time span{text-transform:uppercase;}
.productsTemplate article h3{@include secondFont($package); font-weight:700; font-size:18px; font-size:1.8rem; margin:0 0 13px 0; text-transform:uppercase;}
.productsTemplate article h3 a{color:#000007; text-decoration:none; @include transitioned('color', 0.3s);}
.productsTemplate article h3 a:hover, .productsTemplate article h3 a:focus{color:$thirdColor;}
.productsTemplate article h6{font-size:12px; font-size:1.2rem; @include mainFont($package); text-transform:uppercase; letter-spacing:1px; color:#101010;}
// .productsTemplate article p{@include mainFont($package); font-size:13px; color:#000007; margin:0 0 18px 0; line-height:20px;}
.productsTemplate article .ctaHolder a{color:$thirdColor; text-decoration:none; @include transitioned('color', 0.3s);}
.productsTemplate article .ctaHolder a:hover, .productsTemplate article .ctaHolder a:focus{color:$secondColor;}
.productsTemplate article .ctaHolder a.btn{background:$thirdColor; @include mainFont($package); font-weight:700; /*font-style:italic;*/ font-size:14px; font-size:1.4rem;text-decoration:none; color:#fff; @include rounded-corners($radius: 0px); padding:8px 20px 8px 20px;  @include transitioned('background', 0.3s);}
// .productsTemplate article .ctaHolder a.btn span{font-size:8px; display:inline-block; margin:0 0 0 10px;}
.productsTemplate article .ctaHolder a.btn:hover, .productsTemplate article .ctaHolder a.btn:focus{background:darken($thirdColor,10%);}

.productsTemplate .more{border-bottom:1px solid #ccc7c6; margin:50px auto 10px; float:none; text-align:center; clear:both;}
.productsTemplate .more p{margin:0 0 -9px 0;}
.productsTemplate .more a{@include mainFont($package); font-weight:700; /*font-style:italic;*/ font-size:14px; font-size:1.4rem;text-decoration:none; color:#101010; background:#ebeaea; padding:10px 48px; @include transitioned('color', 0.3s);}
.productsTemplate .more a:hover, .productsTemplate .more a:focus{color:$thirdColor;}
.productsTemplate .more a span{font-size:11px; font-size:1.1rem; display:inline-block; margin:0 0 0 10px;}

/* ==========================================================================
    FAQ
    ========================================================================== */
.faqTemplate{padding:0;}
.faqTemplate .container{position:relative; background:#e1dede; border-bottom:0px solid $secondColor;}

// .faqTemplate .intro{width:100%; padding:45px 0 50px; background:$secondColor;}
// .faqTemplate .intro .txt h1{color:$mainColor; font-size:36px; font-size:3.6rem; @include secondFont($package); font-weight:600; text-align:center; text-transform:uppercase; margin:0 0 10px; letter-spacing:2px; text-shadow:5px 5px 5px rgba(0,0,0,0.5);}
// .faqTemplate .intro .txt span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
// .faqTemplate .intro .txt p{@include mainFont($package); font-size:13px; font-size:1.3rem; font-weight:700; color:#f6f6f6; text-align:center; margin:17px auto 0; width:740px;}

.faqTemplate .faq{margin:0 auto; float:none; padding:10px 0 65px 0;}
.faqTemplate .faq h3{@include secondFont($package); font-weight:600; font-size:20px; font-size:2rem;text-transform:uppercase; color:#101010; letter-spacing:1px; text-align:center; margin:62px auto 0; padding:0 0 20px 0; background:url(../img/layout/iconDevider.png) center bottom no-repeat;}
.faqTemplate .faq p{@include mainFont($package); font-size:16px; font-size:1.6rem; line-height:26px; text-align:center; width:700px; margin:20px auto 0; color:#101010;}
.faqTemplate .faq p a{color:$thirdColor; text-decoration:none; @include transitioned('color', 0.3s);}
.faqTemplate .faq p a:hover, .faqTemplate .faq p a:focus{color:#101010;text-decoration:underline; }

/* ==========================================================================
    Contact
    ========================================================================== */
.contactTemplate{padding:0;}
.contactTemplate .container{position:relative; background:$mainColor;}

// .contactTemplate .intro{width:100%; padding:45px 0 50px; background:$secondColor;}
// .contactTemplate .intro .txt h1{
//     color:$mainColor; 
//     font-size:36px; 
//     font-size:3.6rem;
//     @include secondFont($package); 
//     font-weight:600; 
//     text-align:center; 
//     text-transform:uppercase; 
//     margin:0 0 10px; 
//     padding: 0px 30px;
//     letter-spacing:2px;
//     text-shadow:5px 5px 5px rgba(0,0,0,0.5);

//     span{
//         padding: 10px 20px;
//         background-color: #fff;
//         color: #000;
//     }
// }
// .contactTemplate .intro .txt span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
// .contactTemplate .intro .txt p{
//     @include mainFont($package); 
//     font-size:13px; 
//     font-size:1.3rem;
//     font-weight:700; 
//     color:#f6f6f6; 
//     text-align:center; 
//     margin:17px auto 0; 
//     max-width:740px;
//     padding: 0px 30px;
// }

.contactTemplate .contactInfo{margin:0 auto; float:none; padding:40px 0 55px 0;}
.contactTemplate .contactInfo div.section{border-top:1px solid #ccc7c6; padding:45px 0 0 0; margin:0 0 40px 0;}
.contactTemplate .contactInfo div.section:nth-child(1){border:none;}
.contactTemplate .contactInfo h2{
    @include mainFont($package); 
    font-size:22px; 
    font-size:2.2rem;
    font-weight:700; 
    text-transform:uppercase; 
    color:#101010; 
    letter-spacing:1px; 
    text-align:center; 
    margin:20px auto 0;
    padding: 0px 30px;
}
.contactTemplate .contactInfo h3{
    @include secondFont($package); 
    font-weight:600; 
    font-size:20px; 
    font-size:2rem;
    text-transform:uppercase; 
    color:#101010; 
    letter-spacing:1px; 
    text-align:center; 
    margin:0 auto 0; 
    padding:0 0 20px 0;
    padding-left: 30px;
    padding-right: 30px; 
    background:url(../img/layout/iconDevider.png) center bottom no-repeat;
}
.contactTemplate .contactInfo p{@include mainFont($package); font-size:16px; font-size:1.6rem; line-height:26px; text-align:center; max-width:700px; padding: 0 20px; margin:20px auto 0; color:#101010;}
.contactTemplate .contactInfo p a{color:$thirdColor; text-decoration:underline; @include transitioned('color', 0.3s);}
.contactTemplate .contactInfo p a:hover, .contactTemplate .contactInfo p a:focus{color:#101010;text-decoration:none; }

/* ==========================================================================
    404
    ========================================================================== */
.pageErrorTemplate{padding:0;}
.pageErrorTemplate .container{position:relative; background:$mainColor; border-bottom:0px solid $secondColor;}

// .pageErrorTemplate .intro{width:100%; padding:45px 0 50px; background:$secondColor;}
// .pageErrorTemplate .intro .txt h1{color:$mainColor; font-size:36px; font-size:3.6rem; @include secondFont($package); font-weight:600; text-align:center; text-transform:uppercase; margin:0 0 10px; letter-spacing:2px; text-shadow:5px 5px 5px rgba(0,0,0,0.5);}
// .pageErrorTemplate .intro .txt span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#fff;}
// .pageErrorTemplate .intro .txt p{@include mainFont($package); font-size:13px; font-size:1.3rem; font-weight:700; color:#f6f6f6; text-align:center; margin:17px auto 0; width:740px;}

.pageErrorTemplate .errorMessage{margin:0 auto; float:none; padding:80px 0 55px 0; min-height:340px;}
.pageErrorTemplate .errorMessage h2{@include mainFont($package); font-size:22px; font-size:2.2rem; font-weight:700; text-transform:uppercase; color:#101010; letter-spacing:1px; text-align:center; margin:0 auto;}



/* ==========================================================================
    Helper classes
    ========================================================================== */
.hideText {text-indent: 100%;white-space: nowrap;overflow: hidden;}
.ir {background-color: transparent;border: 0;overflow: hidden;*text-indent: -9999px;}
.ir:before {content: "";display: block;width: 0;height: 150%;}
.hidden {display: none !important; visibility: hidden;}
.visuallyhidden {border: 0;clip: rect(0 0 0 0);height: 1px;margin: -1px;overflow: hidden;padding: 0;position: absolute;width:1px;}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {clip: auto;height: auto;margin: 0;overflow: visible;position: static;width: auto;}
.invisible {visibility: hidden;}
.clearfix:before,
.clearfix:after {content: " ";display: table;}
.clearfix:after {clear: both;}
.clearfix {*zoom: 1;}

.cover-center{
    background-size:cover;
    background-position: center center;
    
}
.bgFixed{
    background-attachment: fixed;
    .mobileOnly & {
        background-attachment: scroll;
    }

}
/* Typo
=================================================================== */
    .main_content.main_content.main_content{
        h2{
            @include mainFont($package);
            .bundle3 &{@include secondFont($package)}
            font-weight:600;
            font-size:20px;
            font-size:2rem;
            color:#101010;
            text-transform:uppercase;
            letter-spacing:1px;
            margin:0 0 15px 0;
            padding-bottom:20px;
            position: relative;
            &:before{
                width:30px;
                height:2px;

                display:block;
                background:#ccc7c6;
                position: absolute;
                content:'';
                left:50%;
                bottom:0;
                -webkit-transform:translateX(-50%);
                        transform:translateX(-50%);
            }
        }
        font-size:16px;
        font-size:1.6rem;
        overflow:hidden;
        p{
            font-size:16px;
            font-size:1.6rem;
            color:#101010;
            a:hover,
            a:focus{
                color:#101010;
            }
        }
        ul,ol{
            display:inline-block;
            padding:0;
            margin:0;
            text-align: left;
            line-height: 26px;
            margin-bottom:25px;
            li{
                list-style: none;
                position:relative;
                padding-left:10px;
                &:before{
                    position: absolute;
                    content:'';
                    left:0;
                    top:10px;
                    height:5px;
                    width:5px;
                    border-radius:100%;
                    background:#101010;
                }

            }
        }

        ol{
            content-reset:ol;
            li{counter-increment: ol; padding-left:20px;}
            li:before{
                background:transparent;
                top:0;
                content:counter(ol);
                color:$thirdColor;
            }
        }
        h1{
            text-transform: uppercase;
        }
        h4{
            font-size: 16px;
            font-size:1.6rem;
            text-transform: uppercase;

        }

    }
    // .banner.banner.banner p{
    //     font-size:14px;
    //     font-size:1.4rem;
    //     font-weight: 700;
    //     .bundle4 & {
    //         font-weight: 500;
    //     }
    //     letter-spacing: 1.8px;
    //     // .dinningTemplate & {font-weight: 700;}
    // }

/* Forms
=================================================================== */
    .formTemplate{
        $f_bg:#e1dede;
        section.success{
            background:#e1dede;
            padding:25px;
            margin-bottom: 35px;
            h2{
                padding:0;
                margin:0;

                position: relative;
                &:before{
                    content:'';
                    position: absolute;
                    border-radius: 100%;
                    background:darken($f_bg,15%);
                    @include square(40px);
                    top: -9px;
                    left: 25px;
                }
                &:after{
                    -webkit-transition:width 0.25s;
                            transition:width 0.25s;
                    width:40px;
                    height:40px;
                    left: 6px;
                    top: -9px;
                    position: absolute;
                    content:'';
                    background:url(../img/layout/checkbox.png) 12px center no-repeat;
                }
                @media all and (max-width:640px){
                    padding-top:40px;
                    &:before{
                        left:50%;

                    }
                    &:after{
                        left:50%;
                        margin-left:-20px;
                    }
                }
            }
        }
        .main{
            // width:33.33333333%;
            width:635px;
            .bundle1 &.success{
                width:696px;
            }
            @media all and (max-width:640px){
                width:100% !important;
            }
        }
        

        .dropdown-toggle.selectpicker{
            background:$f_bg;
            span{color:#101010;}
            box-shadow:none;
        }
        .open .dropdown-toggle.selectpicker{
            background:darken($f_bg,5%);
        }
        .dropdown-menu.inner.selectpicker{
            text-align: left;
            li{
                background:darken($f_bg,5%);
                a{color:#101010; padding-left:15px;}
                // a:hover, &.selected a{background:darken($f_bg,6%);}
            }
        }
        .bootstrap-select.btn-group .btn .filter-option.filter-option.filter-option.filter-option{
            font-style: normal;
            @include secondFont($package);
            letter-spacing: normal;
            text-overflow: ellipsis;
            font-size:13px;
            font-size:1.3rem;
        }
        .dropdown-menu.inner.selectpicker span.text.text{
            @include secondFont($package);
        }
        input,textarea{
            background:$f_bg;
            border:none;
            border-radius: 0;
            box-shadow: none;
            padding:13px 16px;
            font-weight: bold;
            font-size:13px;
            font-size:1.3rem;
            @include secondFont($package);
            color:#101010;
        }
        textarea{
            height:100px;
        }
        input{height:44px;}
        @mixin placeholder(){
            color: #666;
            font-weight: bold;
            font-size:13px;
            font-size:1.3rem;
            @include secondFont($package);


        }
        textarea::-webkit-input-placeholder {
           @include placeholder;
        }

        textarea:-moz-placeholder { /* Firefox 18- */
           @include placeholder;
        }

        textarea::-moz-placeholder {  /* Firefox 19+ */
           @include placeholder; 
        }

        textarea:-ms-input-placeholder {  
           @include placeholder;
        }
        input::-webkit-input-placeholder {
           @include placeholder;
        }

        input:-moz-placeholder { /* Firefox 18- */
           @include placeholder;
        }

        input::-moz-placeholder {  /* Firefox 19+ */
           @include placeholder; 
        }

        input:-ms-input-placeholder {  
           @include placeholder;
        }
        .checkbox_holder{
            display:inline-block;
            font-size: 16px;
            font-size:1.6rem;
            .checkbox{padding:0; margin:0  0 10px;}
            input{position: absolute; left:-9999px;}
            label, label * {vertical-align: middle;}
            label{display:block; text-align: left; cursor:default;}
            label div{
                cursor:pointer;
                margin-right:15px;
                display: inline-block;
                width:40px;
                height:40px;
                background:$f_bg;
                position: relative;
                &:before{
                    -webkit-transition:width 0.25s;
                            transition:width 0.25s;
                    width:0%;
                    height:100%;
                    left:0;
                    top:0;
                    position: absolute;
                    content:'';
                    background:url(../img/layout/checkbox.png) 12px center no-repeat;
                }
            }
            label span{
                @include secondFont($package);
                font-weight: 600;
                font-size: 15px;
                font-size:1.5rem;
            }
            input:checked + label div{

                &:before{
                    width:100%;
                }
            }
        }
        .question h4, .intro h4{
            font-size:16px;
            font-size:1.6rem;
            @include secondFont($package);
            font-weight: 700;
            @extend .aa;
            line-height: 24px;
            margin-bottom:15px;

        }
        .intro{
            margin-bottom: 58px;
            position: relative;
            &:after{
                content:'';
                position: absolute;
                width: 30px;
                height: 2px;
                display: block;
                background: #ccc7c6;
                left:50%;
                margin-left:-15px;
                bottom: -27px;
            }

        }
        input:focus,textarea:focus{
            box-shadow: none;
        }
        button{
            display: block;
            margin:0 auto;
            border:none;
            &:hover,
            &:focus{
                background:darken($f_bg,5%);
            }
        }
    }
    .btn-group.open .dropdown-toggle{box-shadow:none;}

/* SplashScreen
=================================================================== */
    #bodyId_splash{
        
        footer{border-top:none;}
        .fullheight{
            //min-width: 1024px;
            background-size:cover;
            background-position: center center;
            position: relative;
            &:before{
                content:'';
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background:rgba(0,0,0,0) !important;
            }
            ul{
                margin:0;
                padding:0;
                height:100%;
                li{
                    display:block;
                    height:100%;
                    a{
                        display:block;
                        height:100%;
                        text-align: center;
                        position: relative;
                        background:rgba(0,0,0,0);
                        transition:all 0.25s;
                        border-bottom:1px solid rgba(255,255,255,0.2);
                        &:before{
                            content:'';
                            position: absolute;
                            top:0;
                            left:0;
                            height:100%;
                            width:100%;
                            background: url(../img/layout/fadeSplash.png) no-repeat center center;
                        }
                        
                        .holder{
                            width:100%;
                            position: absolute;
                            top:50%;
                            -webkit-transform:translateY(-50%);
                            -mos-transform:translateY(-50%);
                            transform:translateY(-50%);
                            h1{
                                font-size:50px;
                                font-size:5rem;
                                @include secondFont($package);
                                font-weight:600;
                                color:#fff;
                                text-align:center;
                                text-transform:uppercase;
                                letter-spacing:2px;
                                margin:0 0 10px 0;
                                max-width: 590px;
                                margin:0 auto 7px;
                                position: relative;
                                text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
                                
                                &:before{
                                    width:30px;
                                    height:2px;
                                    content:'';
                                    position: absolute;
                                    background:white;
                                    bottom: -19px;
                                    left:50%;
                                    margin-left:-15px;
                                }
                            }
                            address{
                                @include mainFont($package);
                                text-transform: uppercase;
                                color:#fff;
                                letter-spacing: 1px;
                                max-width: 590px;
                                margin:0 auto 10px;
                                display: inline-block;
                                padding: 9px 19px 8px 19px;
                                background: black;
                                transition:all 0.25s;
                                margin-top: 35px;
                                font-size:12px;
                                font-size:1.2rem;
                                i{
                                    font-size: 10px;
                                    font-size:1rem;
                                    position: relative;
                                    left: 6px;
                                }
                                
                            }
                            span{
                                @include mainFont($package);
                                font-size:15px;
                                font-size:1.5rem;
                                font-weight:700;
                                color:#f6f6f6;
                                text-align:center;
                                margin:17px auto 0;
                                max-width:740px;
                                letter-spacing: 2px;
                                // font-style: italic;
                                max-width: 590px;
                                margin:0 auto;
                                i{
                                    font-size: 11px;
                                    font-size:1.1rem;
                                    margin-left: 5px;
                                }
                            }
                        }
                        &:hover,
                        &:focus{
                            background:rgba(0,0,0,0.3);
                            address{
                                background:white;
                                color:black;
                            }
                        }
                    }
                    &:last-child a{border-bottom:1px solid transparent}
                }
            }
        }
    }


/* ==========================================================================
   Print styles
   ========================================================================== */
@media print {
    * {background: transparent !important;color: #000 !important; /* Black prints faster: h5bp.com/s */box-shadow: none !important;text-shadow: none !important;}
    a,a:visited {text-decoration: underline;}
    a[href]:after {content: " (" attr(href) ")";}
    abbr[title]:after {content: " (" attr(title) ")";}
    /*
     * Don't show links for images, or javascript/internal links
     */
    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {content: "";}
    pre,blockquote {border: 1px solid #999;page-break-inside: avoid;}
    thead {display: table-header-group; /* h5bp.com/t */}
    tr,img {page-break-inside: avoid;}
    img {max-width: 100% !important;}
    @page {margin: 0.5cm;}
    p,h2,h3 {orphans: 3;widows: 3;}
    h2,h3 {page-break-after: avoid;}
}

/* ==========================================================================
   Responsive styles
   ========================================================================== */

.mobileDeviceOnly{
    display: none !important;
}

@media all and (max-width: 1765px){
    [class*="listing"], .listing_Holder{
        width:1508px;
        article{
            width:400px;
            margin-left:50px;
            margin-right:50px;
        }
    }
    // footer > .container .social{
    //     width:auto;
    //     min-width: 0;
    // }
    // footer > .container .logo{
    //     float:right;
    // }
}
@media all and (max-width: 1650px){
    .newsDetailsTemplate .stickySocial{
        left:78px;
        width:auto;
    }
    .newsDetailsTemplate .stickyMore{
        width:auto;
        right:78px;
    }
}
@media all and (max-width: 1590px){
    .newsTemplate .upcoming article{
        max-width: 30%;
    }
    [class*="listing"], .listing_Holder{
        width:1358px;
        article{
            margin-left:50px;
            margin-right:50px;
            width:350px;
        }
    }
    // header .navbar.navbar .nav li li{
    //     font-size:12px;
    //     font-size:1.2rem;
    // }
}
@media all and (max-width: 1500px) {

    .newsDetailsTemplate .stickySocial{
        left:38px;
    }
    .newsDetailsTemplate .stickyMore{
        right:38px;
    }
    .dinningTemplate nav{margin-left:-41px;}
    // header .navbar .nav li.left li{
    //     padding-left: 5%;
    // }
    // header .navbar .nav li.right li{
    //     padding-right: 5%;
    // }

    // header .navbar .nav{
    //     width: calc(100% - 474px);
    // }
    
    // header .tool.pull-right{
    //     margin-right: $hSpacing;
    // }
    // header .restaurant.pull-left{
    //     margin-left: $hSpacing;
    // }
    
    // header .topnav .news{
    //     padding-left: $hSpacing;
    // }
    // header .topnav .links{
    //     padding-right: $hSpacing;
    // }
    
    // footer > .container > div > div {
    //     padding: 50px $hSpacing;
    //     // max-width: 425px;
    //     margin: 0 auto;
    // }
    
    // footer .copy address{
    //     margin-left: $hSpacing;
    // }
    // footer .copy p{
    //     margin-right: $hSpacing;
    // }
    
    // footer nav a{ 
    //     font-size: 13px !important;
    //     font-size: 1.3rem !important;
    // }
    
}

@media all and (max-width: 1400px) {

    // .productsTemplate .otherProduct  article > div,
    // .newsDetailsTemplate article > div,
    // .newsTemplate article > div,
    // .homeTemplate .news .container article > div{
    //  width:350px;
    //  margin-left: auto;
    //  margin-right: auto;
    // }
    
    // header .navbar .nav{
    //     width:calc(100% - 400px);
    // }
    // header .restaurant.pull-left{
    //  max-width: 175px; 
        
    // }

    // header .navbar .tool.pull-right {
    //     max-width: 175px; 
        
    //     a{
    //         float: none;
    //         clear: both;    
    //         display: block;
    //         margin: 0px;
    //     }
    // }
    // header .navbar .tool.pull-right a:nth-child(2){
    //     margin-top: 10px;
    // }
    
    // header .navbar .nav li li{
    //     font-size: 13px;
    //     font-size: 1.3rem;
    // }
    
        
    // 
    
    .socialTemplate .mosaique > div.instagram{
        .info{
            bottom:-300px;
            height:100%;
            background:rgba(0,0,0,0.7);
            .content-holder{
                position: absolute;
                width:100%;
                top:50%;
                -webkit-transform:translateY(-50%);
                        transform:translateY(-50%);
                text-align: center;
                padding:25px;

                div,h3,h4,span{
                    display:inline-block;
                    position: static;
                }
                .profilePic{
                    height: 50px;
                    width: 50px;
                    position: static;
                    margin: 0 auto
                }
                span{
                    margin-top:15px;
                }
                .user{
                    position: static;
                    padding:0;
                    line-height: 18px;
                }
            }
        }
    }
}
@media all and (max-width:1375px){
    [class*="listing"], .listing_Holder{
        width:1148px;
        article{
            margin-left: 25px;
            margin-right: 25px;
            width: 330px;
        }
    }

    .homeTemplate .news article{
    
        // &:last-child{display:none;}
    }
    .greenTemplate .mosaique div .content h3{font-size:27px;font-size:2.7rem;}
    .greenTemplate .mosaique div .content h4{font-size:21px;font-size:2.1rem;}
}
@media all and (max-width:1320px){
    .dinningTemplate .categ .room .slider, .dinningTemplate .categ .room .noSlider,[id*="carousel_"]{
        max-width: 690px;
        img{width: 100%;}
    }
    [id*="carousel_"]{margin:0 auto;}
}
@media all and (max-width: 1300px) {
    // footer > .container nav{
    //     padding-top:0;
    // }
    .foodCateg .col-lg-3{
        width: 16%;
    }

    .socialTemplate .mosaique > div.twitter p{
        font-size: 12px;
        font-size:1.2rem;
        line-height: 1.3em;
        width: 230px;
    }
    .socialTemplate .mosaique > div.twitter .profilePic{
        height: 50px;
        width: 50px;
    }
    .homeTemplate .mosaique div .content h3{
        font-size: 24px;
        font-size:2.4rem;
    }

    .menuTemplate nav, .dinningTemplate nav{
        margin-left: 0px;
        padding-left: 0px;
    }
    
    .reservation .container{
        max-width: 964px;
    }
    .reservation .txt{
        margin-bottom: 0px;
        margin-top: 50px;
        max-width:750px;
        width: 100%;
    }
    .reservation .txt p{
        display: inline-block;
        width: 100%;
        margin-right: 20px;
        margin-bottom: 0px;
        vertical-align: top;
    }
    // .reservation .txt p:last-child{
    //  max-width: 300px;
    // }
    .reservation form::after{
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        clear: both;
    }
    .reservation form{
        clear: both;
        float: left;
        margin-top: 0px;
        padding-bottom: 40px;
    }
    
    
    // header .navbar .nav li.left li{
    //     padding-left: 3%;
    // }
    // header .navbar .nav li.right li{
    //     padding-right: 3%;
    // }

    // footer > .container nav div{
    //     clear: both;
    // }
    .locationTemplate .locationHolder{
        width:75%;
    }
}
@media all and (max-width:1220px){
    .newsDetailsTemplate .stickySocial,
    .newsDetailsTemplate .stickyMore,
    {
        &,div{width:115px;}
        p{margin-bottom:10px;}
        p + a{margin-left:-6px;}
    }
    .newsDetailsTemplate .stickyMore{
        .mobilecut{display:none;}
    }
    // footer > .container .social{
    //     width: 30px;
    //     margin-top:0;
    //     a{ margin-bottom: 6px;}
    // }
}
@media all and (max-width: 1200px) {

    // .standardPage header .navbar .restaurant .bootstrap-select > button {
    //     padding: 0 27px 0 42px;
    //     .filter-option{
    //         display: none;
    //     }
    // }

    // .splashPage header .navbar .nav{
    //     width: calc(100% - 295px);

    // }
    // header .navbar .restaurant .bootstrap-select .dropdown-menu, 
    // header .navbar .giftcard .bootstrap-select .dropdown-menu{
    //     width: auto;
    //     right: auto;
    //     left: 0;
    //     top: 43px;
    // }
    // header .restaurant.pull-left{
    //     width: 72px;
    // }
    // header .navbar .nav{
    //     width: calc(100% - 220px);
    // }
    // header .navbar .restaurant > li > a span{
    //     margin-left: 0px;
    // }

    // header .navbar {
    //     .locations{
    //         &.white{
    //             select{
    //                 text-indent: 100px;
    //             }               
    //         }
    //     }
    // }
    // header .navbar .tool a span.mobileHide{
    //  display: none;
    // }
    .dinningTemplate .categ.categ > div{
        padding-left:45px;
    }
    // .standardPage header .icomoon-giftcard{
    //     font-size: 19px;
    //     font-size:1.9rem;
    //     position: relative;
    //     top: -1px;
    // }
    // .standardPage header .icomoon-calendar{
    //     font-size: 21px;
    //     font-size:2.1rem;
    // }
    // .standardPage header .navbar .tool a .mobileHide{
    //     //display:none;
    // }
    // .standardPage header .navbar .tool a .mobileShow{
    //     display: inline-block;
    // }
    // .standardPage header .navbar .tool{
    //     position: relative;
    //     &.one{height:43px;}
    //     height:96px;
    //     width: 72px;
    //     max-width: inherit;
    // }
    // .standardPage header .navbar .tool a{
    //     min-width: 72px;
    //     padding: 0 0 0 20px;
    //     height:43px;
    //     line-height: 43px;
    //     padding-right: 36px;
    //     position: absolute;
    //     right: 0;
    //     overflow: hidden;
    //     // max-width: 72px;
    //     @include transitioned('padding',0.25s);
    //     span{
    //         display: inline-block;
    //         vertical-align: middle;
    //         opacity: 0;
    //         max-width:0;
    //         overflow: hidden;
    //         padding-right: 0;
    //         -webkit-transform-origin: right 50%;
    //         -ms-transform-origin: right 50%;
    //         transform-origin: right 50%;
    //         @include transitioned('all',0.25s);
    //     }
    //     [class*="icon"]{
    //         position: absolute;
    //         right: 10px;
    //     }
    //     &:hover,
    //     &:focus{opacity:1; max-width: 165px;padding-right: 36px;}
    //     &:hover span,
    //     &:focus span{

    //         opacity:1;
    //         max-width:165px;
    //     }
    //     &.openReservation,&.openResy{top:0;}
    //     &:last-child{bottom:0;}
    //     &:after{
    //         @include transitioned('all',0.25s);
    //         content:'';
    //         height:100%;
    //         width:50px;
    //         position: absolute;
    //         left:0;
    //         top:0;
    //     }
    //     &.openReservation:after,&.openResy:after, &:last-child:after{
    //         background-image:url(../img/layout/header_gift_calendar.png);
    //         background-position: 13px 12px;
    //         background-repeat: no-repeat;
    //     }
    //     &.gift:after{
    //         background-position: 13px -42px;
    //     }
    //     &:hover,
    //     &:focus{
    //         &:after{left:-50px;}
    //         // padding-left: 20px;
            
    //     }
    //     &.openReservation:hover,&.openResy:hover,&.openReservation:focus,&.openResy:focus{
            
    //         background-position: -25px 12px;
    //     }
    //     &:last-child:hover,
    //     &:last-child:focus{
            
    //         background-position: -25px -42px;
    //     }
    // }
    // .standardPage header .topnav .links a.link.mobileShow{display:inline-block}
    // .standardPage header .tool.pull-right{
    //     margin-right:41px;
    // }
    // .standardPage header .navbar.navbar .nav li li{
    //     font-size: 13px;
    //     font-size:1.3rem;
    // }
    .greenTemplate .mosaique.gt-1024 div .content h3{font-size:22px; font-size:2.2rem;}
    .greenTemplate .mosaique.gt-1024 div .content h4{font-size:16px; font-size:1.6rem;}
    .greenTemplate .mosaique.gt-1024 div .content > p.view{font-size:13px; font-size:1.3rem;}
    
}

@media all and (max-width: 1180px) {
    // footer > .container nav div a{
    //     margin:0px 35px 12px 0px;
    // }

    // footer > .container .hourCol{
    //     padding: 50px 0;
    // }

    // footer > .container table:last-child{
    //     margin-bottom: -10px;
    // }

    .homeTemplate .news .container{
        text-align: center;
    }
    .homeTemplate .news article{
        margin-left: 25px !important;
        margin-right: 25px !important;
        width:288px;
    }

    // header .navbar .nav li.right li{
    //     padding-right:5%;
    // }
    // header .navbar .nav li.left li{
    //     padding-left:5%;
    // }
    [class*="listing"], .listing_Holder {
        width: auto;
        text-align:center;
        article{
            width:288px;
        }
    }
    .homeTemplate .bx_caption h2 a, #bodyId_splash .fullheight ul li a .holder h1{
        font-size: 38px;
        font-size: 3.8rem;
    }
    
}
@media all and (max-width: 1100px) {
    .menuTemplate .foodCateg .categ dt, .dinningTemplate .categ .menu p{
        font-size:14px;
        font-size:1.4rem;
        line-height: 18px;
    }
    .dinningTemplate .categ .room .slider, .dinningTemplate .categ .room .noSlider,[id*="carousel_"]{
        max-width: 580px;
        img{width: 100%;}
    }
    .dinningTemplate .room .intro{
        max-width: 580px;
        margin: 0 auto
    }
    .dinningTemplate .categ.categ > div{
        padding-left: 68px;
    }
    .dinningTemplate .categ.categ > .inquire{
        padding-left:0;
    }
    .dinningTemplate .categ .menu > .col-md-8 div:last-child{padding-right:4%}
    [class*="listing"], .listing_Holder {
        
        article{
            margin-left: 25px;
            margin-right: 25px;
        }
    }
    // header .navbar.navbar .nav li li{
    //     font-size: 12px;
    //     font-size:1.2rem;
    // }


}

@media all and (max-width: 1024px) {
    .dinningTemplate .banner .txt p{
        max-width:700px;
        margin:0 auto;
    }
    // header .topnav .news{
    //     max-width: 35%;
    // }
    // header .topnav .news strong{
    //     display: none;
    // }
    .greenTemplate .mosaique.gt-1024{display:none;}
    .greenTemplate .mosaique.lt-1024{
        display:block;
        > div{width:100% !important;}
    }
    .iPad .bootstrap-select.btn-group .btn .caret{
        margin-top:-6px;
    }
}

.foodCateg .col-sm-8{
    // width: 66.666%;
    float: left;
}
@media all and (min-width: 768px){
    .greenDetailsTemplate .pointList .point:nth-child(1) div, .greenDetailsTemplate .pointList .point:nth-child(2) div{padding:0; border:none;}


    .locationTemplate .locationHolder{
        width:auto;
    }
}

@media screen and (max-width: 640px) {
    // header .navbar .nav li.left,header .navbar .nav li.right{
    //     width:100%;
    // }

    // .splashPage header .navbar .restaurant .bootstrap-select > button {
    //     padding: 0 27px 0 42px;
    //     .filter-option{
    //         display: none;
            
    //     }
    // }

    // .splashPage header .navbar .nav{
    //     width: 100%;
    // }

    .defaultTemplate .main article img{max-width: 100%;}
    .greenTemplate .banner .txt h1{font-size:19px !important; font-size:1.9rem !important;}
    .greenTemplate .banner .txt h2{font-size:17px; font-size:1.7rem;}
    .homeTemplate .bx_caption h2 a{
        font-size:24px;
        font-size:2.4rem;
        display: inline-block;
    }
    #friendsHolder{margin-top:0;}
    .friendList .content h2{margin-bottom:0;}
    .friendsTemplate .friendList .friend:nth-child(2) div{
        // border-top: 1px solid #ccc7c6;
        padding: 30px 0 0 0;
        margin: 25px auto 0;
    }
    .newsTemplate .upcoming article{
        max-width: none;
    }
    .friendsTemplate .friendList .friend.fakeBorder, .greenDetailsTemplate .fakeBorder{display:none;}
    .locationTemplate .locationHolder{
        min-width:0;
        width:100%;
    }
    [class*="listing"], .listing_Holder{
        width:100%;
        text-align: center;
        margin-bottom:25px;
        article{
            margin-left:50px;
            margin-right:50px;
        }
    }
    .homeTemplate .news article{
        // display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .greenDetailsTemplate .stickyMore{
        top:auto;
        right:0;
        width:100%;
        div{
            width: 100%;
            text-align: center;
            a{
                margin-top:24px;
                display:inline-block;
            }
        }
    }
    .greenDetailsTemplate .pointList > div{margin-top:-55px;}
    .newsDetailsTemplate .main{
        padding-bottom: 0px;
        padding-top:30px;
    }
    .newsDetailsTemplate .main article{
        width:100%;
        max-width:none;
        img{
            max-width: 100%;
        }
    }
    .newsDetailsTemplate .main article p{
        padding-left: 30px;
        padding-right: 30px;
    }
    .newsDetailsTemplate .stickySocial{
        &,div{width:100%;}
        position: static;
        p{margin-bottom:0px;}
        p + a{margin-left:10px;}
        margin:25px 0 20px;
    }
    .newsDetailsTemplate .stickySocial,.newsDetailsTemplate .stickyMore{
        display:block;
        top:auto;
        left:auto;
        right: auto;
        &,div{width:100%;}
        text-align: center;
    }
    .newsDetailsTemplate .stickyMore{
        position: static;
        .mobilecut{display:inline;}
    }
    .newsTemplate .oldNews{
        padding-top: 45px;
    }
    
    .newsTemplate article{
        padding-bottom: 35px;
    }

    .newsDetailsTemplate article{
        width: 100%;
        max-width: 425px;
        float: none;
        padding-bottom: 25px;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    
    .newsDetailsTemplate .oldNews > h3{
        padding-bottom: 45px;
    }
    .homeTemplate .bx_caption h2{font-size:28px; font-size:2.8rem;}
    .homeTemplate .news article p{
        min-height: 0px !important;
    }
    
    .foodCateg .col-sm-8{
        width: auto;
        float: none;
    }
    .splashPage .restaurant.pull-left .dropdown{
        font-size: 0px;
        color: transparent;
    }
    // header{
    //     min-width: 320px;
    //     height: auto;
    // }
    // body > .wrapper{margin-top:0;}
    .mobileDeviceOnly{
        display: block !important;
    }

    .contactTemplate .contactInfo p{
        max-width: 425px;
    }
    html, body{
        min-width: 320px;
    }
    body{
        // padding-top: 65px;
    }
    
    // header{
    //     position: fixed;
    //     top:0px;
    //     .admin-bar & {top:30px;}
    //     left: 0px;
    //     right: 0px;
    //     z-index: 333;
    //     border-bottom:2px solid rgba(0,0,0,0.2);
    // }
    #wpadminbar{position: fixed !important; top:0;}
    // header .tool.pull-right{
    //     display: none;
    // }
    
    .homeTemplate .news .container article{
        width: 100%;
    }
    
    // .wrapper{
    //     margin: 0 auto;
    // }
    
    // header .navbar .nav li.logo{
    //     width: 50px;
    //     height: 50px;
    //     top: 7px;
    //     position: absolute;
    //     left: 50%;
    //     transform: translate(-50%,0);
    // }
    
    // header .navbar .nav li.logo img{
    //     width: 50px;
    //     height: 50px;
    // }
    
    // header .navbar .nav.closed{
    //     height: 65px;
    //     box-shadow: none;
    // }
    
    // header .navbar .nav{
    //     padding: 0px;
    //     position: absolute;
    //     top: 0px;
    //     padding-top: 65px;
    //     left: 0px;
    //     right: 0px;
    //     height: auto;
    //     width: 100%;
    //     transform:none;
    //     -webkit-transition: height 0.25s; /* For Safari 3.1 to 6.0 */
    //     transition: height 0.25s;
    // }
    
    // header .navbar{
    //     height: 65px;
        
    //     float: none;
        
    //     -webkit-overflow-scrolling: touch;
    // }
    
    // header .navbar.open{
    //     overflow: hidden;
    //     overflow-y: auto;
    // }
    
    // header .restaurant.pull-left{
    //     margin-top: 12px;
    //     margin-left: 20px;
    //     position: absolute;
    //     z-index: 888;
    // }
    
    // header .navbar .nav.closed li.right,
    // header .navbar .nav.closed li.left{
    //     display: none;
    // }
    
    // header .navbar .nav::after{
    //     position: absolute;
    //     top:0px;
    //     left: 0px;
    //     width: 100%;
    //     content: "";
    //     background: #F6F6F6;
    //     height: 65px;
    // }
    
    // header .navbar .nav li.right,
    // header .navbar .nav li.left{
    //     position: static;
    //     -webkit-transform: none;
    //             transform: none;
    //     background: #fff;
    //     display: block;

    // }
    // header .navbar.navbar .nav li.left ul,
    // header .navbar.navbar .nav li.right ul{
    //     text-align: left;
    //     padding: 0px;
    // }
    
    // header .navbar .nav li ul li{
    //     display: block;
    //     padding: 0px !important;
    // }
    
    // header .navbar .nav li.right a,
    // header .navbar .nav li.left a{
    //     padding: 11px 10px;
    //     display: block;
    //     text-align: center;
    //     border-top: solid #cecdcd 1px;
    //     font-size: 15px;
    //     font-size:1.5rem;
    //     font-weight: bold;
    //     // -webkit-font-smoothing: antialiased;
    // }
    
    // header .toggleMobileMenu{
    //     position: absolute;
    //     top: 0px;
    //     right: 0px;
    //     height: 65px;
    //     font-size: 17px;
    //     font-size:1.7rem;
    //     width: 65px;
    //     text-align: center;
    //     text-indent: -6px;
    //     line-height: 65px;
    //     cursor: pointer;
    //     z-index: 999;
        
    //     -webkit-touch-callout: none;
    //     -webkit-user-select: none;
    //     -khtml-user-select: none;
    //     -moz-user-select: none;
    //     -ms-user-select: none;
    //     user-select: none;
    // }
    
    // header .navbar .nav .mobileDeviceOnly a{
    //     // font-style: italic;
    //     text-transform: none;
    // }
    
    .home .mosaique{
        display: none;
    }
    
    // footer .col-xs-6{
    //     display: block;
    //     width: auto;
    //     float: none;
    // }
    
    // footer > .container table td:nth-child(2){
    //     float: none;
    // }
    
    // footer .col.right,
    // footer .col.left{
    //     width: 40% !important;
    //     clear: none;
    //     padding-right: 20px;
    // }
    
    // .productsTemplate .otherProduct  article > div,
    // .newsDetailsTemplate article > div,
    // .newsTemplate article > div,
    // .homeTemplate .news .container article > div{
    //  width: 280px;
    // }
    
    // header .topnav{
    //     display: none;
    // }
    
    // .homeTemplate .news .container article.listing p{
    //  padding: 0px;
    // }

    .dinningTemplate .stickyMenu{display:none;}
    .dinningTemplate{
        // .banner{
        //     height:250px;
        // }
        .room{
            .intro{
                padding: 0 15px;
            }
            padding:0;

            img{
                width:100%;
                height:auto;
            }
        }
        .categ.menu{
            .menu{
                padding-left:0;
                padding-right:0;
                p{
                    font-size: 14px;
                    font-size:1.4rem;
                }
            }
            .price{
                position: static;
                text-align: center;
                margin-top:-10px;
                font-size:14px;
                font-size:1.4rem;
                margin-bottom:10px;
            }
        }
    }
    .dinningTemplate .categ.menu{
        padding-bottom:0;
    }
    .dinningTemplate .categ.categ > div{
        // padding-left:10px;
        // padding-right:10px;
        h3{
            font-size:20px;
            font-size:2rem;
        }
        p{
            font-size:16px;
            font-size:1.6rem;
        }
    }
    .bx_pager{
        bottom:10px;
        li a{width:26px;}
    }
    
    // footer > .container{
    //     padding-top: 25px;
    // }
    
    // footer .copy{
    //     height: auto;
    // }
    
    // footer > .container .logo{
    //     display: none;
    // }
    
    // footer > .container > div > div{
    //     padding-top: 0px;
    //     padding-left: 20px;
    //     padding-right: 20px;
    //     padding-bottom: 35px;
    // }

    // footer > .container .hourCol{
    //     padding-top: 0px;
    //     padding-left: 20px;
    //     padding-right: 20px;
    //     padding-bottom: 35px;
    // }
    
    // footer > .container nav{
    //     padding-top: 25px;
    //     margin: 0 auto;
    //     margin-bottom: 5px;
    //     max-width: 375px;
    // }
    
    // footer > .container .social a{
    //     width: 50px;
    //     height: 50px;
    //     padding: 0px;
    //     line-height: 50px;
    // }
    
    // footer > .container nav div.left{
    //     width: 60% !important;
    // }
    
    // footer .copy address{
    //     margin-bottom: 10px;
    //     float: none !important;
    //     margin-right: $hSpacing;
    //     text-align: center;
    // }
    
    // footer .copy address span{
    //     display: block;
    // }
    
    // footer .copy p{
    //     float: none !important;
    //     display: block;
    //     margin: 0px;
    //     padding: 20px 25px;
    //     text-align: center;
    // }


    
    .homeTemplate .news .container article{
        margin: 10px 0 0;
        &.mobileHide{display:none !important;}
    }
    // .newsTemplate article,
    // .homeTemplate .news .container .seeAllHolder + article{
    //  display: block !important;
    //  max-width: 425px;
    //  margin: 0 auto;
    //  float: none;
    //  width: auto;
    // }
    
    .newsletter{
        .bg{display:none;}
        .content{
            top:0;
            bottom:0;
        }
        .left,.right{
            float:none;
            width:100%;
        }
        .requiredField{
            width:100%;
        }
        .requiredField.half{
            font-size:0;
            width:45%; &.first{width:50%;}
            label{
                margin:10px 0 9px 0;
            }
            .field{
                &.select{margin:8px 0 45px 0;}
                margin:0;
                display:block;
                float:none;
            }
        }
        .requiredField.half input{
            width:100%;
        }
    }
    .homeTemplate .news .container{
        padding-bottom: 25px;
        padding-top: 25px;
    }
    .reservation{
        max-height: 100%;
        overflow: auto;
    }
    // footer > .container .social{
    //     width: auto;
    //     max-width: 375px;
    //     margin: 0;
    //     float: none;
    //     padding-bottom: 25px;

    //     a{
    //         width: 30px;
    //         height: 30px;

    //         &:before{
    //             padding: 5px;
    //             min-width: 30px;
    //             margin: 0;
    //             vertical-align: top;
    //             font-size: 12px;
    //             font-size: 1.2rem;
    //         }
    //     }
    // }
    .listingHolder article{
        padding-bottom:60px;
        margin-bottom:30px;
        &:last-child{
            margin-bottom:0px;
        }
    }
    .dinningTemplate .categ .menu.menu > .col-md-8{
        border:none;
    }
    .dinningTemplate .categ .menu.special{
        border:2px solid #ccc7c6;
        padding-bottom:0;
        margin-bottom:25px;
        div.col-md-8{
            padding-bottom:0;
        }
    }
    .newsDetailsTemplate .title time{
        padding:0 10px;
    }
    
    .homeTemplate .news .container .seeAll{
        position: static;
        font-size: 11px;
        font-size:1.1rem;
    }
    .homeTemplate .news .container .seeAll span{
        margin-left: -3px;
    }
    
    .bx_inner>.item>img, 
    .bx_inner>.item>a>img{
        max-width: 300%;
        transform: translateX(-33%);
        -webkit-transform: translateX(-33%);
    }
    
    .homeTemplate .carousel-indicators li{
        width: 26px;
        height: 2px;
        margin: 0px 1px;
    }
    
    .homeTemplate .carousel-indicators li:hover, 
    .homeTemplate .carousel-indicators li:focus, 
    .homeTemplate .carousel-indicators li.active{
        margin: 0px 1px;
    }
    
    .homeTemplate .bx_caption span.devider{
        width: 15px;
        height: 1px;
    }
    
    .homeTemplate .news .container h2{
        margin-bottom: 0px;
        font-size: 24px;
        font-size:2.4rem;
    }
    
    // header .navbar .restaurant > li > a{
    //     height: 40px;
    //     line-height: 40px;
    // }
    
    li.dropdown > a span, div.dropdown > a span{
        margin-right: 10px;
    }
    
    .dropdown-menu{
        top:auto;
    }
    
    .homeTemplate .news .container article.listing p a.btn{
        margin-top: 8px;
    }
    
    .homeTemplate .news .container article h3{
        font-size: 16px;
        font-size:1.6rem;
    }
    
    // footer > .container > div:nth-child(2) > div:nth-child(2){
    //     max-width: none;
    // }
    
    // .menuTemplate .stickyMenu.open{
    //  bottom: 0px !important;
    //  top: auto !important;
    // }
    
    .menuTemplate .stickyMenu{
        width: 100%;
        top:auto;
        left: 50%;
        -webkit-transform: translateX(-50%) !important;
                transform: translateX(-50%) !important;
        right: 0px;
        position: fixed !important;
        bottom:0px;

        nav{
            // border-top:4px solid #101010;
            padding-bottom: 0px;
            ul{border-bottom:1px solid #ccc7c6;}
            ul li{
                border:none;
                position: relative;
                &:before{
                    border:none;
                    display:block;
                    content:'' !important;
                    position: absolute;
                    width:100%;

                    background:#ccc7c6;
                    height:1px;
                    left:0;
                }
            }
            & > ul > li:hover,
            & > ul > li:focus{
                // border:none;
            }
        }
        .download{
            margin-top:0;
        }
        // -webkit-transition: margin-top 0.5s;  For Safari 3.1 to 6.0 
        // transition: margin-top 0.5s;
    }
    .menuTemplate .stickyMenu.floating.top{
        position: fixed;
        top: auto;
    }
    .menuTemplate nav{
        float: none;
        padding: 0px;
        padding-bottom: 20px;
    }
    .menuTemplate .categ.menu{
        width:100%;
        margin-left:0;
        .subMenu{
            width:100%;
        }
    }
    // .menuTemplate .banner, .dinningTemplate .banner{
    //     height:auto;
    //     background-size:cover !important;
    //     background-attachment:scroll !important;
    //     div.txt{
    //         position: relative;
    //         padding:50px 0;
    //         transform:none;
    //         -webkit-transform:none;
    //     }
    // }
    .dinningTemplate nav ul, 
    .menuTemplate nav ul{
        width: auto;    
    }
    
    .dinningTemplate nav ul li.active:before, .menuTemplate nav ul li.active:before, .dinningTemplate nav ul li:hover:before, .menuTemplate nav ul li:hover:before, .dinningTemplate nav ul li:focus:before, .menuTemplate nav ul li:focus:before{
        content: none;
    }
    
    .stickyMenuMobileToggle{
        display: block;
        height: 40px;
        width: 50%;
        left:50%;
        -webkit-transform:translateX(-50%);
                transform:translateX(-50%);
        position: absolute;
        bottom: 100%;
        background: #000;
        text-align: left;
        line-height: 40px;
        color: #FFF;
        padding-left: 31px;
        text-transform: uppercase;
        @include mainFont($package);
        font-size: 11px;
        font-size:1.1rem;
        font-weight: bold;
    }
    
    .stickyMenuMobileToggle .icon-up-open{
        float: right;
        padding-right: 15px;
        padding-left: 15px;
        
        -webkit-transition: transform 0.25s; /* For Safari 3.1 to 6.0 */
        transition: transform 0.25s;
    }
    
    .menuTemplate .stickyMenu.open .icon-up-open{
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    .menuTemplate .stickyMenu{
        nav ul  ul{display:none;}
    }
    
    .menuTemplate nav .download{
        width: auto;
    }
    
    .dinningTemplate nav ul li:before, .menuTemplate nav ul li:before{
        display: none;
    }
    
    .menuTemplate .foodCateg .categ{
        width: 80%;
        margin: 0 auto;
        float: none;
    }
    
    .menuTemplate .foodCateg .categ .sectionDesc.sectionDesc{margin-bottom: 35px;}
    .menuTemplate .foodCateg .categ > .subMenu.subMenu.subMenu dl dt{
        margin: 0 0 20px 0;
    }
    
    .menuTemplate .foodCateg .categ dt{
        width: 90%;
    }
    .menuTemplate .foodCateg .categ dd{
        width: 10%;
        padding-left: 10px;
    }
    
    .menuTemplate .foodCateg .categ dl{
        margin: 0px;
    }
    
    .menuTemplate .foodCateg .categ .devider{
        margin-bottom: 30px;
    }
    
    .menuTemplate .banner .txt h2{
        margin: 0px 30px 10px 30px;
        font-size: 4px;
        font-size: 4rem;
    }
    
    .locationTemplate .info table td:nth-child(2){
        width: 100%;
    }
    
    .locationTemplate .info > div > div {
        padding: 50px 20px 10px 20px;
        max-width: 425px;
        margin: 0 auto;
    }
    
    .defaultTemplate .main article {
        max-width: 425px;
        padding: 0px 20px;
    }
    
    .locationTemplate #google-map{
        height: 170px;
    }
    
    .mosaique > div{
        width: 50% !important;
        &.instagram{
            // height: auto !important;
        }
    }
    
    .newsTemplate .main article img{
        width: 100%;
        margin-top: 35px;
    }
    
    .newsTemplate .main{
        padding-top: 50px;
        padding-bottom: 0px;
    }
    
    .newsTemplate .main article p{
        width: auto;
    }
    
    .newsTemplate .oldNews{
        padding-bottom: 5px;
    }
    
    .newsTemplate .upcoming article{
        margin: 0 auto;
        margin-top: 40px;
        display: none;
        &:first-child{display:inline-block; width:100%}
    }
    
    .newsTemplate .upcoming h1 + span + article{
        display: inline-block !important;
        float:none;
        width:300px;
    }
    
    .reservation{
        min-width: 0px;
    }
    .reservation .txt{
        padding: 20px;
        margin: 0px;
    }
    .reservation .close{
        top:12px;
        right: 20px;
    }
    .reservation .txt p{        
        display: block;
        width: auto;
        padding-top: 20px;
    }
    .reservation form{
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 25px;
    }
    .reservation label{
        margin-top: 10px;
    }
    .reservation .reserve{
        margin-top: 20px;
        clear: both;
        margin-left: 0px;
        width: 100%;
        font-size: 16px;
        font-size:1.6rem;
    }
    
    .bootstrap-select .filter-option,
    .reservation select,
    .reservation input{
        border-radius: 0px;
        font-size: 16px;
        font-size:1.6rem;
    }
    
    .reservation input[type=date]{
        -webkit-appearance: none;
        width: 100%;
        display: block;
        @include mainFont($package);
        padding: 10px;
        background: rgba(255, 255, 255, 0.1);
        color: #F6F6F6;
        // -webkit-font-smoothing: antialiased;
    }
    
    .newsletter .content{
        max-width: 100%;
        padding: 60px 20px;
    }
    .newsletter .content input{
        margin: 0px 0px 10px 0px !important;
        width: 100%;
        font-size: 16px;
        font-size:1.6rem;
    }

    .newsletter .content .field.select{
        margin: 15px 0 45px 0;
    }
    .newsletter .content .field{
        margin-bottom: 10px;
    }
    
    .newsletter .content .btn{
        width: 100%;
        font-size: 16px;
        font-size:1.6rem;
    }
    
    .newsletter .content p.txt a{
        white-space: nowrap;
    }
    .newsletter .left{position: relative; }
    .newsletter .requiredField.emailField{
        position: absolute;
        top: 106px;
    }
    .newsletter .requiredField.lastNameField{
        margin-bottom: 50px;
    }
    .productsTemplate article{
        margin-left: 0px;
        margin-right: 0px;
    }
    
    .productsTemplate .main div p{
        padding-right: 30px;
        // margin-bottom: 30px;
    }
    
    .productsTemplate .main{
        padding-bottom: 30px;
    }
    
    .productsTemplate .otherProduct  article{
        width: 100%;
        margin-top: 30px;
    }
    
    // .productsTemplate .otherProduct  article > div{
    //  margin: 0 auto;
    //  width: 280px;
    // }
    
    .dinningTemplate .categ.categ > div{
        padding: 45px;
    }
    
    .dinningTemplate .categ.categ > .room{
        padding-left: 0px;
        padding-right: 0px;
    }
    .homeTemplate .bx_inner > div{
        height:320px;
        img{
            display:none;
        }
    }
    .productsTemplate .main div img{
        width:auto;
        max-width: 83%;
    }
    .productsTemplate .main article > div:last-child{
        text-align: center;
        padding:0px 20px;
        h2{
            margin-top: 10px;
            padding:0;
            font-size: 22px;
            font-size:2.2rem;
            line-height: 27px;
        }
        p{
            padding:0;
        }
        & * {text-align: center;}
    }
    .dinningTemplate .categ.categ > .inquire{
        padding-left:20px;
        padding-right:20px; 
    }
    #bodyId_splash{
        address.mobileHide {display:none;}
    }
}
@media all and (max-width: 640px) and (orientation: landscape){
    header{
        // position: static
    }
}
@media all and (max-width:640px){
    header{
        
        .admin-bar & {top:45px;}
        
    }
}
@media screen and (max-width: 525px) {
    .homeTemplate .bx_caption .btn{font-size:11px; font-size:1.1rem;white-space: normal;}
    .locationTemplate .info address{
        width:280px;
    }
    .newsletter .requiredField.half.first{width:47%;}
    
    [class*="listing"], .listing_Holder{
    
        article{
            width:320px;
            margin-left:0px;
            margin-right:0px;
            h3,p{padding:0 20px;}
            // a img{padding:0;}
        }
    }
    .mosaique > div{
        width: 100% !important;
        &.instagram{
            // height: auto !important;
        }
    }

    .productsTemplate .main .col-xs-6{
        width: 100%;
    }
    
    .productsTemplate .main div img{
        width: 100%;
    }
    
    .productsTemplate .main article{
        padding: 0px 30px;
    }
    .dinningTemplate .categ .menu p{
        line-height: 16px;
    }
    
    .dinningTemplate .categ.categ > div{
        padding-left:10px;
        padding-right:10px;
        h3{
            font-size:20px;
            font-size:2rem;
        }
        p{
            font-size:16px;
            font-size:1.6rem;
        }
    }

    .locationTemplate #google-map{
        height: 320px;
    }
    #bodyId_splash .fullheight .holder{
        h1,address,span{max-width: 100%; padding: 0px 20px;}
        // address{display:none;}

    }
    #bodyId_splash{
        // footer .container{padding-top: 25px;}
    }
    
}


