.hero
{
    display: flex;
    width:100%;
    // height:100vh;
    min-height:100vh;
    position: relative;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    padding:50px 0;

    position: fixed;
    top:0;
    left:0;
    z-index:-1;

    &__bg
    {
        position: absolute;
        top:0;
        left:0;
        // width:100%;
        // height:100%;
        right:0;
        bottom:0;

        &::before
        {
            content:'';
            top:0;
            left:0;
            bottom:0;
            right:0;
            position: absolute;
            background-color: rgba(black, .10);
        }

        img
        {
            object-fit: cover;
            font-family: 'object-fit: cover;';
            width:100%;
            height:100%;
            display: block;
        }
    }

    &__title
    {
        @extend %reset-pm;
        position: relative;
        padding:30px;
        text-align:center;

        opacity: 0;
    }

    &__scroll,
    {
        position:absolute;
        width:100%;
        text-align:center;
        bottom:50px;
        left:0;

        span
        {
            letter-spacing: tracking(100);
            display: block;
        }
    }
}