

/* FONT-WEIGHTS
=================================================================== */
    /* BOLD = 700
    =================================================================== */
    footer .copyright,
    footer .bnb-footer h3,
    footer .nav-menu-footer li a,
    footer .newsletter__group form input,
    .banner .txt h1,
    .banner .txt h2,
    .banner .txt p,
    .intro .txt h1,
    .intro .txt p,
    header .btn.btn.btn,
    .nav-menu-header li,
    .locations_cards .location,
    .list__type,
    .list__title,
    .list__button,
    .list__load,
    .hero__title,
    .hero__scroll,
    .instagram__title span,
    .instagram__link,
    .experiences__title,
    .block__title,
    .block__button,
    .btn,
    {
        font-weight:700;
    }
    /* NORMAL = 400
    =================================================================== */
    .greenDetailsTemplate .intro .txt p,
    {
        font-weight:400;
    }


/* FONT-SIZES (base is 10px, not 16)
=================================================================== */
    /* 12px = 1.2rem
    =================================================================== */
    footer .copyright,
    header .btn.btn.btn span,
    .locations_cards .location,
    {
        font-size: 1.2rem;
    }
    /* 13px = 1.3rem
    =================================================================== */
    // .friendsTemplate .intro .txt p,
    // .productsTemplate .intro .txt p,
    // .faqTemplate .intro .txt p,
    .intro .txt p,
    {
        font-size:1.3rem;
    }
    /* 14px = 1.4rem
    =================================================================== */
    footer .nav-menu-footer li a,
    footer .newsletter__group form input,
    .banner.banner.banner .txt p,
    .list__button span,
    .list__load,
    .hero__scroll,
    .block__button span,
    {
        font-size: 1.4rem
    }
    /* 16px = 1.6rem
    =================================================================== */
    footer .bnb-footer address,
    footer .bnb-footer h3,
    .socialTemplate .intro .txt p,
    .list__type,
    {
        font-size:1.6rem;
    }
    /* 18px = 1.8rem
    =================================================================== */
    .list__excerpt,
    .block__desc,
    {
        font-size: 1.8rem;
    }
    /* 20px = 2rem
    =================================================================== */
    .instagram__link,
    .experiences__desc,
    {
        font-size:2rem;
    }
    /* 21px = 2.1rem
    =================================================================== */
    footer .social > li a,
    {
        font-size: 2.1rem;
    }
    /* 23px = 2.3rem
    =================================================================== */
    .greenDetailsTemplate .intro .txt p,
    {
        font-size: 2.3rem;
    }
    /* 24px = 2.4rem
    =================================================================== */
    footer .newsletter__group form .icon,
    .hero__scroll .icon,
    {
        font-size: 2.4rem;
    }
    /* 30px = 3rem
    =================================================================== */
    .instagram__title,
    .block__title,
    {
        font-size:3rem;
    }
    /* 36px = 3.6rem
    =================================================================== */
    .dinningTemplate.noFeatured .banner .txt h1,
    .defaultTemplate.noFeatured .banner .txt h1,
    .greenTemplate .banner .txt h2,
    .intro .txt h1,
    {
        font-size:6rem;
    }
    /* 40px = 4rem
    =================================================================== */
    .nav-menu-header,
    .list__title,
    .experiences__title,
    {
        font-size:4rem;
    }
    /* 60px = 6rem
    =================================================================== */
    .defaultTemplate .banner .txt h1,
    .menuTemplate .foodCateg .banner .txt h2,
    .greenTemplate .banner .txt h1,
    .dinningTemplate .banner .txt h1,
    .hero__title,
    {
        font-size:6rem;
    }

    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }
    @media (max-width: 1024px)
    {
        .nav-menu-header,
        .list__title,
        {
            font-size:2.5rem;
        }
    }
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        .nav-menu-header,
        {
            // font-size:3rem;
        }
    }
    
    @media (max-width: #{breakpoint(tb) - 1}px)
    {
        .list__type,
        {
            font-size:1.2rem;
        }
        .list__excerpt,
        .instagram__link,
        .experiences__desc,
        .block__desc,
        {
            font-size:1.4rem;
        }
        .nav-menu-header,
        .list__title,
        .instagram__title,
        .experiences__title,
        .block__title,
        {
            font-size:2rem;
        }

    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }




/* TEXT-TRANSFORM
=================================================================== */
    /* UPPERCASE
    =================================================================== */
    footer .copyright,
    footer .newsletter__group input,
    .banner .txt h1,
    .menuTemplate .foodCateg .banner .txt h2,
    .greenTemplate .banner .txt h2,
    .intro .txt h1,
    header .btn.btn.btn,
    .locations_cards .location,
    .list__button,
    .list__load,
    .hero__scroll,
    .block__button,
    .btn,
    {
        text-transform: uppercase;
    }



/* TEXT-DECORATION
=================================================================== */
    /* NONE
    =================================================================== */
    footer .social a,
    .nav-menu-footer li > a,
    .has-hover .greenTemplate .banner .txt p a:hover,
    .has-hover .greenTemplate .banner .txt p a:focus,
    header .btn.btn.btn,
    .nav-menu-header li > a,
    .list__item,
    .list__item:hover,
    .list__item:focus,
    .list__item > a,
    .list__item > a:hover,
    .list__item > a:focus,
    .list__load,
    .list__load:hover,
    .list__load:focus,
    .instagram__link,
    .instagram__link:hover,
    .instagram__link:focus,
    .block__item:hover,
    .block__item:focus,
    {
        text-decoration:none;
    }