

body
{
    position: relative;
    // min-width: 0;


    *:focus
    {
        // outline: 5px auto red;
        // outline-style: inset;
        // outline-color:red;
        // outline-offset:0;
    }


    &.safari,
    // &.android,
    {
        // overflow: hidden;

        // > .wrapper {
        //     overflow-x: hidden;
        //     overflow-y: scroll;
        //     -webkit-overflow-scrolling: touch;

        //     // max-height:100vh;
        //     height:100vh;
        // }
    }

    &.safari.desktop,

    {
        // overflow: hidden;
        > .wrapper {
            // height:100vh;
        }
    }

    &.android,
    {
        // height:100%;
        // height:100vh;
        > .wrapper
        {
            // height:100%;
        }
    }
}
header
{
    min-width:100%;
    position: fixed;
    z-index: 500;
    top:0;
    left:0;

    $dropShadowColor: rgba(0,0,0,.15);

    .admin-bar & {top:32px;}

    &:not(.new)
    // &.new
    {
        // display: none;
    }

    .site-title,
    .trigger
    {
        z-index:2;
    }

    .overlay
    {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100vh;
        z-index:-1;
        opacity:0;
        visibility:hidden;
        // pointer-events:none;
    }


    .btn.btn.btn
    {
        display: inline-block;
        padding:0 20px;
        line-height:50px;
            margin-bottom:14px;

        &:not(:last-child)
        {
        }

        text-align:center;
        border:0;
        vertical-align: middle;
        transition: background-color $duration ease, color $duration ease;

        .icon
        {
            fill:currentColor;
            width:20px;
            max-height:20px;
            display: inline-block;
            vertical-align: middle;
        }

        span
        {
            line-height:1;
            display: inline-block;
            letter-spacing: tracking(100);
            margin-left:12px;
            vertical-align: baseline;
        }
    }

    .site-title
    {
        @extend %reset-pm;
        display: inline-block;
        order:2;
        position: absolute;
        top:0;
        left:100px;
        box-shadow: 0 0 40px 10px $dropShadowColor;
        // opacity:0;
        // transform: translateX(-100px);
        transform: translateY(-100px);


        a
        {
            display: flex;
            width:100px;
            height:100px;
            justify-content: center;
            align-items: center;
            transition:none;
            padding:10px;
        }

        img
        {
        }



        .home &
        {
            // left:0;
        }
    }

    .trigger
    {

        position: absolute;
        top:0;
        left:0;
        width:100px;
        height:100px;
        padding:0;
        border:none;
        // transform: translateX(-100px);
        transform: translateY(-100px);

        cursor:pointer;
        box-shadow: -20px 0 40px 10px $dropShadowColor;
        // transition: all .25s;
        display: flex;
        justify-content: center;
        transition: background-color $duration ease, box-shadow $duration ease;

        // .has-hover
        // {

        // }
        align-items:center;

        &:active
        {
            outline:0;
        }

        &__wrapper
        {
            
            display: flex;
            justify-content: space-between;
            align-items:center;
            margin:0 auto;
            flex-direction: column;
        }

        span
        {
            width:40px;
            height:2px;
            background-color: currentColor;
            display: block;
            // border-radius: 5px;
            margin:4px 0;
            will-change:transform;
        }
    }


    .reservations
    {
        position: absolute;
        top:0;
        right:0;
        z-index:1;
        // line-height: 1;
        box-shadow: 0 0 40px 0 $dropShadowColor;
        // right:-100%;
        transform: translateY(-60px);
        
        

        // .icon
        // {
        //     fill: currentColor;
        //     width:21px;
        //     height:21px;
        //     display: inline-block;
        //     vertical-align: middle
        // }

        .btn.btn.btn
        {
        //     display: inline-block;
        //     line-height:50px;
            padding-left:28px;
            padding-right:28px;
            margin-bottom:0;

        //     transition: background-color $duration ease, color $duration ease;
        }
        // span
        // {
        //     line-height:1;
        //     display: inline-block;
        //     letter-spacing: tracking(100);
        //     margin-left:12px;
        // }
    }



    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(btb)}px)
    {
        

        .trigger,
        .site-title > a,
        {
            width:60px;
            height:60px;
        }

        .trigger
        {
            &__wrapper
            {
                transform: scale(.8);
                span
                {

                }
            }
        }

        .site-title,
        {
            left:60px;

            img
            {
                max-width:44px;
                width:100%;
            }
        }

        .reservations
        {
            // display: none;
            > .btn.btn.btn
            {
                width:140px;
                height:60px;
                line-height: 60px;
                padding:0;

                .icon
                {
                    width:23px;
                    max-height:23px;
                    display: none;
                }
                span{
                    margin:0;
                }
            }
        }
    }
    
    @media (max-width: #{breakpoint(tb) - 1}px)
    {
        .overlay
        {
            display: none;
        }
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        .admin-bar & {top:45px;}
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}