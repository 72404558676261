.news
{
    &__list
    {
        padding:100px 0 0;

        .home &
        {
            padding-top:80px;
        }

        .container-grid
        {
            display: flex;
            flex-direction: column;

            max-width:1440px;
            // align-items: flex-start
        }

        .list__item
        {
            // align-self:flex-start;
            margin-right:100px;
            .home &
            {
                margin-bottom:80px;
            }

            .list__img img
            {
                width:auto;
                height:auto;
            }

            &:nth-child(2n)
            {
                margin-right:0;
                margin-left:100px;
                .list__img
                {
                    order:2;
                }

                .list__content
                {
                    margin-left:0;
                    // margin-right:-7.5%;
                    margin-right:-17.6%;
                    order:1
                }
            }
        }
    }


    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        &__list
        {
            padding:60px 0 0;
            .container-grid
            {
                padding-left:60px;
                padding-right:60px;
            }

            .list__item:nth-child(1n)
            {
                margin-left:0;
                margin-right: 0;
            }
        }
    }
    
    @media (max-width: #{breakpoint(tb)}px)
    {
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        &__list
        {
            // padding:40px 0 0;
            .container-grid
            {
                padding-left:30px;
                padding-right:30px;
            }

            .home & .list__item
            {
                margin-bottom:40px;
            }
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }

}