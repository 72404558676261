.instagram
{
    // margin-left:-10px;
    // margin-right:-10px;
    padding-bottom: 90px;
    padding-top:100px;

    .container-grid
    {
        max-width:1440px;
    }

    &__wrapper,
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left:-10px;
        margin-right:-10px;

        // display: block;
    }

    &__header
    {
        display: flex;
        justify-content: space-between;
        align-items:baseline;
        margin-bottom:30px;
    }

    &__title
    {
        @extend %reset-pm;
        // letter-spacing: tracking(100);

        .icon,
        span
        {
            display: inline-block;
            vertical-align: baseline;
        }

        .icon
        {
            font-size:20px;
            margin-right:10px;
        }
    }

    &__link
    {
        display: inline-block;
        transition:color $duration ease;
    }

    &__item
    {
        display:block;
        // display:inline-block;
        // max-width:25%;
        // max-height:25%;
        max-width: 295px;
        max-height:295px;
        width:calc(100% - 20px);
        height:100%;
        margin:10px;

        opacity: 0.99;
        will-change:transform;
        backface-visibility: hidden;
        overflow:hidden;

        flex:1;
    }

    &__img,
    {
        width:100%;
        height:100%;
        will-change:transform;
        backface-visibility: hidden;

        transition: transform $duration+1s cubic-bezier(0.19, 1, 0.22, 1);


        object-fit: cover;
            font-family: 'object-fit: cover;';

        .has-hover .instagram__item:hover &,
        .has-hover .instagram__item:focus &,
        {
            // transform: scale3d(1.02, 1.02, 1.02);
                transform: translate3d(0,0,0) scale(1.02, 1.02);
            // 
        }
    }

    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt) - 1}px)
    {
        &__item,
        {
            max-height:240px;
            max-width:240px;
        }
    }
    
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        padding-top:60px;
        padding-bottom:50px;

        .container-grid
        {
            padding-left:60px;
            padding-right:60px;
        }

        &__item
        {
            flex:auto;
        }
    }
    
    @media (max-width: #{breakpoint(tb)}px)
    {
        &__item
        {
            width:calc(50% - 20px)
        }
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        padding-top:30px;
        padding-bottom:20px;

        .container-grid
        {
            padding-left:30px;
            padding-right:30px;
        }

        &__item,
        {
            max-height:150px;
            max-width:150px;
        }

        &__header
        {
            display: block;
        }

        &__link
        {
            margin-top:12px;
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}