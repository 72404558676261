.experiences
{
    padding:80px 0;

    .container-grid
    {
        max-width:1440px;
    }

    &__title,
    {
        @extend %reset-pm;
        margin-bottom:20px;
    }

    &__title,
    &__desc,
    {
        padding:0 30px;
        text-align:center;
        margin-left: auto;
        margin-right: auto;
        // max-width:650px;
        max-width:750px;
    }

    &__desc
    {
        > *:first-child
        {
            margin-top: 0;
        }
        > *:last-child
        {
            margin-bottom: 0;
        }

        > p
        {
            margin:1em 0;
        }
    }

    &__wrapper
    {
        margin-top:100px;
        display: flex;
        margin-left:-10px;
        margin-right:-10px;

        justify-content: center;
    }





    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }

    @media (max-width: 1024px)
    {
        &__wrapper
        {
            display: block;
        }
    }
    
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        padding:60px 0;
        .container-grid
        {
            padding-left:60px;
            padding-right:60px;
        }

        &__wrapper
        {
            margin-top:60px;
        }
    }
    
    @media (max-width: #{breakpoint(tb)}px)
    {
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        padding:30px 0;
        .container-grid
        {
            padding-left:30px;
            padding-right:30px;
        }
        &__wrapper
        {
            margin-top:30px;
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}