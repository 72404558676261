.newsletter{position:fixed; width:100%; top:0; left:0; height:100%; z-index:2000; display:none;}
.newsletter .bg{width:100%; height:100%; background:$secondColor; filter: alpha(opacity=70); opacity:0.70; cursor:pointer;}
.newsletter .content{
    width:875px; 
    position:absolute; 
    background:#000; 
    padding:60px 70px; 
    z-index:10;
    max-height: 100%;
    overflow: auto;
}
.newsletter .content .close{
    @include mainFont($package); 
    font-weight:500; 
    text-transform:uppercase; 
    font-size:11px; 
    font-size:1.1rem;
    color:#fff; 
    position:absolute; 
    top:50px; 
    right:50px; 
    padding: 20px 40px 20px 20px;
    background: url(../img/layout/iconClose.png) right center no-repeat;
    background-size: 10px 10px;
    background-position: 81% 50%;  
    filter: alpha(opacity=100); 
    opacity:1; 
    letter-spacing:1px;
    // -webkit-font-smoothing: antialiased;
    -webkit-transition:opacity 0.25s;
            transition:opacity 0.25s;

    &:hover,
    &:focus{
        opacity:0.5;
    }
}
.newsletter{
    .left,.right{float:left; width:50%;}
    .parsley-errors-list{display:none;}
    .requiredField{width:340px;&.half{width:110px; display:inline-block; &.first{margin-right:20px;}    }}
    .requiredField.requiredField input{
        -webkit-transition:all 0.25s;
                transition:all 0.25s;
        border:1px solid transparent;
        padding-left: 14px;
        position: relative;
    }
    .requiredField{
        position: relative;
        overflow:hidden;
        &.half.half label{
            margin:0;
        }
        &.half{
            margin-top:12px;
            .field{margin:10px 0 45px 0;}
        }
        &:after{
            content:'';
            position: absolute;
            top:13px;
            right:-34px;
            @include circle(15px);
            background:white;
            -webkit-transition:all 0.25s;
                    transition:all 0.25s;
        }
        &.half:after{
            top:59px;
        }
        &.parsley-error:after{right:14px;}
    }
    .parsley-error.parsley-error input{
        border:1px solid white;

    }
}
.newsletter .content h2{
    color:#fff;
    text-align: center;
}
.newsletter .content > .title{color:#f6f6f6; font-size:20px; font-size:2rem; text-transform:uppercase; text-align:center; margin:0 0 15px 0; letter-spacing:1px; @include secondFont($package); font-weight:700;}
.newsletter .content span.devider{width:30px; height:2px; margin:0 auto 13px; display:block; background:#404040; }
.newsletter .content .txt{
    color:#f6f6f6; 
    @include mainFont($package); 
    font-size:14px; 
    font-size:1.4rem;
    line-height:20px; 
    max-width:450px; 
    margin:0 auto 40px; 
    clear:both;
}
.newsletter.success .txt{
    text-align: center;
    i{
        display: inline-block;
        @include circle(50px);
        background:rgba(255,255,255,0.1);
        line-height:50px;
        font-size:14px;
        font-size:1.4rem;
        margin-top:25px;
    }
}
.newsletter .content .txt a{color:#fff; text-decoration:underline; @include transitioned('color', 0.3s);}
.newsletter .content .txt a:hover, .newsletter .content .txt a:focus{color:#f6f6f6;text-decoration:none;}
.newsletter .content p{text-align:center; margin:0 auto;}
.newsletter .content form .title{
    @include mainFont($package); 
    font-size:11px; 
    font-size:1.1rem;
    height:auto;
    color:#f6f6f6; 
    text-transform:uppercase; 
    letter-spacing:1px; 
    width:100%; 
    margin:0 0 10px 0;
    font-weight: normal;
}
.newsletter .content input{
    background:rgba(255, 255, 255, 0.1); 
    height:44px; width:340px; 
    @include rounded-corners($radius: 0px); 
    border:none; 
    @include mainFont($package); 
    padding:12px 15px; 
    font-size:13px; 
    font-size:1.3rem;
    font-weight:700; color:#f6f6f6; 
    display:inline-block; 
    margin:0 0 10px 0;
    // -webkit-font-smoothing: antialiased;
}
.newsletter .content input:nth-child(2n){margin:0 50px 10px 0;}
.newsletter .content .field{display:inline-block; margin:15px 0 45px 0; width:100%; padding-right:28px;}
.newsletter .content .field label{width:130px; display:block;}
.newsletter .content .field input{
    width:110px; 
    margin:0;
}
.newsletter .content .field.select{margin:15px 0 45px 0px;}



.bootstrap-select{width:100% !important; background:rgba(255, 255, 255, 0.1);  display:inline-block; border:none;}
.bootstrap-select button{
    @include rounded-corners($radius: 0px); 
    background: transparent; 
    padding:12px 15px; 
    border:none;  
    box-shadow:none;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0px;
    vertical-align: middle;
    border: none;
    content: "";
    font-size: 11px;
    font-size:1.1rem;
}

.bootstrap-select button span{font-size:13px;font-size:1.3rem; font-weight:700; color:#f6f6f6; @include mainFont($package); text-shadow:none; }
.bootstrap-select button .caret{
    border:none; position:relative; 
    display:inline-block; 
    font-size:11px; 
    font-size:1.1rem;
    transform: translateY(-50%);
    height: 12px;
    font-weight: bold;
    right: 32px !important;
}

.filter-option{
    line-height: 20px;
}

.bootstrap-select.btn-group .dropdown-menu{
    margin:0; 
    padding:0; 
    @include rounded-corners($radius: 0px); 
}

.bootstrap-select.btn-group .dropdown-menu.inner{
    box-shadow: inset rgba(255,255,255,0.15) 0 0 0 200px;
    background: $secondColor;
}

.bootstrap-select.btn-group .dropdown-menu.inner{margin:0; padding:0;}
.bootstrap-select.btn-group .dropdown-menu.inner li a:hover, .bootstrap-select.btn-group .dropdown-menu.inner li a:focus, .bootstrap-select.btn-group .dropdown-menu.inner li.selected a{background:transparent;}
.bootstrap-select.btn-group .dropdown-menu.inner li a{font-size:13px; font-size:1.3rem; font-weight:700; color:#f6f6f6; @include mainFont($package); padding:12px 10px;}
.newsletter .content .btn{background:#e1dede; @include mainFont($package); font-weight:700; font-size:14px; font-size:1.4rem;text-decoration:none; color:$secondColor; @include rounded-corners($radius: 0px); padding:8px 20px 8px 20px; @include transitioned('all', 0.3s);}
.newsletter .content .btn span{font-size:8px;font-size:0.8rem; display:inline-block; margin:0 0 0 5px; @include transitioned('all', 0.3s);}
.newsletter .content .btn:hover, .newsletter .content .btn:focus{& , span{color:white}}

.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus, .dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus{
    background:none;
    box-shadow: inset rgba(0,0,0,0.1) 0 0 0 40px;
}

.bootstrap-select button .caret.icon-up-open{
    display: none !important;
}
.bootstrap-select.open button .caret.icon-up-open{
    display: inline-block !important;
}
.bootstrap-select.open button .caret.icon-down-open{
    display: none !important;
}