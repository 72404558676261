.block
{
    &__bg
    {
        position: absolute;
        top:0;
        left:0;
        // content: '';
        height:100%;
        width:100%;
        // z-index:-1;
        
        transition: background-color $duration ease;

    }

    &__item
    {
        display: flex;
        height:100%;
        margin-left:10px;
        margin-right:10px;
        position: relative;

        &::before
        {
            position: absolute;
            top:0;
            left:0;
            // content: '';
            height:100%;
            width:100%;
            // z-index:-1;
        }



        &.is_grid
        {
            height:auto;
            align-self:stretch;
            // backface-visibility: hidden;
            overflow:hidden;
            // will-change:transform;

            .block__img,
            .block__txt,
            {
                order:1;
            }

            &:nth-child(1),
            {
                width:410px;
                flex-direction: column;

                .block__img
                {
                    max-height: 249px;
                    // min-height: 249px;
                    max-width:none;
                    // height:100%;
                }
                .block__txt
                {
                    flex:1;
                    min-width:0;
                    max-width:none;
                }
            }
            &:nth-child(2),
            {
                // width:66.66666%;
                width:820px;
                .block__img
                {
                    max-width:410px;
                    width:100%;
                    // min-width: 200px;
                    // max-heightheight:498px;
                    @include keepRatio('460/539');
                }
                .block__txt
                {
                    width:50%;
                    max-width:none;
                }
            }
        }
    }

    &__img
    {
        order:2;
        position: relative;
        overflow:hidden;
        max-width:370px;

        opacity: 0.99;
        will-change:transform;
        backface-visibility: hidden;

        img
        {
            width: 100%;
            display: block;
            height:100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            max-width:none;

            will-change:transform;
            overflow:hidden;
            backface-visibility: hidden;

            // transition: transform $duration cubic-bezier(0.445, 0.05, 0.55, 0.95);
            transition: transform $duration+1s cubic-bezier(0.19, 1, 0.22, 1);



            .has-hover .block__item:hover &,
            .has-hover .block__item:focus &,
            {
                // transform: scale3d(1.02, 1.02, 1.02);
                transform: translate3d(0,0,0) scale(1.02, 1.02);
            }
        }
    }

    &__desc
    {
        line-height: 1.55;
        height:100%;
        margin-top:20px;
        margin-bottom:40px;
    }

    &__txt
    {
        position: relative;
        order:1;
        padding:40px;
        min-width:240px;
        max-width:240px;
        transition: color $duration ease;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title
    {
        @extend %reset-pm;
        line-height:1.33333
    }

    &__button
    {
        margin-top:20px;
        font-size:0;
        // padding:0 30px;
        display: inline-block;
        white-space: nowrap;
        align-self:flex-start;

        color: currentColor;

        // transition: color $duration cubic-bezier(0.445, 0.05, 0.55, 0.95);
        will-change:width;

        line-height:1;

        span
        {
            // margin-left:-15px;
            letter-spacing: tracking(100);
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
        }

        &::before
        {
            content:'';
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width:0;
            height:2px;
            // margin:0 0 0 30px;
            will-change: width, margin-right;

            background-color: currentColor;
            transition: width $duration+.5s cubic-bezier(0.19, 1, 0.22, 1), margin-right $duration+.5s cubic-bezier(0.19, 1, 0.22, 1);
        }

        .has-hover .block__item:hover &::before,
        .has-hover .block__item:focus &::before,
        {
            width:40px;
            margin-right:20px;
            // margin-left:-15px
        }
    }

    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: 1280px)
    {

        &__item,
        {
            margin-left:auto;
            margin-right: auto;
            max-width: 800px;
            margin-bottom:30px;
            margin-bottom:30px;

            &.is_grid
            {
                    flex-direction:column;
                &:nth-child(2)
                {

                    .block__img
                    {
                        &::before
                        {
                            content:none;
                        }
                    }
                }
                &:nth-child(1n)
                {
                    width:auto;

                    .block__img
                    {
                        max-height:249px;
                        height:249px;
                        max-width:none;
                    }

                    .block__txt
                    {
                        min-width:0;
                        width: auto;
                        max-width:none;
                    }

                    .block__desc
                    {
                        margin-bottom:20px;
                    }
                }
            }

            &:last-child
            {
                margin-bottom:0;
            }


        }
    }

    @media (max-width: 1024px)
    {
        &__item,
        {
            margin-left:auto;
            margin-right: auto;
            max-width: 610px;
            margin-bottom:30px;
            margin-bottom:30px;

            &.is_grid
            {
                    flex-direction:column;
                &:nth-child(2)
                {

                    .block__img
                    {
                        &::before
                        {
                            content:none;
                        }
                    }
                }
                &:nth-child(1n)
                {
                    width:auto;

                    .block__img
                    {
                        max-height:249px;
                        height:249px;
                        max-width:none;
                    }

                    .block__txt
                    {
                        min-width:0;
                        width: auto;
                        max-width:none;
                    }

                    .block__desc
                    {
                        margin-bottom:20px;
                    }
                }
            }

            &:last-child
            {
                margin-bottom:0;
            }


        }
    }
    
    @media (max-width: #{breakpoint(btb)}px)
    {
    }
    
    @media (max-width: #{breakpoint(tb) - 1}px)
    {
        &__item
        {
            // display: block;
        }

        &__img
        {
            // max-width:none;
        }
        &__txt
        {
            // display: block;
            // width:auto;
        }
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        &__txt
        {
            max-width:none;
        }

        &__img
        {
            // height:200px;
            .wrapper
            {
                height:200px;
            }
        }
        &__item
        {
            display:block;


            &.is_grid
            {
                &:nth-child(1n)
                {
                    .block__img
                    {
                        height:auto;

                        .wrapper
                        {
                            position: relative;
                        }
                    }
                }
            }
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}