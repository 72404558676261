

$mainColor:#f6f6f6 !default;
$secondColor:#101010 !default;
$thirdColor:#e59124 !default;
$greenColor:#737020;
// $package:$phpPackage;
$package:'bundle3' !default;
$hSpacing:35px !default;
// $greenColor:#267731;

$newBackground: #f2f1e7;


$backgroundColor: #fff;
$duration: 0.3s;

$introHeight: 360px;
$introHeightSmall: 200px;