.post-type-archive-newsevents
{
    .news
    {
        &__featured
        {
            // height:100vh;
            // min-height:100%;
            // // height:80vh;
            max-height:700px;
            height:100%;
            position: relative;
            display: flex;
            align-items:center;
            padding:120px 0;
            overflow:hidden;

            .container-grid
            {
                max-width:1440px;
            }
        }

        &__bg
        {
            position: absolute;
            top:-12.5%;
            left:-12.5%;
            width:125%;
            height:125%;

            &::before
            {
                content:'';
                top:0;
                left:0;
                bottom:0;
                right:0;
                position: absolute;
                background-color: rgba(black, .10);
            }

            img
            {
                object-fit: cover;
            font-family: 'object-fit: cover;';
                width:100%;
                height:100%;
                display: block;
            }
        }
        
        &__item
        {
            &.featured
            {

                // width:calc( 100% - 100px - 100px );
                // max-width:49.5%;
                max-width:620px;
                margin:0;
                padding:0;
                // padding-right:50px;
            }

            .list__content
            {
                width:100%;
                max-width:620px;
                margin:0 !important;
            }
        } 


        // MEDIA QUERIES
        @media (max-width: #{breakpoint(dt)}px)
        {
        }
        
        @media (max-width: #{breakpoint(lt)}px)
        {
        }
        
        @media (max-width: #{breakpoint(btb) - 1}px)
        {
            &__featured,
            {
                .container-grid,
                {
                    padding-left:60px;
                    padding-right:60px;
                }
            }
            &__item
            {
                &.featured
                {
                    width:auto;
                    max-width:none;
                }
            }
        }
        
        @media (max-width: #{breakpoint(tb)}px)
        {
        }
        
        @media (max-width: #{breakpoint(bmb)}px)
        {
            &__featured,
            {
                height:100%;
                max-height:none;
                min-height:0;
                padding:40px 0;
                .container-grid,
                {
                    padding:30px 30px;
                }
            }
        }
        
        @media (max-width: #{breakpoint(cb)}px)
        {
        }
    }

}