/* UTILS
=================================================================== */
/* MIXINS
    =================================================================== */
/* Reset padding and margin */
/* inline-block hack */
/* Box Sizing */
/* Collumn */
/* border radius */
/* Border-radius Custom */
/* box-shadow */
/* Text-shadow */
/* transition */
/* transform (rotate, scale, skew, translate */
/* gradient */
/* quick-gradient */
/* reflection */
/* filter */
/* FlexBox
=================================================================== */
/********************************************
 *
 * CLASS LESS
 *
 *********************************************/
/* GRIDDER
    =================================================================== */
.clearfix:before, .clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

*, *:before, *:after {
  box-sizing: border-box; }

[class*="col-grid-"] {
  padding: 0 50px;
  margin-bottom: 100px;
  float: left;
  position: relative;
  min-height: 1px;
  width: 100%; }

.container-grid {
  width: 100%;
  margin: auto;
  padding: 0 100px; }

.row-grid {
  margin-left: -50px;
  margin-right: -50px; }

@media all and (min-width: 0px) {
  .container-grid {
    max-width: 100%; }
  .col-grid-mb-1 {
    width: 8.33333%; }
  .col-grid-mb-push-1 {
    left: 8.33333%; }
  .col-grid-mb-pull-1 {
    right: 8.33333%; }
  .col-grid-mb-offset-1 {
    margin-left: 8.33333%; }
  .col-grid-mb-push-0 {
    left: auto; }
  .col-grid-mb-pull-0 {
    right: auto; }
  .col-grid-mb-offset-0 {
    margin-left: 0; }
  .col-grid-mb-2 {
    width: 16.66667%; }
  .col-grid-mb-push-2 {
    left: 16.66667%; }
  .col-grid-mb-pull-2 {
    right: 16.66667%; }
  .col-grid-mb-offset-2 {
    margin-left: 16.66667%; }
  .col-grid-mb-3 {
    width: 25%; }
  .col-grid-mb-push-3 {
    left: 25%; }
  .col-grid-mb-pull-3 {
    right: 25%; }
  .col-grid-mb-offset-3 {
    margin-left: 25%; }
  .col-grid-mb-4 {
    width: 33.33333%; }
  .col-grid-mb-push-4 {
    left: 33.33333%; }
  .col-grid-mb-pull-4 {
    right: 33.33333%; }
  .col-grid-mb-offset-4 {
    margin-left: 33.33333%; }
  .col-grid-mb-5 {
    width: 41.66667%; }
  .col-grid-mb-push-5 {
    left: 41.66667%; }
  .col-grid-mb-pull-5 {
    right: 41.66667%; }
  .col-grid-mb-offset-5 {
    margin-left: 41.66667%; }
  .col-grid-mb-6 {
    width: 50%; }
  .col-grid-mb-push-6 {
    left: 50%; }
  .col-grid-mb-pull-6 {
    right: 50%; }
  .col-grid-mb-offset-6 {
    margin-left: 50%; }
  .col-grid-mb-7 {
    width: 58.33333%; }
  .col-grid-mb-push-7 {
    left: 58.33333%; }
  .col-grid-mb-pull-7 {
    right: 58.33333%; }
  .col-grid-mb-offset-7 {
    margin-left: 58.33333%; }
  .col-grid-mb-8 {
    width: 66.66667%; }
  .col-grid-mb-push-8 {
    left: 66.66667%; }
  .col-grid-mb-pull-8 {
    right: 66.66667%; }
  .col-grid-mb-offset-8 {
    margin-left: 66.66667%; }
  .col-grid-mb-9 {
    width: 75%; }
  .col-grid-mb-push-9 {
    left: 75%; }
  .col-grid-mb-pull-9 {
    right: 75%; }
  .col-grid-mb-offset-9 {
    margin-left: 75%; }
  .col-grid-mb-10 {
    width: 83.33333%; }
  .col-grid-mb-push-10 {
    left: 83.33333%; }
  .col-grid-mb-pull-10 {
    right: 83.33333%; }
  .col-grid-mb-offset-10 {
    margin-left: 83.33333%; }
  .col-grid-mb-11 {
    width: 91.66667%; }
  .col-grid-mb-push-11 {
    left: 91.66667%; }
  .col-grid-mb-pull-11 {
    right: 91.66667%; }
  .col-grid-mb-offset-11 {
    margin-left: 91.66667%; }
  .col-grid-mb-12 {
    width: 100%; }
  .col-grid-mb-push-12 {
    left: 100%; }
  .col-grid-mb-pull-12 {
    right: 100%; }
  .col-grid-mb-offset-12 {
    margin-left: 100%; }
  .col-grid-mb-5th {
    width: 20%; }
  .col-grid-mb-push-5th {
    left: 20%; }
  .col-grid-mb-pull-5th {
    right: 20%; }
  .col-grid-mb-offset-5th {
    margin-left: 20%; } }

@media all and (min-width: 320px) {
  .col-grid-cb-1 {
    width: 8.33333%; }
  .col-grid-cb-push-1 {
    left: 8.33333%; }
  .col-grid-cb-pull-1 {
    right: 8.33333%; }
  .col-grid-cb-offset-1 {
    margin-left: 8.33333%; }
  .col-grid-cb-push-0 {
    left: auto; }
  .col-grid-cb-pull-0 {
    right: auto; }
  .col-grid-cb-offset-0 {
    margin-left: 0; }
  .col-grid-cb-2 {
    width: 16.66667%; }
  .col-grid-cb-push-2 {
    left: 16.66667%; }
  .col-grid-cb-pull-2 {
    right: 16.66667%; }
  .col-grid-cb-offset-2 {
    margin-left: 16.66667%; }
  .col-grid-cb-3 {
    width: 25%; }
  .col-grid-cb-push-3 {
    left: 25%; }
  .col-grid-cb-pull-3 {
    right: 25%; }
  .col-grid-cb-offset-3 {
    margin-left: 25%; }
  .col-grid-cb-4 {
    width: 33.33333%; }
  .col-grid-cb-push-4 {
    left: 33.33333%; }
  .col-grid-cb-pull-4 {
    right: 33.33333%; }
  .col-grid-cb-offset-4 {
    margin-left: 33.33333%; }
  .col-grid-cb-5 {
    width: 41.66667%; }
  .col-grid-cb-push-5 {
    left: 41.66667%; }
  .col-grid-cb-pull-5 {
    right: 41.66667%; }
  .col-grid-cb-offset-5 {
    margin-left: 41.66667%; }
  .col-grid-cb-6 {
    width: 50%; }
  .col-grid-cb-push-6 {
    left: 50%; }
  .col-grid-cb-pull-6 {
    right: 50%; }
  .col-grid-cb-offset-6 {
    margin-left: 50%; }
  .col-grid-cb-7 {
    width: 58.33333%; }
  .col-grid-cb-push-7 {
    left: 58.33333%; }
  .col-grid-cb-pull-7 {
    right: 58.33333%; }
  .col-grid-cb-offset-7 {
    margin-left: 58.33333%; }
  .col-grid-cb-8 {
    width: 66.66667%; }
  .col-grid-cb-push-8 {
    left: 66.66667%; }
  .col-grid-cb-pull-8 {
    right: 66.66667%; }
  .col-grid-cb-offset-8 {
    margin-left: 66.66667%; }
  .col-grid-cb-9 {
    width: 75%; }
  .col-grid-cb-push-9 {
    left: 75%; }
  .col-grid-cb-pull-9 {
    right: 75%; }
  .col-grid-cb-offset-9 {
    margin-left: 75%; }
  .col-grid-cb-10 {
    width: 83.33333%; }
  .col-grid-cb-push-10 {
    left: 83.33333%; }
  .col-grid-cb-pull-10 {
    right: 83.33333%; }
  .col-grid-cb-offset-10 {
    margin-left: 83.33333%; }
  .col-grid-cb-11 {
    width: 91.66667%; }
  .col-grid-cb-push-11 {
    left: 91.66667%; }
  .col-grid-cb-pull-11 {
    right: 91.66667%; }
  .col-grid-cb-offset-11 {
    margin-left: 91.66667%; }
  .col-grid-cb-12 {
    width: 100%; }
  .col-grid-cb-push-12 {
    left: 100%; }
  .col-grid-cb-pull-12 {
    right: 100%; }
  .col-grid-cb-offset-12 {
    margin-left: 100%; }
  .col-grid-cb-5th {
    width: 20%; }
  .col-grid-cb-push-5th {
    left: 20%; }
  .col-grid-cb-pull-5th {
    right: 20%; }
  .col-grid-cb-offset-5th {
    margin-left: 20%; } }

@media all and (min-width: 640px) {
  .container-grid {
    max-width: 100%; }
  .col-grid-bmb-1 {
    width: 8.33333%; }
  .col-grid-bmb-push-1 {
    left: 8.33333%; }
  .col-grid-bmb-pull-1 {
    right: 8.33333%; }
  .col-grid-bmb-offset-1 {
    margin-left: 8.33333%; }
  .col-grid-bmb-push-0 {
    left: auto; }
  .col-grid-bmb-pull-0 {
    right: auto; }
  .col-grid-bmb-offset-0 {
    margin-left: 0; }
  .col-grid-bmb-2 {
    width: 16.66667%; }
  .col-grid-bmb-push-2 {
    left: 16.66667%; }
  .col-grid-bmb-pull-2 {
    right: 16.66667%; }
  .col-grid-bmb-offset-2 {
    margin-left: 16.66667%; }
  .col-grid-bmb-3 {
    width: 25%; }
  .col-grid-bmb-push-3 {
    left: 25%; }
  .col-grid-bmb-pull-3 {
    right: 25%; }
  .col-grid-bmb-offset-3 {
    margin-left: 25%; }
  .col-grid-bmb-4 {
    width: 33.33333%; }
  .col-grid-bmb-push-4 {
    left: 33.33333%; }
  .col-grid-bmb-pull-4 {
    right: 33.33333%; }
  .col-grid-bmb-offset-4 {
    margin-left: 33.33333%; }
  .col-grid-bmb-5 {
    width: 41.66667%; }
  .col-grid-bmb-push-5 {
    left: 41.66667%; }
  .col-grid-bmb-pull-5 {
    right: 41.66667%; }
  .col-grid-bmb-offset-5 {
    margin-left: 41.66667%; }
  .col-grid-bmb-6 {
    width: 50%; }
  .col-grid-bmb-push-6 {
    left: 50%; }
  .col-grid-bmb-pull-6 {
    right: 50%; }
  .col-grid-bmb-offset-6 {
    margin-left: 50%; }
  .col-grid-bmb-7 {
    width: 58.33333%; }
  .col-grid-bmb-push-7 {
    left: 58.33333%; }
  .col-grid-bmb-pull-7 {
    right: 58.33333%; }
  .col-grid-bmb-offset-7 {
    margin-left: 58.33333%; }
  .col-grid-bmb-8 {
    width: 66.66667%; }
  .col-grid-bmb-push-8 {
    left: 66.66667%; }
  .col-grid-bmb-pull-8 {
    right: 66.66667%; }
  .col-grid-bmb-offset-8 {
    margin-left: 66.66667%; }
  .col-grid-bmb-9 {
    width: 75%; }
  .col-grid-bmb-push-9 {
    left: 75%; }
  .col-grid-bmb-pull-9 {
    right: 75%; }
  .col-grid-bmb-offset-9 {
    margin-left: 75%; }
  .col-grid-bmb-10 {
    width: 83.33333%; }
  .col-grid-bmb-push-10 {
    left: 83.33333%; }
  .col-grid-bmb-pull-10 {
    right: 83.33333%; }
  .col-grid-bmb-offset-10 {
    margin-left: 83.33333%; }
  .col-grid-bmb-11 {
    width: 91.66667%; }
  .col-grid-bmb-push-11 {
    left: 91.66667%; }
  .col-grid-bmb-pull-11 {
    right: 91.66667%; }
  .col-grid-bmb-offset-11 {
    margin-left: 91.66667%; }
  .col-grid-bmb-12 {
    width: 100%; }
  .col-grid-bmb-push-12 {
    left: 100%; }
  .col-grid-bmb-pull-12 {
    right: 100%; }
  .col-grid-bmb-offset-12 {
    margin-left: 100%; }
  .col-grid-bmb-5th {
    width: 20%; }
  .col-grid-bmb-push-5th {
    left: 20%; }
  .col-grid-bmb-pull-5th {
    right: 20%; }
  .col-grid-bmb-offset-5th {
    margin-left: 20%; } }

@media all and (min-width: 768px) {
  .container-grid {
    max-width: 100%; }
  .col-grid-tb-1 {
    width: 8.33333%; }
  .col-grid-tb-push-1 {
    left: 8.33333%; }
  .col-grid-tb-pull-1 {
    right: 8.33333%; }
  .col-grid-tb-offset-1 {
    margin-left: 8.33333%; }
  .col-grid-tb-push-0 {
    left: auto; }
  .col-grid-tb-pull-0 {
    right: auto; }
  .col-grid-tb-offset-0 {
    margin-left: 0; }
  .col-grid-tb-2 {
    width: 16.66667%; }
  .col-grid-tb-push-2 {
    left: 16.66667%; }
  .col-grid-tb-pull-2 {
    right: 16.66667%; }
  .col-grid-tb-offset-2 {
    margin-left: 16.66667%; }
  .col-grid-tb-3 {
    width: 25%; }
  .col-grid-tb-push-3 {
    left: 25%; }
  .col-grid-tb-pull-3 {
    right: 25%; }
  .col-grid-tb-offset-3 {
    margin-left: 25%; }
  .col-grid-tb-4 {
    width: 33.33333%; }
  .col-grid-tb-push-4 {
    left: 33.33333%; }
  .col-grid-tb-pull-4 {
    right: 33.33333%; }
  .col-grid-tb-offset-4 {
    margin-left: 33.33333%; }
  .col-grid-tb-5 {
    width: 41.66667%; }
  .col-grid-tb-push-5 {
    left: 41.66667%; }
  .col-grid-tb-pull-5 {
    right: 41.66667%; }
  .col-grid-tb-offset-5 {
    margin-left: 41.66667%; }
  .col-grid-tb-6 {
    width: 50%; }
  .col-grid-tb-push-6 {
    left: 50%; }
  .col-grid-tb-pull-6 {
    right: 50%; }
  .col-grid-tb-offset-6 {
    margin-left: 50%; }
  .col-grid-tb-7 {
    width: 58.33333%; }
  .col-grid-tb-push-7 {
    left: 58.33333%; }
  .col-grid-tb-pull-7 {
    right: 58.33333%; }
  .col-grid-tb-offset-7 {
    margin-left: 58.33333%; }
  .col-grid-tb-8 {
    width: 66.66667%; }
  .col-grid-tb-push-8 {
    left: 66.66667%; }
  .col-grid-tb-pull-8 {
    right: 66.66667%; }
  .col-grid-tb-offset-8 {
    margin-left: 66.66667%; }
  .col-grid-tb-9 {
    width: 75%; }
  .col-grid-tb-push-9 {
    left: 75%; }
  .col-grid-tb-pull-9 {
    right: 75%; }
  .col-grid-tb-offset-9 {
    margin-left: 75%; }
  .col-grid-tb-10 {
    width: 83.33333%; }
  .col-grid-tb-push-10 {
    left: 83.33333%; }
  .col-grid-tb-pull-10 {
    right: 83.33333%; }
  .col-grid-tb-offset-10 {
    margin-left: 83.33333%; }
  .col-grid-tb-11 {
    width: 91.66667%; }
  .col-grid-tb-push-11 {
    left: 91.66667%; }
  .col-grid-tb-pull-11 {
    right: 91.66667%; }
  .col-grid-tb-offset-11 {
    margin-left: 91.66667%; }
  .col-grid-tb-12 {
    width: 100%; }
  .col-grid-tb-push-12 {
    left: 100%; }
  .col-grid-tb-pull-12 {
    right: 100%; }
  .col-grid-tb-offset-12 {
    margin-left: 100%; }
  .col-grid-tb-5th {
    width: 20%; }
  .col-grid-tb-push-5th {
    left: 20%; }
  .col-grid-tb-pull-5th {
    right: 20%; }
  .col-grid-tb-offset-5th {
    margin-left: 20%; } }

@media all and (min-width: 992px) {
  .container-grid {
    max-width: 100%; }
  .col-grid-btb-1 {
    width: 8.33333%; }
  .col-grid-btb-push-1 {
    left: 8.33333%; }
  .col-grid-btb-pull-1 {
    right: 8.33333%; }
  .col-grid-btb-offset-1 {
    margin-left: 8.33333%; }
  .col-grid-btb-push-0 {
    left: auto; }
  .col-grid-btb-pull-0 {
    right: auto; }
  .col-grid-btb-offset-0 {
    margin-left: 0; }
  .col-grid-btb-2 {
    width: 16.66667%; }
  .col-grid-btb-push-2 {
    left: 16.66667%; }
  .col-grid-btb-pull-2 {
    right: 16.66667%; }
  .col-grid-btb-offset-2 {
    margin-left: 16.66667%; }
  .col-grid-btb-3 {
    width: 25%; }
  .col-grid-btb-push-3 {
    left: 25%; }
  .col-grid-btb-pull-3 {
    right: 25%; }
  .col-grid-btb-offset-3 {
    margin-left: 25%; }
  .col-grid-btb-4 {
    width: 33.33333%; }
  .col-grid-btb-push-4 {
    left: 33.33333%; }
  .col-grid-btb-pull-4 {
    right: 33.33333%; }
  .col-grid-btb-offset-4 {
    margin-left: 33.33333%; }
  .col-grid-btb-5 {
    width: 41.66667%; }
  .col-grid-btb-push-5 {
    left: 41.66667%; }
  .col-grid-btb-pull-5 {
    right: 41.66667%; }
  .col-grid-btb-offset-5 {
    margin-left: 41.66667%; }
  .col-grid-btb-6 {
    width: 50%; }
  .col-grid-btb-push-6 {
    left: 50%; }
  .col-grid-btb-pull-6 {
    right: 50%; }
  .col-grid-btb-offset-6 {
    margin-left: 50%; }
  .col-grid-btb-7 {
    width: 58.33333%; }
  .col-grid-btb-push-7 {
    left: 58.33333%; }
  .col-grid-btb-pull-7 {
    right: 58.33333%; }
  .col-grid-btb-offset-7 {
    margin-left: 58.33333%; }
  .col-grid-btb-8 {
    width: 66.66667%; }
  .col-grid-btb-push-8 {
    left: 66.66667%; }
  .col-grid-btb-pull-8 {
    right: 66.66667%; }
  .col-grid-btb-offset-8 {
    margin-left: 66.66667%; }
  .col-grid-btb-9 {
    width: 75%; }
  .col-grid-btb-push-9 {
    left: 75%; }
  .col-grid-btb-pull-9 {
    right: 75%; }
  .col-grid-btb-offset-9 {
    margin-left: 75%; }
  .col-grid-btb-10 {
    width: 83.33333%; }
  .col-grid-btb-push-10 {
    left: 83.33333%; }
  .col-grid-btb-pull-10 {
    right: 83.33333%; }
  .col-grid-btb-offset-10 {
    margin-left: 83.33333%; }
  .col-grid-btb-11 {
    width: 91.66667%; }
  .col-grid-btb-push-11 {
    left: 91.66667%; }
  .col-grid-btb-pull-11 {
    right: 91.66667%; }
  .col-grid-btb-offset-11 {
    margin-left: 91.66667%; }
  .col-grid-btb-12 {
    width: 100%; }
  .col-grid-btb-push-12 {
    left: 100%; }
  .col-grid-btb-pull-12 {
    right: 100%; }
  .col-grid-btb-offset-12 {
    margin-left: 100%; }
  .col-grid-btb-5th {
    width: 20%; }
  .col-grid-btb-push-5th {
    left: 20%; }
  .col-grid-btb-pull-5th {
    right: 20%; }
  .col-grid-btb-offset-5th {
    margin-left: 20%; } }

@media all and (min-width: 1440px) {
  .container-grid {
    max-width: 100%; }
  .col-grid-lt-1 {
    width: 8.33333%; }
  .col-grid-lt-push-1 {
    left: 8.33333%; }
  .col-grid-lt-pull-1 {
    right: 8.33333%; }
  .col-grid-lt-offset-1 {
    margin-left: 8.33333%; }
  .col-grid-lt-push-0 {
    left: auto; }
  .col-grid-lt-pull-0 {
    right: auto; }
  .col-grid-lt-offset-0 {
    margin-left: 0; }
  .col-grid-lt-2 {
    width: 16.66667%; }
  .col-grid-lt-push-2 {
    left: 16.66667%; }
  .col-grid-lt-pull-2 {
    right: 16.66667%; }
  .col-grid-lt-offset-2 {
    margin-left: 16.66667%; }
  .col-grid-lt-3 {
    width: 25%; }
  .col-grid-lt-push-3 {
    left: 25%; }
  .col-grid-lt-pull-3 {
    right: 25%; }
  .col-grid-lt-offset-3 {
    margin-left: 25%; }
  .col-grid-lt-4 {
    width: 33.33333%; }
  .col-grid-lt-push-4 {
    left: 33.33333%; }
  .col-grid-lt-pull-4 {
    right: 33.33333%; }
  .col-grid-lt-offset-4 {
    margin-left: 33.33333%; }
  .col-grid-lt-5 {
    width: 41.66667%; }
  .col-grid-lt-push-5 {
    left: 41.66667%; }
  .col-grid-lt-pull-5 {
    right: 41.66667%; }
  .col-grid-lt-offset-5 {
    margin-left: 41.66667%; }
  .col-grid-lt-6 {
    width: 50%; }
  .col-grid-lt-push-6 {
    left: 50%; }
  .col-grid-lt-pull-6 {
    right: 50%; }
  .col-grid-lt-offset-6 {
    margin-left: 50%; }
  .col-grid-lt-7 {
    width: 58.33333%; }
  .col-grid-lt-push-7 {
    left: 58.33333%; }
  .col-grid-lt-pull-7 {
    right: 58.33333%; }
  .col-grid-lt-offset-7 {
    margin-left: 58.33333%; }
  .col-grid-lt-8 {
    width: 66.66667%; }
  .col-grid-lt-push-8 {
    left: 66.66667%; }
  .col-grid-lt-pull-8 {
    right: 66.66667%; }
  .col-grid-lt-offset-8 {
    margin-left: 66.66667%; }
  .col-grid-lt-9 {
    width: 75%; }
  .col-grid-lt-push-9 {
    left: 75%; }
  .col-grid-lt-pull-9 {
    right: 75%; }
  .col-grid-lt-offset-9 {
    margin-left: 75%; }
  .col-grid-lt-10 {
    width: 83.33333%; }
  .col-grid-lt-push-10 {
    left: 83.33333%; }
  .col-grid-lt-pull-10 {
    right: 83.33333%; }
  .col-grid-lt-offset-10 {
    margin-left: 83.33333%; }
  .col-grid-lt-11 {
    width: 91.66667%; }
  .col-grid-lt-push-11 {
    left: 91.66667%; }
  .col-grid-lt-pull-11 {
    right: 91.66667%; }
  .col-grid-lt-offset-11 {
    margin-left: 91.66667%; }
  .col-grid-lt-12 {
    width: 100%; }
  .col-grid-lt-push-12 {
    left: 100%; }
  .col-grid-lt-pull-12 {
    right: 100%; }
  .col-grid-lt-offset-12 {
    margin-left: 100%; }
  .col-grid-lt-5th {
    width: 20%; }
  .col-grid-lt-push-5th {
    left: 20%; }
  .col-grid-lt-pull-5th {
    right: 20%; }
  .col-grid-lt-offset-5th {
    margin-left: 20%; } }

@media all and (min-width: 1920px) {
  .container-grid {
    max-width: 1920px; }
  .col-grid-dt-1 {
    width: 8.33333%; }
  .col-grid-dt-push-1 {
    left: 8.33333%; }
  .col-grid-dt-pull-1 {
    right: 8.33333%; }
  .col-grid-dt-offset-1 {
    margin-left: 8.33333%; }
  .col-grid-dt-push-0 {
    left: auto; }
  .col-grid-dt-pull-0 {
    right: auto; }
  .col-grid-dt-offset-0 {
    margin-left: 0; }
  .col-grid-dt-2 {
    width: 16.66667%; }
  .col-grid-dt-push-2 {
    left: 16.66667%; }
  .col-grid-dt-pull-2 {
    right: 16.66667%; }
  .col-grid-dt-offset-2 {
    margin-left: 16.66667%; }
  .col-grid-dt-3 {
    width: 25%; }
  .col-grid-dt-push-3 {
    left: 25%; }
  .col-grid-dt-pull-3 {
    right: 25%; }
  .col-grid-dt-offset-3 {
    margin-left: 25%; }
  .col-grid-dt-4 {
    width: 33.33333%; }
  .col-grid-dt-push-4 {
    left: 33.33333%; }
  .col-grid-dt-pull-4 {
    right: 33.33333%; }
  .col-grid-dt-offset-4 {
    margin-left: 33.33333%; }
  .col-grid-dt-5 {
    width: 41.66667%; }
  .col-grid-dt-push-5 {
    left: 41.66667%; }
  .col-grid-dt-pull-5 {
    right: 41.66667%; }
  .col-grid-dt-offset-5 {
    margin-left: 41.66667%; }
  .col-grid-dt-6 {
    width: 50%; }
  .col-grid-dt-push-6 {
    left: 50%; }
  .col-grid-dt-pull-6 {
    right: 50%; }
  .col-grid-dt-offset-6 {
    margin-left: 50%; }
  .col-grid-dt-7 {
    width: 58.33333%; }
  .col-grid-dt-push-7 {
    left: 58.33333%; }
  .col-grid-dt-pull-7 {
    right: 58.33333%; }
  .col-grid-dt-offset-7 {
    margin-left: 58.33333%; }
  .col-grid-dt-8 {
    width: 66.66667%; }
  .col-grid-dt-push-8 {
    left: 66.66667%; }
  .col-grid-dt-pull-8 {
    right: 66.66667%; }
  .col-grid-dt-offset-8 {
    margin-left: 66.66667%; }
  .col-grid-dt-9 {
    width: 75%; }
  .col-grid-dt-push-9 {
    left: 75%; }
  .col-grid-dt-pull-9 {
    right: 75%; }
  .col-grid-dt-offset-9 {
    margin-left: 75%; }
  .col-grid-dt-10 {
    width: 83.33333%; }
  .col-grid-dt-push-10 {
    left: 83.33333%; }
  .col-grid-dt-pull-10 {
    right: 83.33333%; }
  .col-grid-dt-offset-10 {
    margin-left: 83.33333%; }
  .col-grid-dt-11 {
    width: 91.66667%; }
  .col-grid-dt-push-11 {
    left: 91.66667%; }
  .col-grid-dt-pull-11 {
    right: 91.66667%; }
  .col-grid-dt-offset-11 {
    margin-left: 91.66667%; }
  .col-grid-dt-12 {
    width: 100%; }
  .col-grid-dt-push-12 {
    left: 100%; }
  .col-grid-dt-pull-12 {
    right: 100%; }
  .col-grid-dt-offset-12 {
    margin-left: 100%; }
  .col-grid-dt-5th {
    width: 20%; }
  .col-grid-dt-push-5th {
    left: 20%; }
  .col-grid-dt-pull-5th {
    right: 20%; }
  .col-grid-dt-offset-5th {
    margin-left: 20%; } }

/* COMMON
=================================================================== */
/* FONT-WEIGHTS
=================================================================== */
/* BOLD = 700
    =================================================================== */
footer .copyright,
footer .bnb-footer h3,
footer .nav-menu-footer li a,
footer .newsletter__group form input,
.banner .txt h1,
.banner .txt h2,
.banner .txt p,
.intro .txt h1,
.intro .txt p,
header .btn.btn.btn,
.nav-menu-header li,
.locations_cards .location,
.list__type,
.list__title,
.list__button,
.list__load,
.hero__title,
.hero__scroll,
.instagram__title span,
.instagram__link,
.experiences__title,
.block__title,
.block__button,
.btn {
  font-weight: 700; }

/* NORMAL = 400
    =================================================================== */
.greenDetailsTemplate .intro .txt p {
  font-weight: 400; }

/* FONT-SIZES (base is 10px, not 16)
=================================================================== */
/* 12px = 1.2rem
    =================================================================== */
footer .copyright,
header .btn.btn.btn span,
.locations_cards .location {
  font-size: 1.2rem; }

/* 13px = 1.3rem
    =================================================================== */
.intro .txt p {
  font-size: 1.3rem; }

/* 14px = 1.4rem
    =================================================================== */
footer .nav-menu-footer li a,
footer .newsletter__group form input,
.banner.banner.banner .txt p,
.list__button span,
.list__load,
.hero__scroll,
.block__button span {
  font-size: 1.4rem; }

/* 16px = 1.6rem
    =================================================================== */
footer .bnb-footer address,
footer .bnb-footer h3,
.socialTemplate .intro .txt p,
.list__type {
  font-size: 1.6rem; }

/* 18px = 1.8rem
    =================================================================== */
.list__excerpt,
.block__desc {
  font-size: 1.8rem; }

/* 20px = 2rem
    =================================================================== */
.instagram__link,
.experiences__desc {
  font-size: 2rem; }

/* 21px = 2.1rem
    =================================================================== */
footer .social > li a {
  font-size: 2.1rem; }

/* 23px = 2.3rem
    =================================================================== */
.greenDetailsTemplate .intro .txt p {
  font-size: 2.3rem; }

/* 24px = 2.4rem
    =================================================================== */
footer .newsletter__group form .icon,
.hero__scroll .icon {
  font-size: 2.4rem; }

/* 30px = 3rem
    =================================================================== */
.instagram__title,
.block__title {
  font-size: 3rem; }

/* 36px = 3.6rem
    =================================================================== */
.dinningTemplate.noFeatured .banner .txt h1,
.defaultTemplate.noFeatured .banner .txt h1,
.greenTemplate .banner .txt h2,
.intro .txt h1 {
  font-size: 6rem; }

/* 40px = 4rem
    =================================================================== */
.nav-menu-header,
.list__title,
.experiences__title {
  font-size: 4rem; }

/* 60px = 6rem
    =================================================================== */
.defaultTemplate .banner .txt h1,
.menuTemplate .foodCateg .banner .txt h2,
.greenTemplate .banner .txt h1,
.dinningTemplate .banner .txt h1,
.hero__title {
  font-size: 6rem; }

@media (max-width: 1024px) {
  .nav-menu-header,
  .list__title {
    font-size: 2.5rem; } }

@media (max-width: 767px) {
  .list__type {
    font-size: 1.2rem; }
  .list__excerpt,
  .instagram__link,
  .experiences__desc,
  .block__desc {
    font-size: 1.4rem; }
  .nav-menu-header,
  .list__title,
  .instagram__title,
  .experiences__title,
  .block__title {
    font-size: 2rem; } }

/* TEXT-TRANSFORM
=================================================================== */
/* UPPERCASE
    =================================================================== */
footer .copyright,
footer .newsletter__group input,
.banner .txt h1,
.menuTemplate .foodCateg .banner .txt h2,
.greenTemplate .banner .txt h2,
.intro .txt h1,
header .btn.btn.btn,
.locations_cards .location,
.list__button,
.list__load,
.hero__scroll,
.block__button,
.btn {
  text-transform: uppercase; }

/* TEXT-DECORATION
=================================================================== */
/* NONE
    =================================================================== */
footer .social a,
.nav-menu-footer li > a,
.has-hover .greenTemplate .banner .txt p a:hover,
.has-hover .greenTemplate .banner .txt p a:focus,
header .btn.btn.btn,
.nav-menu-header li > a,
.list__item,
.list__item:hover,
.list__item:focus,
.list__item > a,
.list__item > a:hover,
.list__item > a:focus,
.list__load,
.list__load:hover,
.list__load:focus,
.instagram__link,
.instagram__link:hover,
.instagram__link:focus,
.block__item:hover,
.block__item:focus {
  text-decoration: none; }

/* FONT-FAMILIES
=================================================================== */
/* mainFont( $package );
    =================================================================== */
footer .copyright,
footer .bnb-footer address,
.banner .txt p,
.intro .txt p,
.list__type,
.experiences__desc,
.block__desc,
.list__excerpt,
.reservation .txt p {
  font-family: 'Asap', sans-serif; }

/* secondFont( $package )
    =================================================================== */
footer .bnb-footer h3,
.nav-menu-footer li a,
footer .newsletter__group form input,
.banner .txt h1,
.intro .txt h1,
.menuTemplate .foodCateg .banner .txt h2,
.locationTemplate .intro .txt h1,
.greenDetailsTemplate .intro .txt p,
.greenTemplate .intro .txt h2,
header .btn.btn.btn,
.nav-menu-header li,
.locations_cards .location,
.list__title,
.list__button,
.list__load,
.hero__title,
.hero__scroll,
.instagram__title,
.instagram__link,
.experiences__title,
.block__title,
.block__button,
body .btn.btn.btn.btn.btn.btn.btn,
.reservation .txt > div, h1, h2, h3, h4, h5, h6 {
  font-family: 'Signika', sans-serif; }

/* COLORS
=================================================================== */
/* $greenColor = #737020
    =================================================================== */
/* $backgroundColor = #fff
    =================================================================== */
footer .copyright,
footer .social a,
footer .bnb-footer,
footer .bnb-footer a,
footer .newsletter__group form,
.defaultTemplate.noFeatured .banner .txt h1,
.greenTemplate .banner .txt,
.menuTemplate .foodCateg .banner .txt h2,
.greenTemplate .banner .txt p a,
header .btn.btn.btn,
.navigation__nav li a,
.locations_cards,
header .trigger,
.list__button,
.list__load:hover,
.list__load:focus,
.hero,
.has-hover .block__item:hover .block__txt,
.has-hover .block__item:focus .block__txt {
  color: #fff; }

/* $backgroundColor, 30% opacity
        =================================================================== */
.has-hover footer .social a:hover,
.has-hover footer .social a:focus,
.has-hover footer .bnb-footer a:hover,
.has-hover footer .bnb-footer a:focus,
.has-hover footer .newsletter__group button:hover,
.has-hover footer .newsletter__group button:focus,
.navigation__nav.hover li > a:not(:hover):not(:focus) {
  color: rgba(255, 255, 255, 0.3); }

/* black = #000
    =================================================================== */
.defaultTemplate .banner .txt h1 span,
.intro .txt h1 span,
.dinningTemplate .banner .txt h1 span,
.menuTemplate .foodCateg .banner .txt h2 span,
.newsDetailsTemplate .title .txt h1 span {
  color: #000; }

/* #f6f6f6
    =================================================================== */
.banner .txt p,
.intro .txt p {
  color: #f6f6f6; }

/* grey = #333
    =================================================================== */
.list__item,
.list__item:hover,
.list__item:focus,
.list__item > a,
.list__item > a:hover,
.list__item > a:focus,
.instagram__header,
.instagram__header a,
.experiences__title,
.experiences__desc,
.block__txt {
  color: #333; }

.list__load {
  color: rgba(51, 51, 51, 0.75); }

/* BACKGROUND-COLORS
=================================================================== */
/* $greenColor = #737020
    =================================================================== */
.greenDetailsTemplate .intro {
  background-color: #737020; }

/* $backgroundColor = #fff
    =================================================================== */
.intro .txt h1 span,
.newsDetailsTemplate .title .txt h1 span,
.defaultTemplate .banner .txt h1 span,
.banner .txt span.devider,
.intro .txt span.devider,
.contactTemplate .intro .txt h1 span,
.site-title,
.dinningTemplate .banner .txt h1 span,
.list__content,
.menuTemplate .foodCateg .banner .txt h2 span,
.home .experiences,
.home .restaurant,
.home .grid {
  background-color: #fff; }

/* #f6f6f6
    =================================================================== */
/* #404040
    =================================================================== */
.socialTemplate .intro .txt span.devider {
  background-color: #404040; }

/* $newBackground = #f2f1e7;
    =================================================================== */
.news__list,
section.instagram,
.block__bg {
  background-color: #f2f1e7; }

/* $newBackground + 93% grey
        =================================================================== */
.list__load {
  background-color: #e5e4da; }

/* grey = #333
    =================================================================== */
/* COLORS
=================================================================== */
/* $mainColor
    =================================================================== */
.intro .txt h1 {
  color: #f6f6f6; }

/* $secondColor
    =================================================================== */
/* $thirdColor
    =================================================================== */
.list__type,
.has-hover .instagram__link:hover,
.has-hover .instagram__link:focus {
  color: #e59124; }

/* BACKGROUND-COLORS
=================================================================== */
/* $mainColor
    =================================================================== */
/* $secondColor
    =================================================================== */
.menuTemplate .banner.noBg,
.dinningTemplate .banner.noBg,
.greenTemplate .banner,
.noFeatured .banner,
.intro {
  background-color: #101010; }

/* $thirdColor
    =================================================================== */
footer,
header .reservations a,
header .trigger,
header .navigation,
.list__button,
.has-hover .block__item:hover .block__bg,
.has-hover .block__item:focus .block__bg {
  background-color: #e59124; }

/* $thirdColor + 20% black
        =================================================================== */
footer .colophon,
.has-hover header .reservations a:hover,
.has-hover header .reservations a:focus,
.locations_cards .restaurant,
header .navigation__wrapper .btn.btn,
.list__content:hover .list__button,
.list__content:focus .list__button,
.list__load:hover,
.list__load:focus {
  background-color: #b7741d; }

/* $thirdColor + 40% black
        =================================================================== */
.has-hover .locations_cards .restaurant:hover,
.has-hover header .navigation__wrapper .btn.btn:hover,
.has-hover header .navigation__wrapper .btn.btn:focus,
.has-hover header .trigger:hover,
.has-hover.menu-open header .trigger,
.has-hover header .trigger:focus {
  background-color: #895716; }

/* $thirdColor + opacity
        =================================================================== */
header .overlay {
  background-color: rgba(229, 145, 36, 0.6); }

/* SUPPORT CLASSES
=================================================================== */
.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  position: absolute; }

.resetVisuallyHidden {
  overflow: visible;
  margin: auto;
  height: auto;
  width: auto;
  clip: auto;
  white-space: normal; }

.hidden-sr {
  position: absolute;
  left: -9999px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  margin: -1px; }

/* RESET
=================================================================== */
/* RESET PADDINGS + MARGINS
    =================================================================== */
.list__type, .list__title, .hero__title, .instagram__title, .experiences__title, .block__title, header .site-title, footer p,
footer h3 {
  margin: 0;
  padding: 0; }

/* RESET UL + OL
    =================================================================== */
.navigation__nav, footer ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/* RESET BUTTON
    =================================================================== */
.navigation .locations_cards .location, footer .newsletter__group input, footer .newsletter__group button {
  padding: 0;
  margin: 0;
  border: 0;
  background: none; }

/* color $duration ease
=================================================================== */
footer .social > li a,
footer .nav-menu-footer a,
footer .newsletter__group button {
  transition: color 0.3s ease; }

/* MODULES
=================================================================== */
.newsletter {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2000;
  display: none; }

.newsletter .bg {
  width: 100%;
  height: 100%;
  background: #101010;
  filter: alpha(opacity=70);
  opacity: 0.70;
  cursor: pointer; }

.newsletter .content {
  width: 875px;
  position: absolute;
  background: #000;
  padding: 60px 70px;
  z-index: 10;
  max-height: 100%;
  overflow: auto; }

.newsletter .content .close {
  font-family: 'Asap', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  font-size: 1.1rem;
  color: #fff;
  position: absolute;
  top: 50px;
  right: 50px;
  padding: 20px 40px 20px 20px;
  background: url(../img/layout/iconClose.png) right center no-repeat;
  background-size: 10px 10px;
  background-position: 81% 50%;
  filter: alpha(opacity=100);
  opacity: 1;
  letter-spacing: 1px;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s; }
  .newsletter .content .close:hover, .newsletter .content .close:focus {
    opacity: 0.5; }

.newsletter .left, .newsletter .right {
  float: left;
  width: 50%; }

.newsletter .parsley-errors-list {
  display: none; }

.newsletter .requiredField {
  width: 340px; }
  .newsletter .requiredField.half {
    width: 110px;
    display: inline-block; }
    .newsletter .requiredField.half.first {
      margin-right: 20px; }

.newsletter .requiredField.requiredField input {
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  border: 1px solid transparent;
  padding-left: 14px;
  position: relative; }

.newsletter .requiredField {
  position: relative;
  overflow: hidden; }
  .newsletter .requiredField.half.half label {
    margin: 0; }
  .newsletter .requiredField.half {
    margin-top: 12px; }
    .newsletter .requiredField.half .field {
      margin: 10px 0 45px 0; }
  .newsletter .requiredField:after {
    content: '';
    position: absolute;
    top: 13px;
    right: -34px;
    width: 15px;
    height: 15px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: white;
    -webkit-transition: all 0.25s;
    transition: all 0.25s; }
  .newsletter .requiredField.half:after {
    top: 59px; }
  .newsletter .requiredField.parsley-error:after {
    right: 14px; }

.newsletter .parsley-error.parsley-error input {
  border: 1px solid white; }

.newsletter .content h2 {
  color: #fff;
  text-align: center; }

.newsletter .content > .title {
  color: #f6f6f6;
  font-size: 20px;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 15px 0;
  letter-spacing: 1px;
  font-family: 'Signika', sans-serif;
  font-weight: 700; }

.newsletter .content span.devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 13px;
  display: block;
  background: #404040; }

.newsletter .content .txt {
  color: #f6f6f6;
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20px;
  max-width: 450px;
  margin: 0 auto 40px;
  clear: both; }

.newsletter.success .txt {
  text-align: center; }
  .newsletter.success .txt i {
    display: inline-block;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.1);
    line-height: 50px;
    font-size: 14px;
    font-size: 1.4rem;
    margin-top: 25px; }

.newsletter .content .txt a {
  color: #fff;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsletter .content .txt a:hover, .newsletter .content .txt a:focus {
  color: #f6f6f6;
  text-decoration: none; }

.newsletter .content p {
  text-align: center;
  margin: 0 auto; }

.newsletter .content form .title {
  font-family: 'Asap', sans-serif;
  font-size: 11px;
  font-size: 1.1rem;
  height: auto;
  color: #f6f6f6;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  margin: 0 0 10px 0;
  font-weight: normal; }

.newsletter .content input {
  background: rgba(255, 255, 255, 0.1);
  height: 44px;
  width: 340px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border: none;
  font-family: 'Asap', sans-serif;
  padding: 12px 15px;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 700;
  color: #f6f6f6;
  display: inline-block;
  margin: 0 0 10px 0; }

.newsletter .content input:nth-child(2n) {
  margin: 0 50px 10px 0; }

.newsletter .content .field {
  display: inline-block;
  margin: 15px 0 45px 0;
  width: 100%;
  padding-right: 28px; }

.newsletter .content .field label {
  width: 130px;
  display: block; }

.newsletter .content .field input {
  width: 110px;
  margin: 0; }

.newsletter .content .field.select {
  margin: 15px 0 45px 0px; }

.bootstrap-select {
  width: 100% !important;
  background: rgba(255, 255, 255, 0.1);
  display: inline-block;
  border: none; }

.bootstrap-select button {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background: transparent;
  padding: 12px 15px;
  border: none;
  box-shadow: none; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0px;
  vertical-align: middle;
  border: none;
  content: "";
  font-size: 11px;
  font-size: 1.1rem; }

.bootstrap-select button span {
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 700;
  color: #f6f6f6;
  font-family: 'Asap', sans-serif;
  text-shadow: none; }

.bootstrap-select button .caret {
  border: none;
  position: relative;
  display: inline-block;
  font-size: 11px;
  font-size: 1.1rem;
  transform: translateY(-50%);
  height: 12px;
  font-weight: bold;
  right: 32px !important; }

.filter-option {
  line-height: 20px; }

.bootstrap-select.btn-group .dropdown-menu {
  margin: 0;
  padding: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }

.bootstrap-select.btn-group .dropdown-menu.inner {
  box-shadow: inset rgba(255, 255, 255, 0.15) 0 0 0 200px;
  background: #101010; }

.bootstrap-select.btn-group .dropdown-menu.inner {
  margin: 0;
  padding: 0; }

.bootstrap-select.btn-group .dropdown-menu.inner li a:hover, .bootstrap-select.btn-group .dropdown-menu.inner li a:focus, .bootstrap-select.btn-group .dropdown-menu.inner li.selected a {
  background: transparent; }

.bootstrap-select.btn-group .dropdown-menu.inner li a {
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 700;
  color: #f6f6f6;
  font-family: 'Asap', sans-serif;
  padding: 12px 10px; }

.newsletter .content .btn {
  background: #e1dede;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #101010;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "all";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "all";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "all";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsletter .content .btn span {
  font-size: 8px;
  font-size: 0.8rem;
  display: inline-block;
  margin: 0 0 0 5px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "all";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "all";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "all";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsletter .content .btn:hover, .newsletter .content .btn:hover span, .newsletter .content .btn:focus, .newsletter .content .btn:focus span {
  color: white; }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus, .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background: none;
  box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 0 40px; }

.bootstrap-select button .caret.icon-up-open {
  display: none !important; }

.bootstrap-select.open button .caret.icon-up-open {
  display: inline-block !important; }

.bootstrap-select.open button .caret.icon-down-open {
  display: none !important; }

.banner .txt {
  display: flex;
  position: relative;
  width: 100%;
  z-index: 10;
  left: 0;
  height: 100%;
  align-items: center;
  justify-content: center; }
  .greenTemplate .banner .txt,
  .dinningTemplate .banner .txt {
    padding: 60px 40px;
    flex-direction: column; }
  .banner .txt h1,
  .menuTemplate .foodCateg .banner .txt h2,
  .greenTemplate .banner .txt h2 {
    width: 100%;
    text-align: center;
    letter-spacing: 2px;
    margin: 0 30px; }
    .banner .txt h1 span,
    .menuTemplate .foodCateg .banner .txt h2 span,
    .greenTemplate .banner .txt h2 span {
      padding: 10px 20px; }
  .greenTemplate .banner .txt h1,
  .dinningTemplate .banner .txt h1 {
    margin-bottom: 10px; }
  .greenTemplate .banner .txt h2,
  .dinningTemplate .banner .txt h2 {
    margin: 0 0 10px 0; }
  .banner .txt p {
    text-align: center;
    margin: 17px 0 0 0; }
    .dinningTemplate .banner .txt p {
      padding: 0px 30px; }
    .greenTemplate .banner .txt p {
      margin: 17px auto 0;
      max-width: 740px;
      padding: 0 20px; }
    .menuTemplate .banner .txt p,
    .defaultTemplate .banner .txt p {
      display: none; }
  .banner .txt span.devider {
    display: block;
    width: 30px;
    height: 2px;
    margin: 0 auto; }
    .greenTemplate .banner .txt span.devider {
      margin-bottom: 15px; }
    .defaultTemplate .banner .txt span.devider {
      display: none; }

.banner.banner.banner p {
  letter-spacing: 1.8px; }
  .bundle4 .banner.banner.banner p {
    font-weight: 500; }

.menuTemplate .banner,
.dinningTemplate .banner,
.defaultTemplate .banner,
.greenTemplate .banner {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 360px; }
  .menuTemplate .banner.parallax,
  .dinningTemplate .banner.parallax,
  .defaultTemplate .banner.parallax,
  .greenTemplate .banner.parallax {
    background-attachment: fixed;
    background-position: 50% 0; }
    .mobileOnly .menuTemplate .banner.parallax,
    .ie .menuTemplate .banner.parallax, .mobileOnly
    .dinningTemplate .banner.parallax,
    .ie
    .dinningTemplate .banner.parallax, .mobileOnly
    .defaultTemplate .banner.parallax,
    .ie
    .defaultTemplate .banner.parallax, .mobileOnly
    .greenTemplate .banner.parallax,
    .ie
    .greenTemplate .banner.parallax {
      background-attachment: scroll;
      background-position: center center; }

.greenTemplate .banner {
  height: auto;
  background-image: none !important; }

.iPad .menuTemplate .banner,
.iPad .dinningTemplate .banner {
  background-position: center center !important;
  background-size: cover !important;
  height: auto; }
  .iPad .menuTemplate .banner .txt,
  .iPad .dinningTemplate .banner .txt {
    position: relative;
    padding: 125px 0;
    transform: none; }
    .iPad .menuTemplate .banner .txt h1,
    .iPad .dinningTemplate .banner .txt h1 {
      max-width: 700px;
      margin: 0 auto; }

.dinningTemplate .banner:before,
.defaultTemplate .banner:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.dinningTemplate .banner::before {
  background: url(../img/layout/FiltreLarge.png) center center no-repeat;
  opacity: 0.5; }

.menuTemplate .foodCateg .banner .bg {
  position: absolute;
  height: 140%;
  width: 100%;
  background-size: 100%; }

@media (max-width: 640px) {
  .menuTemplate .banner,
  .dinningTemplate .banner,
  .defaultTemplate .banner,
  .greenTemplate .banner {
    padding-top: 60px; }
  .menuTemplate .banner,
  .dinningTemplate .banner {
    background-size: cover !important;
    background-attachment: scroll !important; } }

.intro,
div.title {
  height: 360px; }
  .intro .txt,
  div.title .txt {
    display: flex;
    position: relative;
    width: 100%;
    z-index: 10;
    left: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .intro .txt h1,
    div.title .txt h1 {
      width: 100%;
      text-align: center;
      letter-spacing: 2px;
      margin: 0 30px;
      font-size: 6rem; }
      .locationTemplate .intro .txt h1, .locationTemplate
      div.title .txt h1 {
        margin-bottom: 10px; }
      .socialTemplate .intro .txt h1, .socialTemplate
      div.title .txt h1 {
        margin-bottom: 15px; }
      .greenDetailsTemplate .intro .txt h1,
      .friendsTemplate .intro .txt h1,
      .productsTemplate .intro .txt h1,
      .contactTemplate .intro .txt h1,
      .pageErrorTemplate .intro .txt h1,
      .newsDetailsTemplate .intro .txt h1, .greenDetailsTemplate
      div.title .txt h1,
      .friendsTemplate
      div.title .txt h1,
      .productsTemplate
      div.title .txt h1,
      .contactTemplate
      div.title .txt h1,
      .pageErrorTemplate
      div.title .txt h1,
      .newsDetailsTemplate
      div.title .txt h1 {
        margin: 0 0 10px; }
      .friendsTemplate .intro .txt h1,
      .productsTemplate .intro .txt h1,
      .contactTemplate .intro .txt h1, .friendsTemplate
      div.title .txt h1,
      .productsTemplate
      div.title .txt h1,
      .contactTemplate
      div.title .txt h1 {
        padding: 0 30px; }
      .intro .txt h1 span,
      div.title .txt h1 span {
        padding: 10px 20px; }
    .intro .txt img,
    div.title .txt img {
      margin: 0 auto 15px;
      display: block; }
    .intro .txt span.devider,
    div.title .txt span.devider {
      display: none;
      width: 30px;
      height: 2px;
      margin: 0 auto 13px; }
    .intro .txt p,
    div.title .txt p {
      display: none;
      text-align: center;
      margin: 17px 0 0 0; }
      .bundle4 .intro .txt p, .bundle4
      div.title .txt p {
        font-weight: 500; }
      .socialTemplate .intro .txt p, .socialTemplate
      div.title .txt p {
        line-height: 26px;
        padding: 0px 30px 0 30px;
        max-width: 410px;
        margin: 0 auto; }
      .friendsTemplate .intro .txt p,
      .productsTemplate .intro .txt p,
      .faqTemplate .intro .txt p,
      .contactTemplate .intro .txt p,
      .pageErrorTemplate .intro .txt p, .friendsTemplate
      div.title .txt p,
      .productsTemplate
      div.title .txt p,
      .faqTemplate
      div.title .txt p,
      .contactTemplate
      div.title .txt p,
      .pageErrorTemplate
      div.title .txt p {
        margin: 4px auto 0;
        max-width: 740px; }
      .friendsTemplate .intro .txt p,
      .productsTemplate .intro .txt p,
      .contactTemplate .intro .txt p, .friendsTemplate
      div.title .txt p,
      .productsTemplate
      div.title .txt p,
      .contactTemplate
      div.title .txt p {
        padding: 0px 30px; }
  @media (max-width: 640px) {
    .intro,
    div.title {
      padding-top: 60px; } }

.navigation {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  max-width: 50%;
  height: 100vh;
  min-width: 320px;
  transform: translateX(-100%);
  padding: 120px 0 0px; }
  .admin-bar .navigation {
    top: 32px; }
  .navigation__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .navigation .locations_cards {
    margin: 40px 30px 0 100px;
    font-size: 0;
    position: relative;
    transform: translateX(calc(-100% - 100px)); }
    .navigation .locations_cards .restaurant {
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      transition: background-color 0.3s ease;
      padding: 0 0 0 20px;
      font-size: 0;
      text-align: center;
      margin-right: 12px;
      margin-bottom: 14px; }
      .navigation .locations_cards .restaurant .icon {
        fill: currentColor;
        width: 21px;
        height: 21px;
        display: inline-block;
        vertical-align: middle; }
    .navigation .locations_cards .location {
      border-radius: 0;
      position: relative;
      appearance: none;
      cursor: pointer;
      padding-left: 12px;
      vertical-align: middle;
      color: currentColor;
      line-height: 50px;
      letter-spacing: 0.1em; }
  .navigation__nav {
    margin-left: 100px;
    margin-right: 90px; }
    .navigation__nav li {
      line-height: 1;
      padding: 16px 0;
      position: relative;
      transform: translateX(calc(-100% - 100px)); }
      .navigation__nav li:first-child {
        padding-top: 0; }
      .navigation__nav li:last-child {
        padding-bottom: 0; }
    .navigation__nav a {
      display: block;
      transition: color 1s ease;
      position: relative; }
      .navigation__nav a::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -100px;
        width: 0;
        height: 2px;
        transform: translate(0, -50%);
        background-color: #fff;
        transition: width 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
      .has-hover .navigation__nav a:hover:before,
      .has-hover .navigation__nav a:focus:before {
        width: 80px; }
  @media (max-width: 1024px) {
    .navigation .locations_cards {
      margin: 30px 60px 0 100px; } }
  @media (max-height: 700px) {
    .navigation__nav li {
      padding: 10px 0; } }
  @media (max-width: 992px) {
    .navigation {
      width: 100%;
      padding: 70px 0 0; }
      .navigation__nav {
        margin: 0 30px 0 60px; }
        .navigation__nav li {
          padding: 10px 0;
          transform: translateX(calc( -100% - 60px)); }
        .has-hover .navigation__nav a:hover:before {
          width: 45px; }
      .navigation .locations_cards {
        margin: 30px 60px 0;
        transform: translateX(calc( -100% - 60px)); }
        .navigation .locations_cards .restaurant,
        .navigation .locations_cards .btn.btn.btn {
          display: block;
          margin-right: 0;
          width: 100%;
          max-width: 220px; } }
  @media (max-width: 767px) {
    .navigation {
      max-width: none; } }

.list__item {
  position: relative;
  display: flex;
  margin-bottom: 100px;
  align-items: flex-start; }

.list__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 60px 120px 60px 100px;
  z-index: 2;
  margin-left: -200px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 620px;
  align-self: flex-end; }

.list__load {
  margin-bottom: 100px;
  display: block;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 50px;
  transition: color 0.3s ease, background-color 0.3s ease; }

.list__type {
  order: -1; }

.list__title {
  line-height: 1.25em;
  margin: 20px 0; }

.list__excerpt {
  line-height: 1.55em; }

.list__img {
  position: relative;
  width: calc( 100% - 0px - 100px);
  overflow: hidden;
  opacity: .99;
  backface-visibility: hidden;
  z-index: 1;
  max-height: 480px; }
  .list__img > a {
    display: block;
    position: relative;
    z-index: 1; }
  .list__img::before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1; }
  .list__img img {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    will-change: transform;
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1); }
  .has-hover .list__img > a:hover > img,
  .has-hover .list__img > a:focus > img {
    transform: translate3d(0, 0, 0) scale(1.02, 1.02); }

.list__button {
  margin-top: 20px;
  font-size: 0;
  padding: 0 30px;
  display: inline-block;
  white-space: nowrap;
  align-self: flex-start;
  transition: background-color 0.3s ease;
  will-change: width;
  line-height: 40px; }
  .list__button span {
    letter-spacing: 0.1em;
    line-height: 1;
    display: inline-block;
    vertical-align: middle; }
  .list__button::before {
    content: '';
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 2px;
    will-change: width, margin-right;
    background-color: currentColor;
    transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1), margin-right 0.8s cubic-bezier(0.19, 1, 0.22, 1); }
  .has-hover .list__content:hover .list__button::before,
  .has-hover .list__content:focus .list__button::before {
    width: 40px;
    margin-right: 20px; }

@media (max-width: 991px) {
  .list__item {
    margin-bottom: 80px;
    display: block;
    padding-bottom: 0; }
  .list__content {
    padding: 40px 60px;
    width: auto;
    max-width: none;
    margin: -100px 60px 0 !important; }
  .list__img {
    max-width: none;
    width: 100%; }
  .list__load {
    margin-bottom: 80px; } }

@media (max-width: 768px) {
  .list__title {
    margin: 12px 0; }
  .list__button {
    margin-top: 8px; } }

@media (max-width: 640px) {
  .list__img {
    max-height: 350px; }
    .list__img img {
      width: 100% !important;
      height: 100% !important; }
  .list__item {
    margin-bottom: 40px; }
  .list__content {
    padding: 30px 30px;
    margin: -100px 15px 0 !important; }
  .list__load {
    margin-bottom: 40px; } }

.news__list {
  padding: 100px 0 0; }
  .home .news__list {
    padding-top: 80px; }
  .news__list .container-grid {
    display: flex;
    flex-direction: column;
    max-width: 1440px; }
  .news__list .list__item {
    margin-right: 100px; }
    .home .news__list .list__item {
      margin-bottom: 80px; }
    .news__list .list__item .list__img img {
      width: auto;
      height: auto; }
    .news__list .list__item:nth-child(2n) {
      margin-right: 0;
      margin-left: 100px; }
      .news__list .list__item:nth-child(2n) .list__img {
        order: 2; }
      .news__list .list__item:nth-child(2n) .list__content {
        margin-left: 0;
        margin-right: -17.6%;
        order: 1; }

@media (max-width: 991px) {
  .news__list {
    padding: 60px 0 0; }
    .news__list .container-grid {
      padding-left: 60px;
      padding-right: 60px; }
    .news__list .list__item:nth-child(1n) {
      margin-left: 0;
      margin-right: 0; } }

@media (max-width: 640px) {
  .news__list .container-grid {
    padding-left: 30px;
    padding-right: 30px; }
  .home .news__list .list__item {
    margin-bottom: 40px; } }

.hero {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1; }
  .hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .hero__bg::before {
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1); }
    .hero__bg img {
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 100%;
      height: 100%;
      display: block; }
  .hero__title {
    position: relative;
    padding: 30px;
    text-align: center;
    opacity: 0; }
  .hero__scroll {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 50px;
    left: 0; }
    .hero__scroll span {
      letter-spacing: 0.1em;
      display: block; }

.instagram {
  padding-bottom: 90px;
  padding-top: 100px; }
  .instagram .container-grid {
    max-width: 1440px; }
  .instagram__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -10px;
    margin-right: -10px; }
  .instagram__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 30px; }
  .instagram__title .icon,
  .instagram__title span {
    display: inline-block;
    vertical-align: baseline; }
  .instagram__title .icon {
    font-size: 20px;
    margin-right: 10px; }
  .instagram__link {
    display: inline-block;
    transition: color 0.3s ease; }
  .instagram__item {
    display: block;
    max-width: 295px;
    max-height: 295px;
    width: calc(100% - 20px);
    height: 100%;
    margin: 10px;
    opacity: 0.99;
    will-change: transform;
    backface-visibility: hidden;
    overflow: hidden;
    flex: 1; }
  .instagram__img {
    width: 100%;
    height: 100%;
    will-change: transform;
    backface-visibility: hidden;
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1);
    object-fit: cover;
    font-family: 'object-fit: cover;'; }
    .has-hover .instagram__item:hover .instagram__img,
    .has-hover .instagram__item:focus .instagram__img {
      transform: translate3d(0, 0, 0) scale(1.02, 1.02); }
  @media (max-width: 1439px) {
    .instagram__item {
      max-height: 240px;
      max-width: 240px; } }
  @media (max-width: 991px) {
    .instagram {
      padding-top: 60px;
      padding-bottom: 50px; }
      .instagram .container-grid {
        padding-left: 60px;
        padding-right: 60px; }
      .instagram__item {
        flex: auto; } }
  @media (max-width: 768px) {
    .instagram__item {
      width: calc(50% - 20px); } }
  @media (max-width: 640px) {
    .instagram {
      padding-top: 30px;
      padding-bottom: 20px; }
      .instagram .container-grid {
        padding-left: 30px;
        padding-right: 30px; }
      .instagram__item {
        max-height: 150px;
        max-width: 150px; }
      .instagram__header {
        display: block; }
      .instagram__link {
        margin-top: 12px; } }

.experiences {
  padding: 80px 0; }
  .experiences .container-grid {
    max-width: 1440px; }
  .experiences__title {
    margin-bottom: 20px; }
  .experiences__title, .experiences__desc {
    padding: 0 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 750px; }
  .experiences__desc > *:first-child {
    margin-top: 0; }
  .experiences__desc > *:last-child {
    margin-bottom: 0; }
  .experiences__desc > p {
    margin: 1em 0; }
  .experiences__wrapper {
    margin-top: 100px;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: center; }
  @media (max-width: 1024px) {
    .experiences__wrapper {
      display: block; } }
  @media (max-width: 991px) {
    .experiences {
      padding: 60px 0; }
      .experiences .container-grid {
        padding-left: 60px;
        padding-right: 60px; }
      .experiences__wrapper {
        margin-top: 60px; } }
  @media (max-width: 640px) {
    .experiences {
      padding: 30px 0; }
      .experiences .container-grid {
        padding-left: 30px;
        padding-right: 30px; }
      .experiences__wrapper {
        margin-top: 30px; } }

.block__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: background-color 0.3s ease; }

.block__item {
  display: flex;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  position: relative; }
  .block__item::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .block__item.is_grid {
    height: auto;
    align-self: stretch;
    overflow: hidden; }
    .block__item.is_grid .block__img,
    .block__item.is_grid .block__txt {
      order: 1; }
    .block__item.is_grid:nth-child(1) {
      width: 410px;
      flex-direction: column; }
      .block__item.is_grid:nth-child(1) .block__img {
        max-height: 249px;
        max-width: none; }
      .block__item.is_grid:nth-child(1) .block__txt {
        flex: 1;
        min-width: 0;
        max-width: none; }
    .block__item.is_grid:nth-child(2) {
      width: 820px; }
      .block__item.is_grid:nth-child(2) .block__img {
        max-width: 410px;
        width: 100%;
        position: relative; }
        .block__item.is_grid:nth-child(2) .block__img:before {
          content: '';
          display: block;
          padding-top: 117.17391%; }
        .block__item.is_grid:nth-child(2) .block__img .wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }
      .block__item.is_grid:nth-child(2) .block__txt {
        width: 50%;
        max-width: none; }

.block__img {
  order: 2;
  position: relative;
  overflow: hidden;
  max-width: 370px;
  opacity: 0.99;
  will-change: transform;
  backface-visibility: hidden; }
  .block__img img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    max-width: none;
    will-change: transform;
    overflow: hidden;
    backface-visibility: hidden;
    transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1); }
    .has-hover .block__item:hover .block__img img,
    .has-hover .block__item:focus .block__img img {
      transform: translate3d(0, 0, 0) scale(1.02, 1.02); }

.block__desc {
  line-height: 1.55;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 40px; }

.block__txt {
  position: relative;
  order: 1;
  padding: 40px;
  min-width: 240px;
  max-width: 240px;
  transition: color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.block__title {
  line-height: 1.33333; }

.block__button {
  margin-top: 20px;
  font-size: 0;
  display: inline-block;
  white-space: nowrap;
  align-self: flex-start;
  color: currentColor;
  will-change: width;
  line-height: 1; }
  .block__button span {
    letter-spacing: 0.1em;
    line-height: 1;
    display: inline-block;
    vertical-align: middle; }
  .block__button::before {
    content: '';
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 2px;
    will-change: width, margin-right;
    background-color: currentColor;
    transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1), margin-right 0.8s cubic-bezier(0.19, 1, 0.22, 1); }
  .has-hover .block__item:hover .block__button::before,
  .has-hover .block__item:focus .block__button::before {
    width: 40px;
    margin-right: 20px; }

@media (max-width: 1280px) {
  .block__item {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    margin-bottom: 30px;
    margin-bottom: 30px; }
    .block__item.is_grid {
      flex-direction: column; }
      .block__item.is_grid:nth-child(2) .block__img::before {
        content: none; }
      .block__item.is_grid:nth-child(1n) {
        width: auto; }
        .block__item.is_grid:nth-child(1n) .block__img {
          max-height: 249px;
          height: 249px;
          max-width: none; }
        .block__item.is_grid:nth-child(1n) .block__txt {
          min-width: 0;
          width: auto;
          max-width: none; }
        .block__item.is_grid:nth-child(1n) .block__desc {
          margin-bottom: 20px; }
    .block__item:last-child {
      margin-bottom: 0; } }

@media (max-width: 1024px) {
  .block__item {
    margin-left: auto;
    margin-right: auto;
    max-width: 610px;
    margin-bottom: 30px;
    margin-bottom: 30px; }
    .block__item.is_grid {
      flex-direction: column; }
      .block__item.is_grid:nth-child(2) .block__img::before {
        content: none; }
      .block__item.is_grid:nth-child(1n) {
        width: auto; }
        .block__item.is_grid:nth-child(1n) .block__img {
          max-height: 249px;
          height: 249px;
          max-width: none; }
        .block__item.is_grid:nth-child(1n) .block__txt {
          min-width: 0;
          width: auto;
          max-width: none; }
        .block__item.is_grid:nth-child(1n) .block__desc {
          margin-bottom: 20px; }
    .block__item:last-child {
      margin-bottom: 0; } }

@media (max-width: 640px) {
  .block__txt {
    max-width: none; }
  .block__img .wrapper {
    height: 200px; }
  .block__item {
    display: block; }
    .block__item.is_grid:nth-child(1n) .block__img {
      height: auto; }
      .block__item.is_grid:nth-child(1n) .block__img .wrapper {
        position: relative; } }

.restaurant {
  padding: 80px 0; }
  .restaurant .container-grid {
    max-width: 1440px; }
  .restaurant .list__item {
    margin-bottom: 0;
    padding-bottom: 0; }
  .restaurant .list__content {
    background-color: transparent;
    padding: 0;
    margin: 0 0 0 100px; }
  .restaurant .list__img {
    max-height: none;
    width: auto;
    position: relative;
    z-index: 1;
    margin-top: -110px;
    margin-bottom: -110px; }
    .restaurant .list__img img {
      transform: none !important; }
  .restaurant + .news__list .list__item:nth-child(odd) {
    margin-right: 0;
    margin-left: 100px; }
    .restaurant + .news__list .list__item:nth-child(odd) .list__img {
      order: 2; }
    .restaurant + .news__list .list__item:nth-child(odd) .list__content {
      margin-left: 0;
      margin-right: -17.6%;
      order: 1; }
  .restaurant + .news__list .list__item:nth-child(even) {
    margin-right: 100px;
    margin-left: 0; }
    .restaurant + .news__list .list__item:nth-child(even) .list__img {
      order: 1; }
    .restaurant + .news__list .list__item:nth-child(even) .list__content {
      margin-left: -17.6%;
      margin-right: 0;
      order: 2; }
  @media (max-width: 991px) {
    .restaurant {
      padding: 60px 0; }
      .restaurant .container-grid {
        padding-left: 60px;
        padding-right: 60px; }
      .restaurant + .news__list .list__item:nth-child(odd), .restaurant + .news__list .list__item:nth-child(even) {
        margin-left: 0;
        margin-right: 0; }
      .restaurant .list__content {
        margin-bottom: 60px;
        margin-top: 30px !important; }
      .restaurant .list__img {
        margin: 0;
        max-height: 480px;
        transform: none !important; } }
  @media (max-width: 640px) {
    .restaurant {
      padding: 30px 0; }
      .restaurant .container-grid {
        padding-left: 30px;
        padding-right: 30px; }
      .restaurant .list__content {
        margin-bottom: 30px; } }

.promo-banner {
  height: 100vh;
  max-height: 700px;
  position: relative;
  overflow: hidden; }
  .promo-banner .container-grid {
    max-width: 1440px;
    display: flex;
    align-items: center;
    height: 100%; }
  .promo-banner__img {
    position: absolute;
    top: -12.5%;
    left: -12.5%;
    width: 125%;
    height: 125%; }
    .promo-banner__img img {
      display: block;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 100%;
      height: 100%; }
  .promo-banner .list__item {
    margin: 0;
    padding-bottom: 0; }
  .promo-banner .list__content {
    margin-left: 0;
    margin-bottom: 0;
    align-self: auto; }
  @media (max-width: 991px) {
    .promo-banner .container-grid {
      padding-left: 60px;
      padding-right: 60px; }
    .promo-banner .list__item {
      margin-right: 0; }
    .promo-banner .list__content {
      margin: 0 !important; }
    .promo-banner__img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: none !important; } }
  @media (max-width: 767px) {
    .promo-banner {
      max-height: 480px; } }
  @media (max-width: 640px) {
    .promo-banner .container-grid {
      padding-left: 30px;
      padding-right: 30px; } }

.grid {
  padding: 80px 0; }
  .grid .container-grid {
    max-width: 1440px; }
  .grid__wrapper {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: center; }
  @media (max-width: 1024px) {
    .grid__wrapper {
      display: block; } }
  @media (max-width: 991px) {
    .grid {
      padding: 60px 0; }
      .grid .container-grid {
        padding-left: 60px;
        padding-right: 60px; }
      .grid__wrapper {
        margin-top: 60px; } }
  @media (max-width: 640px) {
    .grid {
      padding: 30px 0; }
      .grid .container-grid {
        padding-left: 30px;
        padding-right: 30px; }
      .grid__wrapper {
        margin-top: 30px; } }

/* CORE
=================================================================== */
/*::-moz-selection{background: #fcd700; color: #fff; text-shadow: none;}
::selection {background: #fcd700; color: #fff; text-shadow: none;}*/
/*a:link {-webkit-tap-highlight-color: #fcd700;}*/
/* ==========================================================================
    HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
    ========================================================================== */
html,
button,
input,
select,
textarea {
  color: #222; }

body {
  line-height: 1.4;
  cursor: default;
  -webkit-text-size-adjust: none; }

img.inline.inline.inline.inline {
  display: inline-block;
  margin: 0 25px; }

img {
  max-width: 100%;
  height: auto; }

::-webkit-input-placeholder {
  color: white;
  opacity: 1; }

:-moz-placeholder {
  /* Firefox 18- */
  color: white;
  opacity: 1; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
  opacity: 1; }

:-ms-input-placeholder {
  color: white;
  opacity: 1; }

input[name=newsletter]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
  opacity: 1; }

input[name=newsletter]:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.5);
  opacity: 1; }

input[name=newsletter]::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.5);
  opacity: 1; }

input[name=newsletter]:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
  opacity: 1; }

[class^="icon-"], [class*=" icon-"],
.aa,
.formTemplate .question h4,
.formTemplate .intro h4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  filter: none !important;
  -webkit-overflow-scrolling: touch; }

body {
  -webkit-overflow-scrolling: touch; }

#windowRef {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -999; }

#widgetReservation {
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 800px;
  z-index: 1;
  padding: 100px 0; }

.btn:active, .btn-group.open .dropdown-toggle:active {
  box-shadow: none; }

/********************************************
 *
 * SCSS
 *  
 *********************************************/
.fluidImage {
  max-width: 100%; }

.img100 {
  width: 100%; }

a.skipContent {
  display: block;
  color: #fff;
  width: 100%;
  font-family: 'Asap', sans-serif;
  transform: translateY(-100%);
  will-change: transform;
  transition: all 0.5s;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  padding: 20px 0;
  opacity: 0;
  z-index: 1000000; }
  a.skipContent:focus {
    transform: translateY(0);
    opacity: 1; }

/* ==========================================================================
    General
    ========================================================================== */
p a {
  color: #e59124;
  transition: all 0.25s;
  text-decoration: underline; }
  p a:hover, p a:focus {
    color: #101010;
    text-decoration: none; }

.container {
  width: 100%;
  max-width: 1920px;
  padding: 0;
  position: relative; }

.dropdown-menu {
  display: block !important;
  opacity: 1;
  min-height: 0 !important;
  max-height: 0 !important;
  overflow: hidden;
  transition: all 0.5s;
  transform: translateZ(0px);
  -webkit-transform: translateZ(0px); }

.open > .dropdown-menu {
  min-height: 0 !important;
  opacity: 1;
  max-height: 500px !important; }

.safari .bootstrap-select.btn-group .btn .caret {
  margin-top: -6px; }

.dropDown_b_b {
  float: left;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 40px;
  overflow: hidden;
  position: relative;
  background: #1f1e1e;
  transition: background 0.3s ease; }
  .dropDown_b_b.locations {
    background: #1f1e1e url(../img/layout/iconPinWhite.png) 14px center no-repeat;
    background-size: 13px 15px;
    padding-right: 63px;
    padding-left: 24px; }
    .dropDown_b_b.locations select {
      text-indent: 1.1rem; }
  .dropDown_b_b.locations.white {
    background: #e1dede url(../img/layout/iconPin.png) 15px center no-repeat;
    background-size: 13px 15px;
    transition: background 0.3s ease, opacity 0.3s ease;
    padding-right: 43px; }
  .dropDown_b_b.white {
    background: #e1dede; }
    .dropDown_b_b.white.giftcard {
      float: right; }
    .dropDown_b_b.white:before {
      color: #000007; }
    .dropDown_b_b.white:hover, .dropDown_b_b.white:focus {
      background-color: #e1dede !important;
      opacity: 0.7; }
    .dropDown_b_b.white select {
      color: #000;
      font-family: 'Asap', sans-serif;
      font-weight: 700;
      font-style: normal; }
  .dropDown_b_b:before {
    position: absolute;
    color: #fff;
    top: 15px;
    right: 16px;
    content: '\e804';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    height: 12px;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    font-size: 11px;
    font-size: 1.1rem; }
  .dropDown_b_b select {
    position: relative;
    font-family: 'Asap', sans-serif;
    font-size: 13px;
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 1px;
    height: 42px;
    width: calc(100% + 93px);
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    z-index: 1;
    -webkit-appearance: none; }
    .dropDown_b_b select option {
      color: #000; }

.btn {
  background: #e59124;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: white;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: all;
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear; }
  .btn:hover, .btn:focus {
    color: white;
    background: #bf7617; }
  header .btn {
    font-style: normal; }
  .btn span {
    vertical-align: middle;
    font-size: 12px;
    font-size: 1.2rem;
    position: relative; }
  .btn.reservations {
    margin-bottom: 28px; }

.reservation {
  width: 100%;
  height: auto;
  background: #000;
  position: fixed;
  z-index: 1500;
  bottom: 100%;
  left: 0;
  display: none; }

.reservation .btn {
  font-style: normal; }
  .reservation .btn.reserve {
    padding-top: 11px;
    padding-bottom: 10px; }

.reservation .container {
  margin: 0 auto;
  max-width: 1250px; }

.reservation > div {
  position: relative; }

.reservation .close {
  font-family: 'Asap', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  font-size: 1.1rem;
  color: #fff;
  position: absolute;
  top: 60px;
  right: 0px;
  padding: 20px 40px 20px 20px;
  background: url(../img/layout/iconClose.png) right center no-repeat;
  background-size: 10px 10px;
  background-position: 81% 50%;
  filter: alpha(opacity=100);
  opacity: 1;
  letter-spacing: 1px;
  z-index: 999;
  transition: all 0.25s; }
  .reservation .close:hover, .reservation .close:focus {
    opacity: 0.7; }

.reservation .txt {
  width: 400px;
  margin: 80px 50px 45px 0px;
  float: left; }

.reservation form {
  margin: 80px 0px 0px 0px;
  float: right; }

.reservation .field:first-child .dropdown-toggle {
  background-image: url(../img/layout/iconPinWhite.png);
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 13px 16px;
  padding-left: 41px; }
  .reservation .field:first-child .dropdown-toggle.disabled {
    opacity: 1;
    background-color: transparent; }
    .reservation .field:first-child .dropdown-toggle.disabled span.caret {
      display: none; }

.reservation .field .dropdown-toggle:hover, .reservation .field .dropdown-toggle:focus {
  color: white;
  background-color: #bf7617; }

.reservation .field label {
  display: block; }

.ui-datepicker {
  background: #101010;
  padding: 10px;
  font-size: 13px;
  font-size: 1.3rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Asap', sans-serif;
  margin-top: 2px;
  display: none; }

.ui-datepicker th {
  text-align: center; }

.ui-datepicker .ui-state-default {
  color: #fff;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.ui-datepicker td {
  cursor: pointer; }

.ui-datepicker td:hover .ui-state-default,
.ui-datepicker td:focus .ui-state-default {
  text-decoration: none; }

.ui-datepicker::before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: -1; }

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  cursor: pointer;
  text-decoration: none;
  padding: 4px 5px;
  color: #fff; }

.ui-icon-circle-triangle-e:before,
.ui-icon-circle-triangle-w:before {
  font-size: 11px;
  font-size: 1.1rem; }

.ui-datepicker .ui-datepicker-next:hover,
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:focus,
.ui-datepicker .ui-datepicker-prev:focus {
  background: rgba(255, 255, 255, 0.1); }

.ui-datepicker .ui-datepicker-prev {
  float: left;
  margin-right: 10px; }

.ui-datepicker .ui-datepicker-next {
  float: right;
  margin-left: 10px; }

.ui-datepicker .ui-datepicker-title {
  overflow: hidden;
  text-align: center;
  color: #fff;
  line-height: 23px;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem; }

.ui-datepicker-calendar {
  width: 205px;
  margin-top: 5px; }

.ui-datepicker .ui-datepicker-today a {
  color: #fff !important; }

.ui-datepicker .ui-state-disabled .ui-state-default {
  color: white; }

.ui-datepicker .ui-state-disabled {
  opacity: 0.5; }

.ui-datepicker .ui-state-active {
  background: #e59124;
  color: #fff;
  padding: 2px 5px; }

.reservation input[type=text], .iPad .reservation input[type=date] {
  border: none;
  padding: 12px 15px;
  min-width: 100px;
  color: white;
  font-size: 13px;
  font-size: 1.3rem;
  height: 44px;
  background: rgba(255, 255, 255, 0.1);
  font-family: 'Asap', sans-serif; }

.iPad .reservation input[type=date] {
  -webkit-appearance: none; }

.reservation form .field {
  float: left;
  margin-right: 10px; }

.reservation label {
  color: #f6f6f6;
  font-size: 11px;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  font-weight: normal;
  letter-spacing: 1px;
  margin-top: 1em;
  margin-top: 42px;
  margin-bottom: 10px; }

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
  background: none; }

.dropdown-menu {
  box-shadow: none;
  border: none; }

.reservation .reserve {
  float: left;
  margin-top: 67px;
  background: #e59124;
  border-radius: 0px;
  color: #fff; }

.reservation .reserve:hover, .reservation .reserve:focus {
  background: #bf7617; }

.reservation .reserve .icon-right-open {
  margin-left: 5px; }

.reservation .txt > div {
  font-size: 18px;
  font-size: 1.8rem;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700; }

.reservation .txt p {
  color: #f6f6f6;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20px;
  margin: 0 0 20px 0; }

.reservation .txt p:last-child {
  margin: 0px; }

.reservation .txt p a {
  color: #fff !important;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.reservation .txt p a:hover, .reservation .txt p a:focus {
  color: #f6f6f6;
  text-decoration: none; }

.carousel-indicators {
  z-index: 333; }

/* ==========================================================================
    Home
    ========================================================================== */
.homeTemplate {
  padding: 0; }

.carousel:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.25) 100%);
  bottom: 0;
  left: 0;
  z-index: 40; }

.homeTemplate.newsFirst .news {
  background: #e1dede; }

.homeTemplate .carousel-indicators {
  bottom: 17px;
  z-index: 50;
  font-size: 0px; }

.bx_inner {
  overflow: visible; }

.homeTemplate .carousel-indicators li {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  width: 52px;
  height: 4px;
  margin: 0 2px;
  border: none;
  background: #f6f6f6;
  filter: alpha(opacity=20);
  opacity: 0.2;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "opacity";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "opacity";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "opacity";
  transition-delay: 0s;
  transition-timing-function: linear; }

.homeTemplate .carousel-indicators li:hover, .homeTemplate .carousel-indicators li:focus, .homeTemplate .carousel-indicators li.active {
  filter: alpha(opacity=100);
  opacity: 1;
  margin: 0 2px; }

.homeTemplate .bx_caption {
  position: absolute;
  text-align: center;
  text-shadow: none;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  bottom: auto;
  padding: 0; }
  .homeTemplate .bx_caption .btn {
    font-family: 'Asap', sans-serif;
    margin-top: 20px;
    background: #fff;
    color: #101010;
    transition: all,0.25s; }
  .homeTemplate .bx_caption > a {
    display: inline-block; }
    .homeTemplate .bx_caption > a:hover, .homeTemplate .bx_caption > a:focus {
      text-decoration: none; }
      .homeTemplate .bx_caption > a:hover .btn, .homeTemplate .bx_caption > a:hover h2 span, .homeTemplate .bx_caption > a:focus .btn, .homeTemplate .bx_caption > a:focus h2 span {
        color: white;
        background: #e59124; }

.homeTemplate .bx_caption h2 {
  font-size: 60px;
  font-size: 6rem;
  font-family: 'Signika', sans-serif;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 13px 0;
  word-break: break-word; }
  .homeTemplate .bx_caption h2 span {
    font-weight: 600;
    font-style: normal;
    padding: 10px 40px;
    color: #000;
    background-color: #fff;
    transition: all,0.25s;
    display: inline-block; }

.bx_caption {
  left: 30px;
  right: 30px; }
  .bx_caption * {
    -webkit-backface-visibility: hidden; }

.homeTemplate .bx-viewport .item {
  position: relative; }
  .homeTemplate .bx-viewport .item:before {
    content: '';
    background: url(../img/layout/FiltreLarge.png) center center no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.3;
    height: 100%; }

.homeTemplate .bx_caption span.devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 13px;
  display: block;
  background: #fff; }

.homeTemplate .news .container {
  text-align: center;
  position: relative;
  max-width: 1752px;
  margin: 0 auto;
  padding: 60px 0px 70px 0px; }

.homeTemplate .news .container h2 {
  font-family: 'Signika', sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  color: #272727;
  margin: 0px;
  text-align: center;
  margin-bottom: -37px;
  position: relative;
  z-index: 10;
  font-weight: 700; }
  .homeTemplate .news .container h2 a {
    color: inherit;
    -moz-transition-duration: 0.25s;
    -moz-transition-property: "all";
    -moz-transition-delay: 0s;
    -moz-transition-timing-function: linear;
    -webkit-transition-duration: 0.25s;
    -webkit-transition-property: "all";
    -webkit-transition-delay: 0s;
    -webkit-transition-timing-function: linear;
    transition-duration: 0.25s;
    transition-property: "all";
    transition-delay: 0s;
    transition-timing-function: linear; }
    .homeTemplate .news .container h2 a:hover, .homeTemplate .news .container h2 a:focus {
      text-decoration: none;
      color: #e59124; }

.seeAllHolder {
  margin-bottom: 10px; }

.homeTemplate .news {
  background: #f6f6f6;
  max-width: 1920px;
  margin: 0 auto; }

.homeTemplate .news .container .seeAll {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  color: #e59124;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 1px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear;
  line-height: 36px;
  position: absolute;
  right: 90px;
  z-index: 10; }

.homeTemplate .news .container .seeAll:hover, .homeTemplate .news .container .seeAll:focus {
  color: #101010; }

.homeTemplate .news .container .seeAll span {
  font-size: 10px;
  font-size: 1rem;
  display: inline-block;
  margin: 0 0 0 5px; }

.homeTemplate .news .container article {
  vertical-align: top;
  text-align: center; }
  .homeTemplate .news .container article > div {
    margin: 0 auto; }

.homeTemplate .news .container article img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 0 18px 0;
  background: #f6f6f6;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "opacity";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "opacity";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "opacity";
  transition-delay: 0s;
  transition-timing-function: linear; }

.homeTemplate .news .container article a:hover img, .homeTemplate .news .container article a:focus img {
  filter: alpha(opacity=60);
  opacity: 0.6; }

.homeTemplate .news .container article time {
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0 0 18px 0;
  color: #000007;
  margin: 0 0 18px 0;
  display: block;
  letter-spacing: 1px; }

.homeTemplate .news .container article time span {
  text-transform: uppercase; }

.homeTemplate .news .container article h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0 0 13px 0;
  text-transform: uppercase; }

.homeTemplate .news .container article h3 a {
  color: #000007;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.homeTemplate .news .container article h3 a:hover, .homeTemplate .news .container article h3 a:focus {
  color: #e59124; }

.homeTemplate .news .container article.listing p {
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #000007;
  line-height: 20px; }

.homeTemplate .news .container article.listing p a {
  color: #e59124;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.homeTemplate .news .container article.listing p a:hover, .homeTemplate .news .container article.listing p a:focus {
  color: #101010;
  text-decoration: none; }

.homeTemplate .news .container article.listing p a.btn {
  background: #e59124;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #fff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear;
  margin-top: 18px; }
  .homeTemplate .news .container article.listing p a.btn:hover, .homeTemplate .news .container article.listing p a.btn:focus {
    background: #bf7617;
    color: white; }

.homeTemplate .news .container article.listing p a.btn span {
  font-size: 8px;
  font-size: 0.8rem;
  display: inline-block;
  margin: 0 0 0 10px; }

.homeTemplate .mosaique {
  background: #101010; }

.homeTemplate .mosaique div {
  padding: 0;
  position: relative;
  cursor: pointer; }

.homeTemplate .mosaique a.elmt {
  background: #101010;
  display: block;
  padding: 0;
  z-index: 50; }
  .homeTemplate .mosaique a.elmt .bgImg {
    transition: opacity 0.25s;
    position: relative; }
    .homeTemplate .mosaique a.elmt .bgImg:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
      -webkit-transition: 0.25s  all;
      transition: 0.25s  all; }
  .homeTemplate .mosaique a.elmt:hover .content:not(.location):not(.chief) h3, .homeTemplate .mosaique a.elmt:focus .content:not(.location):not(.chief) h3 {
    color: #fff; }
  .homeTemplate .mosaique a.elmt:hover .content:not(.location):not(.chief) p, .homeTemplate .mosaique a.elmt:focus .content:not(.location):not(.chief) p {
    color: #fff; }
  .homeTemplate .mosaique a.elmt:hover .content:not(.location):not(.chief) span.devider, .homeTemplate .mosaique a.elmt:focus .content:not(.location):not(.chief) span.devider {
    background-color: #fff; }
  .homeTemplate .mosaique a.elmt:hover .content:not(.location):not(.chief) span.social, .homeTemplate .mosaique a.elmt:focus .content:not(.location):not(.chief) span.social {
    color: #fff; }
  .homeTemplate .mosaique a.elmt .content {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    text-align: center;
    max-width: 100%;
    padding: 10px;
    -moz-transition-duration: 0.3s;
    -moz-transition-property: "background";
    -moz-transition-delay: 0s;
    -moz-transition-timing-function: linear;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-property: "background";
    -webkit-transition-delay: 0s;
    -webkit-transition-timing-function: linear;
    transition-duration: 0.3s;
    transition-property: "background";
    transition-delay: 0s;
    transition-timing-function: linear; }
    .homeTemplate .mosaique a.elmt .content:not(.location):not(.chief) {
      background-color: #fff; }
      .homeTemplate .mosaique a.elmt .content:not(.location):not(.chief) h3 {
        padding: 10px;
        -moz-transition-duration: 0.3s;
        -moz-transition-property: "all";
        -moz-transition-delay: 0s;
        -moz-transition-timing-function: linear;
        -webkit-transition-duration: 0.3s;
        -webkit-transition-property: "all";
        -webkit-transition-delay: 0s;
        -webkit-transition-timing-function: linear;
        transition-duration: 0.3s;
        transition-property: "all";
        transition-delay: 0s;
        transition-timing-function: linear;
        color: #000; }
      .homeTemplate .mosaique a.elmt .content:not(.location):not(.chief) > p {
        display: none;
        padding: 5px 10px;
        -moz-transition-duration: 0.3s;
        -moz-transition-property: "background-color,color";
        -moz-transition-delay: 0s;
        -moz-transition-timing-function: linear;
        -webkit-transition-duration: 0.3s;
        -webkit-transition-property: "background-color,color";
        -webkit-transition-delay: 0s;
        -webkit-transition-timing-function: linear;
        transition-duration: 0.3s;
        transition-property: "background-color,color";
        transition-delay: 0s;
        transition-timing-function: linear; }
        .homeTemplate .mosaique a.elmt .content:not(.location):not(.chief) > p span.social {
          padding: 5px;
          -moz-transition-duration: 0.3s;
          -moz-transition-property: "color";
          -moz-transition-delay: 0s;
          -moz-transition-timing-function: linear;
          -webkit-transition-duration: 0.3s;
          -webkit-transition-property: "color";
          -webkit-transition-delay: 0s;
          -webkit-transition-timing-function: linear;
          transition-duration: 0.3s;
          transition-property: "color";
          transition-delay: 0s;
          transition-timing-function: linear; }
    .homeTemplate .mosaique a.elmt .content h3 {
      font-family: 'Signika', sans-serif;
      font-weight: 600;
      font-size: 36px;
      font-size: 3.8rem;
      text-transform: uppercase;
      color: #fff;
      margin: 0;
      letter-spacing: 3px;
      word-wrap: break-word; }
  .homeTemplate .mosaique a.elmt.location *, .homeTemplate .mosaique a.elmt.chief * {
    color: #fff; }
  .homeTemplate .mosaique a.elmt.location span.devider, .homeTemplate .mosaique a.elmt.chief span.devider {
    background-color: #fff; }

.homeTemplate .mosaique div .content.location h3 {
  background: url(../img/layout/iconPinWhite.png) top center no-repeat;
  background-size: 18px 22px;
  padding: 44px 0 0 0;
  margin-top: -44px; }

.homeTemplate .mosaique div .content.chief h3 {
  background: url(../img/layout/iconChief.png) top center no-repeat;
  background-size: 29px 26px;
  padding: 46px 0 0 0;
  margin-top: -46px; }

.homeTemplate .mosaique div .content span.devider {
  width: 30px;
  height: 2px;
  margin: 13px auto;
  display: none;
  background: #000;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear; }

.homeTemplate .mosaique div .content p {
  margin: 0; }

.homeTemplate .mosaique div .content > p {
  color: #000;
  text-decoration: none;
  /*font-style:italic;*/
  font-weight: 700;
  font-family: 'Asap', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-size: 1.4rem; }

.homeTemplate .mosaique div .content > p span {
  font-size: 11px;
  font-size: 1.1rem;
  position: relative;
  top: 1px; }

.homeTemplate .mosaique div .content > p span.social {
  color: #000;
  font-size: 19px;
  font-size: 1.9rem;
  text-decoration: none;
  margin: 0 4px; }

.homeTemplate .mosaique div .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: #101010;
  -webkit-transition: background 0.25s;
  transition: background 0.25s; }

.homeTemplate .mosaique a:hover .chief + .bg, .homeTemplate .mosaique a:hover .location + .bg,
.homeTemplate .mosaique a:focus .chief + .bg,
.homeTemplate .mosaique a:focus .location + .bg {
  background: #000; }

/* ==========================================================================
    Menu
    ========================================================================== */
.menuTemplate {
  padding: 0; }

.menuTemplate .container {
  position: relative;
  background: #f6f6f6; }

.menuTemplate .stickyMenu {
  position: absolute;
  top: 405px;
  left: 0;
  z-index: 100;
  padding: 0; }

.menuTemplate .stickyMenu.floating.top {
  position: fixed;
  top: 45px; }

.menuTemplate .stickyMenu.floating.bottom {
  position: absolute;
  top: 100%;
  transform: translateY(-100%); }

.menuTemplate .stickyMenu {
  position: absolute;
  top: 405px;
  left: 0;
  z-index: 100;
  padding: 0; }

.menuTemplate nav {
  background: #f6f6f6;
  /*margin:0 0 0 75px;*/
  margin: 0;
  padding: 40px 20px 40px 0;
  float: left; }

.menuTemplate nav ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.menuTemplate nav ul li {
  padding: 0;
  margin: 0; }

.menuTemplate nav ul li a {
  color: #250b06;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 700;
  display: block;
  padding: 10px 0 10px 15px;
  position: relative;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background, color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background, color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background, color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.menuTemplate nav ul li a span.bullet {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #ccc7c6;
  display: inline-block;
  margin: 0 10px 0 0;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear; }

.menuTemplate nav > ul li a:hover span.bullet, .menuTemplate nav > ul li a:focus span.bullet, .menuTemplate nav > ul li.active a span.bullet {
  background: #fff; }

.menuTemplate nav ul li a span.mask {
  width: 9px;
  height: 36px;
  background: url(../img/layout/maskActiveMenu.png) right center no-repeat;
  background-size: 9px 36px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0; }

.menuTemplate nav ul li ul {
  padding: 0 0 0 16px;
  height: 0;
  overflow: hidden; }

.menuTemplate nav ul li ul li {
  margin: 12px 0;
  padding-left: 15px; }

.menuTemplate nav ul li ul li:nth-child(1) {
  margin: 10px 0 3px; }

.menuTemplate nav ul li ul li a {
  font-weight: 400;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear;
  display: inline;
  padding: 0 0 0 0px; }

.menuTemplate nav ul li ul li a:hover, .menuTemplate nav ul li.active ul li a:hover, .menuTemplate nav ul li ul li a:focus, .menuTemplate nav ul li.active ul li a:focus {
  color: #e59124;
  background: transparent; }

.menuTemplate nav ul li.active ul li a {
  background: transparent;
  color: #250b06; }

.menuTemplate nav .download {
  border-top: 1px solid #ccc7c6;
  width: 180px;
  margin: 15px 0 0 0; }

.menuTemplate nav .download a {
  display: block;
  color: #250b06;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 10px 30px;
  margin: 0px 0 0 0;
  transition: all 0.25s; }

.menuTemplate nav .download a:hover, .menuTemplate nav .download a:hover span,
.menuTemplate nav .download a:focus,
.menuTemplate nav .download a:focus span {
  color: #101010; }

.menuTemplate nav .download a span {
  transition: all 0.25s;
  font-weight: 400;
  color: #e59124;
  /*font-style:italic;*/
  text-transform: none; }

.menuTemplate .foodCateg {
  float: left;
  clear: both;
  width: 100%; }

.menuTemplate .foodCateg > div {
  padding: 0; }

.stickyMenuMobileToggle {
  display: none; }

.menuTemplate .noBG {
  background: #101010; }

.menuTemplate .foodCateg .categ {
  float: left;
  clear: both;
  width: 100%;
  padding: 60px 30px 45px 0; }

.menuTemplate .foodCateg .categ > div {
  padding: 0; }

.menuTemplate .foodCateg .categ > div.subMenu.right dl dt {
  margin: 0 0 20px 15%; }

.menuTemplate .foodCateg .categ:nth-child(1) {
  border: none; }

.menuTemplate .foodCateg .categ h3 {
  font-size: 20px;
  font-size: 2.0rem;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  color: #000007;
  margin: 0 0 15px; }

.menuTemplate .foodCateg .categ .devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 13px;
  display: block;
  background: #ccc7c6;
  margin: 0 auto; }

.menuTemplate .foodCateg .categ dl {
  width: 100%;
  margin: 50px 0 0 0; }

.menuTemplate .foodCateg .categ dt {
  width: 60%;
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  color: #101010;
  text-align: left;
  margin: 0 0 20px 0;
  line-height: 24px; }

.menuTemplate .foodCateg .categ dt span {
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  /*font-style:italic;*/
  width: 100%;
  font-weight: 400;
  display: block;
  line-height: 18px; }

.menuTemplate .foodCateg .categ dd {
  color: #101010;
  font-size: 17px;
  font-size: 1.7rem;
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  display: inline-block;
  text-align: right;
  vertical-align: top;
  width: 18%; }

.menuTemplate .desc.categ {
  border: none; }

.sectionDesc {
  margin-top: 20px; }

.menuTemplate .desc.categ.categ.categ span {
  margin-bottom: 16px; }

.menuTemplate .desc.desc.desc.categ p, .menuTemplate .sectionDesc.sectionDesc.sectionDesc p {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 23px;
  text-align: center;
  max-width: 560px;
  margin: 0 auto; }

.menuTemplate .categ.menu {
  width: 50%;
  margin-left: 25%;
  width: calc(100% - 400px);
  margin-left: 200px; }
  .menuTemplate .categ.menu .subMenu {
    width: 50%;
    float: left; }

a[href*="tel"] {
  color: inherit; }

/* ==========================================================================
    News
    ========================================================================== */
.newsTemplate {
  padding: 0; }

.newsTemplate .container {
  background: #f6f6f6; }

.newsTemplate .upcoming {
  width: 100%;
  background: #101010;
  padding: 41px 0 30px;
  text-align: center; }

.newsTemplate .upcoming h1 {
  color: #f6f6f6;
  font-size: 36px;
  font-size: 3.6rem;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 10px;
  letter-spacing: 2px;
  padding: 0px 30px; }

.newsTemplate .upcoming span.devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 13px;
  display: block;
  background: #fff; }

.newsTemplate .upcoming h2 {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  letter-spacing: 1.2px; }

.newsTemplate .upcoming h2 a {
  color: #f6f6f6;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsTemplate .upcoming h2 a:hover, .newsTemplate .upcoming h2 a:focus {
  color: #e59124; }

.newsTemplate .upcoming article {
  margin: 0px;
  padding: 0px 20px 47px;
  margin-top: 30px;
  float: none;
  max-width: 20%; }
  .newsTemplate .upcoming article.noCta {
    padding-bottom: 0; }

.newsTemplate .upcoming article time, .newsTemplate .upcoming article time * {
  color: #e59124;
  text-align: center;
  margin: 0 0 10px 0; }

.newsTemplate .upcoming article .ctaHolder {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0; }

.newsTemplate .upcoming article .ctaHolder a.btn {
  background: #e1dede;
  color: #101010; }

.newsTemplate .upcoming article .ctaHolder a.btn:hover, .newsTemplate .upcoming article .ctaHolder a.btn:focus {
  background: #e59124;
  color: white; }

.newsTemplate .main {
  margin: 0 auto;
  padding: 70px 0 45px;
  text-align: center;
  border-bottom: 1px solid #ccc7c6;
  float: none; }
  .newsTemplate .main.nothingToShow {
    padding-bottom: 0;
    border: none; }

.newsTemplate .main h1 {
  color: #101010;
  font-size: 36px;
  font-size: 3.6rem;
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 10px;
  letter-spacing: 2px;
  padding: 0px 30px; }

.newsTemplate .main p {
  padding: 0px 30px; }

.newsTemplate .main span.devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 13px;
  display: block;
  background: #c8c8c8; }

.newsTemplate .main h5 {
  color: #101010;
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0; }

.newsTemplate .main article {
  width: 100%;
  margin: 45px auto 0; }

.newsTemplate .main article img {
  width: 100%;
  height: auto;
  margin: 65px auto 0; }

.newsTemplate .main article time {
  margin: 25px 0 18px 0; }

.newsTemplate .main article h3 {
  color: #101010;
  font-size: 50px;
  font-size: 5rem;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0px 30px; }

.newsTemplate .main article p {
  width: 680px;
  margin: 0 auto 20px;
  height: auto;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20px;
  font-family: 'Asap', sans-serif; }

.newsTemplate .oldNews {
  text-align: center;
  padding: 10px 0 60px 0; }

.newsTemplate article {
  margin: 65px 0px 0;
  padding: 0px;
  display: inline-block;
  vertical-align: top; }

.newsTemplate article img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 0 18px 0;
  background: #f6f6f6;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "opacity";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "opacity";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "opacity";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsTemplate article a:hover img, .newsTemplate article a:focus img {
  opacity: 0.8; }

.newsTemplate article time {
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0 0 18px 0;
  margin: 0 0 18px 0;
  display: block;
  letter-spacing: 1px; }
  .newsTemplate article time, .newsTemplate article time * {
    color: #000007; }

.newsTemplate article time span {
  text-transform: uppercase; }

.newsTemplate article h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0 0 13px 0;
  text-transform: uppercase; }

.newsTemplate article h3 a {
  color: #000007;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsTemplate article h3 a:hover, .newsTemplate article h3 a:focus {
  color: #e59124; }

.newsTemplate .more {
  border-bottom: 1px solid #ccc7c6;
  margin: 0 auto 40px;
  margin-top: 20px;
  float: none;
  text-align: center;
  clear: both; }

.newsTemplate .more p {
  margin: 0 0 -9px 0; }

.newsTemplate .more a {
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  /*font-style:italic;*/
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #101010;
  background: #f6f6f6;
  padding: 10px 48px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsTemplate .more a:hover, .newsTemplate .more a:focus {
  color: #e59124; }

.newsTemplate .more a span {
  font-size: 11px;
  font-size: 1.1rem;
  display: inline-block;
  margin: 0 0 0 10px; }

/* ==========================================================================
    News Details
    ========================================================================== */
.newsDetailsTemplate {
  padding-bottom: 0px; }

.newsDetailsTemplate .container {
  background: #f6f6f6;
  position: relative; }

.newsDetailsTemplate .stickySocial {
  position: absolute;
  top: 440px;
  left: 200px;
  z-index: 100;
  padding: 0; }

.newsDetailsTemplate .stickySocial p {
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0;
  color: #101010;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  display: inline-block; }

.newsDetailsTemplate .stickySocial a {
  font-size: 17px;
  font-size: 1.7rem;
  margin: 0 0 0 10px;
  text-decoration: none;
  display: inline-block;
  color: #101010;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsDetailsTemplate .stickySocial a:hover, .newsDetailsTemplate .stickySocial a:focus {
  color: #e59124; }

.newsDetailsTemplate .stickyMore {
  position: absolute;
  top: 440px;
  right: 200px;
  z-index: 100;
  padding: 0;
  text-align: right; }

.newsDetailsTemplate .stickyMore > div {
  width: 320px; }

.newsDetailsTemplate .stickyMore .seeAll {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  color: #e59124;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 1px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsDetailsTemplate .stickyMore .seeAll:hover, .newsDetailsTemplate .stickyMore .seeAll:focus {
  color: #101010; }

.newsDetailsTemplate .stickyMore .seeAll span:last-child {
  font-size: 10px;
  font-size: 1rem;
  display: inline-block;
  margin: 0 0 0 5px; }

.newsDetailsTemplate .title {
  width: 100%;
  background: #101010;
  padding: 70px 0 60px;
  text-align: center; }

.newsDetailsTemplate .title h1 {
  color: #f6f6f6;
  font-size: 36px;
  font-size: 3.6rem;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 10px;
  letter-spacing: 2px;
  padding: 0 30px; }

.newsDetailsTemplate .title span.devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 13px;
  display: block;
  background: #fff; }

.newsDetailsTemplate .title time {
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #e1dede;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 20px 0 0 0; }

.newsDetailsTemplate .main {
  margin: 0 auto;
  padding: 80px 0 65px;
  text-align: center;
  border-bottom: 1px solid #ccc7c6;
  float: none;
  margin: 0 auto 30px;
  padding-bottom: 20px; }

.newsDetailsTemplate .main article {
  width: 100%;
  margin: 0 auto; }

.newsDetailsTemplate .main article img {
  max-width: 740px;
  height: auto;
  margin: 0 auto 35px;
  width: auto; }

.newsDetailsTemplate .main article p {
  max-width: 680px;
  margin: 0 auto 28px;
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #000007;
  line-height: 20px; }

.newsDetailsTemplate .main article p a {
  color: #e59124;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsDetailsTemplate .main article p a:hover, .newsDetailsTemplate .main article p a:focus {
  color: #101010;
  text-decoration: none; }

.newsDetailsTemplate .oldNews {
  text-align: center;
  padding: 10px 0 0px 0; }

.newsDetailsTemplate .oldNews > h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-size: 2rem;
  color: #101010;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px 0; }

.newsDetailsTemplate .oldNews .devider {
  width: 30px;
  height: 2px;
  margin: 0 auto -25px;
  display: block;
  background: #ccc7c6; }

.newsDetailsTemplate article {
  margin: 65px 0px 40px 0px;
  padding: 0px;
  display: inline-block;
  vertical-align: top; }

.newsDetailsTemplate article img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 0 18px 0;
  background: #f6f6f6;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "opacity";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "opacity";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "opacity";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsDetailsTemplate article time {
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0 0 18px 0;
  color: #000007;
  margin: 0 0 18px 0;
  display: block;
  letter-spacing: 1px; }
  .newsDetailsTemplate article time, .newsDetailsTemplate article time * {
    color: #000007; }

.newsDetailsTemplate article time span {
  text-transform: uppercase; }

.newsDetailsTemplate article h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0 0 13px 0;
  text-transform: uppercase; }

.newsDetailsTemplate article h3 a {
  color: #000007;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsDetailsTemplate article h3 a:hover, .newsDetailsTemplate article h3 a:focus {
  color: #e59124; }

.newsDetailsTemplate article.listing p {
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #000007;
  margin: 0 0 18px 0;
  line-height: 20px; }

.newsDetailsTemplate article.listing .ctaHolder a {
  color: #e59124;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsDetailsTemplate article.listing .ctaHolder a.btn {
  background: #e59124;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  /*font-style:italic; */
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #fff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear; }

.newsDetailsTemplate article.listing .ctaHolder a.btn span {
  font-size: 8px;
  font-size: 0.8rem;
  display: inline-block;
  margin: 0 0 0 10px; }

.newsDetailsTemplate article.listing .ctaHolder a.btn:hover, .newsDetailsTemplate article.listing .ctaHolder a.btn:focus {
  background: #bf7617; }

/* ==========================================================================
    Private Dinning
    ========================================================================== */
.dinningTemplate {
  padding: 0; }

.dinningTemplate .container {
  position: relative;
  background: #f6f6f6; }

.dinningTemplate .stickyMenu {
  position: absolute;
  top: 405px;
  left: 0;
  z-index: 100;
  padding: 0; }

.dinningTemplate nav {
  background: #f6f6f6;
  margin: 0 0 0 75px;
  padding: 40px;
  float: left; }

.dinningTemplate nav ul, .menuTemplate nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 171px; }
  .dinningTemplate nav ul div.text, .menuTemplate nav ul div.text {
    display: inline-block; }
  .dinningTemplate nav ul > li, .menuTemplate nav ul > li {
    position: relative;
    border-top: 1px solid #ccc7c6; }
    .dinningTemplate nav ul > li a, .menuTemplate nav ul > li a {
      color: #101010; }
    .dinningTemplate nav ul > li .mask, .menuTemplate nav ul > li .mask {
      display: none; }
    .dinningTemplate nav ul > li.active, .menuTemplate nav ul > li.active {
      border: none; }
    .dinningTemplate nav ul > li.active ul li, .menuTemplate nav ul > li.active ul li {
      border: none !important; }
    .dinningTemplate nav ul > li:last-child, .menuTemplate nav ul > li:last-child {
      border-bottom: 1px solid #ccc7c6; }
      .dinningTemplate nav ul > li:last-child ul li:last-child, .menuTemplate nav ul > li:last-child ul li:last-child {
        border: none; }
    .dinningTemplate nav ul > li ul li, .menuTemplate nav ul > li ul li {
      border: none; }
    .dinningTemplate nav ul > li ul li.active a, .menuTemplate nav ul > li ul li.active a {
      color: #101010;
      font-weight: bold;
      font-family: 'Signika', sans-serif; }
    .dinningTemplate nav ul > li:before, .menuTemplate nav ul > li:before {
      content: '';
      position: absolute;
      right: -9px;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 17px 0 17px 9px;
      border-color: transparent transparent transparent transparent;
      -moz-transition-duration: 0.3s;
      -moz-transition-property: "all";
      -moz-transition-delay: 0s;
      -moz-transition-timing-function: linear;
      -webkit-transition-duration: 0.3s;
      -webkit-transition-property: "all";
      -webkit-transition-delay: 0s;
      -webkit-transition-timing-function: linear;
      transition-duration: 0.3s;
      transition-property: "all";
      transition-delay: 0s;
      transition-timing-function: linear; }
  .dinningTemplate nav ul > li.active a, .menuTemplate nav ul > li.active a {
    background: #e59124;
    color: white; }
  .dinningTemplate nav ul > li.active:before, .menuTemplate nav ul > li.active:before {
    border-color: transparent transparent transparent #e59124; }
  .dinningTemplate nav ul > li:not(.active) a:hover,
  .dinningTemplate nav ul > li:not(.active) a:focus, .menuTemplate nav ul > li:not(.active) a:hover,
  .menuTemplate nav ul > li:not(.active) a:focus {
    background: #101010;
    color: white; }

.dinningTemplate nav .download, .menuTemplate nav .download {
  border: none; }

.dinningTemplate nav .download a, .menuTemplate nav .download a {
  color: #101010;
  font-weight: 400; }

.dinningTemplate nav ul li {
  padding: 0;
  margin: 0; }

.dinningTemplate nav ul li a {
  color: #250b06;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 700;
  display: block;
  padding: 10px 0 9px 15px;
  position: relative;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background, color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background, color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background, color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.dinningTemplate nav > ul li a:hover, .dinningTemplate nav > ul li a:focus, .dinningTemplate nav > ul > li.active > a {
  background: #e59124;
  color: #fff; }

.dinningTemplate nav ul li a span.bullet {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #ccc7c6;
  display: inline-block;
  margin: 0 10px 0 0;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear; }

.dinningTemplate nav > ul li a:hover span.bullet, .dinningTemplate nav > ul li a:focus span.bullet, .dinningTemplate nav > ul li.active a span.bullet {
  background: #fff; }

.dinningTemplate .categ {
  float: left;
  width: 100%; }

.dinningTemplate .categ > div {
  padding: 60px 0;
  float: left;
  width: 100%; }

@media all and (max-width: 1600px) {
  .dinningTemplate .categ.categ.categ.categ.categ h2 {
    max-width: 310px;
    margin: 0 auto 15px; } }

.dinningTemplate .categ h2 {
  font-size: 20px;
  font-size: 2rem;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  color: #000007;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px; }

.dinningTemplate .categ .devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 13px;
  display: block;
  background: #ccc7c6;
  margin: 0 auto 32px; }

.dinningTemplate .categ h3 {
  color: #000007;
  font-family: 'Asap', sans-serif;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 25px 0;
  line-height: 30px; }

.dinningTemplate .categ .txt {
  max-width: 715px;
  margin: 0 auto; }

.dinningTemplate .categ .txt p {
  font-family: 'Signika', sans-serif; }

.dinningTemplate .categ p {
  font-family: 'Asap', sans-serif;
  text-align: center;
  line-height: 24px;
  max-width: 715px;
  margin: 0 auto 25px; }

.dinningTemplate .categ p a {
  color: #e59124;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.dinningTemplate .categ p a:hover, .dinningTemplate .categ p a:focus {
  color: #101010;
  text-decoration: none; }

.dinningTemplate .categ .room {
  width: 100%;
  float: left; }
  .dinningTemplate .categ .room .intro {
    height: auto;
    background-color: transparent; }

.dinningTemplate .categ .room:nth-child(odd) {
  background: #e1dede; }

.dinningTemplate.evenRoom .categ.menu {
  background: #e1dede; }

.dinningTemplate .categ.menu {
  padding-bottom: 80px; }

.dinningTemplate .categ .room .col-md-6 {
  padding: 0; }

.dinningTemplate .categ .room .slider, .dinningTemplate .categ .room .noSlider {
  margin: 0 auto;
  max-width: 730px; }
  .dinningTemplate .categ .room .slider img, .dinningTemplate .categ .room .noSlider img {
    max-width: 100%; }

[id*="carousel_"] {
  position: relative;
  max-width: 730px;
  margin: 0 auto;
  margin-top: 25px; }
  #bodyId_dinning [id*="carousel_"] {
    min-width: 0; }
  .dinningTemplate [id*="carousel_"] {
    margin-top: 0; }
  [id*="carousel_"] .item.item.item img {
    max-width: 100%; }
  .home [id*="carousel_"] {
    max-width: 100%;
    margin: 0 auto; }

.closeslider, .nextslide, .prevslide {
  position: absolute;
  left: -2000px; }
  .closeslider:focus, .nextslide:focus, .prevslide:focus {
    display: block;
    bottom: 0;
    left: 0;
    background: rgba(229, 145, 36, 0.55);
    color: #fff;
    font-size: 12px;
    font-size: 1.2rem;
    z-index: 1000;
    padding: 10px 20px; }

.bx-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 55; }
  .bx-controls .bx-prev {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    text-indent: -9999px;
    width: 25px;
    height: 50px;
    background: url(../img/layout/arrow_slider_prev.png) 0 0 no-repeat;
    transition: opacity 0.25s; }
    @media screen and (max-width: 640px) {
      .bx-controls .bx-prev {
        left: 10px; } }
    .bx-controls .bx-prev:hover {
      opacity: 0.3; }
  .bx-controls .bx-next {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    text-indent: -9999px;
    width: 25px;
    height: 50px;
    background: url(../img/layout/arrow_slider.png) 0 0 no-repeat;
    transition: opacity 0.25s; }
    @media screen and (max-width: 640px) {
      .bx-controls .bx-next {
        right: 10px; } }
    .bx-controls .bx-next:hover {
      opacity: 0.3; }

ul.bx_pager.bx_pager.bx_pager.bx_pager {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  margin: 0 auto;
  text-align: center !important;
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 55;
  display: none; }
  ul.bx_pager.bx_pager.bx_pager.bx_pager li {
    display: inline-block;
    list-style: none; }
    ul.bx_pager.bx_pager.bx_pager.bx_pager li:before {
      display: none; }
    ul.bx_pager.bx_pager.bx_pager.bx_pager li a {
      cursor: pointer;
      padding: 10px 0;
      font-size: 0;
      position: relative;
      width: 52px;
      display: block;
      margin: 0 3px; }
      ul.bx_pager.bx_pager.bx_pager.bx_pager li a span {
        -webkit-transition: background 0.25s;
        transition: background 0.25s; }
      ul.bx_pager.bx_pager.bx_pager.bx_pager li a .outer {
        height: 4px;
        width: 100%;
        display: block;
        background: rgba(255, 255, 255, 0.2);
        overflow: hidden;
        position: relative;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.5); }
        ul.bx_pager.bx_pager.bx_pager.bx_pager li a .outer .inner {
          left: 0;
          height: 10px;
          width: 0%;
          position: absolute;
          display: block;
          background: white; }
      ul.bx_pager.bx_pager.bx_pager.bx_pager li a:hover .outer,
      ul.bx_pager.bx_pager.bx_pager.bx_pager li a:focus .outer {
        background: rgba(255, 255, 255, 0.5); }
      ul.bx_pager.bx_pager.bx_pager.bx_pager li a.active .inner {
        width: 100%; }

.dinningTemplate .categ .menu {
  padding: 60px 0 0 0; }

.dinningTemplate .categ .menu.onlyOneMenu {
  margin-top: 80px; }

.dinningTemplate .categ .menu > .col-md-8 {
  border-bottom: 1px solid #ccc7c6;
  padding: 0 10px 30px 10px;
  position: relative; }

.dinningTemplate .categ .menu.special > .col-md-8 {
  border: 2px solid #ccc7c6;
  padding: 60px 10px 30px 10px;
  margin: -61px 0 0 0; }

.dinningTemplate .categ .menu > .col-md-8 ul {
  padding: 0;
  list-style: none; }

.dinningTemplate .categ .menu .price {
  position: absolute;
  top: -42px;
  right: 2%;
  color: #e59124;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold; }

.dinningTemplate .categ .menu.special .price {
  top: 2%;
  right: 2%; }

.dinningTemplate .categ .menu li {
  width: auto;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: left;
  margin: 0 0 15px 0; }

.dinningTemplate .categ .menu li span {
  width: 100%;
  font-family: 'Asap', sans-serif;
  font-size: 13px;
  font-size: 1.3rem;
  /*font-style:italic;*/
  width: 100%;
  font-weight: 400;
  display: block;
  line-height: 18px; }

.dinningTemplate .categ .menu .note {
  font-family: 'Asap', sans-serif;
  font-size: 13px;
  font-size: 1.3rem;
  width: 100%;
  font-weight: 400;
  display: block;
  line-height: 18px;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 50px 0 20px 0;
  float: left; }

.dinningTemplate .categ .inquire {
  background: #101010;
  margin: 0px 0 0 0; }

.dinningTemplate .categ .inquire > div {
  padding: 0; }

.dinningTemplate .categ .inquire.inquire.inquire h2 {
  color: #f6f6f6 !important; }

.dinningTemplate .categ .inquire .content p {
  color: #f6f6f6;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 20px;
  max-width: 575px;
  margin: 0 auto 30px; }

.dinningTemplate .categ .inquire p {
  text-align: center;
  margin: 0 auto; }

.dinningTemplate .categ .inquire p a.btn {
  background: #f6f6f6;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  /*font-style:italic;*/
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #101010;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "all";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "all";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "all";
  transition-delay: 0s;
  transition-timing-function: linear; }

.dinningTemplate .categ .inquire p a.btn:hover,
.dinningTemplate .categ .inquire p a.btn:focus {
  background: #e59124;
  color: white; }

.bannerSep {
  height: 15px;
  background: #e1dede; }

/* ==========================================================================
    Private Dinning Page
    ========================================================================== */
@media all and (max-width: 640px) {
  .dinningPage .banner .txt .content_drop_down {
    width: 300px !important; }
    .dinningPage .banner .txt .content_drop_down .dropDown_b_b {
      width: auto !important;
      float: none; }
      .dinningPage .banner .txt .content_drop_down .dropDown_b_b .dropdown .dropdown-toggle {
        font-size: 10px !important;
        font-size: 1rem !important;
        padding-left: 17px !important;
        background-size: 7px 9px !important; }
      .dinningPage .banner .txt .content_drop_down .dropDown_b_b .dropdown .dropdown-menu, .dinningPage .banner .txt .content_drop_down .dropDown_b_b .dropdown a {
        font-size: 11px;
        font-size: 1.1rem;
        min-width: auto; }
    .dinningPage .banner .txt .content_drop_down .dropDown_b_b.concepts {
      margin-left: 0 !important;
      margin-top: 20px; }
      .dinningPage .banner .txt .content_drop_down .dropDown_b_b.concepts .dropdown .dropdown-toggle {
        background-size: 6px 11px !important; } }

.dinningPage .banner .txt .content_drop_down {
  display: inline-block;
  position: relative;
  margin-top: 40px; }
  .dinningPage .banner .txt .content_drop_down .dropDown_b_b.concepts {
    padding-left: 30px;
    margin-left: 20px;
    background: #1f1e1e url(../img/layout/fork_icon.png) 14px center no-repeat;
    background-size: 7px 16px; }

.dinningPage .listingRestaurants {
  width: 100%; }
  .dinningPage .listingRestaurants .wrapper_location {
    float: left;
    width: 100%;
    padding: 60px 0 30px 0;
    border-bottom: 1px solid #ccc7c6; }
    .dinningPage .listingRestaurants .wrapper_location .location_title .title {
      height: auto;
      font-size: 36px;
      font-size: 3.6rem;
      font-family: 'Signika', sans-serif;
      text-transform: uppercase;
      text-align: center; }
    .dinningPage .listingRestaurants .wrapper_location .location_title .border_bottom {
      width: 30px;
      margin: 5px auto 0 auto;
      height: 2px;
      background-color: #2c2b2b; }
    .dinningPage .listingRestaurants .wrapper_location .location_title .nbr {
      width: 100%;
      text-align: center;
      font-size: 13px;
      font-size: 1.3rem;
      font-family: 'Asap', sans-serif;
      font-weight: 600;
      margin-top: 16px; }
    @media all and (max-width: 1190px) {
      .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list {
        margin: 0 auto; } }
    @media all and (max-width: 1780px) {
      .dinningPage .listingRestaurants .wrapper_location .listingHolder.alone {
        width: auto; } }
    .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list {
      width: auto !important;
      position: relative;
      text-align: center;
      display: block;
      font-size: 0; }
      @media all and (max-width: 640px) {
        .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article.the_article {
          width: 100%;
          position: relative; }
          .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article.the_article.onlyOne {
            margin: 0 auto; }
          .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article.the_article .wrapper_resto .wrap_nav {
            height: auto; }
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article.the_article .wrapper_resto .wrap_nav a {
              height: 40px;
              line-height: 20px;
              width: 100%;
              color: rgba(246, 246, 246, 0.9); }
              .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article.the_article .wrapper_resto .wrap_nav a .onglet {
                line-height: 20px;
                border-right: none; } }
      .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list.onlyOne article.the_article {
        margin: 0 auto; }
      .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article {
        background-color: #f6f6f6;
        padding: 0px;
        vertical-align: top;
        width: 33.33333%;
        margin: 0;
        padding: 25px; }
        .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto {
          height: 560px !important;
          width: 100%;
          position: relative; }
          .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .head_image {
            height: 258px;
            position: relative; }
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .head_image .image_logo {
              position: relative;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
              top: 50%; }
          .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav {
            background-color: #f6f6f6;
            float: left;
            min-height: 45px;
            width: 100%; }
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a {
              float: left;
              width: 100%;
              transition: all 0.3s; }
              .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a.column-2 {
                width: 50%; }
              .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a.column-3 {
                width: 33.3333%; }
              .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a .onglet {
                text-align: center;
                font-family: 'Asap', sans-serif;
                color: #fff;
                font-weight: 500;
                line-height: 25px;
                border-right: 1px solid rgba(246, 246, 246, 0.8);
                padding: 10px 6px;
                letter-spacing: 1px;
                text-decoration: none;
                font-size: 14px;
                font-size: 1.4rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
              @media all and (max-width: 768px) {
                .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a.column-2, .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a.column-3 {
                  width: 100%;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
                  .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a.column-2:last-child, .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a.column-3:last-child {
                    border-bottom: 0; } }
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a:last-child .onglet {
              border-right: none; }
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a:hover,
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .wrap_nav a:focus {
              text-decoration: none;
              background: #000; }
          .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .content_info {
            width: 100%;
            float: left;
            background-color: #fff;
            padding-bottom: 30px;
            text-align: center; }
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .content_info h2 {
              color: #2c2b2b;
              font-size: 18px;
              font-size: 1.8rem;
              float: left;
              font-family: 'Signika', sans-serif;
              font-weight: 600;
              text-transform: uppercase;
              text-align: center;
              clear: both;
              width: 100%;
              margin: 30px 0 20px 0; }
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .content_info address, .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .content_info h3 {
              color: #2c2b2b;
              font-size: 14px;
              font-size: 1.4rem;
              float: left;
              font-family: 'Asap', sans-serif;
              font-weight: 600;
              text-transform: uppercase;
              text-align: center;
              clear: both;
              width: 100%;
              margin: 2px 0; }
            .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .content_info p {
              color: #000007;
              font-size: 13px;
              font-size: 1.3rem;
              float: left;
              font-family: 'Asap', sans-serif;
              font-weight: 400;
              text-align: center;
              clear: both;
              width: 80%;
              margin-left: 10%; }
              .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article .wrapper_resto .content_info p img {
                margin-left: 6px; }
        @media (max-width: 960px) and (min-width: 641px) {
          .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article {
            width: 50%; } }
        @media (max-width: 640px) {
          .dinningPage .listingRestaurants .wrapper_location .the_restaurant_list article {
            width: 100%;
            padding: 10px 5px; } }
  .dinningPage .listingRestaurants .wrapper_location:last-child {
    border: none; }

.privateDinningFooter {
  background-color: #f6f6f6 !important; }
  .privateDinningFooter .locationFooter .content_restaurants_list {
    float: left;
    position: relative;
    column-count: 3; }
    .privateDinningFooter .locationFooter .content_restaurants_list .content_city {
      float: left;
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      page-break-inside: avoid;
      break-inside: avoid; }
      .privateDinningFooter .locationFooter .content_restaurants_list .content_city .city_title {
        color: #2c2b2b;
        font-size: 13px;
        font-size: 1.3rem;
        font-family: 'Asap', sans-serif;
        text-transform: uppercase;
        font-weight: 600; }
      .privateDinningFooter .locationFooter .content_restaurants_list .content_city .restaurant_title {
        color: #f6f6f6;
        float: left;
        clear: both;
        font-family: 'Asap', sans-serif;
        letter-spacing: 1px;
        line-height: 20px; }
      .privateDinningFooter .locationFooter .content_restaurants_list .content_city .restaurant_title:hover,
      .privateDinningFooter .locationFooter .content_restaurants_list .content_city .restaurant_title:focus {
        text-decoration: underline; }

.footerMenu {
  background-color: #e1e1e1; }
  .footerMenu .menu-footer-container #menu-footer {
    list-style-type: none;
    padding: 0;
    float: left;
    margin: 35px 0 50px 0;
    font-size: 0; }
    .footerMenu .menu-footer-container #menu-footer li, .footerMenu .menu-footer-container #menu-footer a {
      text-decoration: none;
      color: #2c2b2b;
      font-size: 14px;
      font-size: 1.4rem;
      font-family: 'Asap', sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      width: auto;
      display: inline-block;
      padding-right: 5px; }
    .footerMenu .menu-footer-container #menu-footer li {
      margin-bottom: 20px;
      width: 50%;
      vertical-align: top; }
    .footerMenu .menu-footer-container #menu-footer a {
      transition: color 0.3s ease; }

@media all and (max-width: 640px) {
  .privateDinningFooter {
    max-height: 100%; }
    .privateDinningFooter .col-xs-3 {
      width: 100% !important; }
    .privateDinningFooter .content_restaurants_list {
      padding: 20px 0; }
  .footerMenu .menu-footer-container #menu-footer {
    margin: 35px 0; }
    .footerMenu .menu-footer-container #menu-footer li:last-child {
      margin-bottom: 0; } }

@media all and (max-width: 1220px) {
  .privateDinningFooter {
    max-height: 100%; }
  .footerMenu .menu-footer-container #menu-footer li {
    margin-bottom: 5px;
    width: 100%; } }

#bodyId_privateDinningPage .copy {
  background-color: #2c2b2b;
  float: left;
  color: #f6f6f6;
  font-family: 'Asap', sans-serif;
  letter-spacing: 1px;
  width: 100%;
  font-size: 11px;
  font-size: 1.1rem;
  padding: 0 70px; }
  #bodyId_privateDinningPage .copy .pull-left, #bodyId_privateDinningPage .copy .pull-right {
    margin: 20px 0; }

/* ==========================================================================
    Restaurant
    ========================================================================== */
.defaultTemplate {
  padding: 0; }

.defaultTemplate .container {
  position: relative;
  background: #f6f6f6; }

.defaultTemplate .main {
  margin: 0 auto;
  padding: 55px 0 20px;
  text-align: center;
  float: none;
  margin: 0 auto; }

.defaultTemplate .main article {
  width: 100%;
  margin: 0 auto; }

.defaultTemplate .main article blockquote {
  border: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700; }

.defaultTemplate .main article blockquote p {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px !important;
  font-size: 2.2rem !important;
  color: #101010;
  line-height: 30px;
  font-weight: 700;
  max-width: 730px; }

.defaultTemplate .main article h3 {
  font-family: 'Signika', sans-serif;
  font-size: 22px;
  font-size: 2.2rem;
  color: #101010;
  line-height: 30px;
  font-weight: 700;
  max-width: 730px;
  margin: 15px auto 25px;
  text-transform: uppercase; }
  .bundle3 .defaultTemplate .main article h3 {
    font-family: 'Asap', sans-serif; }

.defaultTemplate .main article img {
  max-width: 730px;
  height: auto;
  margin: 0 auto 25px; }

.defaultTemplate .main .bx-wrapper {
  margin-bottom: 25px; }

.defaultTemplate .main .slider img {
  margin: 0 auto; }

.defaultTemplate .main article p, .newsDetailsTemplate .main article p {
  max-width: 730px;
  margin: 0 auto 28px;
  font-family: 'Asap', sans-serif;
  color: #000007;
  line-height: 26px; }

.defaultTemplate .main article p a {
  color: #e59124;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear;
  text-decoration: underline; }

.defaultTemplate .main article p a:hover, .defaultTemplate .main article p a:focus {
  color: #101010;
  text-decoration: none; }

.defaultTemplate .team {
  text-align: center;
  padding: 45px 0 40px 0;
  background: #ebeaea; }

.defaultTemplate .organizations {
  text-align: center;
  padding: 45px 0 40px 0; }

.defaultTemplate h2, .dinningTemplate .txt h2 {
  font-family: 'Asap', sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-size: 2.0rem;
  color: #101010;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px 0;
  padding-bottom: 20px;
  position: relative; }
  .bundle3 .defaultTemplate h2, .bundle3 .dinningTemplate .txt h2 {
    font-family: 'Signika', sans-serif; }
  .defaultTemplate h2:before, .dinningTemplate .txt h2:before {
    width: 30px;
    height: 2px;
    display: block;
    background: #ccc7c6;
    position: absolute;
    content: '';
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }

.defaultTemplate .team .devider {
  margin: 0 auto -25px; }

.defaultTemplate article {
  width: 400px;
  margin: 65px 90px 0;
  padding: 0px;
  display: inline-block;
  float: none;
  vertical-align: top; }

.listing article[class*="col"], .listing h2 {
  text-align: center; }

[class*="listing"], .listing_Holder {
  display: inline-block;
  text-align: left;
  width: 1748px; }
  [class*="listing"].center, .listing_Holder.center {
    text-align: center; }
  [class*="listing"] article, .listing_Holder article {
    padding: 0;
    margin: 65px 90px 0;
    width: 400px;
    float: none;
    display: inline-block;
    text-align: center;
    padding-bottom: 30px;
    position: relative; }
    [class*="bundle"] [class*="listing"] article, [class*="bundle"] .listing_Holder article {
      padding-bottom: 50px; }

.listing_Holder article {
  padding-bottom: 0; }

#secondary .listing_Holder {
  border-top: 1px solid #ccc7c6;
  margin-top: 60px; }
  #secondary .listing_Holder a {
    color: #101010;
    text-decoration: none;
    display: inline-block;
    transition: all 0.25s; }
    #secondary .listing_Holder a:hover, #secondary .listing_Holder a:focus {
      text-decoration: none;
      opacity: 0.6; }

[class*="listing"] article p, .menuTemplate .desc.categ p, .menuTemplate .sectionDesc p {
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #000007;
  margin: 0 0 0px 0;
  line-height: 20px;
  text-align: center !important; }

[class*="listing"] article p a {
  color: #e59124;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

[class*="listing"] article p a:hover, [class*="listing"] article p a:focus {
  color: #101010;
  text-decoration: none; }

[class*="listing"] article .ctaHolder {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  max-height: 50px; }

[class*="listing"] article .ctaHolder a.btn {
  background: #e59124;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #fff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear; }
  [class*="listing"] article .ctaHolder a.btn:hover, [class*="listing"] article .ctaHolder a.btn:focus {
    background: #bf7617; }

.defaultTemplate article img {
  height: auto;
  display: block;
  margin: 0 auto 18px;
  background: #f6f6f6;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "opacity";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "opacity";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "opacity";
  transition-delay: 0s;
  transition-timing-function: linear; }

.defaultTemplate .team article a:hover img, .defaultTemplate .team article a:focus img {
  filter: alpha(opacity=60);
  opacity: 0.6; }

.defaultTemplate article h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0 0 13px 0;
  text-transform: uppercase; }

.defaultTemplate article h3 a {
  color: #000007;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.defaultTemplate article h3 a:hover, .defaultTemplate article h3 a:focus {
  color: #e59124; }

.defaultTemplate article a:hover h3, .defaultTemplate article a:focus h3 {
  text-decoration: underline; }

.defaultTemplate article p.txt {
  font-family: 'Asap', sans-serif;
  font-size: 13px;
  font-size: 1.3rem;
  color: #000007;
  margin: 0 0 18px 0;
  line-height: 20px;
  height: auto;
  font-weight: 400;
  text-transform: none; }

.defaultTemplate article p.txt a, .defaultTemplate article .content a {
  color: #e59124;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.defaultTemplate article p.txt a:hover, .defaultTemplate article .content a:hover, .defaultTemplate article p.txt a:focus, .defaultTemplate article .content a:focus {
  color: #101010; }

.defaultTemplate article .ctaHolder a.btn {
  background: #e59124;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  /*font-style:italic;*/
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #fff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear; }

.defaultTemplate article .ctaHolder a.btn:hover, .defaultTemplate article .ctaHolder a.btn:focus {
  background: #bf7617; }

.defaultTemplate article .content h2 {
  display: none; }

.defaultTemplate .join {
  background: #101010;
  text-align: center;
  padding: 50px 0;
  width: 100%; }

.defaultTemplate .join h2 {
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-size: 2rem;
  color: #e1dede;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px 0; }

.defaultTemplate .join h3 {
  font-family: 'Asap', sans-serif;
  font-size: 22px;
  font-size: 2.2rem;
  color: #e1dede;
  line-height: 30px;
  font-weight: 700;
  max-width: 730px;
  margin: 15px auto 25px;
  text-transform: uppercase; }

.defaultTemplate .join p {
  max-width: 720px;
  padding: 0 20px;
  font-family: 'Asap', sans-serif;
  font-size: 17px;
  font-size: 1.7rem;
  color: #e1dede;
  margin: 0 auto 23px;
  line-height: 20px; }

.defaultTemplate .join p a:not(.btn) {
  color: #fff !important;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.defaultTemplate .join p a:hover, .defaultTemplate .join p a:focus {
  color: #fff;
  text-decoration: none; }

.defaultTemplate .join p a.btn {
  background: #e1dede;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  /*font-style:italic;*/
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #101010;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "all";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "all";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "all";
  transition-delay: 0s;
  transition-timing-function: linear; }
  .bundle4 .defaultTemplate .join p a.btn {
    font-weight: 500; }

.defaultTemplate .join p a.btn span {
  margin: 0 0 0 5px; }

.defaultTemplate .join p a.btn:hover, .defaultTemplate .join p a.btn:focus {
  background: #e59124;
  color: white; }

/* ==========================================================================
    Location
    ========================================================================== */
.locationTemplate {
  padding: 0; }

.locationTemplate .container {
  position: relative;
  background: #f6f6f6; }

.locationTemplate .info > div {
  padding: 0;
  margin: 0 auto;
  float: none; }

.locationTemplate .info > div > div {
  padding: 50px 0 70px 30px; }

.locationTemplate .info h2 {
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  font-size: 2.2rem;
  color: #101010;
  letter-spacing: 1px;
  margin: 0 0 40px 0; }

.locationTemplate .info address {
  font-family: 'Asap', sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 26px;
  margin: 0 0 35px 0;
  width: 350px; }

.locationTemplate .info a.btn {
  background: #e59124;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #ffffff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear; }
  .locationTemplate .info a.btn:hover, .locationTemplate .info a.btn:focus {
    background: #bf7617; }
  .bundle4 .locationTemplate .info a.btn {
    font-weight: 500; }

.locationTemplate .info h5 {
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  color: #101010 !important;
  text-transform: uppercase;
  margin: 0 0 20px 0; }

.locationTemplate .info table {
  width: 100%;
  margin: 0 0 35px 0; }

.locationTemplate .info table th,
.locationTemplate .info table td {
  float: left;
  font-family: 'Asap', sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  color: #101010;
  line-height: 1.2em;
  font-weight: 400;
  width: 50%;
  margin-bottom: 3px;
  font-weight: 400; }

.locationTemplate .info table td:nth-child(2) {
  float: right;
  font-family: 'Asap', sans-serif;
  color: #e59124; }

.locationTemplate #google-map {
  width: 100%;
  height: 565px; }

.locationTemplate #google-map .gmnoprint,
.locationTemplate #google-map .gm-style-cc {
  top: 0 !important;
  bottom: inherit !important; }

.locationTemplate .locationHolder {
  width: 66.66%; }

/* ==========================================================================
    Social
    ========================================================================== */
.socialTemplate {
  padding: 0; }

.socialTemplate .container {
  position: relative;
  background: #101010;
  border-bottom: 0px solid #101010; }

.socialTemplate .intro .txt p a.social {
  font-size: 20px;
  font-size: 2rem;
  margin: 10px 3px 0 3px;
  display: inline-block; }

.socialTemplate .intro .txt p a {
  color: #f6f6f6;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.socialTemplate .intro .txt p a:hover, .socialTemplate .intro .txt p a:focus {
  color: #e59124;
  text-decoration: none; }

.socialTemplate .mosaique > div {
  padding: 0; }

.socialTemplate .mosaique > div.instagram {
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  background: transparent; }
  .socialTemplate .mosaique > div.instagram .loadingBar {
    position: absolute;
    left: 0;
    top: 0;
    height: 0%;
    background: rgba(255, 255, 255, 0.05);
    width: 100%; }
  .socialTemplate .mosaique > div.instagram .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1; }
  .socialTemplate .mosaique > div.instagram:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    z-index: 10;
    background: -webkit-linear-gradient(top, transparent 50%, black 100%);
    background: linear-gradient(to bottom, transparent 50%, black 100%); }
  .socialTemplate .mosaique > div.instagram:hover:before, .socialTemplate .mosaique > div.instagram:focus:before {
    opacity: 1; }

.socialTemplate .mosaique > div.instagram img {
  width: 100%;
  height: 100%;
  display: block; }

.socialTemplate .mosaique > div.instagram .info {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 15;
  top: 100%;
  left: 0; }
  .socialTemplate .mosaique > div.instagram .info .user {
    position: absolute;
    line-height: 4px;
    bottom: 40px;
    width: 100%;
    padding-left: 112px;
    padding-right: 82px; }

.socialTemplate .mosaique > div.instagram:hover .info, .socialTemplate .mosaique > div.instagram:focus .info {
  top: 0%; }

.socialTemplate .mosaique > div.instagram .info .profilePic {
  width: 66px;
  height: 66px;
  display: block;
  position: absolute;
  z-index: 10;
  bottom: 27px;
  left: 27px;
  -webkit-border-radius: 33px;
  -moz-border-radius: 33px;
  border-radius: 33px;
  background-size: cover; }

.socialTemplate .mosaique > div.instagram .info h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  font-size: 1.5rem;
  color: #e1dede;
  margin: 0; }

.socialTemplate .mosaique > div.instagram .info h4 {
  font-family: 'Asap', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 1.2rem;
  color: #e59124;
  margin: 0; }
  .socialTemplate .mosaique > div.instagram .info h4 a {
    color: inherit; }

.socialTemplate .mosaique > div.instagram .info span {
  color: #e1dede;
  font-size: 19px;
  font-size: 1.9rem;
  position: absolute;
  bottom: 43px;
  right: 40px; }

.socialTemplate .mosaique > div.twitter .profilePic {
  background-size: cover;
  background-position: center center;
  width: 66px;
  height: 66px;
  display: block;
  margin: 0 auto 18px;
  -webkit-border-radius: 33px;
  -moz-border-radius: 33px;
  border-radius: 33px;
  background-color: #101010;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "opacity";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "opacity";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "opacity";
  transition-delay: 0s;
  transition-timing-function: linear; }
  .socialTemplate .mosaique > div.twitter .profilePic:hover, .socialTemplate .mosaique > div.twitter .profilePic:focus {
    opacity: 0.7; }

.socialTemplate .mosaique > div.twitter img:hover, .socialTemplate .mosaique > div.twitter img:focus {
  filter: alpha(opacity=60);
  opacity: 0.6; }

.socialTemplate .mosaique > div.twitter h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  font-size: 1.5;
  color: #e1dede;
  text-align: center;
  width: 250px;
  margin: 0 auto 5px; }

.socialTemplate .mosaique > div.twitter h4 {
  font-family: 'Asap', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 1.2rem;
  text-align: center;
  width: 250px;
  margin: 0 auto 15px; }

.socialTemplate .mosaique > div.twitter h4 a {
  color: #e59124;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.socialTemplate .mosaique > div.twitter h4 a:hover, .socialTemplate .mosaique > div.twitter h4 a:focus {
  color: #e1dede; }

.socialTemplate .mosaique > div.twitter span.devider {
  width: 30px;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #404040; }

.socialTemplate .mosaique > div.twitter p {
  font-family: 'Asap', sans-serif;
  font-weight: 400;
  color: #e1dede;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 26px;
  width: 250px;
  margin: 15px auto 0;
  text-align: center; }

.socialTemplate .mosaique > div.twitter p a {
  color: #e59124;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.socialTemplate .mosaique > div.twitter p a:hover, .socialTemplate .mosaique > div.twitter p a:focus {
  color: #e1dede;
  text-decoration: none; }

.socialTemplate .mosaique > div.twitter span.icon-twitter {
  color: #e1dede;
  font-size: 19px;
  font-size: 1.9rem;
  margin: 20px auto 0;
  display: block;
  text-align: center; }

.socialTemplate .mosaique .twitter .info {
  display: table;
  height: 100%;
  width: 100%; }
  .socialTemplate .mosaique .twitter .info > div {
    display: table-cell;
    vertical-align: middle; }

.socialTemplate .container {
  border-bottom: 0; }

.socialTemplate .more {
  float: none;
  text-align: center;
  clear: both;
  position: relative; }
  .socialTemplate .more:before {
    content: '';
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 37px;
    width: 90%;
    height: 1px;
    left: 5%; }

.socialTemplate .more p {
  margin: 0;
  height: 70px;
  padding-top: 16px; }

.socialTemplate .more a {
  display: inline-block;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #e1dede;
  background: #101010;
  padding: 13px 48px 13px;
  position: relative;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }
  .socialTemplate .more a.disabled, .socialTemplate .more a.disabled:hover, .socialTemplate .more a.disabled:focus {
    color: rgba(16, 16, 16, 0.5); }

.socialTemplate .more a:hover, .socialTemplate .more a:focus {
  color: #e59124; }

.socialTemplate .more a span {
  font-size: 11px;
  font-size: 1.1rem;
  display: inline-block;
  margin: 0 0 0 10px; }

.socialTemplate .loadMore {
  text-align: center;
  width: 100%;
  background: #101010;
  position: relative; }

/* ==========================================================================
    Green Initiative
    ========================================================================== */
.greenTemplate {
  padding: 0; }

.greenTemplate .container {
  position: relative;
  background: #f6f6f6; }

.greenTemplate .mosaique {
  background: #737020;
  -moz-transition-duration: 0.25s;
  -moz-transition-property: "all";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.25s;
  -webkit-transition-property: "all";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.25s;
  transition-property: "all";
  transition-delay: 0s;
  transition-timing-function: linear; }

.greenTemplate .mosaique div {
  padding: 0;
  position: relative;
  cursor: pointer; }

.greenTemplate .mosaique a.elmt {
  display: block;
  background: #101010;
  padding: 0;
  margin: 0; }

.greenTemplate .mosaique div .content {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 10;
  padding: 15px;
  background-color: #fff; }
  .greenTemplate .mosaique div .content.location {
    background-color: transparent; }
    .greenTemplate .mosaique div .content.location > *, .greenTemplate .mosaique div .content.location > .view, .greenTemplate .mosaique div .content.location > .view span {
      color: #fff; }
    .greenTemplate .mosaique div .content.location span.devider {
      background-color: #fff; }

.greenTemplate .mosaique div .content img {
  margin: 0 auto 12px;
  display: block; }

.greenTemplate .mosaique div .content h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 31px;
  font-size: 3.1rem;
  text-transform: uppercase;
  color: #101010;
  text-align: center;
  margin: 0 0 13px 0; }

.greenTemplate .mosaique div .content h4 {
  font-family: 'Signika', sans-serif;
  font-weight: 400;
  font-size: 25px;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #101010;
  text-align: center;
  margin: 0 0 13px 0; }

.greenTemplate .mosaique div .content span.devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 18px;
  display: block;
  background: #101010; }

.greenTemplate .mosaique div .content p {
  text-align: center;
  margin: 0; }

.greenTemplate .mosaique div .content > p.view {
  color: #101010;
  text-decoration: none;
  /*font-style:italic;*/
  font-weight: 700;
  font-family: 'Asap', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center; }

.greenTemplate .mosaique div .content > p.view span {
  font-size: 8px;
  font-size: 0.8rem;
  display: inline-block;
  margin: 0 0 0 5px; }

.greenTemplate .mosaique div .content > p a.social {
  color: #101010;
  font-size: 19px;
  font-size: 1.9rem;
  text-decoration: none;
  margin: 0 4px; }

.greenTemplate .mosaique div .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: #737020;
  -moz-transition-duration: 0.25s;
  -moz-transition-property: "all";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.25s;
  -webkit-transition-property: "all";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.25s;
  transition-property: "all";
  transition-delay: 0s;
  transition-timing-function: linear; }

.greenTemplate .mosaique a.elmt {
  background: #737020;
  display: block;
  padding: 0; }
  .greenTemplate .mosaique a.elmt .bgImg {
    position: relative; }
    .greenTemplate .mosaique a.elmt .bgImg:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.4;
      display: block; }
    .greenTemplate .mosaique a.elmt .bgImg:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(120, 113, 33, 0);
      -webkit-transition: 0.25s  all;
      transition: 0.25s  all; }
  .greenTemplate .mosaique a.elmt:hover .bgImg:after,
  .greenTemplate .mosaique a.elmt:focus .bgImg:after {
    background: rgba(120, 113, 33, 0.2); }

.greenTemplate .mosaique div a.elmt:hover .bg, .greenTemplate .mosaique div a.elmt:focus .bg {
  background: #5f5d1a; }

.greenTemplate .mosaique.lt-1024 {
  display: none; }
  .greenTemplate .mosaique.lt-1024 a.elmt {
    position: relative;
    display: block;
    background-size: cover; }
    .greenTemplate .mosaique.lt-1024 a.elmt div.content {
      position: relative;
      display: inline-block;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      top: auto;
      padding: 50px;
      min-width: 281px; }
      @media (max-width: 640px) {
        .greenTemplate .mosaique.lt-1024 a.elmt div.content {
          padding: 20px;
          min-width: 221px; } }

/* ==========================================================================
    Green Initiative Details
    ========================================================================== */
.greenDetailsTemplate {
  padding: 0; }

.greenDetailsTemplate .container {
  position: relative;
  background: #f6f6f6;
  border-bottom: 0px solid #101010; }

.greenDetailsTemplate .pointList {
  float: left;
  width: 100%;
  padding: 40px 0 80px 0; }

.greenDetailsTemplate .pointList li {
  padding: 0;
  text-align: center; }

.greenDetailsTemplate .pointList > div {
  margin: 55px auto 0;
  float: none; }

.greenDetailsTemplate .pointList > div > ol > li:nth-child(odd) {
  clear: left; }

.greenDetailsTemplate .pointList > .title {
  font-size: 22px;
  font-size: 2.2rem;
  font-family: 'Asap', sans-serif;
  font-weight: 600;
  padding: 0 20px;
  max-width: 660px;
  margin: 70px auto 0;
  text-align: center;
  letter-spacing: 1px;
  line-height: 26px; }

.greenDetailsTemplate .pointList > .sousTitle {
  font-size: 12px;
  font-size: 1.2rem;
  font-family: 'Asap', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 20px;
  max-width: 660px;
  margin: 45px auto 0;
  text-align: center;
  letter-spacing: 1px; }

.greenDetailsTemplate .pointList > h2,
.greenDetailsTemplate .pointList > h3,
.greenDetailsTemplate .pointList > h4,
.greenDetailsTemplate .pointList > h5 {
  text-align: center;
  max-width: 660px;
  margin: 20px auto 0; }
  .greenDetailsTemplate .pointList > h2 a img,
  .greenDetailsTemplate .pointList > h3 a img,
  .greenDetailsTemplate .pointList > h4 a img,
  .greenDetailsTemplate .pointList > h5 a img {
    margin-bottom: 20px;
    height: auto; }

.greenDetailsTemplate .pointList .point {
  display: inline-block;
  padding: 0 6%; }

.greenDetailsTemplate .pointList .point div {
  border-top: 1px solid #ccc7c6;
  padding: 60px 0 0 0;
  margin: 25px auto 0; }

.greenDetailsTemplate .pointList .point:nth-child(1) div {
  border: none; }

.greenDetailsTemplate .pointList .point span {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: #737020; }

.greenDetailsTemplate .pointList .point span i {
  position: relative;
  top: 50%;
  transform: translate(0px, -50%);
  display: block;
  font-family: 'Signika', sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 400;
  color: #fff; }

.greenDetailsTemplate .pointList .point p {
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 26px;
  width: 90%;
  margin: 15px auto 0; }

.greenDetailsTemplate .stickyMore {
  position: absolute;
  top: 305px;
  right: 200px;
  z-index: 100;
  padding: 0;
  text-align: right; }

.greenDetailsTemplate .stickyMore > div {
  width: 320px; }

.greenDetailsTemplate .stickyMore .seeAll {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Asap', sans-serif;
  color: #737020;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 1px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.greenDetailsTemplate .stickyMore .seeAll:hover, .greenDetailsTemplate .stickyMore .seeAll:focus {
  color: #101010; }

.greenDetailsTemplate .stickyMore .seeAll span {
  font-size: 10px;
  font-size: 1.0rem;
  display: inline-block;
  margin: 0 0 0 5px; }

/* ==========================================================================
    Friends
    ========================================================================== */
.friendsTemplate {
  padding: 0; }

.friendsTemplate .container {
  position: relative;
  background: #f6f6f6;
  border-bottom: 0px solid #101010; }

.friendsTemplate .friendList {
  float: left;
  width: 100%;
  padding: 80px 0; }

.friendsTemplate .friendList div {
  padding: 0;
  text-align: center; }

.friendsTemplate .friendList > div {
  margin: 55px auto 0;
  float: none; }

.friendsTemplate .friendList img {
  margin: 0 auto 30px;
  width: 100%;
  max-width: 740px;
  height: auto;
  float: none;
  display: block; }

.friendsTemplate .friendList h3, .friendsTemplate .content h2 {
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-size: 2.0rem;
  text-transform: uppercase;
  color: #101010;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 auto 15px;
  padding: 0px 20px; }

.friendsTemplate .content {
  margin-top: 0 !important; }

.friendsTemplate .content h2 {
  margin-bottom: 30px;
  position: relative; }
  .friendsTemplate .content h2:before {
    width: 30px;
    height: 2px;
    display: block;
    background: #ccc7c6;
    position: absolute;
    content: '';
    left: 50%;
    bottom: -17px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }

.friendsTemplate .friendList span.devider {
  width: 30px;
  height: 2px;
  margin: 0 auto 13px;
  display: block;
  background: #c3bdbc; }

.friendsTemplate .friendList p {
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-size: 1.4rem;
  color: #101010;
  line-height: 26px;
  padding: 0px 20px;
  max-width: 710px;
  margin: 0 auto;
  text-align: center; }

.friendsTemplate .friendList .friend {
  float: left;
  padding: 0 6%; }

.friendsTemplate .friendList .friend:nth-child(3n+1) {
  clear: left; }

.friendsTemplate .friendList .friend div {
  padding: 30px 0 0 0;
  margin: 25px auto 0; }

.friendsTemplate .friendList .friend h3 {
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #101010;
  text-transform: uppercase;
  margin: 0 auto 5px; }

.friendsTemplate .friendList .friend p {
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 26px;
  width: 90%;
  margin: 0 auto 5px; }

.friendsTemplate .friendList .friend a {
  color: #e59124;
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  margin: 0 auto;
  text-align: center;
  display: inline;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear;
  word-wrap: break-word; }

.friendsTemplate .friendList .friend a:hover, .friendsTemplate .friendList .friend a:focus {
  color: #101010; }

/* ==========================================================================
    Products
    ========================================================================== */
.productsTemplate {
  padding: 0; }

.productsTemplate .container {
  position: relative;
  background: #f6f6f6;
  border-bottom: 0px solid #101010; }

.productsTemplate .main {
  margin: 0 auto;
  padding: 60px 0 70px;
  text-align: center;
  float: none;
  width: 100%;
  max-width: 940px; }

.productsTemplate .main article {
  width: 100%;
  margin: 0 auto; }

.productsTemplate .main div {
  padding: 0;
  display: inline-block; }

.productsTemplate .main div img {
  margin: 0 auto;
  width: 100%;
  height: auto;
  display: block; }

.productsTemplate .main div h5 {
  text-align: left;
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #e59124;
  text-transform: uppercase;
  margin: 10px 0 20px; }

.productsTemplate .main div h2 {
  color: #272727;
  font-size: 36px;
  font-size: 3.6rem;
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  text-align: center;
  line-height: 44px;
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 10px;
  padding-right: 20px;
  letter-spacing: 2px; }

.productsTemplate .main div h6 {
  text-align: left;
  margin: 0 0 27px 0; }

.productsTemplate .main div p {
  text-align: left; }

.productsTemplate .otherProduct {
  text-align: center;
  padding: 50px 0 60px 0;
  background: #ebeaea; }

.productsTemplate .otherProduct h2 {
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-size: 2rem;
  color: #101010;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px 0; }

.productsTemplate .otherProduct .devider {
  width: 30px;
  height: 2px;
  margin: 0 auto -25px;
  display: block;
  background: #ccc7c6; }

.productsTemplate.productsTemplate.productsTemplate article {
  vertical-align: top;
  display: inline-block;
  padding-bottom: 30px; }

.productsTemplate article img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 0 18px 0;
  background: #f6f6f6;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "opacity";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "opacity";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "opacity";
  transition-delay: 0s;
  transition-timing-function: linear; }

.productsTemplate .listingHolder.listingHolder.listingHolder article {
  padding-bottom: 50px; }

.productsTemplate .listingHolder article img {
  max-height: 275px;
  max-width: 100%;
  width: auto;
  display: block;
  margin: 0 auto 18px auto;
  background: #f6f6f6;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "opacity";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "opacity";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "opacity";
  transition-delay: 0s;
  transition-timing-function: linear; }

.productsTemplate .otherProduct article img:hover, .productsTemplate .otherProduct article img:focus {
  filter: alpha(opacity=60);
  opacity: 0.6; }

.productsTemplate article time {
  font-family: 'Asap', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0 0 18px 0;
  color: #000007;
  margin: 0 0 18px 0;
  display: block;
  letter-spacing: 1px; }

.productsTemplate article time span {
  text-transform: uppercase; }

.productsTemplate article h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  margin: 0 0 13px 0;
  text-transform: uppercase; }

.productsTemplate article h3 a {
  color: #000007;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.productsTemplate article h3 a:hover, .productsTemplate article h3 a:focus {
  color: #e59124; }

.productsTemplate article h6 {
  font-size: 12px;
  font-size: 1.2rem;
  font-family: 'Asap', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #101010; }

.productsTemplate article .ctaHolder a {
  color: #e59124;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.productsTemplate article .ctaHolder a:hover, .productsTemplate article .ctaHolder a:focus {
  color: #101010; }

.productsTemplate article .ctaHolder a.btn {
  background: #e59124;
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  /*font-style:italic;*/
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #fff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 8px 20px 8px 20px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "background";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "background";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "background";
  transition-delay: 0s;
  transition-timing-function: linear; }

.productsTemplate article .ctaHolder a.btn:hover, .productsTemplate article .ctaHolder a.btn:focus {
  background: #bf7617; }

.productsTemplate .more {
  border-bottom: 1px solid #ccc7c6;
  margin: 50px auto 10px;
  float: none;
  text-align: center;
  clear: both; }

.productsTemplate .more p {
  margin: 0 0 -9px 0; }

.productsTemplate .more a {
  font-family: 'Asap', sans-serif;
  font-weight: 700;
  /*font-style:italic;*/
  font-size: 14px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #101010;
  background: #ebeaea;
  padding: 10px 48px;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.productsTemplate .more a:hover, .productsTemplate .more a:focus {
  color: #e59124; }

.productsTemplate .more a span {
  font-size: 11px;
  font-size: 1.1rem;
  display: inline-block;
  margin: 0 0 0 10px; }

/* ==========================================================================
    FAQ
    ========================================================================== */
.faqTemplate {
  padding: 0; }

.faqTemplate .container {
  position: relative;
  background: #e1dede;
  border-bottom: 0px solid #101010; }

.faqTemplate .faq {
  margin: 0 auto;
  float: none;
  padding: 10px 0 65px 0; }

.faqTemplate .faq h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-size: 2rem;
  text-transform: uppercase;
  color: #101010;
  letter-spacing: 1px;
  text-align: center;
  margin: 62px auto 0;
  padding: 0 0 20px 0;
  background: url(../img/layout/iconDevider.png) center bottom no-repeat; }

.faqTemplate .faq p {
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 26px;
  text-align: center;
  width: 700px;
  margin: 20px auto 0;
  color: #101010; }

.faqTemplate .faq p a {
  color: #e59124;
  text-decoration: none;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.faqTemplate .faq p a:hover, .faqTemplate .faq p a:focus {
  color: #101010;
  text-decoration: underline; }

/* ==========================================================================
    Contact
    ========================================================================== */
.contactTemplate {
  padding: 0; }

.contactTemplate .container {
  position: relative;
  background: #f6f6f6; }

.contactTemplate .contactInfo {
  margin: 0 auto;
  float: none;
  padding: 40px 0 55px 0; }

.contactTemplate .contactInfo div.section {
  border-top: 1px solid #ccc7c6;
  padding: 45px 0 0 0;
  margin: 0 0 40px 0; }

.contactTemplate .contactInfo div.section:nth-child(1) {
  border: none; }

.contactTemplate .contactInfo h2 {
  font-family: 'Asap', sans-serif;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #101010;
  letter-spacing: 1px;
  text-align: center;
  margin: 20px auto 0;
  padding: 0px 30px; }

.contactTemplate .contactInfo h3 {
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 20px;
  font-size: 2rem;
  text-transform: uppercase;
  color: #101010;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 auto 0;
  padding: 0 0 20px 0;
  padding-left: 30px;
  padding-right: 30px;
  background: url(../img/layout/iconDevider.png) center bottom no-repeat; }

.contactTemplate .contactInfo p {
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 26px;
  text-align: center;
  max-width: 700px;
  padding: 0 20px;
  margin: 20px auto 0;
  color: #101010; }

.contactTemplate .contactInfo p a {
  color: #e59124;
  text-decoration: underline;
  -moz-transition-duration: 0.3s;
  -moz-transition-property: "color";
  -moz-transition-delay: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: "color";
  -webkit-transition-delay: 0s;
  -webkit-transition-timing-function: linear;
  transition-duration: 0.3s;
  transition-property: "color";
  transition-delay: 0s;
  transition-timing-function: linear; }

.contactTemplate .contactInfo p a:hover, .contactTemplate .contactInfo p a:focus {
  color: #101010;
  text-decoration: none; }

/* ==========================================================================
    404
    ========================================================================== */
.pageErrorTemplate {
  padding: 0; }

.pageErrorTemplate .container {
  position: relative;
  background: #f6f6f6;
  border-bottom: 0px solid #101010; }

.pageErrorTemplate .errorMessage {
  margin: 0 auto;
  float: none;
  padding: 80px 0 55px 0;
  min-height: 340px; }

.pageErrorTemplate .errorMessage h2 {
  font-family: 'Asap', sans-serif;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #101010;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 auto; }

/* ==========================================================================
    Helper classes
    ========================================================================== */
.hideText {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px; }

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

.cover-center {
  background-size: cover;
  background-position: center center; }

.bgFixed {
  background-attachment: fixed; }
  .mobileOnly .bgFixed {
    background-attachment: scroll; }

/* Typo
=================================================================== */
.main_content.main_content.main_content {
  font-size: 16px;
  font-size: 1.6rem;
  overflow: hidden; }
  .main_content.main_content.main_content h2 {
    font-family: 'Asap', sans-serif;
    font-weight: 600;
    font-size: 20px;
    font-size: 2rem;
    color: #101010;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 15px 0;
    padding-bottom: 20px;
    position: relative; }
    .bundle3 .main_content.main_content.main_content h2 {
      font-family: 'Signika', sans-serif; }
    .main_content.main_content.main_content h2:before {
      width: 30px;
      height: 2px;
      display: block;
      background: #ccc7c6;
      position: absolute;
      content: '';
      left: 50%;
      bottom: 0;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%); }
  .main_content.main_content.main_content p {
    font-size: 16px;
    font-size: 1.6rem;
    color: #101010; }
    .main_content.main_content.main_content p a:hover,
    .main_content.main_content.main_content p a:focus {
      color: #101010; }
  .main_content.main_content.main_content ul, .main_content.main_content.main_content ol {
    display: inline-block;
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 26px;
    margin-bottom: 25px; }
    .main_content.main_content.main_content ul li, .main_content.main_content.main_content ol li {
      list-style: none;
      position: relative;
      padding-left: 10px; }
      .main_content.main_content.main_content ul li:before, .main_content.main_content.main_content ol li:before {
        position: absolute;
        content: '';
        left: 0;
        top: 10px;
        height: 5px;
        width: 5px;
        border-radius: 100%;
        background: #101010; }
  .main_content.main_content.main_content ol {
    content-reset: ol; }
    .main_content.main_content.main_content ol li {
      counter-increment: ol;
      padding-left: 20px; }
    .main_content.main_content.main_content ol li:before {
      background: transparent;
      top: 0;
      content: counter(ol);
      color: #e59124; }
  .main_content.main_content.main_content h1 {
    text-transform: uppercase; }
  .main_content.main_content.main_content h4 {
    font-size: 16px;
    font-size: 1.6rem;
    text-transform: uppercase; }

/* Forms
=================================================================== */
.formTemplate section.success {
  background: #e1dede;
  padding: 25px;
  margin-bottom: 35px; }
  .formTemplate section.success h2 {
    padding: 0;
    margin: 0;
    position: relative; }
    .formTemplate section.success h2:before {
      content: '';
      position: absolute;
      border-radius: 100%;
      background: #bdb6b6;
      width: 40px;
      height: 40px;
      top: -9px;
      left: 25px; }
    .formTemplate section.success h2:after {
      -webkit-transition: width 0.25s;
      transition: width 0.25s;
      width: 40px;
      height: 40px;
      left: 6px;
      top: -9px;
      position: absolute;
      content: '';
      background: url(../img/layout/checkbox.png) 12px center no-repeat; }
    @media all and (max-width: 640px) {
      .formTemplate section.success h2 {
        padding-top: 40px; }
        .formTemplate section.success h2:before {
          left: 50%; }
        .formTemplate section.success h2:after {
          left: 50%;
          margin-left: -20px; } }

.formTemplate .main {
  width: 635px; }
  .bundle1 .formTemplate .main.success {
    width: 696px; }
  @media all and (max-width: 640px) {
    .formTemplate .main {
      width: 100% !important; } }

.formTemplate .dropdown-toggle.selectpicker {
  background: #e1dede;
  box-shadow: none; }
  .formTemplate .dropdown-toggle.selectpicker span {
    color: #101010; }

.formTemplate .open .dropdown-toggle.selectpicker {
  background: #d5d1d1; }

.formTemplate .dropdown-menu.inner.selectpicker {
  text-align: left; }
  .formTemplate .dropdown-menu.inner.selectpicker li {
    background: #d5d1d1; }
    .formTemplate .dropdown-menu.inner.selectpicker li a {
      color: #101010;
      padding-left: 15px; }

.formTemplate .bootstrap-select.btn-group .btn .filter-option.filter-option.filter-option.filter-option {
  font-style: normal;
  font-family: 'Signika', sans-serif;
  letter-spacing: normal;
  text-overflow: ellipsis;
  font-size: 13px;
  font-size: 1.3rem; }

.formTemplate .dropdown-menu.inner.selectpicker span.text.text {
  font-family: 'Signika', sans-serif; }

.formTemplate input, .formTemplate textarea {
  background: #e1dede;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 13px 16px;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif;
  color: #101010; }

.formTemplate textarea {
  height: 100px; }

.formTemplate input {
  height: 44px; }

.formTemplate textarea::-webkit-input-placeholder {
  color: #666;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif; }

.formTemplate textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #666;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif; }

.formTemplate textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #666;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif; }

.formTemplate textarea:-ms-input-placeholder {
  color: #666;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif; }

.formTemplate input::-webkit-input-placeholder {
  color: #666;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif; }

.formTemplate input:-moz-placeholder {
  /* Firefox 18- */
  color: #666;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif; }

.formTemplate input::-moz-placeholder {
  /* Firefox 19+ */
  color: #666;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif; }

.formTemplate input:-ms-input-placeholder {
  color: #666;
  font-weight: bold;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: 'Signika', sans-serif; }

.formTemplate .checkbox_holder {
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem; }
  .formTemplate .checkbox_holder .checkbox {
    padding: 0;
    margin: 0  0 10px; }
  .formTemplate .checkbox_holder input {
    position: absolute;
    left: -9999px; }
  .formTemplate .checkbox_holder label, .formTemplate .checkbox_holder label * {
    vertical-align: middle; }
  .formTemplate .checkbox_holder label {
    display: block;
    text-align: left;
    cursor: default; }
  .formTemplate .checkbox_holder label div {
    cursor: pointer;
    margin-right: 15px;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #e1dede;
    position: relative; }
    .formTemplate .checkbox_holder label div:before {
      -webkit-transition: width 0.25s;
      transition: width 0.25s;
      width: 0%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      content: '';
      background: url(../img/layout/checkbox.png) 12px center no-repeat; }
  .formTemplate .checkbox_holder label span {
    font-family: 'Signika', sans-serif;
    font-weight: 600;
    font-size: 15px;
    font-size: 1.5rem; }
  .formTemplate .checkbox_holder input:checked + label div:before {
    width: 100%; }

.formTemplate .question h4, .formTemplate .intro h4 {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 15px; }

.formTemplate .intro {
  margin-bottom: 58px;
  position: relative; }
  .formTemplate .intro:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    display: block;
    background: #ccc7c6;
    left: 50%;
    margin-left: -15px;
    bottom: -27px; }

.formTemplate input:focus, .formTemplate textarea:focus {
  box-shadow: none; }

.formTemplate button {
  display: block;
  margin: 0 auto;
  border: none; }
  .formTemplate button:hover, .formTemplate button:focus {
    background: #d5d1d1; }

.btn-group.open .dropdown-toggle {
  box-shadow: none; }

/* SplashScreen
=================================================================== */
#bodyId_splash footer {
  border-top: none; }

#bodyId_splash .fullheight {
  background-size: cover;
  background-position: center center;
  position: relative; }
  #bodyId_splash .fullheight:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent !important; }
  #bodyId_splash .fullheight ul {
    margin: 0;
    padding: 0;
    height: 100%; }
    #bodyId_splash .fullheight ul li {
      display: block;
      height: 100%; }
      #bodyId_splash .fullheight ul li a {
        display: block;
        height: 100%;
        text-align: center;
        position: relative;
        background: transparent;
        transition: all 0.25s;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
        #bodyId_splash .fullheight ul li a:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: url(../img/layout/fadeSplash.png) no-repeat center center; }
        #bodyId_splash .fullheight ul li a .holder {
          width: 100%;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -mos-transform: translateY(-50%);
          transform: translateY(-50%); }
          #bodyId_splash .fullheight ul li a .holder h1 {
            font-size: 50px;
            font-size: 5rem;
            font-family: 'Signika', sans-serif;
            font-weight: 600;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin: 0 0 10px 0;
            max-width: 590px;
            margin: 0 auto 7px;
            position: relative;
            text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); }
            #bodyId_splash .fullheight ul li a .holder h1:before {
              width: 30px;
              height: 2px;
              content: '';
              position: absolute;
              background: white;
              bottom: -19px;
              left: 50%;
              margin-left: -15px; }
          #bodyId_splash .fullheight ul li a .holder address {
            font-family: 'Asap', sans-serif;
            text-transform: uppercase;
            color: #fff;
            letter-spacing: 1px;
            max-width: 590px;
            margin: 0 auto 10px;
            display: inline-block;
            padding: 9px 19px 8px 19px;
            background: black;
            transition: all 0.25s;
            margin-top: 35px;
            font-size: 12px;
            font-size: 1.2rem; }
            #bodyId_splash .fullheight ul li a .holder address i {
              font-size: 10px;
              font-size: 1rem;
              position: relative;
              left: 6px; }
          #bodyId_splash .fullheight ul li a .holder span {
            font-family: 'Asap', sans-serif;
            font-size: 15px;
            font-size: 1.5rem;
            font-weight: 700;
            color: #f6f6f6;
            text-align: center;
            margin: 17px auto 0;
            max-width: 740px;
            letter-spacing: 2px;
            max-width: 590px;
            margin: 0 auto; }
            #bodyId_splash .fullheight ul li a .holder span i {
              font-size: 11px;
              font-size: 1.1rem;
              margin-left: 5px; }
        #bodyId_splash .fullheight ul li a:hover, #bodyId_splash .fullheight ul li a:focus {
          background: rgba(0, 0, 0, 0.3); }
          #bodyId_splash .fullheight ul li a:hover address, #bodyId_splash .fullheight ul li a:focus address {
            background: white;
            color: black; }
      #bodyId_splash .fullheight ul li:last-child a {
        border-bottom: 1px solid transparent; }

/* ==========================================================================
   Print styles
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links for images, or javascript/internal links
     */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

/* ==========================================================================
   Responsive styles
   ========================================================================== */
.mobileDeviceOnly {
  display: none !important; }

@media all and (max-width: 1765px) {
  [class*="listing"], .listing_Holder {
    width: 1508px; }
    [class*="listing"] article, .listing_Holder article {
      width: 400px;
      margin-left: 50px;
      margin-right: 50px; } }

@media all and (max-width: 1650px) {
  .newsDetailsTemplate .stickySocial {
    left: 78px;
    width: auto; }
  .newsDetailsTemplate .stickyMore {
    width: auto;
    right: 78px; } }

@media all and (max-width: 1590px) {
  .newsTemplate .upcoming article {
    max-width: 30%; }
  [class*="listing"], .listing_Holder {
    width: 1358px; }
    [class*="listing"] article, .listing_Holder article {
      margin-left: 50px;
      margin-right: 50px;
      width: 350px; } }

@media all and (max-width: 1500px) {
  .newsDetailsTemplate .stickySocial {
    left: 38px; }
  .newsDetailsTemplate .stickyMore {
    right: 38px; }
  .dinningTemplate nav {
    margin-left: -41px; } }

@media all and (max-width: 1400px) {
  .socialTemplate .mosaique > div.instagram .info {
    bottom: -300px;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); }
    .socialTemplate .mosaique > div.instagram .info .content-holder {
      position: absolute;
      width: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
      padding: 25px; }
      .socialTemplate .mosaique > div.instagram .info .content-holder div, .socialTemplate .mosaique > div.instagram .info .content-holder h3, .socialTemplate .mosaique > div.instagram .info .content-holder h4, .socialTemplate .mosaique > div.instagram .info .content-holder span {
        display: inline-block;
        position: static; }
      .socialTemplate .mosaique > div.instagram .info .content-holder .profilePic {
        height: 50px;
        width: 50px;
        position: static;
        margin: 0 auto; }
      .socialTemplate .mosaique > div.instagram .info .content-holder span {
        margin-top: 15px; }
      .socialTemplate .mosaique > div.instagram .info .content-holder .user {
        position: static;
        padding: 0;
        line-height: 18px; } }

@media all and (max-width: 1375px) {
  [class*="listing"], .listing_Holder {
    width: 1148px; }
    [class*="listing"] article, .listing_Holder article {
      margin-left: 25px;
      margin-right: 25px;
      width: 330px; }
  .greenTemplate .mosaique div .content h3 {
    font-size: 27px;
    font-size: 2.7rem; }
  .greenTemplate .mosaique div .content h4 {
    font-size: 21px;
    font-size: 2.1rem; } }

@media all and (max-width: 1320px) {
  .dinningTemplate .categ .room .slider, .dinningTemplate .categ .room .noSlider, [id*="carousel_"] {
    max-width: 690px; }
    .dinningTemplate .categ .room .slider img, .dinningTemplate .categ .room .noSlider img, [id*="carousel_"] img {
      width: 100%; }
  [id*="carousel_"] {
    margin: 0 auto; } }

@media all and (max-width: 1300px) {
  .foodCateg .col-lg-3 {
    width: 16%; }
  .socialTemplate .mosaique > div.twitter p {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.3em;
    width: 230px; }
  .socialTemplate .mosaique > div.twitter .profilePic {
    height: 50px;
    width: 50px; }
  .homeTemplate .mosaique div .content h3 {
    font-size: 24px;
    font-size: 2.4rem; }
  .menuTemplate nav, .dinningTemplate nav {
    margin-left: 0px;
    padding-left: 0px; }
  .reservation .container {
    max-width: 964px; }
  .reservation .txt {
    margin-bottom: 0px;
    margin-top: 50px;
    max-width: 750px;
    width: 100%; }
  .reservation .txt p {
    display: inline-block;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 0px;
    vertical-align: top; }
  .reservation form::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    clear: both; }
  .reservation form {
    clear: both;
    float: left;
    margin-top: 0px;
    padding-bottom: 40px; }
  .locationTemplate .locationHolder {
    width: 75%; } }

@media all and (max-width: 1220px) {
  .newsDetailsTemplate .stickySocial, .newsDetailsTemplate .stickySocial div,
  .newsDetailsTemplate .stickyMore,
  .newsDetailsTemplate .stickyMore div {
    width: 115px; }
  .newsDetailsTemplate .stickySocial p,
  .newsDetailsTemplate .stickyMore p {
    margin-bottom: 10px; }
  .newsDetailsTemplate .stickySocial p + a,
  .newsDetailsTemplate .stickyMore p + a {
    margin-left: -6px; }
  .newsDetailsTemplate .stickyMore .mobilecut {
    display: none; } }

@media all and (max-width: 1200px) {
  .dinningTemplate .categ.categ > div {
    padding-left: 45px; }
  .greenTemplate .mosaique.gt-1024 div .content h3 {
    font-size: 22px;
    font-size: 2.2rem; }
  .greenTemplate .mosaique.gt-1024 div .content h4 {
    font-size: 16px;
    font-size: 1.6rem; }
  .greenTemplate .mosaique.gt-1024 div .content > p.view {
    font-size: 13px;
    font-size: 1.3rem; } }

@media all and (max-width: 1180px) {
  .homeTemplate .news .container {
    text-align: center; }
  .homeTemplate .news article {
    margin-left: 25px !important;
    margin-right: 25px !important;
    width: 288px; }
  [class*="listing"], .listing_Holder {
    width: auto;
    text-align: center; }
    [class*="listing"] article, .listing_Holder article {
      width: 288px; }
  .homeTemplate .bx_caption h2 a, #bodyId_splash .fullheight ul li a .holder h1 {
    font-size: 38px;
    font-size: 3.8rem; } }

@media all and (max-width: 1100px) {
  .menuTemplate .foodCateg .categ dt, .dinningTemplate .categ .menu p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 18px; }
  .dinningTemplate .categ .room .slider, .dinningTemplate .categ .room .noSlider, [id*="carousel_"] {
    max-width: 580px; }
    .dinningTemplate .categ .room .slider img, .dinningTemplate .categ .room .noSlider img, [id*="carousel_"] img {
      width: 100%; }
  .dinningTemplate .room .intro {
    max-width: 580px;
    margin: 0 auto; }
  .dinningTemplate .categ.categ > div {
    padding-left: 68px; }
  .dinningTemplate .categ.categ > .inquire {
    padding-left: 0; }
  .dinningTemplate .categ .menu > .col-md-8 div:last-child {
    padding-right: 4%; }
  [class*="listing"] article, .listing_Holder article {
    margin-left: 25px;
    margin-right: 25px; } }

@media all and (max-width: 1024px) {
  .dinningTemplate .banner .txt p {
    max-width: 700px;
    margin: 0 auto; }
  .greenTemplate .mosaique.gt-1024 {
    display: none; }
  .greenTemplate .mosaique.lt-1024 {
    display: block; }
    .greenTemplate .mosaique.lt-1024 > div {
      width: 100% !important; }
  .iPad .bootstrap-select.btn-group .btn .caret {
    margin-top: -6px; } }

.foodCateg .col-sm-8 {
  float: left; }

@media all and (min-width: 768px) {
  .greenDetailsTemplate .pointList .point:nth-child(1) div, .greenDetailsTemplate .pointList .point:nth-child(2) div {
    padding: 0;
    border: none; }
  .locationTemplate .locationHolder {
    width: auto; } }

@media screen and (max-width: 640px) {
  .defaultTemplate .main article img {
    max-width: 100%; }
  .greenTemplate .banner .txt h1 {
    font-size: 19px !important;
    font-size: 1.9rem !important; }
  .greenTemplate .banner .txt h2 {
    font-size: 17px;
    font-size: 1.7rem; }
  .homeTemplate .bx_caption h2 a {
    font-size: 24px;
    font-size: 2.4rem;
    display: inline-block; }
  #friendsHolder {
    margin-top: 0; }
  .friendList .content h2 {
    margin-bottom: 0; }
  .friendsTemplate .friendList .friend:nth-child(2) div {
    padding: 30px 0 0 0;
    margin: 25px auto 0; }
  .newsTemplate .upcoming article {
    max-width: none; }
  .friendsTemplate .friendList .friend.fakeBorder, .greenDetailsTemplate .fakeBorder {
    display: none; }
  .locationTemplate .locationHolder {
    min-width: 0;
    width: 100%; }
  [class*="listing"], .listing_Holder {
    width: 100%;
    text-align: center;
    margin-bottom: 25px; }
    [class*="listing"] article, .listing_Holder article {
      margin-left: 50px;
      margin-right: 50px; }
  .homeTemplate .news article {
    margin-left: auto !important;
    margin-right: auto !important; }
  .greenDetailsTemplate .stickyMore {
    top: auto;
    right: 0;
    width: 100%; }
    .greenDetailsTemplate .stickyMore div {
      width: 100%;
      text-align: center; }
      .greenDetailsTemplate .stickyMore div a {
        margin-top: 24px;
        display: inline-block; }
  .greenDetailsTemplate .pointList > div {
    margin-top: -55px; }
  .newsDetailsTemplate .main {
    padding-bottom: 0px;
    padding-top: 30px; }
  .newsDetailsTemplate .main article {
    width: 100%;
    max-width: none; }
    .newsDetailsTemplate .main article img {
      max-width: 100%; }
  .newsDetailsTemplate .main article p {
    padding-left: 30px;
    padding-right: 30px; }
  .newsDetailsTemplate .stickySocial {
    position: static;
    margin: 25px 0 20px; }
    .newsDetailsTemplate .stickySocial, .newsDetailsTemplate .stickySocial div {
      width: 100%; }
    .newsDetailsTemplate .stickySocial p {
      margin-bottom: 0px; }
    .newsDetailsTemplate .stickySocial p + a {
      margin-left: 10px; }
  .newsDetailsTemplate .stickySocial, .newsDetailsTemplate .stickyMore {
    display: block;
    top: auto;
    left: auto;
    right: auto;
    text-align: center; }
    .newsDetailsTemplate .stickySocial, .newsDetailsTemplate .stickySocial div, .newsDetailsTemplate .stickyMore, .newsDetailsTemplate .stickyMore div {
      width: 100%; }
  .newsDetailsTemplate .stickyMore {
    position: static; }
    .newsDetailsTemplate .stickyMore .mobilecut {
      display: inline; }
  .newsTemplate .oldNews {
    padding-top: 45px; }
  .newsTemplate article {
    padding-bottom: 35px; }
  .newsDetailsTemplate article {
    width: 100%;
    max-width: 425px;
    float: none;
    padding-bottom: 25px;
    margin-bottom: 0px;
    margin-top: 0px; }
  .newsDetailsTemplate .oldNews > h3 {
    padding-bottom: 45px; }
  .homeTemplate .bx_caption h2 {
    font-size: 28px;
    font-size: 2.8rem; }
  .homeTemplate .news article p {
    min-height: 0px !important; }
  .foodCateg .col-sm-8 {
    width: auto;
    float: none; }
  .splashPage .restaurant.pull-left .dropdown {
    font-size: 0px;
    color: transparent; }
  .mobileDeviceOnly {
    display: block !important; }
  .contactTemplate .contactInfo p {
    max-width: 425px; }
  html, body {
    min-width: 320px; }
  #wpadminbar {
    position: fixed !important;
    top: 0; }
  .homeTemplate .news .container article {
    width: 100%; }
  .home .mosaique {
    display: none; }
  .dinningTemplate .stickyMenu {
    display: none; }
  .dinningTemplate .room {
    padding: 0; }
    .dinningTemplate .room .intro {
      padding: 0 15px; }
    .dinningTemplate .room img {
      width: 100%;
      height: auto; }
  .dinningTemplate .categ.menu .menu {
    padding-left: 0;
    padding-right: 0; }
    .dinningTemplate .categ.menu .menu p {
      font-size: 14px;
      font-size: 1.4rem; }
  .dinningTemplate .categ.menu .price {
    position: static;
    text-align: center;
    margin-top: -10px;
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 10px; }
  .dinningTemplate .categ.menu {
    padding-bottom: 0; }
  .dinningTemplate .categ.categ > div h3 {
    font-size: 20px;
    font-size: 2rem; }
  .dinningTemplate .categ.categ > div p {
    font-size: 16px;
    font-size: 1.6rem; }
  .bx_pager {
    bottom: 10px; }
    .bx_pager li a {
      width: 26px; }
  .homeTemplate .news .container article {
    margin: 10px 0 0; }
    .homeTemplate .news .container article.mobileHide {
      display: none !important; }
  .newsletter .bg {
    display: none; }
  .newsletter .content {
    top: 0;
    bottom: 0; }
  .newsletter .left, .newsletter .right {
    float: none;
    width: 100%; }
  .newsletter .requiredField {
    width: 100%; }
  .newsletter .requiredField.half {
    font-size: 0;
    width: 45%; }
    .newsletter .requiredField.half.first {
      width: 50%; }
    .newsletter .requiredField.half label {
      margin: 10px 0 9px 0; }
    .newsletter .requiredField.half .field {
      margin: 0;
      display: block;
      float: none; }
      .newsletter .requiredField.half .field.select {
        margin: 8px 0 45px 0; }
  .newsletter .requiredField.half input {
    width: 100%; }
  .homeTemplate .news .container {
    padding-bottom: 25px;
    padding-top: 25px; }
  .reservation {
    max-height: 100%;
    overflow: auto; }
  .listingHolder article {
    padding-bottom: 60px;
    margin-bottom: 30px; }
    .listingHolder article:last-child {
      margin-bottom: 0px; }
  .dinningTemplate .categ .menu.menu > .col-md-8 {
    border: none; }
  .dinningTemplate .categ .menu.special {
    border: 2px solid #ccc7c6;
    padding-bottom: 0;
    margin-bottom: 25px; }
    .dinningTemplate .categ .menu.special div.col-md-8 {
      padding-bottom: 0; }
  .newsDetailsTemplate .title time {
    padding: 0 10px; }
  .homeTemplate .news .container .seeAll {
    position: static;
    font-size: 11px;
    font-size: 1.1rem; }
  .homeTemplate .news .container .seeAll span {
    margin-left: -3px; }
  .bx_inner > .item > img,
  .bx_inner > .item > a > img {
    max-width: 300%;
    transform: translateX(-33%);
    -webkit-transform: translateX(-33%); }
  .homeTemplate .carousel-indicators li {
    width: 26px;
    height: 2px;
    margin: 0px 1px; }
  .homeTemplate .carousel-indicators li:hover,
  .homeTemplate .carousel-indicators li:focus,
  .homeTemplate .carousel-indicators li.active {
    margin: 0px 1px; }
  .homeTemplate .bx_caption span.devider {
    width: 15px;
    height: 1px; }
  .homeTemplate .news .container h2 {
    margin-bottom: 0px;
    font-size: 24px;
    font-size: 2.4rem; }
  li.dropdown > a span, div.dropdown > a span {
    margin-right: 10px; }
  .dropdown-menu {
    top: auto; }
  .homeTemplate .news .container article.listing p a.btn {
    margin-top: 8px; }
  .homeTemplate .news .container article h3 {
    font-size: 16px;
    font-size: 1.6rem; }
  .menuTemplate .stickyMenu {
    width: 100%;
    top: auto;
    left: 50%;
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    right: 0px;
    position: fixed !important;
    bottom: 0px; }
    .menuTemplate .stickyMenu nav {
      padding-bottom: 0px; }
      .menuTemplate .stickyMenu nav ul {
        border-bottom: 1px solid #ccc7c6; }
      .menuTemplate .stickyMenu nav ul li {
        border: none;
        position: relative; }
        .menuTemplate .stickyMenu nav ul li:before {
          border: none;
          display: block;
          content: '' !important;
          position: absolute;
          width: 100%;
          background: #ccc7c6;
          height: 1px;
          left: 0; }
    .menuTemplate .stickyMenu .download {
      margin-top: 0; }
  .menuTemplate .stickyMenu.floating.top {
    position: fixed;
    top: auto; }
  .menuTemplate nav {
    float: none;
    padding: 0px;
    padding-bottom: 20px; }
  .menuTemplate .categ.menu {
    width: 100%;
    margin-left: 0; }
    .menuTemplate .categ.menu .subMenu {
      width: 100%; }
  .dinningTemplate nav ul,
  .menuTemplate nav ul {
    width: auto; }
  .dinningTemplate nav ul li.active:before, .menuTemplate nav ul li.active:before, .dinningTemplate nav ul li:hover:before, .menuTemplate nav ul li:hover:before, .dinningTemplate nav ul li:focus:before, .menuTemplate nav ul li:focus:before {
    content: none; }
  .stickyMenuMobileToggle {
    display: block;
    height: 40px;
    width: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    bottom: 100%;
    background: #000;
    text-align: left;
    line-height: 40px;
    color: #FFF;
    padding-left: 31px;
    text-transform: uppercase;
    font-family: 'Asap', sans-serif;
    font-size: 11px;
    font-size: 1.1rem;
    font-weight: bold; }
  .stickyMenuMobileToggle .icon-up-open {
    float: right;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-transition: transform 0.25s;
    /* For Safari 3.1 to 6.0 */
    transition: transform 0.25s; }
  .menuTemplate .stickyMenu.open .icon-up-open {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .menuTemplate .stickyMenu nav ul ul {
    display: none; }
  .menuTemplate nav .download {
    width: auto; }
  .dinningTemplate nav ul li:before, .menuTemplate nav ul li:before {
    display: none; }
  .menuTemplate .foodCateg .categ {
    width: 80%;
    margin: 0 auto;
    float: none; }
  .menuTemplate .foodCateg .categ .sectionDesc.sectionDesc {
    margin-bottom: 35px; }
  .menuTemplate .foodCateg .categ > .subMenu.subMenu.subMenu dl dt {
    margin: 0 0 20px 0; }
  .menuTemplate .foodCateg .categ dt {
    width: 90%; }
  .menuTemplate .foodCateg .categ dd {
    width: 10%;
    padding-left: 10px; }
  .menuTemplate .foodCateg .categ dl {
    margin: 0px; }
  .menuTemplate .foodCateg .categ .devider {
    margin-bottom: 30px; }
  .menuTemplate .banner .txt h2 {
    margin: 0px 30px 10px 30px;
    font-size: 4px;
    font-size: 4rem; }
  .locationTemplate .info table td:nth-child(2) {
    width: 100%; }
  .locationTemplate .info > div > div {
    padding: 50px 20px 10px 20px;
    max-width: 425px;
    margin: 0 auto; }
  .defaultTemplate .main article {
    max-width: 425px;
    padding: 0px 20px; }
  .locationTemplate #google-map {
    height: 170px; }
  .mosaique > div {
    width: 50% !important; }
  .newsTemplate .main article img {
    width: 100%;
    margin-top: 35px; }
  .newsTemplate .main {
    padding-top: 50px;
    padding-bottom: 0px; }
  .newsTemplate .main article p {
    width: auto; }
  .newsTemplate .oldNews {
    padding-bottom: 5px; }
  .newsTemplate .upcoming article {
    margin: 0 auto;
    margin-top: 40px;
    display: none; }
    .newsTemplate .upcoming article:first-child {
      display: inline-block;
      width: 100%; }
  .newsTemplate .upcoming h1 + span + article {
    display: inline-block !important;
    float: none;
    width: 300px; }
  .reservation {
    min-width: 0px; }
  .reservation .txt {
    padding: 20px;
    margin: 0px; }
  .reservation .close {
    top: 12px;
    right: 20px; }
  .reservation .txt p {
    display: block;
    width: auto;
    padding-top: 20px; }
  .reservation form {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 25px; }
  .reservation label {
    margin-top: 10px; }
  .reservation .reserve {
    margin-top: 20px;
    clear: both;
    margin-left: 0px;
    width: 100%;
    font-size: 16px;
    font-size: 1.6rem; }
  .bootstrap-select .filter-option,
  .reservation select,
  .reservation input {
    border-radius: 0px;
    font-size: 16px;
    font-size: 1.6rem; }
  .reservation input[type=date] {
    -webkit-appearance: none;
    width: 100%;
    display: block;
    font-family: 'Asap', sans-serif;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    color: #F6F6F6; }
  .newsletter .content {
    max-width: 100%;
    padding: 60px 20px; }
  .newsletter .content input {
    margin: 0px 0px 10px 0px !important;
    width: 100%;
    font-size: 16px;
    font-size: 1.6rem; }
  .newsletter .content .field.select {
    margin: 15px 0 45px 0; }
  .newsletter .content .field {
    margin-bottom: 10px; }
  .newsletter .content .btn {
    width: 100%;
    font-size: 16px;
    font-size: 1.6rem; }
  .newsletter .content p.txt a {
    white-space: nowrap; }
  .newsletter .left {
    position: relative; }
  .newsletter .requiredField.emailField {
    position: absolute;
    top: 106px; }
  .newsletter .requiredField.lastNameField {
    margin-bottom: 50px; }
  .productsTemplate article {
    margin-left: 0px;
    margin-right: 0px; }
  .productsTemplate .main div p {
    padding-right: 30px; }
  .productsTemplate .main {
    padding-bottom: 30px; }
  .productsTemplate .otherProduct article {
    width: 100%;
    margin-top: 30px; }
  .dinningTemplate .categ.categ > div {
    padding: 45px; }
  .dinningTemplate .categ.categ > .room {
    padding-left: 0px;
    padding-right: 0px; }
  .homeTemplate .bx_inner > div {
    height: 320px; }
    .homeTemplate .bx_inner > div img {
      display: none; }
  .productsTemplate .main div img {
    width: auto;
    max-width: 83%; }
  .productsTemplate .main article > div:last-child {
    text-align: center;
    padding: 0px 20px; }
    .productsTemplate .main article > div:last-child h2 {
      margin-top: 10px;
      padding: 0;
      font-size: 22px;
      font-size: 2.2rem;
      line-height: 27px; }
    .productsTemplate .main article > div:last-child p {
      padding: 0; }
    .productsTemplate .main article > div:last-child * {
      text-align: center; }
  .dinningTemplate .categ.categ > .inquire {
    padding-left: 20px;
    padding-right: 20px; }
  #bodyId_splash address.mobileHide {
    display: none; } }

@media all and (max-width: 640px) {
  .admin-bar header {
    top: 45px; } }

@media screen and (max-width: 525px) {
  .homeTemplate .bx_caption .btn {
    font-size: 11px;
    font-size: 1.1rem;
    white-space: normal; }
  .locationTemplate .info address {
    width: 280px; }
  .newsletter .requiredField.half.first {
    width: 47%; }
  [class*="listing"] article, .listing_Holder article {
    width: 320px;
    margin-left: 0px;
    margin-right: 0px; }
    [class*="listing"] article h3, [class*="listing"] article p, .listing_Holder article h3, .listing_Holder article p {
      padding: 0 20px; }
  .mosaique > div {
    width: 100% !important; }
  .productsTemplate .main .col-xs-6 {
    width: 100%; }
  .productsTemplate .main div img {
    width: 100%; }
  .productsTemplate .main article {
    padding: 0px 30px; }
  .dinningTemplate .categ .menu p {
    line-height: 16px; }
  .dinningTemplate .categ.categ > div {
    padding-left: 10px;
    padding-right: 10px; }
    .dinningTemplate .categ.categ > div h3 {
      font-size: 20px;
      font-size: 2rem; }
    .dinningTemplate .categ.categ > div p {
      font-size: 16px;
      font-size: 1.6rem; }
  .locationTemplate #google-map {
    height: 320px; }
  #bodyId_splash .fullheight .holder h1, #bodyId_splash .fullheight .holder address, #bodyId_splash .fullheight .holder span {
    max-width: 100%;
    padding: 0px 20px; } }

body {
  position: relative; }

header {
  min-width: 100%;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0; }
  .admin-bar header {
    top: 32px; }
  header .site-title,
  header .trigger {
    z-index: 2; }
  header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    opacity: 0;
    visibility: hidden; }
  header .btn.btn.btn {
    display: inline-block;
    padding: 0 20px;
    line-height: 50px;
    margin-bottom: 14px;
    text-align: center;
    border: 0;
    vertical-align: middle;
    transition: background-color 0.3s ease, color 0.3s ease; }
    header .btn.btn.btn .icon {
      fill: currentColor;
      width: 20px;
      max-height: 20px;
      display: inline-block;
      vertical-align: middle; }
    header .btn.btn.btn span {
      line-height: 1;
      display: inline-block;
      letter-spacing: 0.1em;
      margin-left: 12px;
      vertical-align: baseline; }
  header .site-title {
    display: inline-block;
    order: 2;
    position: absolute;
    top: 0;
    left: 100px;
    box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(-100px); }
    header .site-title a {
      display: flex;
      width: 100px;
      height: 100px;
      justify-content: center;
      align-items: center;
      transition: none;
      padding: 10px; }
  header .trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    padding: 0;
    border: none;
    transform: translateY(-100px);
    cursor: pointer;
    box-shadow: -20px 0 40px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    align-items: center; }
    header .trigger:active {
      outline: 0; }
    header .trigger__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      flex-direction: column; }
    header .trigger span {
      width: 40px;
      height: 2px;
      background-color: currentColor;
      display: block;
      margin: 4px 0;
      will-change: transform; }
  header .reservations {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
    transform: translateY(-60px); }
    header .reservations .btn.btn.btn {
      padding-left: 28px;
      padding-right: 28px;
      margin-bottom: 0; }
  @media (max-width: 992px) {
    header .trigger,
    header .site-title > a {
      width: 60px;
      height: 60px; }
    header .trigger__wrapper {
      transform: scale(0.8); }
    header .site-title {
      left: 60px; }
      header .site-title img {
        max-width: 44px;
        width: 100%; }
    header .reservations > .btn.btn.btn {
      width: 140px;
      height: 60px;
      line-height: 60px;
      padding: 0; }
      header .reservations > .btn.btn.btn .icon {
        width: 23px;
        max-height: 23px;
        display: none; }
      header .reservations > .btn.btn.btn span {
        margin: 0; } }
  @media (max-width: 767px) {
    header .overlay {
      display: none; } }
  @media (max-width: 640px) {
    .admin-bar header {
      top: 45px; } }

footer {
  min-width: 100%; }
  footer ul.social {
    padding-top: 14px;
    padding-bottom: 14px;
    margin-left: -6px;
    order: 2; }
    footer ul.social > li {
      font-size: 0;
      display: inline-block;
      margin: 0 6px;
      vertical-align: middle; }
      footer ul.social > li:last-child {
        margin-right: 0; }
  footer ul.menu {
    display: inline-block;
    columns: 2;
    overflow: hidden; }
  footer .bnb-footer {
    padding: 56px 0; }
    footer .bnb-footer .container-grid {
      display: flex;
      justify-content: space-between; }
    footer .bnb-footer address {
      margin: 1em 0;
      line-height: norma; }
    footer .bnb-footer .address {
      margin-right: 30px;
      width: 100%;
      max-width: 295px;
      min-width: 180px; }
  footer .nav-menu-footer {
    width: 100%;
    margin-right: 30px; }
    footer .nav-menu-footer li {
      margin-bottom: 30px;
      margin-right: 30px; }
      footer .nav-menu-footer li > a {
        display: inline-block; }
  footer .newsletter__group {
    max-width: 40%;
    min-width: 300px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    footer .newsletter__group input {
      width: calc(100% - 30px);
      color: currentColor;
      line-height: 40px;
      letter-spacing: 0.1em; }
      footer .newsletter__group input[name=newsletter]::-webkit-input-placeholder {
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.3); }
      footer .newsletter__group input[name=newsletter]:-moz-placeholder {
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.3); }
      footer .newsletter__group input[name=newsletter]::-moz-placeholder {
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.3); }
      footer .newsletter__group input[name=newsletter]:-ms-input-placeholder {
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.3); }
    footer .newsletter__group button:hover, footer .newsletter__group button:focus {
      background: transparent; }
    footer .newsletter__group button .icon {
      margin-right: -6px; }
    footer .newsletter__group form {
      flex-wrap: wrap;
      display: flex;
      border-bottom: 3px solid currentColor;
      justify-content: space-between;
      width: 100%;
      margin-right: 30px;
      max-width: 295px; }
      footer .newsletter__group form label {
        margin-bottom: 1.5em;
        display: block;
        width: 100%; }
    footer .newsletter__group .logo {
      width: 70px; }
      footer .newsletter__group .logo > a {
        display: inline-block; }
        footer .newsletter__group .logo > a img {
          min-width: 70px; }
  footer .colophon .container-grid {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  footer .colophon .copyright {
    order: 1; }
  @media (max-width: 991px) {
    footer .container-grid {
      padding-left: 60px;
      padding-right: 60px; }
    footer .bnb-footer .nav-menu-footer {
      min-width: auto; } }
  @media (max-width: 1024px) {
    footer .container-grid {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (max-width: 768px) {
    footer .bnb-footer .container-grid {
      flex-direction: column; }
    footer .bnb-footer .address,
    footer .bnb-footer .newsletter__group,
    footer .bnb-footer .nav-menu-footer {
      max-width: none; }
    footer .bnb-footer .newsletter__group {
      margin-top: 40px;
      min-width: auto;
      display: block; }
      footer .bnb-footer .newsletter__group .logo {
        margin-top: 30px; }
    footer .bnb-footer .nav-menu-footer {
      margin-right: 0;
      margin-top: 20px;
      min-width: auto; } }
  @media (max-width: 640px) {
    footer .container-grid {
      padding-left: 30px;
      padding-right: 30px; }
    footer .colophon {
      padding-bottom: 70px; }
      footer .colophon .container-grid {
        height: auto;
        flex-direction: column; }
    footer ul.social {
      width: 100%;
      order: 1;
      text-align: center; } }

/* VIEWS
=================================================================== */
.home .buffer {
  width: 100%;
  background-color: #fff;
  opacity: .3;
  min-height: 100vh;
  position: relative;
  z-index: -2; }

@media (max-width: 1280px) {
  .home .grid .grid__wrapper {
    display: block; } }

.home .popUpAlert {
  position: fixed;
  bottom: -500px;
  width: 86%;
  max-width: 1440px;
  left: 50%;
  transform: translateX(-50%);
  padding: 45px 5%;
  z-index: 100;
  background: #f6f6f6;
  transition: bottom 0.8s ease; }
  .home .popUpAlert.open {
    bottom: 0px; }
  @media screen and (max-width: 640px) {
    .home .popUpAlert {
      width: 100%; } }
  .home .popUpAlert .btnClose {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #101010;
    width: 16px;
    height: 14px;
    font-size: 16px;
    cursor: pointer;
    transition: opacity 0.3s ease; }
    .home .popUpAlert .btnClose i.icomoon-chevron-right {
      position: absolute;
      left: -5px;
      top: 0; }
    .home .popUpAlert .btnClose i.icomoon-chevron-left {
      position: absolute;
      right: 0;
      top: 0; }
    .home .popUpAlert .btnClose:hover, .home .popUpAlert .btnClose:focus {
      opacity: 0.5; }
  .home .popUpAlert h2 {
    width: 30%;
    float: left;
    margin: 0;
    padding: 0 6% 0 0;
    color: #101010;
    font-size: 40px;
    line-height: 46px; }
    @media screen and (max-width: 1280px) {
      .home .popUpAlert h2 {
        font-size: 30px;
        line-height: 34px;
        width: 40%; } }
  @media screen and (max-width: 1280px) and (max-width: 640px) {
    .home .popUpAlert h2 {
      font-size: 20px;
      line-height: 22px;
      margin: 0 0 20px;
      width: 100%;
      padding: 0; } }
  .home .popUpAlert .main_content {
    width: 70%;
    float: left;
    margin: 0;
    padding: 0 0 0 6%; }
    @media screen and (max-width: 1280px) {
      .home .popUpAlert .main_content {
        width: 60%; } }
  @media screen and (max-width: 1280px) and (max-width: 640px) {
    .home .popUpAlert .main_content {
      width: 100%;
      padding: 0; } }

.post-type-archive-newsevents .news__featured {
  max-height: 700px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 120px 0;
  overflow: hidden; }
  .post-type-archive-newsevents .news__featured .container-grid {
    max-width: 1440px; }

.post-type-archive-newsevents .news__bg {
  position: absolute;
  top: -12.5%;
  left: -12.5%;
  width: 125%;
  height: 125%; }
  .post-type-archive-newsevents .news__bg::before {
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1); }
  .post-type-archive-newsevents .news__bg img {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
    height: 100%;
    display: block; }

.post-type-archive-newsevents .news__item.featured {
  max-width: 620px;
  margin: 0;
  padding: 0; }

.post-type-archive-newsevents .news__item .list__content {
  width: 100%;
  max-width: 620px;
  margin: 0 !important; }

@media (max-width: 991px) {
  .post-type-archive-newsevents .news__featured .container-grid {
    padding-left: 60px;
    padding-right: 60px; }
  .post-type-archive-newsevents .news__item.featured {
    width: auto;
    max-width: none; } }

@media (max-width: 640px) {
  .post-type-archive-newsevents .news__featured {
    height: 100%;
    max-height: none;
    min-height: 0;
    padding: 40px 0; }
    .post-type-archive-newsevents .news__featured .container-grid {
      padding: 30px 30px; } }
