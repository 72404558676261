//Ratio
@function gcd($a,$b){
	@if $b == 0{
		@return $a;
	}@else{
		@return gcd($b,$a % $b);
	}
}

@function getRatio($w,$h){
	$r:gcd($w,$h);
	@return "#{$w / $r}/#{$h/$r}";
}

@mixin keepRatio($ratio:'1/1'){
	// Usage @include keepRatio('16/9')
	// content must be wrapped with a .wrapper class.
	// edit the mixin to change the class if needed.
	// $_ratio:toString($ratio);
	$ratio1:str-slice($ratio,1,(str-index($ratio,'/') - 1));
	$ratio2:str-slice($ratio,(str-index($ratio,'/') + 1));
	position: relative;
	&:before{
		content:'';
		display: block;
		padding-top: to-number($ratio2) * 100 / to-number($ratio1) * 1%;
	}
	.wrapper{
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}
}

//Helper

@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    $_: log('Value for `to-number` should be a number or a string.');
  }
  
  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);
  
  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);
    
    @if not (index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i))
    }
    
    @if $character == '.' {
      $digits: 1; 
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);  
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }
  
  @return if($minus, -$result, $result);;
}