$nbCols:12;
$gutter:100px;
$containerGutter:$gutter;
$colPrefix:"col-grid";
$suffix:"-grid";
$query:(
    mb: 0, // Mobile
    cb: 320, // Mobile
    bmb: 640, // Mobile
    tb: 768, // Tablet
    btb:992, // mid Tablet/Laptop
    lt: 1440, // Laptop
    dt: 1920, // Desktop
);

// Coming from bootstrap
// $query:(
//  xs: 0,
//  sm: 768,
//  md: 992,
//  lg: 1200
// );
// $container:(
//  xs: 100%,
//  sm: 750px,
//  md: 970px,
//  lg: 1170px
// )

$custom-cols:(
    5th: (100%/5)
);
$container:(
    mb: 100%, // Mobile
    bd: 100%, // Mobile
    bmb: 100%,
    tb: 100%, // Tablet
    btb: 100%, // Tablet
    lt: 100%, // Laptop
    dt: 1920px, // Desktop
);

// Helpers
@function breakpoint($key){
    @if not map-has-key($query, $key){
        @warn "Breakpoint #{$key} not found.";
    }
    @return map-get($query, $key);
}

@mixin create-col($args){
    padding:0 ($gutter / 2);
    margin-bottom:$gutter;
    float:left;
    position: relative;
    min-height: 1px;
    width:100%;
    @each $key, $value in $args{
        @media all and (min-width:#{breakpoint($key)}px){
            $percent: (($value) * 100 / $nbCols);
            width: ($percent * 1%);
        }
    }
}
// Usage
// $cols:(
//     bmb:12,
//     tb:6,
//     lt:3,
//     dt:4
// );
// .whatever{
//     @include create-col($cols);
// }