
/* FONT-FAMILIES
=================================================================== */

    /* mainFont( $package );
    =================================================================== */
    footer .copyright,
    footer .bnb-footer address,
    .banner .txt p,
    .intro .txt p,
    .list__type,
    .experiences__desc,
    .block__desc,
    .list__excerpt,
    .reservation .txt p,
    {
        @include mainFont( $package );
    }

    /* secondFont( $package )
    =================================================================== */
    footer .bnb-footer h3,
    .nav-menu-footer li a,
    footer .newsletter__group form input,
    .banner .txt h1,
    .intro .txt h1,
    .menuTemplate .foodCateg .banner .txt h2,
    .locationTemplate .intro .txt h1,
    .greenDetailsTemplate .intro .txt p,
    .greenTemplate .intro .txt h2,
    header .btn.btn.btn,
    .nav-menu-header li,
    .locations_cards .location,
    .list__title,
    .list__button,
    .list__load,
    .hero__title,
    .hero__scroll,
    .instagram__title,
    .instagram__link,
    .experiences__title,
    .block__title,
    .block__button,
    body .btn.btn.btn.btn.btn.btn.btn,
    .reservation .txt > div, h1,h2,h3,h4,h5,h6
    {
        @include secondFont( $package )
    }

