
/* SUPPORT CLASSES
=================================================================== */
.visuallyHidden {
     border: 0; 
     clip: rect(0 0 0 0); 
     height: 1px; 
     width: 1px; 
     margin: -1px; 
     padding: 0;
     white-space: nowrap;
     overflow: hidden; 
     position: absolute;
}
.resetVisuallyHidden {
    overflow:visible;
    margin: auto;
    height:auto;
    width:auto;
    clip :auto;
    white-space: normal;
}

.hidden-sr
{
    position: absolute;
    left:-9999px;
    height: 1px;
    width: 1px;
    overflow: hidden;
    margin: -1px;
}



/* RESET
=================================================================== */
    /* RESET PADDINGS + MARGINS
    =================================================================== */
    %reset-pm
    {
        margin:0;
        padding:0;
    }

    /* RESET UL + OL
    =================================================================== */
    %reset-ul-ol
    {
        margin:0;
        padding:0;

        list-style:none;
    }

    /* RESET BUTTON
    =================================================================== */
    %reset-button
    {
        padding:0;
        margin:0;
        // outline:0;
        border:0;
        background:none;
    }