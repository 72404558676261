@import '../utils/mixins/fonts';
@import '../common/variables';

.home
{
    .buffer
    {
        width:100%;
        background-color: #fff;
        opacity:.3;
        min-height: 100vh;
        position: relative;
        z-index:-2;

    }
    .grid{
        .grid__wrapper{
            @media (max-width: 1280px){
                display:block;
            }
        }
    }
    > .wrapper
    {
        // margin-top:calc(100% + 100px);
        // margin-top:100%;
    }
    #main
    {
        // min-height:100vh;
    }
    .popUpAlert{
        position:fixed;
        bottom:-500px;
        width:86%;
        max-width:1440px;
        left:50%;
        transform:translateX(-50%);
        padding:45px 5%;
        z-index:100;
        background:$mainColor;
        transition: bottom 0.8s ease;
        &.open{
            bottom:0px;
        }
        @media screen and (max-width: 640px) {
            width:100%;
        }
        .btnClose{
            position:absolute;
            top:20px;
            right:20px;
            color:$secondColor;
            width:16px;
            height:14px;
            font-size:16px;
            cursor:pointer;
            transition: opacity $duration ease;
            i.icomoon-chevron-right{
                position:absolute;
                left:-5px;
                top:0;
            }
            i.icomoon-chevron-left{
                position:absolute;
                right:0;
                top:0;
            }
            &:hover, &:focus{
                opacity:0.5;
            }
        }
        h2{
            width:30%;
            float:left;
            margin:0;
            padding:0 6% 0 0;
            color:$secondColor;
            font-size:40px;
            line-height:46px;
            @media screen and (max-width: 1280px) {
                font-size:30px;
                line-height:34px;
                width:40%;
                @media screen and (max-width: 640px) {
                    font-size:20px;
                    line-height:22px;
                    margin:0 0 20px;
                    width:100%;
                    padding:0;
                }
            }
        }
        .main_content{
            width:70%;
            float:left;
            margin:0;
            padding:0 0 0 6%;
            @media screen and (max-width: 1280px) {
                width:60%;
                @media screen and (max-width: 640px) {
                    width:100%;
                    padding:0;
                }
            }
        }
    }
}