.clearfix{
    &:before,&:after{
        display:table;
        content:'';
    }
    &:after{
        clear:both;
    }
}
*, *:before, *:after {
    box-sizing: border-box;
}