

/* COLORS
=================================================================== */
    /* $greenColor = #737020
    =================================================================== */
    /* $backgroundColor = #fff
    =================================================================== */
    footer .copyright,
    footer .social a,
    footer .bnb-footer,
    footer .bnb-footer a,
    footer .newsletter__group form,
    .defaultTemplate.noFeatured .banner .txt h1,
    .greenTemplate .banner .txt,
    .menuTemplate .foodCateg .banner .txt h2,
    .greenTemplate .banner .txt p a,
    header .btn.btn.btn,
    .navigation__nav li a,
    .locations_cards,
    header .trigger,
    .list__button,
    .list__load:hover,
    .list__load:focus,
    .hero,
    .has-hover .block__item:hover .block__txt,
    .has-hover .block__item:focus .block__txt,
    {
        color: $backgroundColor;
    }
        /* $backgroundColor, 30% opacity
        =================================================================== */
        .has-hover footer .social a:hover,
        .has-hover footer .social a:focus,
        .has-hover footer .bnb-footer a:hover,
        .has-hover footer .bnb-footer a:focus,
        .has-hover footer .newsletter__group button:hover,
        .has-hover footer .newsletter__group button:focus,

        // .navigation__nav.hover li > a:not(:hover),
        .navigation__nav.hover li > a:not(:hover):not(:focus),
        {
            color: rgba($backgroundColor, .3);
        }
    /* black = #000
    =================================================================== */
    .defaultTemplate .banner .txt h1 span,
    .intro .txt h1 span,
    .dinningTemplate .banner .txt h1 span,
    .menuTemplate .foodCateg .banner .txt h2 span,
    .newsDetailsTemplate .title .txt h1 span,

    {
        color: #000;
    }
    /* #f6f6f6
    =================================================================== */
    .banner .txt p,
    .intro .txt p,
    {
        color: #f6f6f6;
    }

    /* grey = #333
    =================================================================== */
    .list__item,
    .list__item:hover,
    .list__item:focus,
    .list__item > a,
    .list__item > a:hover,
    .list__item > a:focus,
    .instagram__header,
    .instagram__header a,
    .experiences__title,
    .experiences__desc,
    .block__txt,
    {
        color: #333;
    }
    .list__load,
    {
        color: rgba(#333, .75);
        // color: mix(#333, #000, .25);
    }


/* BACKGROUND-COLORS
=================================================================== */
    /* $greenColor = #737020
    =================================================================== */
    .greenDetailsTemplate .intro,
    {
        background-color: $greenColor;
    }
    /* $backgroundColor = #fff
    =================================================================== */
    .intro .txt h1 span,
    .newsDetailsTemplate .title .txt h1 span,
    .defaultTemplate .banner .txt h1 span,
    .banner .txt span.devider,
    .intro .txt span.devider,
    .contactTemplate .intro .txt h1 span,
    .site-title,
    .dinningTemplate .banner .txt h1 span,
    .list__content,
    .menuTemplate .foodCateg .banner .txt h2 span,
    .home .experiences,
    .home .restaurant,
    .home .grid,
    {
        background-color: $backgroundColor;
    }
    /* #f6f6f6
    =================================================================== */
    /* #404040
    =================================================================== */
    .socialTemplate .intro .txt span.devider,
    {
        background-color: #404040;
    }
    /* $newBackground = #f2f1e7;
    =================================================================== */
    .news__list,
    section.instagram,
    // .block__txt,
    .block__bg,
    {
        background-color: $newBackground;
    }
        /* $newBackground + 93% grey
        =================================================================== */
        .list__load,
        {
             background-color: mix( $newBackground, #333, 93% );
        }
    /* grey = #333
    =================================================================== */
        