footer
{
    min-width:100%;

    &:not(.new)
    // &.new
    {
        // display: none;
    }

    p,
    h3,
    {
        @extend %reset-pm;
    }

    ul
    {
        @extend %reset-ul-ol;

        &.social
        {
            padding-top:14px;
            padding-bottom:14px;
            margin-left:-6px;
            order:2;

            > li
            {
                font-size:0;
                display: inline-block;
                margin:0 6px;
                vertical-align: middle;

                &:last-child
                {
                    margin-right:0;
                }
            }
        }

        &.menu
        {
            display: inline-block;
            columns:2;
            overflow:hidden;
        }
    }

    .bnb-footer
    {
        padding:56px 0;

        .container-grid
        {
            display: flex;
            justify-content: space-between;
        }

        address
        {
            margin:1em 0;
            line-height:norma;
        }

        .address
        {
            margin-right:30px;
            width:100%;
            max-width:295px;
            min-width:180px;
        }

    }

    .nav-menu-footer
    {
        width:100%;
        // max-width:45%;
        // min-width:420px;
        margin-right:30px;

        // columns:2;

        li,
        {
            margin-bottom:30px;
            margin-right:30px;
            // break-inside:avoid;

            > a
            {
                display: inline-block;
            }
        }
    }

    .newsletter__group
    {
        // max-width:50%;
        max-width:40%;
        min-width:300px;
        width:100%;
        // min-width:calc(100% - 420px - 295px);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        input, button,
        {
            @extend %reset-button;
        }

        input,
        {
            width:calc(100% - 30px);
            color:currentColor;
            line-height:40px;
            letter-spacing: tracking(100);

            &[name=newsletter]::-webkit-input-placeholder,
            {
                text-transform: uppercase;
                color: rgba($backgroundColor, .3);
            }
            &[name=newsletter]:-moz-placeholder,
            {
                text-transform: uppercase;
                color: rgba($backgroundColor, .3);
            }
            &[name=newsletter]::-moz-placeholder,
            {
                text-transform: uppercase;
                color: rgba($backgroundColor, .3);
            }
            &[name=newsletter]:-ms-input-placeholder,
            {
                text-transform: uppercase;
                color: rgba($backgroundColor, .3);
            }
        }
        button
        {
            &:hover,
            &:focus,
            {
                background:transparent;
            }

            .icon
            {
                margin-right:-6px;
            }
        }

        form
        {
            flex-wrap:wrap;
            display: flex;
            border-bottom:3px solid currentColor;
            justify-content: space-between;

            width:100%;
            margin-right:30px;
            max-width:295px;

            label
            {
                margin-bottom:1.5em;
                display: block;
                width:100%;
            }
        }


        .logo
        {
            width:70px;
            > a
            {
                display: inline-block;

                img
                {
                    min-width:70px;
                }
            }
        }
    }


    .colophon,
    {
        .container-grid
        {
            height:60px;
            display: flex;
            align-items:center;
            justify-content: space-between
        }
        .copyright
        {
            order:1;
        }
    }


    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        .container-grid
        {
            padding-left:60px;
            padding-right:60px;
        }
        .bnb-footer
        {
            .nav-menu-footer
            {
                min-width:auto;
            }
        }
    }

    @media (max-width: 1024px)
    {
        .bnb-footer
        {
        }
        .container-grid
        {
            padding-left:30px;
            padding-right:30px;
        }
    }
    
    @media (max-width: #{breakpoint(tb)}px)
    {
        .bnb-footer
        {

            .container-grid
            {
                flex-direction:column;
            }


            .address,
            .newsletter__group,
            .nav-menu-footer,
            {
                max-width:none;
            }


            .newsletter__group,
            {
                margin-top:40px;
                min-width:auto;
                display: block;

                .logo
                {
                    margin-top:30px;
                }
            }


            .nav-menu-footer
            {
                margin-right:0;
                margin-top:20px;
                min-width:auto;
            }
        }
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        .container-grid
        {
            padding-left:30px;
            padding-right:30px;
        }

        .colophon
        {
            padding-bottom:70px;

            .container-grid
            {
                height:auto;
                flex-direction:column;
            }
        }
        ul.social
        {
            width:100%;
            order:1;
            text-align:center;
        }

        
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}

