.grid
{
    padding:80px 0;

    .container-grid
    {
        max-width:1440px;
    }


    &__wrapper
    {
        // margin-top:100px;
        display: flex;
        margin-left:-10px;
        margin-right:-10px;

        justify-content: center;
    }





    // MEDIA QUERIES
    @media (max-width: #{breakpoint(dt)}px)
    {
    }
    
    @media (max-width: #{breakpoint(lt)}px)
    {
    }

    @media (max-width: 1024px)
    {
        &__wrapper
        {
            display: block;
        }
    }
    
    @media (max-width: #{breakpoint(btb) - 1}px)
    {
        padding:60px 0;
        .container-grid
        {
            padding-left:60px;
            padding-right:60px;
        }

        &__wrapper
        {
            margin-top:60px;
        }
    }
    
    @media (max-width: #{breakpoint(tb)}px)
    {
    }
    
    @media (max-width: #{breakpoint(bmb)}px)
    {
        padding:30px 0;
        .container-grid
        {
            padding-left:30px;
            padding-right:30px;
        }
        &__wrapper
        {
            margin-top:30px;
        }
    }
    
    @media (max-width: #{breakpoint(cb)}px)
    {
    }
}